interface PropsI {
  fill: string;
}

export const RocketIcon = ({ fill }: PropsI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="37"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15.5475 13.4519L9.30583 19.6935M9.97943 15.1637L6.92217 14.4415C6.58581 14.3621 6.46751 13.9445 6.71118 13.6999L9.14251 11.2686C9.30583 11.1053 9.52742 11.0117 9.75961 11.01L12.5008 10.9861M15.9536 7.8494C17.9938 9.24075 19.7586 11.0055 21.1499 13.0458M13.8348 19.0199L14.5569 22.0772C14.6364 22.4135 15.054 22.5318 15.2985 22.2882L17.7298 19.8568C17.8932 19.6935 17.9867 19.4719 17.9885 19.2397L18.0124 16.4985M21.6735 11.6112L22.3868 8.1787C22.581 7.24466 21.7547 6.41832 20.8207 6.61255L17.3882 7.32588C16.3773 7.53599 15.4504 8.03656 14.7211 8.76667L11.9049 11.582C10.8305 12.6564 10.1313 14.0496 9.91057 15.553L9.90086 15.6175C9.76137 16.5771 10.0827 17.5456 10.7678 18.2315C11.4529 18.9166 12.4222 19.238 13.3819 19.0976L13.4463 19.0879C14.9498 18.8681 16.3429 18.168 17.4173 17.0936L20.2327 14.2782C20.9628 13.549 21.4634 12.622 21.6735 11.6112Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
