import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { useAppDispatch } from '../../../hooks';
import { ApplicantI } from '../../../api/types/applicants';

import { ISelectOfferReq, ISendOfferTypeReq } from '../../../api/types/offer';
import {
  useFinalizeApplicationMutation,
  useSelectOfferMutation,
  useSendOffersByTypeMutation,
} from '../../../api/offer';
import { setMessage } from '../../../reducers/common/commonSlice';
import {
  useLazyCheckOfferStatusesQuery,
  useLazyGetApplicationQuery,
  useRefreshOfferStatusesMutation,
} from '../../../api/applicants';
import { isApiError } from '../../../utils/general';
import './index.scss';

interface Props {
  applicationData?: ApplicantI;
  renewApplication: any;
}

export default function useViewApplicationController({
  applicationData,
  renewApplication,
}: Props) {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >('email');
  const [isOfferSelected, setIsOfferSelected] = useState<boolean>(false);
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(false);
  const [openItem, setOpenItem] = useState<number | null>(null);
  const [selectOption, setSelectOption] = useState<number | null>(null);

  const [getApplication, { isError, error }] = useLazyGetApplicationQuery();

  const [sendOffersByType] = useSendOffersByTypeMutation();
  const [finalizeApplication] = useFinalizeApplicationMutation();
  const [
    sendSelectedOffer,
    {
      data: selectedOfferData,
      isLoading: isSelectedOfferLoading,
      isSuccess: isSelectedOfferSuccess,
    },
  ] = useSelectOfferMutation();
  const navigate = useNavigate();

  const [refreshOfferStatus, { isSuccess: refreshSuccess }] =
    useRefreshOfferStatusesMutation();
  const [checkOffersStatus, { data: checkData }] =
    useLazyCheckOfferStatusesQuery();

  const ISSENDBTN =
    applicationData?.data?.application_status === 'Awaiting Signature' &&
    (applicationData?.data?.offers?.[0]?.banks?.[0]?.key === 'genesis' ||
      applicationData?.data?.offers?.[0]?.banks?.[0]?.key === 'okinus');

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const options = [
    { value: 'By Phone', label: 'phone' },
    { value: 'By Email', label: 'email' },
  ];

  const handleChange = (value: string | number | undefined) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (isSelectedOfferSuccess) {
      setIsOfferSelected(true);
    }
  }, [isSelectedOfferSuccess]);

  const getStatus = (status: string) => {
    switch (status) {
      case 'Failed':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );
      case 'Refunded':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );

      case 'No Offer':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );
      case 'Declined':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );

      case 'In Progress':
        return (
          <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
            {status}
          </p>
        );

      case 'Awaiting Signature':
        return (
          <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
            {status}
          </p>
        );

      case 'Withdrawn':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );

      case 'Timed Out':
        return (
          <p className="ViewApplication__status-label ViewApplication__red-status-label">
            {status}
          </p>
        );
      case 'Pending':
        return (
          <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
            {status}
          </p>
        );

      default:
        return (
          <p className="ViewApplication__status-label ViewApplication__green-status-label">
            {status}
          </p>
        );
    }
  };

  const getOffersLabel = (
    applicationStatus: string,
    transactionStatus: string
  ) => {
    if (
      applicationStatus === 'Offer Made' &&
      transactionStatus === 'In Progress'
    ) {
      return 'Available offers';
    } else if (
      applicationStatus === 'Offer Made' &&
      transactionStatus === null
    ) {
      return 'Withdrawn offers';
    } else if (
      applicationStatus === 'Offer Made' &&
      transactionStatus === 'Timed Out'
    ) {
      return 'Withdrawn offers';
    } else {
      return 'Offer information';
    }
  };

  const offersLabel =
    applicationData?.data.application_status &&
    getOffersLabel(
      applicationData?.data.application_status,
      applicationData?.data.transaction_status
    );

  const refreshStatus = () => {
    refreshOfferStatus(applicationData?.data.id);
  };

  useEffect(() => {
    if (refreshSuccess) {
      setIsChecking(true);
      checkOffersStatus(applicationData?.data.id);
      const intervalId = setInterval(() => {
        checkOffersStatus(applicationData?.data.id);
      }, 10000);
      if (checkData?.key === 'done') {
        renewApplication();
        setIsChecking(false);
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }
  }, [refreshSuccess, checkData]);

  const offersChecked = checkData?.key === 'done';

  const isAvailableOffers = offersLabel === 'Available offers';

  const handleSendOffersByType = async (applicantId: number) => {
    try {
      const data: ISendOfferTypeReq = {
        applicant_id: applicantId,
        type: {
          type: selectedValue,
        },
      };
      if (ISSENDBTN) {
        await finalizeApplication(data);
      } else {
        await sendOffersByType(data);
      }
      ISSENDBTN
        ? dispatch(setMessage('Link has been successfully sent!'))
        : dispatch(setMessage('Offers list has been successfully sent!'));
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Send offer failed');
    }
  };

  const handleOfferSend = async (applicantId: number, offerId: number) => {
    try {
      const data: ISelectOfferReq = {
        applicant_id: applicantId,
        offer: {
          offer: {
            id: offerId,
          },
        },
      };
      await sendSelectedOffer(data);
      await getApplication(location.pathname.split('/')[3]);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Selected offer failed');
    }
  };

  return {
    selectedValue,
    navigate,
    options,
    handleChange,
    getStatus,
    handleSendOffersByType,
    offersLabel,
    isAvailableOffers,
    selectedOfferData,
    isSelectedOfferLoading,
    isSelectedOfferSuccess,
    isOfferSelected,
    setIsOfferSelected,
    isError,
    error,
    contextHolder,
    messageApi,
    openItem,
    selectOption,
    setOpenItem,
    setSelectOption,
    refreshStatus,
    offersChecked,
    isChecking,
    ISSENDBTN,
  };
}
