import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import './index.scss';
import { Spinner } from '../Spinner/Spinner';
import { useGetTicketsCategoryListQuery } from '../../api/tickets';
import { CustomSelect } from '../../customFields/CustomSelect';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  maskClosable?: boolean;
  className?: string;
  category: string | number | undefined;
  setCategory: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  changeCategory: () => void;
  loading: boolean;
}

export const ChangeTicketCategoryModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  maskClosable = true,
  className,
  category,
  setCategory,
  changeCategory,
  loading,
}) => {
  const { data: categoryData } = useGetTicketsCategoryListQuery({});
  const categoryOptions = categoryData?.map((item: any) => {
    return { label: item.id, value: item.name };
  });
  function swapLabelWithValue(option: any) {
    return {
      label: option.value,
      value: option.label,
    };
  }

  if (!categoryOptions) {
    // return <Spinner size="large"/>
  }

  return (
    <Modal
      destroyOnClose
      className={`confirmation-modal ${className}`}
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={<></>}
      closeIcon={<></>}
      width={400}
      maskClosable={maskClosable}
    >
      <div className="confirmation-modal__container">
        <div className="confirmation-modal__title">Change category</div>
        <div className="confirmation-modal__subtitle">
          Please select a different category for this ticket
        </div>
        <div className="w-100 mb-16">
          <CustomSelect
            name="Category"
            options={categoryOptions || []}
            placeholder="Please choose category"
            className="input-white-small"
            onChange={(value) => {
              setCategory(value);
            }}
            //@ts-ignore
            defaultValue={
              category &&
              swapLabelWithValue(
                categoryOptions?.find((item: any) => {
                  return item.label === +category;
                })
              )
            }
          />
        </div>
        <div className="confirmation-modal__btns">
          <Button
            className="gray-btn-lg"
            style={{ marginRight: '12px' }}
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={'blue-btn-lg'}
            onClick={changeCategory}
            disabled={!category || loading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
