import dayjs from 'dayjs';
import React from 'react';
import { SelectOption } from '../../types.ts/commonTypes';
import { TableParamsGetTotals } from '../../api/types/dashboard';
import chart_success from '../../assets/chart-success.svg';
import chart_zero from '../../assets/chart-zero.svg';
import chart_warning from '../../assets/chart-warning.svg';
import chart_critical from '../../assets/chart-critical.svg';
import arrow_critical from '../../assets/arrow-down-critical.svg';
import arrow_warning from '../../assets/arrow-down-warning.svg';
import arrow_success from '../../assets/arrow-up-success.svg';
import arrow_success_small from '../../assets/arrow-success-small.svg';
import arrow_critical_small from '../../assets/arrow-critical-small.svg';

export function formatNumberToCurrency(number: number) {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier === 0) return `$${number}`;

  const suffix = suffixes[tier];

  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  return `$${scaled.toFixed(1)}${suffix}`;
}

export function formatNumberToCurrencyWithoutDollar(number: number) {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier === 0) return `${number}`;

  const suffix = suffixes[tier];

  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  const formatted = `${scaled.toFixed(1)}${suffix}`;
  return formatted.replace(/\.\d+/g, '');
}

export const calcPercentWithChart = (cur: number, prev: number) => {
  let result;
  if (prev !== 0) {
    result = ((cur - prev) / prev) * 100;
  } else {
    result = cur !== 0 ? 100 : 0;
  }

  let formattedPercentage = result.toFixed(2);
  if (formattedPercentage.endsWith('00')) {
    formattedPercentage = formattedPercentage.slice(0, -3);
  }

  let color;
  if (result > 0) {
    color = '#027A48';
  } else if (result === 0) {
    color = '#667085';
  } else if (result < 0 && result >= -50) {
    color = '#DC6803';
  } else if (result < -50) {
    color = '#B42318';
  } else {
    color = '#DA4C53';
  }

  const divStyle = {
    color: color,
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
  };

  return (
    <>
      <div style={divStyle}>
        {result > 0 && <img src={arrow_success} alt="success" />}
        {result < 0 && result > -50 && (
          <img src={arrow_warning} alt="warning" />
        )}
        {result <= -50 && <img src={arrow_critical} alt="critical" />}
        &nbsp;
        {result > 0 ? '+' : ''}
        {formattedPercentage}%
      </div>
      {result > 0 && <img src={chart_success} alt="success" />}
      {result === 0 && <img src={chart_zero} alt="zero" />}
      {result < 0 && result > -50 && <img src={chart_warning} alt="warning" />}
      {result <= -50 && <img src={chart_critical} alt="critical" />}
    </>
  );
};

export const calcPercent = (cur: number, prev: number) => {
  let result;
  if (prev !== 0) {
    result = ((cur - prev) / prev) * 100;
  } else {
    result = cur !== 0 ? 100 : 0;
  }

  let formattedPercentage = result.toFixed(2);
  if (formattedPercentage.endsWith('00')) {
    formattedPercentage = formattedPercentage.slice(0, -3);
  }

  let color;
  let background;
  if (result > 0) {
    color = '#027A48';
    background = '#ECFDF3';
  } else if (result === 0) {
    color = '#808086';
    background = '#fff';
  } else if (result < 0 && result >= -50) {
    color = '#F04438';
    background = '#FEF3F2';
  } else if (result < -50) {
    color = '#F04438';
    background = '#FEF3F2';
  } else {
    color = '#D7A420';
    background = '#F04438';
  }

  const divStyle = {
    color: color,
    background: background,
    padding: '2px 8px',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
  };

  return (
    <div style={divStyle}>
      {result > 0 && <img src={arrow_success_small} alt="success" />}
      {result < 0 && result > -50 && (
        <img src={arrow_critical_small} alt="warning" />
      )}
      {result <= -50 && <img src={arrow_critical_small} alt="critical" />}
      &nbsp;
      {result > 0 ? '+' : ''}
      {formattedPercentage}%
    </div>
  );
};

export const calculateDateDifference = (
  start: dayjs.Dayjs | null | Date,
  end: dayjs.Dayjs | null | Date
): number => {
  //@ts-ignore
  return end.diff(start, 'day');
};

export interface filtersI {
  period: string;
  comparedPeriod: string;
  breakdown: string;
}

export const setComparedPeriodParams = (
  filters: filtersI,
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  setParamsCompared: React.Dispatch<React.SetStateAction<TableParamsGetTotals>>
) => {
  if (
    filters.comparedPeriod === 'Previous period' &&
    dateRange[0] &&
    dateRange[1]
  ) {
    let periodEnd = dayjs(dateRange[0]).subtract(1, 'day').endOf('day');
    let periodStart = periodEnd
      .subtract(dateRange[1].diff(dateRange[0], 'day'), 'day')
      .startOf('day');
    if (filters.period === 'Year to date') {
      periodStart = dayjs(dateRange[0])
        .subtract(1, 'year')
        .subtract(1, 'day')
        .endOf('day');
      periodEnd = dayjs(dateRange[1]).subtract(1, 'year').endOf('day');
    }
    if (filters.period === 'Month to date') {
      periodStart = dayjs(dateRange[0])
        .subtract(1, 'month')
        .subtract(1, 'day')
        .endOf('day');
      periodEnd = dayjs(dateRange[1]).subtract(1, 'month').endOf('day');
    }
    if (filters.period === 'Quarter to date') {
      periodStart = dayjs(dateRange[0])
        .subtract(3, 'months')
        .subtract(1, 'day')
        .endOf('day');
      periodEnd = dayjs(dateRange[1]).subtract(3, 'months').endOf('day');
    }

    setParamsCompared({
      date_from: dayjs(periodStart).toDate(),
      date_to: dayjs(periodEnd).toDate(),
      breakdown:
        filters.breakdown === 'Hourly'
          ? 'hour'
          : filters.breakdown === 'Daily'
          ? 'day'
          : filters.breakdown === 'Weekly'
          ? 'week'
          : filters.breakdown === 'Monthly'
          ? 'month'
          : '',
    });
  }
  if (
    filters.comparedPeriod === 'Previous year' &&
    dateRange[0] &&
    dateRange[1]
  ) {
    const previousYearStart = dayjs(dateRange[0]).subtract(1, 'year');
    const previousYearEnd = dayjs(dateRange[1]).subtract(1, 'year');

    setParamsCompared({
      date_from: previousYearStart.toDate(),
      date_to: previousYearEnd.toDate(),
      breakdown:
        filters.breakdown === 'Hourly'
          ? 'hour'
          : filters.breakdown === 'Daily'
          ? 'day'
          : filters.breakdown === 'Weekly'
          ? 'week'
          : filters.breakdown === 'Monthly'
          ? 'month'
          : '',
    });
  }
  if (
    filters.comparedPeriod === 'Previous month' &&
    dateRange[0] &&
    dateRange[1]
  ) {
    const previousMonthStart = dayjs(dateRange[0]).subtract(1, 'month');
    const previousMonthEnd = dayjs(dateRange[1]).subtract(1, 'month');

    setParamsCompared({
      date_from: previousMonthStart.toDate(),
      date_to: previousMonthEnd.toDate(),
      breakdown:
        filters.breakdown === 'Hourly'
          ? 'hour'
          : filters.breakdown === 'Daily'
          ? 'day'
          : filters.breakdown === 'Weekly'
          ? 'week'
          : filters.breakdown === 'Monthly'
          ? 'month'
          : '',
    });
  }
};

export const setValuesFromTo = (
  filters: filtersI,
  fromToFounding: string[],
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  setfromToFounding: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (!dateRange[0] || !dateRange[1]) return;
  let copyArray = [...fromToFounding];
  if (filters.period === 'Today') {
    copyArray[0] = '12:00 AM';
    copyArray[1] = '11:59 PM';
  } else if (
    filters.period === 'Last 7 days' ||
    filters.period === 'Last 4 weeks' ||
    filters.period === 'Month to date'
  ) {
    if (filters.breakdown === 'Hourly') {
      copyArray[0] = dayjs(dateRange[0]).format('MM.DD.YYYY hh:mm A');
      copyArray[1] = dayjs(dateRange[1]).format('MM.DD.YYYY hh:mm A');
    }
    if (filters.breakdown === 'Daily') {
      copyArray[0] = dayjs(dateRange[0]).format('MM.DD.YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MM.DD.YYYY');
    }
  } else if (
    filters.period === 'Custom' ||
    filters.period === 'Quarter to date'
  ) {
    if (filters.breakdown === 'Daily') {
      copyArray[0] = dayjs(dateRange[0]).format('MM.DD.YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MM.DD.YYYY');
    }
    if (filters.breakdown === 'Weekly') {
      copyArray[0] = dayjs(dateRange[0]).format('MM.DD.YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MM.DD.YYYY');
    }
  } else if (
    filters.period === 'Last 3 months' ||
    filters.period === 'Year to date' ||
    filters.period === 'All time'
  ) {
    if (filters.breakdown === 'Weekly' || filters.breakdown === 'Daily') {
      copyArray[0] = dayjs(dateRange[0]).format('MM.DD.YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MM.DD.YYYY');
    } else if (filters.breakdown === 'Monthly') {
      copyArray[0] = dayjs(dateRange[0]).format('MMMM YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MMMM YYYY');
    }
  } else if (filters.period === 'Last 12 months') {
    if (filters.breakdown === 'Weekly' || filters.breakdown === 'Daily') {
      copyArray[0] = dayjs(dateRange[0]).format('MM.DD.YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MM.DD.YYYY');
    } else if (filters.breakdown === 'Monthly') {
      copyArray[0] = dayjs(dateRange[0]).format('MMMM YYYY');
      copyArray[1] = dayjs(dateRange[1]).format('MMMM YYYY');
    }
  }

  setfromToFounding(copyArray);
};

export const updateFilters = (
  filters: filtersI,
  setFilters: React.Dispatch<React.SetStateAction<filtersI>>,
  setBreakdownOptions: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >,
  setComparedOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  setDateRange: React.Dispatch<
    React.SetStateAction<[dayjs.Dayjs | null, dayjs.Dayjs | null]>
  >,
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  setParamsCurrent: React.Dispatch<React.SetStateAction<TableParamsGetTotals>>,
  dataFirst: string
) => {
  const data: any = {
    date_from: null,
    date_to: null,
    breakdown: filters.breakdown,
    comparedPeriod: filters.comparedPeriod,
  };

  let breakdownOptions: { value: string; label: string }[] = [];
  let comparedOptions: SelectOption[] = [];

  const today = dayjs();

  if (filters.period === 'Today') {
    data.date_from = today.startOf('day').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown !== 'Hourly') {
      data.breakdown = 'Hourly';
    }
    breakdownOptions = [{ value: 'Hourly', label: 'Hourly' }];
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      { value: 'Previous month', label: 'Previous month' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Last 7 days') {
    data.date_from = today.subtract(7, 'day').startOf('day').toDate();
    data.date_to = today.endOf('day').toDate();

    if (data.breakdown === 'Weekly' || data.breakdown === 'Monthly') {
      data.breakdown = 'Hourly';
    }
    breakdownOptions = [
      { value: 'Hourly', label: 'Hourly' },
      { value: 'Daily', label: 'Daily' },
    ];
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      { value: 'Previous month', label: 'Previous month' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Last 4 weeks') {
    data.date_from = today.subtract(4, 'week').startOf('day').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly' || filters.breakdown === 'Monthly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
    ];
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      { value: 'Previous month', label: 'Previous month' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Last 3 months') {
    data.date_from = today.subtract(3, 'month').startOf('day').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Monthly', label: 'Monthly' },
    ];
    if (data.comparedPeriod === 'Previous month') {
      data.comparedPeriod = 'Previous period';
    }
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Last 12 months') {
    data.date_from = today.subtract(1, 'year').startOf('day').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Monthly', label: 'Monthly' },
    ];
    if (data.comparedPeriod === 'Previous month') {
      data.comparedPeriod = 'Previous period';
    }
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Quarter to date') {
    const currentQuarter = Math.floor((today.month() + 3) / 3);

    data.date_from = today
      .startOf('year')
      .add(currentQuarter * 3 - 3, 'month')
      .toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Monthly', label: 'Monthly' },
    ];
    if (data.comparedPeriod === 'Previous month') {
      data.comparedPeriod = 'Previous period';
    }
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Month to date') {
    data.date_from = today.startOf('month').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly' || filters.breakdown === 'Monthly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
    ];
    if (data.comparedPeriod === 'Previous month') {
      data.comparedPeriod = 'Previous period';
    }
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'Year to date') {
    data.date_from = today.startOf('year').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Monthly', label: 'Monthly' },
    ];
    if (data.comparedPeriod === 'Previous month') {
      data.comparedPeriod = 'Previous period';
    }
    comparedOptions = [
      { value: 'Previous period', label: 'Previous period' },
      {
        value: 'Previous year',
        label: 'Previous year',
      },
      { value: 'No comparison', label: 'No comparison' },
    ];
  }
  if (filters.period === 'All time') {
    data.date_from = dayjs(dataFirst).startOf('day').toDate();
    data.date_to = today.endOf('day').toDate();
    if (filters.breakdown === 'Hourly') {
      data.breakdown = 'Daily';
    }
    breakdownOptions = [
      { value: 'Daily', label: 'Daily' },
      { value: 'Weekly', label: 'Weekly' },
      { value: 'Monthly', label: 'Monthly' },
    ];

    data.comparedPeriod = 'No comparison';

    comparedOptions = [{ value: 'No comparison', label: 'No comparison' }];
  }

  setFilters((prev: filtersI) => ({
    ...prev,
    breakdown: data.breakdown,
    comparedPeriod: data.comparedPeriod,
  }));
  setComparedOptions(comparedOptions);
  setBreakdownOptions(breakdownOptions);

  setDateRange([dayjs(data.date_from), dayjs(data.date_to)]);

  setParamsCurrent({
    date_from: dayjs(data.date_from).toDate(),
    date_to: dayjs(data.date_to).toDate(),
    breakdown:
      data.breakdown === 'Hourly'
        ? 'hour'
        : data.breakdown === 'Daily'
        ? 'day'
        : data.breakdown === 'Weekly'
        ? 'week'
        : data.breakdown === 'Monthly'
        ? 'month'
        : '',
  });
};

export const updateCustomFilters = (
  filters: filtersI,
  setFilters: React.Dispatch<React.SetStateAction<filtersI>>,
  setBreakdownOptions: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >,
  setComparedOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null],
  setParamsCurrent: React.Dispatch<React.SetStateAction<TableParamsGetTotals>>
) => {
  const data: any = {
    date_from: null,
    date_to: null,
    breakdown: filters.breakdown,
    comparedPeriod: filters.comparedPeriod,
  };

  let breakdownOptions: { value: string; label: string }[] = [];
  let comparedOptions: SelectOption[] = [];

  if (dateRange[0] && dateRange[1]) {
    data.date_from = dayjs(dateRange[0]).toDate();
    data.date_to = dayjs(dateRange[1]).toDate();
    const differenceInDays = calculateDateDifference(
      dateRange[0],
      dateRange[1]
    );

    if (differenceInDays < 7 && differenceInDays > 0) {
      if (data.breakdown === 'Weekly' || data.breakdown === 'Monthly') {
        data.breakdown = 'Hourly';
      }
      breakdownOptions = [
        { value: 'Hourly', label: 'Hourly' },
        { value: 'Daily', label: 'Daily' },
      ];
      if (data.breakdown !== 'Hourly') {
        data.breakdown = 'Hourly';
      }

      comparedOptions = [
        { value: 'Previous period', label: 'Previous period' },
        { value: 'Previous month', label: 'Previous month' },
        {
          value: 'Previous year',
          label: 'Previous year',
        },
        { value: 'No comparison', label: 'No comparison' },
      ];
    } else if (differenceInDays >= 7 && differenceInDays < 13) {
      breakdownOptions = [{ value: 'Daily', label: 'Daily' }];
      if (
        data.breakdown === 'Weekly' ||
        data.breakdown === 'Monthly' ||
        data.breakdown === 'Hourly'
      ) {
        data.breakdown = 'Daily';
      }
      comparedOptions = [
        { value: 'Previous period', label: 'Previous period' },
        { value: 'Previous month', label: 'Previous month' },
        {
          value: 'Previous year',
          label: 'Previous year',
        },
        { value: 'No comparison', label: 'No comparison' },
      ];
    } else if (differenceInDays === 0) {
      breakdownOptions = [{ value: 'Hourly', label: 'Hourly' }];
      data.breakdown = 'Hourly';
      comparedOptions = [
        { value: 'Previous period', label: 'Previous period' },
        { value: 'Previous month', label: 'Previous month' },
        {
          value: 'Previous year',
          label: 'Previous year',
        },
        { value: 'No comparison', label: 'No comparison' },
      ];
    } else if (differenceInDays >= 13 && differenceInDays < 60) {
      breakdownOptions = [
        { value: 'Daily', label: 'Daily' },
        { value: 'Weekly', label: 'Weekly' },
      ];

      if (data.breakdown === 'Monthly' || data.breakdown === 'Hourly') {
        data.breakdown = 'Daily';
      }

      if (differenceInDays >= 14 && differenceInDays <= 30) {
        comparedOptions = [
          { value: 'Previous period', label: 'Previous period' },
          { value: 'Previous month', label: 'Previous month' },
          {
            value: 'Previous year',
            label: 'Previous year',
          },
          { value: 'No comparison', label: 'No comparison' },
        ];
      }
      if (differenceInDays > 30 && differenceInDays < 60) {
        comparedOptions = [
          { value: 'Previous period', label: 'Previous period' },
          {
            value: 'Previous year',
            label: 'Previous year',
          },
          { value: 'No comparison', label: 'No comparison' },
        ];
      }
    } else if (differenceInDays >= 60) {
      breakdownOptions = [
        { value: 'Daily', label: 'Daily' },
        { value: 'Weekly', label: 'Weekly' },
        { value: 'Monthly', label: 'Monthly' },
      ];
      if (data.breakdown === 'Hourly') {
        data.breakdown = 'Daily';
      }
      if (data.comparedPeriod === 'Previous month') {
        data.comparedPeriod = 'Previous period';
      }
      comparedOptions = [
        { value: 'Previous period', label: 'Previous period' },
        {
          value: 'Previous year',
          label: 'Previous year',
        },
        { value: 'No comparison', label: 'No comparison' },
      ];
    }
  }

  setFilters((prev: filtersI) => ({
    ...prev,
    breakdown: data.breakdown,
    comparedPeriod: data.comparedPeriod,
  }));
  setComparedOptions(comparedOptions);
  setBreakdownOptions(breakdownOptions);

  setParamsCurrent({
    date_from: dayjs(dateRange[0]).toDate(),
    date_to: dayjs(dateRange[1]).toDate(),
    breakdown:
      data.breakdown === 'Hourly'
        ? 'hour'
        : data.breakdown === 'Daily'
        ? 'day'
        : data.breakdown === 'Weekly'
        ? 'week'
        : data.breakdown === 'Monthly'
        ? 'month'
        : '',
  });
};
