import { useState, useEffect, useRef, useCallback } from 'react';
import { CustomInput } from '../../customFields/CustomInput';
import { CustomSelect } from '../../customFields/CustomSelect';
import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { useAppSelector } from '../../hooks';
import { LoanProducts } from '../../api/types/partners';
import './index.scss';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import useDebounce from '../../hooks/useDebounce';
import { ApplicationsTable } from '../../components/Tables/ApplicationsTable/ApplicationsTable';
import { useLazyGetApplicantsListQuery } from '../../api/applicants';
import { Form, DatePicker, Button, message } from 'antd';
import { TableParamsApp } from '../../api/types/applicants';
import { useGetAccountTypesQuery } from '../../api/common';
import { AccountTypesItem } from '../../api/types/common';
import { SelectOption } from '../../types.ts/commonTypes';
import {
  ADMIN,
  MAIN_ACCOUNT,
  PARTNER,
  SUPER_ADMIN,
} from '../../helpers/variables';
import { disabledDateAfterToday, rangePresets } from '../../helpers/common';
import { TableSkeleton } from '../../components/Skeletons/TableSkeleton';

export interface idsI {
  id: number;
  key: { status_key: number };
}

export interface TableAdminsDataI {
  length?: number;
  id: number;
  company_name: string;
  city: string;
  state: string;
  phone: string;
  status: string;
  logo: string;
}

export const Applications = () => {
  const [tableData, setTableData] = useState<any>(null);
  const [tableParams, setTableParams] = useState<TableParamsApp>({
    per_page: 50,
    search: '',
    order_by: '',
    order: 'desc',
    status_key: '',
    page: 1,
    loan_product_id: null,
    application_status: null,
    transaction_status: null,
    application_date_from: null,
    application_date_to: null,
  });
  const [getList, { data, isLoading, isError }] =
    useLazyGetApplicantsListQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAppSelector((state) => state.profile);
  const [searchMainAcc, setSearchMainAcc] = useState('');
  const debouncedSearchMainAcc = useDebounce(searchMainAcc);
  const ref = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm<FormData>();
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [products, setProducts] = useState<SelectOption[]>([]);
  const { RangePicker } = DatePicker;
  const [rangeDates, setRangeDates] = useState<any>({
    date_from: null,
    date_to: null,
  });

  const statusOptionsPartner = [
    { value: 'Offer Made', label: 'Offer Made' },
    { value: 'No Offer', label: 'No Offer' },
    {
      value: 'Awaiting Signature',
      label: 'Awaiting Signature',
    },
    {
      value: 'Awaiting Down Payment',
      label: 'Awaiting Down Payment',
    },
    {
      value: 'Awaiting Approval',
      label: 'Awaiting Approval',
    },
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Refunded', label: 'Refunded' },
    // { value: 'Draft', label: 'Draft' },
  ];
  const statusOptions = [
    { value: 'Offer Made', label: 'Offer Made' },
    { value: 'No Offer', label: 'No Offer' },
    {
      value: 'Awaiting Signature',
      label: 'Awaiting Signature',
    },
    {
      value: 'Awaiting Down Payment',
      label: 'Awaiting Down Payment',
    },
    {
      value: 'Awaiting Approval',
      label: 'Awaiting Approval',
    },
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Refunded', label: 'Refunded' },
  ];

  const errorMessage = useCallback(
    (text: string) => {
      messageApi.open({
        type: 'error',
        content: text,
        style: {
          marginTop: '90vh',
        },
      });
    },
    [messageApi]
  );
  const clearFilters = () => {
    setSearchMainAcc('');
    setRangeDates({ date_from: null, date_to: null });
    setTableParams((prevState) => ({
      ...prevState,
      loan_product_id: null,
      application_status: null,
      transaction_status: null,
      application_date_from: null,
      application_date_to: null,
    }));
  };

  useEffect(() => {
    if (accountTypesData) {
      const array: SelectOption[] = [];
      accountTypesData.data.forEach((elem: AccountTypesItem) => {
        elem.loan_products?.forEach((item: LoanProducts) => {
          array.push({ label: item.id, value: item.name });
        });
      });
      setProducts(array);
    }
  }, [accountTypesData]);

  useEffect(() => {
    setTableParams((prev: TableParamsApp) => ({
      ...prev,
      search: debouncedSearchMainAcc,
    }));
  }, [debouncedSearchMainAcc]);

  useEffect(() => {
    setTableParams((prev: TableParamsApp) => ({ ...prev, page: 1 }));
  }, [tableParams.search, tableParams.status_key]);

  useEffect(() => {
    getList(tableParams);
  }, [tableParams, getList]);

  useEffect(() => {
    if (isError) {
      errorMessage('Fetch applications failed');
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    if (!data?.data) return;

    //app flow (push data.data)
    const filtredData = data.data.filter(
      (elem) => elem.application_status !== 'Draft'
    );
    setTableData(filtredData);
  }, [data?.data]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const dateString0 = dateStrings[0];
      const dateString1 = dateStrings[1];
      let date0 = dayjs(dateString0);
      let date1 = dayjs(dateString1);
      const startOfDay = date0.startOf('day');
      const endOfDay = date1.endOf('day');
      setRangeDates({ date_from: date0, date_to: date1 });
      setTableParams((prev: TableParamsApp) => ({
        ...prev,
        application_date_from: startOfDay ? startOfDay.toDate() : null,
        application_date_to: endOfDay ? endOfDay.toDate() : null,
      }));
    } else {
      setRangeDates({ date_from: null, date_to: null });
      setTableParams((prev: TableParamsApp) => ({
        ...prev,
        application_date_from: null,
        application_date_to: null,
      }));
    }
  };

  return (
    <div className="container">
      {contextHolder}
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className="members">
          <div className="members__table transparent">
            <div className="members__filters">
              <div
                className="members__row"
                ref={ref}
                style={{ flexDirection: 'column' }}
              >
                <div className="members__toprow">
                  <div style={{ width: '32%', margin: '0 0 11px 0' }}>
                    <CustomInput
                      className="input-white-small"
                      name="Search"
                      placeholder={
                        user.role.key === PARTNER
                          ? 'Search by ID or Applicant Name'
                          : user.role.key === SUPER_ADMIN ||
                            user.role.key === ADMIN
                          ? 'Search by ID, Applicant Name or Partner Company Name'
                          : user.role.key === MAIN_ACCOUNT
                          ? 'Search by ID, Applicant Name or Partner Company Name'
                          : ''
                      }
                      value={searchMainAcc}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchMainAcc(e.target.value)
                      }
                      prefix={<img src={searchIcon} alt="search" />}
                      suffix={
                        searchMainAcc ? (
                          <img
                            style={{ margin: '0 0 0 0' }}
                            className="cancel-btn"
                            src={cancel}
                            onClick={() => setSearchMainAcc('')}
                            alt="cancel"
                          />
                        ) : (
                          <></>
                        )
                      }
                    />{' '}
                  </div>
                  <div className="topfilters__item" style={{ width: '32%' }}>
                    <div className="custom-field__title">Application Date</div>
                    <RangePicker
                      value={[rangeDates.date_from, rangeDates.date_to]}
                      style={{ margin: '0 0 15px 0' }}
                      inputReadOnly
                      presets={rangePresets}
                      onChange={onRangeChange}
                      className="input-white-small"
                      disabledDate={disabledDateAfterToday}
                      allowClear={true}
                      clearIcon={
                        <img
                          className="cancel-btn"
                          style={{ margin: '0 0 0 0' }}
                          src={cancel}
                          onClick={() => {
                            setTableParams((prev: TableParamsApp) => ({
                              ...prev,
                              application_date_from: null,
                              application_date_to: null,
                            }));
                          }}
                          alt="cancel"
                        />
                      }
                    />
                  </div>
                  <Button
                    className="blue-btn"
                    style={{ width: '32%', height: '32px' }}
                    onClick={clearFilters}
                  >
                    Clear all filters
                  </Button>
                </div>

                <div className={`members__topfilters topfilters`}>
                  <Form form={form}>
                    <div className="topfilters__container">
                      <div
                        className="topfilters__item"
                        style={{ width: '32%' }}
                      >
                        <CustomSelect
                          value={tableParams.loan_product_id}
                          name="Product"
                          className="input-white-small"
                          placeholder="Show All"
                          options={products}
                          onChange={(value: string | number | undefined) => {
                            setTableParams((prev: TableParamsApp) => ({
                              ...prev,
                              loan_product_id:
                                value !== undefined ? String(value) : null,
                            }));
                          }}
                          isSearchSelect={false}
                          allowClear={true}
                          suffixIcon={
                            tableParams.loan_product_id ? (
                              <img
                                className="cancel-btn"
                                src={cancel}
                                onClick={() => {
                                  setTableParams((prev: TableParamsApp) => ({
                                    ...prev,
                                    loan_product_id: null,
                                  }));
                                }}
                                alt="cancel"
                              />
                            ) : (
                              false
                            )
                          }
                        />
                      </div>
                      <div
                        className="topfilters__item"
                        style={{ width: '32%' }}
                      >
                        <CustomSelect
                          value={tableParams.application_status}
                          name="Application Status"
                          className="input-white-small"
                          placeholder="Show All"
                          options={
                            user.role.key === PARTNER
                              ? statusOptionsPartner
                              : statusOptions
                          }
                          onChange={(value: string | number | undefined) => {
                            setTableParams((prev: TableParamsApp) => ({
                              ...prev,
                              application_status:
                                value !== undefined ? String(value) : null,
                            }));
                          }}
                          isSearchSelect={false}
                          allowClear={true}
                          suffixIcon={
                            tableParams.application_status ? (
                              <img
                                className="cancel-btn"
                                style={{ margin: '-4px 0 0 0' }}
                                src={cancel}
                                onClick={() => {
                                  setTableParams((prev: TableParamsApp) => ({
                                    ...prev,
                                    loan_product_id: null,
                                  }));
                                }}
                                alt="cancel"
                              />
                            ) : (
                              false
                            )
                          }
                        />
                      </div>
                      <div
                        className="topfilters__item"
                        style={{ width: '32%' }}
                      >
                        <CustomSelect
                          value={tableParams.transaction_status}
                          name="Transaction Status"
                          className="input-white-small"
                          placeholder="Show All"
                          options={[
                            { value: 'In Progress', label: 'In Progress' },
                            { value: 'Timed Out', label: 'Timed Out' },
                            { value: 'Approved', label: 'Approved' },
                          ]}
                          onChange={(value: string | number | undefined) => {
                            setTableParams((prev: TableParamsApp) => ({
                              ...prev,
                              transaction_status:
                                value !== undefined ? String(value) : null,
                            }));
                          }}
                          isSearchSelect={false}
                          allowClear={true}
                          suffixIcon={
                            tableParams.transaction_status ? (
                              <img
                                className="cancel-btn"
                                style={{ margin: '-4px 0 0 0' }}
                                src={cancel}
                                onClick={() => {
                                  setTableParams((prev: TableParamsApp) => ({
                                    ...prev,
                                    loan_product_id: null,
                                  }));
                                }}
                                alt="cancel"
                              />
                            ) : (
                              false
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <ApplicationsTable
              data={tableData}
              setTableParams={setTableParams}
              tableParams={tableParams}
              metaData={data}
            />
          </div>
        </div>
      )}
    </div>
  );
};
