import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import logo_wl from '../../assets/login-wl-image.svg';
import { useAppDispatch } from '../../hooks';
import * as Yup from 'yup';
import { useLoginMutation } from '../../api/auth';
import image from '../../assets/mainLoginImage.svg';
import './index.scss';
import { Button, Checkbox, Form, message } from 'antd';
import { CustomInput } from '../../customFields/CustomInput';
import { yupSync } from '../../utils';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { ForgotPassword } from './ForgotPassword';
import { Spinner } from '../../components/Spinner/Spinner';
import { SuccessPopup } from './SuccessPopup';
import { CustomErrorType } from '../../types.ts/commonTypes';
import { isApiError } from '../../utils/general';
import { subdomain } from '../../helpers/common';
import faviconWL from '../../assets/WLFavicon_192x192.svg';
import faviconNoLable from '../../assets/Favicon192x192.svg';
import Favicon from 'react-favicon';
import { beforeInit, beforeLogin } from '../../api/axiosCrud';

interface FormData {
  email: string;
  password: string;
  remember_me?: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

export const Login: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<FormData>();
  const [isOpenForgotPasswordModal, setIsOpenForgotPasswordModal] =
    useState<boolean>(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);

  const [initData, setInitData] = useState<any>();

  const [login, { data, isSuccess, isLoading, originalArgs, isError, error }] =
    useLoginMutation();
  const { handleKeyPress } = usePreventSpaceTyping();
  const [searchParams] = useSearchParams();
  const [credError, setCredError] = useState<string>('');
  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (initData?.company_name) {
      if (subdomain === 'project') {
        document.title = 'project';
      } else {
        document.title = initData?.company_name;
      }
    }
  }, [initData, subdomain]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (token) {
      localStorage.setItem('token', token);
      navigate('/dashboard');
    }
  }, [location]);

  const loginFunc = async (params: FormData) => {
    try {
      const response = await beforeLogin(params);

      if (response.status > 200 && response.status < 300) {
        await localStorage.setItem('token', 'token');
        await navigate('/dashboard');
      } else {
        setCredError(response?.error?.data?.message);
      }
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Auth failed');
    }
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: FormData) => {
        const formData = {
          email: values.email.toLowerCase(),
          password: values.password,
          remember_me: values.remember_me,
          subdomain,
        };
        loginFunc(formData);
      })
      .catch(() => {});
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
    if (name === 'password') {
      setCredError('');
    }
  };

  useEffect(() => {
    if (!subdomain) return;
    beforeInit({ subdomain: subdomain }).then((response: any) => {
      setInitData(response?.data);
    });
  }, [subdomain]);

  // if (isInitLoading) {
  //   // return <Spinner size="large"/>
  // }

  return (
    <>
      <Favicon
        url={initData?.wl ? faviconWL : faviconNoLable}
        sizes="192x192"
      />
      {contextHolder}
      <div className="auth">
        <div className="auth__block block">
          <div className="block__login">
            <div className="block__title">Log in</div>
            <div className="block__subtitle"></div>
            <div className="block__form">
              <Form form={form} onFinish={handleFormSubmit}>
                <Form.Item
                  className="input-wrapper"
                  name="email"
                  rules={yupSync('email', validationSchema, true)}
                >
                  <CustomInput
                    name="Email"
                    inputSize="lg"
                    className="input-lg"
                    maxLength={100}
                    onKeyPress={handleKeyPress}
                    placeholder="example@gmail.com"
                    onBlur={() => validateFormOnBlur('email')}
                    error={credError}
                    onChange={() => setCredError('')}
                  />
                </Form.Item>
                <Form.Item
                  className="input-wrapper"
                  name="password"
                  rules={yupSync('password', validationSchema, true)}
                >
                  <CustomInput
                    name="Password"
                    inputSize="lg"
                    className="input-lg"
                    maxLength={100}
                    type="password"
                    onKeyPress={handleKeyPress}
                    placeholder="********"
                    onBlur={() => validateFormOnBlur('password')}
                    error={credError}
                    onChange={() => setCredError('')}
                  />
                </Form.Item>
                <div
                  className="error-text"
                  style={{ margin: '-10px 0 15px 0' }}
                >
                  {credError}
                </div>
                <div className="d-flex justify-between align-center">
                  <Form.Item
                    className="info-wrap__remember"
                    name="remember_me"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox className="info-wrap__checkbox">
                      <div className="info-wrap__title">Remember me</div>
                    </Checkbox>
                  </Form.Item>
                  <div
                    className="block__forgot"
                    onClick={() => setIsOpenForgotPasswordModal(true)}
                  >
                    Forgot the password?
                  </div>
                </div>

                <div className="block__info-wrap info-wrap">
                  <Button className="blue-btn-lg w-100" htmlType="submit">
                    Log In {isLoading && <Spinner />}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="auth__block block">
          {!initData && <div className="block__image grey"></div>}
          {initData && initData?.wl && (
            <img src={logo_wl} className="block__image" />
          )}
          {initData && !initData?.wl && (
            <img src={image} className="block__image" />
          )}
        </div>
      </div>

      {isOpenForgotPasswordModal && (
        <ForgotPassword
          openModal={isOpenForgotPasswordModal}
          setOpenModal={setIsOpenForgotPasswordModal}
          setIsSuccessPopup={setIsSuccessPopup}
        />
      )}
      {isSuccessPopup && (
        <SuccessPopup
          openModal={isSuccessPopup}
          setOpenModal={setIsSuccessPopup}
        />
      )}
    </>
  );
};
