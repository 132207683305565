import './index.scss';
import {Button, Form, Modal} from "antd";
import upload from "../../../assets/upload-cloud.svg"
import upload_blue from "../../../assets/upload-cloud-blue.svg"

import picture from "../../../assets/picture_blue_lg.svg"
import file from "../../../assets/file_blue_lg.svg"
import trash from "../../../assets/trash-new.svg"

import {CustomSelect} from "../../../customFields/CustomSelect";
import {CustomInput} from "../../../customFields/CustomInput";
import { Input, Upload } from 'antd';
import React, {useEffect, useState} from "react";
import {filesize} from "filesize";
import {trimmer} from "../../../helpers/common";
import {useAppSelector} from "../../../hooks";
import {UserI} from "../../../reducers/profile/profileSlice";
import {ADMIN, SUPER_ADMIN} from "../../../helpers/variables";
import {
    useAddFileMutation,
    useCreateTicketMutation,
    useGetTicketsCategoryListQuery,
    useLazyGetTicketAdminsQuery
} from "../../../api/tickets";
import {selectTabs, setOpenSubmitTicket as setOpenModal} from "../../../reducers/tabs/tabsSlice";
import {useDispatch} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";

const { TextArea } = Input;

interface SubmitTicketModalI {
    ticketCreateSuccess: any
}

export const SubmitTicketModal = ({
                                      ticketCreateSuccess
                                  } :
                                      SubmitTicketModalI) => {
    const { openSubmitTicket } = useAppSelector(selectTabs)
    const dispatch = useDispatch()
    const setOpenSubmitTicket = (payload: boolean): AnyAction => dispatch(setOpenModal(payload))

    const [getAdmins, {data: adminsData}] = useLazyGetTicketAdminsQuery()
    const {data: categoryData} = useGetTicketsCategoryListQuery({})
    const [createTicket, {
        isLoading:isCreateLoading,
        isError: isCreateError,
        isSuccess: isCreateSuccess
    }] = useCreateTicketMutation()
    const [addFile, {data: fileData, isLoading: isFileLoading}] = useAddFileMutation()
    const user = useAppSelector((state: { profile: UserI }) => state.profile);
    const [form] = Form.useForm<FormData>();
    const [fileList, setFileList] = useState<any[]>([])

    const adminsOptions = adminsData?.data.map((item: any) => {
        return {label:item.id, value: `${item.first_name} ${item.last_name}`}
    })
    const categoryOptions = categoryData?.map((item: any) => {
        return {label:item.id, value: item.name}
    })

    const closeModal = () => {
        setOpenSubmitTicket(false)
        setFileList([])
        form.resetFields()
    }

    const onChange = async ( info: any) => {
        const formData = new FormData();
        formData.append('model', 'ticket');
        info?.file &&
        formData.append('field', info?.file);
        await addFile(formData).unwrap()
            .then((data) => {
                setFileList(prev => ([
                    ...prev,
                    {
                        fileId: data?.data?.id,
                        name: info?.file?.name,
                        size: info?.file?.size,
                        type: info?.file?.type,
                        uid: info?.file?.uid,
                    }
                ]))
            })
            .catch(() => {})
    };
    const deleteFile = (uid: string) => {
        setFileList(prev => {
            return prev.filter(item => item.uid !== uid)
        });
    };

    const validateFormOnBlur = (name: string) => {
        form.validateFields([name]);
    };

    const submit = () => {
        form.validateFields().then(values => {
            const data = {
                ...values,
                files: fileList.map(item => item.fileId)
            }
            createTicket(data)
        }).catch(() => {})
    }

    useEffect(() => {
        if (user.role.key === SUPER_ADMIN || user.role.key === ADMIN) {
            getAdmins({
                per_page: 'all',
                search: '',
                order_by: '',
                order: 'desc',
                status_key: '',
                page: 1,
            })
        }
    },[user.role.key])

    useEffect(() => {
        if (isCreateSuccess) {
            ticketCreateSuccess()
            closeModal()
        }
    }, [isCreateSuccess])

    return (
        <Modal
        destroyOnClose
        className=""
        centered
        open={openSubmitTicket}
        onCancel={closeModal}
        closeIcon={false}
        footer={false}
        width={400}
    >
            <Form
                form={form}
            >
                <div className="modal-title mb-16">
                    New ticket
                </div>
                <Form.Item
                    name="category_id"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <CustomSelect
                        name="Category"
                        options={categoryOptions || []}
                        placeholder="Please choose"
                        className="input-white-small"
                        onChange={() => {}}
                    />
                </Form.Item>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <CustomInput
                        name="Subject"
                        className="input-white-small"
                        max={100}
                        placeholder="Enter a subject"
                        onBlur={() => validateFormOnBlur('subject')}
                        // error={isError ? 'err' : ''}
                        onChange={() => {}}
                    />
                </Form.Item>
                <div className="additional-input-header">Description</div>
                <Form.Item
                    name="text"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <TextArea
                        rows={4} placeholder="Enter a description..."
                        onBlur={() => validateFormOnBlur('description')}
                        maxLength={2000}
                    />
                </Form.Item>
                <Form.Item
                    style={{height: '154px'}}
                    name="files"
                >
                    <Upload
                        accept=".pdf,image/png,image/jpeg"
                        beforeUpload={() => false}
                        disabled={isFileLoading}
                        multiple={false}
                        fileList={fileList}
                        onChange={(info) => onChange(info)}
                    >
                        <div
                            className={`submit-ticket-upload ${isFileLoading && 'disabled'}`}
                        >
                            <img src={isFileLoading ? upload : upload_blue} alt="upload"/>
                            <div className={`submit-ticket-upload__title ${isFileLoading && 'disabled'}`}>Click to upload</div>
                            <div className="submit-ticket-upload__text">PDF, Pictures (PNG or JPG )</div>
                        </div >
                    </Upload>
                </Form.Item>
                {fileList.map(item => {
                    const image = item.type?.includes('image')
                    return (
                        <div className="submit-ticket-upload__file-container" key={item.uid}>
                            <div className="d-flex">
                                <img src={image? picture : file}/>
                                <div className="ml-5">
                                    <div className="submit-ticket-upload__file-title">
                                        {trimmer(item.name, 30) }
                                    </div>
                                    <div className="submit-ticket-upload__text">
                                        {filesize(item.size || 0)}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="submit-ticket-upload__file-delete-block"
                                onClick={() => {deleteFile(item.uid)}}
                            >
                                <img src={trash} alt="trash"/>
                            </div>
                        </div>
                    )
                })}
                {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && (
                    <Form.Item
                        style={{marginTop: '16px'}}
                        name="assigned_admin_id"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <CustomSelect
                            name="Addressee"
                            options={(adminsOptions && [{label:0, value: 'Not Assigned'},
                                ...adminsOptions]) || []}
                            placeholder="Choose addressee"
                            className="input-white-small"
                            onChange={() => {}}
                        />
                    </Form.Item>
                )}
            </Form>
            {!(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && <div className="mb-16"></div>}
            <footer className="d-flex justify-between">
                    <Button className="gray-btn-lg" style={{marginRight: '12px'}} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        className='blue-btn-lg'
                        onClick={submit}
                    >
                        Submit
                    </Button>
            </footer>
        </Modal>)
}