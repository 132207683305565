import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  AdminItem,
  AdminResponse,
  AdminsTableResponse,
  TableParamsAdmin,
} from './types/admins';

export const adminsApi = createApi({
  reducerPath: 'adminsApi',
  baseQuery,
  tagTypes: ['Admins'],
  endpoints: (builder) => ({
    getAdminsList: builder.query<AdminsTableResponse, TableParamsAdmin>({
      query: (params) => ({
        url: '/admins/',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Admins'],
    }),
    deleteAdmin: builder.mutation<number, string>({
      query: (id) => ({
        url: `/admins/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags:['Admins'],
    }),
    resendInvitation: builder.mutation<number, string>({
      query: (id) => ({
        url: `/admins/${id}/invitation`,
        method: 'PUT',
      }),
    }),
    createAdmin: builder.mutation({
      query: (arg) => ({
        url: '/admins',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags:['Admins'],
    }),
    getAdmin: builder.query<AdminResponse, number | string>({
      query: (id) => ({
        url: `/admins/${id}`,
        method: 'GET',
      }),
    }),
    editAdmin: builder.mutation({
      query: (data) => ({
        url: `/admins/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags:['Admins'],
    }),
    changeAdminStatus: builder.mutation({
      query: (data) => ({
        url: `/admins/${data.id}/status`,
        method: 'PUT',
        body: {status_key: data.key},
      }),
      invalidatesTags:['Admins'],
    }),
  }),
});

export const {
  useGetAdminsListQuery,
  useLazyGetAdminsListQuery,
  useDeleteAdminMutation,
  useResendInvitationMutation,
  useCreateAdminMutation,
  useLazyGetAdminQuery,
  useEditAdminMutation,
    useChangeAdminStatusMutation
} = adminsApi;
