import React, { FC } from 'react';

interface PropsI {
  openItem: number | null | undefined;
  offer: any;
  selectOption: number | null;
  item: any;
  elem: any;
  index: number;
  setSelectOption: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommonOpensOffer: FC<PropsI> = (props) => {
  const { openItem, offer, selectOption, item, elem, index, setSelectOption } =
    props;

  return openItem === offer.id && selectOption === item.id ? (
    <div className="OfferItem__option-selected">
      <div className="d-flex">
        {elem.offers.length > 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">Option {index + 1}</div>
          </div>
        )}
        {item.status === 'Expired' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Expired' && (
          <div className="w-50">
            <div className="OfferItem__row-expired">Expired</div>
          </div>
        )}
        {item.status === 'Declined' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Declined' && (
          <div className="w-50">
            <div className="OfferItem__row-declined">Declined</div>
          </div>
        )}
        {item.status === 'Pending' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Pending' && (
          <div className="w-50">
            <div className="OfferItem__row-pending">Pending</div>
          </div>
        )}
      </div>
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Loan Amount</p>
          <p className="OfferItem__value bold">
            ${' '}
            {item.offer_value
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">APR </p>
          <p className="OfferItem__value bold">{item.rate} %</p>
        </div>
      </div>

      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Monthly payment</p>
          <p className="OfferItem__value">$ {item.monthly_payment}</p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Term</p>
          <p className="OfferItem__value">{item.term}</p>
        </div>
      </div>

      <div className="OfferItem__row">
        {item.down_payment && (
          <div className="OfferItem__wrapper">
            <p className="OfferItem__name">Down payment</p>
            <p className="OfferItem__value">{item.down_payment}</p>
          </div>
        )}
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name"></p>
          <p className="OfferItem__value">
            {elem.secured ? 'Secured' : 'Unsecured'}
          </p>
        </div>
      </div>
      <div className="OfferItem__line"></div>
    </div>
  ) : (
    <div className="OfferItem__option" onClick={() => setSelectOption(item.id)}>
      <div className="d-flex">
        {elem.offers.length > 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">Option {index + 1}</div>
          </div>
        )}
        {item.status === 'Expired' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Expired' && (
          <div className="w-50">
            <div className="OfferItem__row-expired">Expired</div>
          </div>
        )}
        {item.status === 'Declined' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Declined' && (
          <div className="w-50">
            <div className="OfferItem__row-declined">Declined</div>
          </div>
        )}
        {item.status === 'Pending' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Pending' && (
          <div className="w-50">
            <div className="OfferItem__row-pending">Pending</div>
          </div>
        )}
      </div>
      {item.name && item.bank.key !== 'ottomoto' && (
        <div className="OfferItem__promo-text">{item.name}</div>
      )}
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Loan Amount</p>
          <p className="OfferItem__value bold">
            ${' '}
            {item.offer_value
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">APR </p>
          <p className="OfferItem__value bold">{item.rate} %</p>
        </div>
      </div>
      <div className="OfferItem__line"></div>
    </div>
  );
};
