import {message, Tooltip} from 'antd';
import {useEffect, useState} from 'react';
import trash from '../../assets/trash-new.svg'
import edit from '../../assets/edit-new.svg'
import pause from '../../assets/pause-new.svg';
import activate from '../../assets/activate-new.svg'
import send from '../../assets/send-new.svg'
import arrowDown from '../../assets/arrow-down-new.svg'
import './index.scss';
import {ConfirmationModal} from "../Modals/ConfirmationModal";
import {successMessage} from "../Messages/messages";
import {useChangeAdminStatusMutation, useResendInvitationMutation} from "../../api/admins";
import {AdminItem} from "../../api/types/admins";
import {useDeleteAdminMutation} from "../../api/admins";

interface EmployeeTableProps {
    employees: AdminItem[]
    setEditEmployee: React.Dispatch<React.SetStateAction<AdminItem | null>>
    setAddEmployeeModal: React.Dispatch<React.SetStateAction<boolean>>
    view?: boolean
    setFilters: any
    order:string
}

export const AdminsManagementTable = ({
                                   employees,
                                   setEditEmployee,
                                   setAddEmployeeModal,
                                   setFilters,
                                   view,
                                   order
                               }: EmployeeTableProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [deleteEmployeeRequest, {isSuccess:deleteSuccess}] = useDeleteAdminMutation()
    const [resendLinkRequest, {isSuccess: resendLinkSuccess}] = useResendInvitationMutation()
    const [changeStatusRequest, {isSuccess: changeStatusSuccess}] = useChangeAdminStatusMutation()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [employeeToDelete, setEmployeeToDelete] = useState<AdminItem | null>(null)
    const [openPauseModal, setOpenPauseModal] = useState(false)
    const [employeeToPause, setEmployeeToPause] = useState<AdminItem | null>(null)

    const changeStatus = (user: AdminItem) => {
        if (user.status === 'Active') {
            changeStatusRequest({id:user.id, key:1})
            setOpenPauseModal(false)
            setEmployeeToPause(null)
        }
        if (user.status === 'Paused') {
            changeStatusRequest({id:user.id, key:2})
        }
    }
    const deleteEmployee = () => {
        if (employeeToDelete) {
            deleteEmployeeRequest(employeeToDelete.id.toString())
            setOpenDeleteModal(false)
            setEmployeeToDelete(null)
        }
    }
    const cancelDelete = () => {
        setOpenDeleteModal(false)
        setEmployeeToDelete(null)
    }
    const cancelPause = () => {
        setOpenPauseModal(false)
        setEmployeeToPause(null)
    }

    const resendLink = (id:string) => {
        resendLinkRequest(id)
    }

    useEffect(() => {
        deleteSuccess && successMessage(messageApi, 'User has been successfully deleted!')
    },[deleteSuccess])

    useEffect(() => {
        resendLinkSuccess && successMessage(messageApi,'Invitation link has been successfully sent!')
    },[resendLinkSuccess])



    return (
        <div className={`${view && 'no-pointer'}`}>
            {contextHolder}
            <div className="team-table-container">
            <table className="team-table">
                <thead>
                <tr>
                    <th className="team-table__header-item">Name</th>
                    <th className="team-table__header-item">
                        <div className="d-flex">
                            Status
                            <img
                                className={`team-table__arrow ${order === 'asc' && 'rotate-180'}`}
                                src={arrowDown}
                                alt="arrow"
                                onClick={setFilters}
                            />
                        </div>
                    </th>
                    <th className="team-table__header-item">Contact number</th>
                    <th className="team-table__header-item">Contact email</th>
                    {!view && <th className="team-table__header-item">&nbsp;</th>}
                </tr>
                </thead>
                <tbody>
                {employees?.map((item) => {
                        return (
                            <tr className="team-table__row">
                                <td className={`team-table__row-cell`}>
                                    <div className="team-table__name">
                                        {item.first_name} {item.last_name}
                                    </div>
                                    <div className="team-table__id">#{item.item_id}</div>
                                </td>
                                <td className={`team-table__row-cell`}>
                                    <div className={`team-table__status 
                                    ${item.status === "Active" && "active"}
                                    ${item.status === "Paused" && "paused"}
                                    ${item.status === "Pending" && "pending"}
                                    `}>
                                        <div
                                            className={`team-table__dot 
                                            ${item.status === "Active" && "active"}
                                            ${item.status === "Paused" && "paused"}
                                            ${item.status === "Pending" && "pending"}
                                            `}
                                        >&#8226;
                                        </div> {item.status}
                                    </div>
                                </td>
                                <td className={`team-table__row-cell`}>{item.contact_number}</td>
                                <td className={`team-table__row-cell`}>{item.contact_email}</td>
                                {!view && <td className={`team-table__row-cell`}>
                                    <div className="d-flex justify-end">
                                        {item.status === "Pending" && (
                                            <Tooltip title="Resend invitation link">
                                                <div
                                                    className="team-table__icon-wrapper"
                                                    onClick={() => resendLink(item.id.toString())}
                                                >
                                                    <img src={send} alt="send"/>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {item.status === "Active" && (
                                            <Tooltip title="Pause user">
                                                <div
                                                    className="team-table__icon-wrapper"
                                                    onClick={() => {
                                                        setEmployeeToPause(item)
                                                        setOpenPauseModal(true)
                                                    }}
                                                >
                                                    <img src={pause} alt="send"/>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {item.status === "Paused" && (
                                            <Tooltip title="Reactivate user">
                                                <div
                                                    className="team-table__icon-wrapper"
                                                    onClick={() => {changeStatus(item)}}
                                                >
                                                    <img src={activate} alt="send"/>
                                                </div>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="Delete user">
                                            <div
                                                className="team-table__icon-wrapper"
                                                onClick={() => {
                                                    setOpenDeleteModal(true)
                                                    setEmployeeToDelete(item)
                                                }}
                                            >
                                                <img src={trash} alt="trash"/>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Edit user">
                                            <div
                                                className="team-table__icon-wrapper"
                                                onClick={() => {
                                                    setEditEmployee(item)
                                                    setAddEmployeeModal(true)
                                                }}
                                            >
                                                <img src={edit} alt="edit"/>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </td>}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            </div>
            <ConfirmationModal
                type="warning"
                openModal={openDeleteModal}
                setOpenModal={setOpenDeleteModal}
                title={"Delete user"}
                subtitle={`Are you sure you want
                    to delete ${employeeToDelete?.first_name} ${employeeToDelete?.last_name}?`}
                cancelText="Cancel"
                confirmText="Delete user"
                confirm={() => {
                    deleteEmployee()
                }}
                cancel={cancelDelete}
                closeIcon={false}
                maskClosable={false}
            />
            <ConfirmationModal
                type="alert"
                openModal={openPauseModal}
                setOpenModal={setOpenPauseModal}
                title={"Pause user"}
                subtitle={`Are you sure you want
                    to pause ${employeeToPause?.first_name} ${employeeToPause?.last_name}?`}
                cancelText="Cancel"
                confirmText="Pause user"
                confirm={() => {
                    employeeToPause && changeStatus(employeeToPause)
                }}
                cancel={cancelPause}
                closeIcon={false}
                maskClosable={false}
            />
        </div>
    );
};
