import { Avatar, Col, Divider, Row, Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 55,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const stylesSmallLongInput = {
  width: 300,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
const stylesLargeInput = {
  width: 70,
  height: 40,
  margin: '10px',
  borderRadius: 40,
};
const stylesMediumInput = {
  width: 70,
  height: 27,
  margin: '10px',
  borderRadius: 40,
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const divider = {
  width: 600,
  height: 1,
  margin: '15px 20px 0 20px',
  borderRadius: 40,
};
const round = {
  width: 25,
  height: 25,
  borderRadius: 50,
};

const dash = {
  width: 10,
  height: 5,
  margin: '10px 0 0 0',
};
const MediumDash = {
  width: 15,
  height: 10,
  borderRadius: 40,
  margin: '10px 0 0 0',
};

export const TableSkeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__row table_filters">
          <Skeleton.Avatar size="small" style={round} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={dash} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={dash} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button active style={{ width: 140, height: 33 }} />
        </div>

        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
        <div className="skeleton__item table_row">
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
        </div>
      </div>
      <div className="skeleton__pagination">
        <div className="skeleton__wrapper">
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
          <Skeleton.Button size="small" style={dash} active />
        </div>
        <div className="skeleton__wrapper">
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Button size="small" style={dash} active />
          <Skeleton.Avatar size="small" style={MediumDash} active />
        </div>
      </div>
    </>
  );
};
