import { Checkbox, Form, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { Ficotable } from './Ficotable';
import { AddFicoModal } from './AddFicoModal';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { CustomInput } from '../../../customFields/CustomInput';
import { MultiSelect } from '../../../customFields/MultiSelect';
import { useGetAccountTypesQuery } from '../../../api/common';
import { AccountTypesItem } from '../../../api/types/common';
import { LoanProduct } from '../../../customFields/LoanProduct';
import { LoanProducts } from '../../../api/types/partners';
import { setIsDirtyAction } from '../../../reducers/common/commonSlice';
import { useAppDispatch } from '../../../hooks';
import { DiscountFeeTable } from '../../../components/Tables/DiscountFeeTable';
import { AddFeesModal, FeesI } from './AddFeesModal';

export interface ficoProps {
  name?: string;
  id?: string;
  fico_range_from: string;
  fico_range_to: string;
  fico_range_id?: number | null;
  apr: string;
}

interface Props {
  setLoanData: any;
  id: number;
  loanData: any;
  setError: any;
  errors: any;
  setErrorData: any;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface LoanProps {
  name?: string;
  personal: boolean;
  commercial: boolean;
  secured: boolean;
  coverage_amount_max: string;
  coverage_amount_min: string;
  fico_ranges: ficoProps[];
  loan_type_id: string;
  processing_fees: FeesI[];
  id: number;
  bank_fee?: string;
}

export const LoanTypeTab = ({
  setLoanData,
  id,
  loanData,
  setError,
  errors,
  setErrorData,
  setIsDirty,
}: Props) => {
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [isFicoModal, setIsFicoModal] = useState(false);
  const [editFico, setEditFico] = useState<number | null>(null);
  const [isEditFico, setIsEditFico] = useState(false);
  const { handleKeyPress } = usePreventSpaceTyping();
  const [LoanProdErr, setLoanProdErr] = useState<any>(false);
  const [covError, setCovError] = useState(false);
  const loanDataForId = loanData.find(
    (elem: LoanProps) => elem.loan_type_id + '' === id + ''
  );
  const [openFeesModal, setOpenFeesModal] = useState(false);
  const [existedFees, setExistedFees] = useState<string[]>([]);
  const [LoanProductsOptions, setLoanProductsOptions] =
    useState<AccountTypesItem[]>();
  const [editFee, setEditFee] = useState<FeesI | null>(null);

  useEffect(() => {
    if (accountTypesData?.data) {
      setLoanProductsOptions(accountTypesData.data);
    }
  }, [accountTypesData?.data]);

  useEffect(() => {
    let arrayFees = loanDataForId.processing_fees?.map((elem: FeesI) => {
      if (!elem.fee.includes('.')) {
        elem.fee += '.00';
      }
      return elem.fee;
    });
    setExistedFees(arrayFees);
    let arrayExistedLoan: number[] = [];
    loanDataForId.processing_fees?.forEach((elem: FeesI) => {
      elem.loan_products?.forEach((item: number) =>
        arrayExistedLoan.push(item)
      );
    });

    if (editFee) {
      arrayExistedLoan = arrayExistedLoan.filter(
        (elem: any) => !editFee.loan_products.includes(elem)
      );
    }
    const filteredData: any = accountTypesData?.data.map(
      (accountType: AccountTypesItem) => {
        const filteredProducts = accountType.loan_products.filter(
          (product: LoanProducts) => {
            const isProductExisted = arrayExistedLoan.includes(product.id);

            return !isProductExisted;
          }
        );
        return {
          ...accountType,
          loan_products: filteredProducts,
        };
      }
    );

    setLoanProductsOptions(filteredData);
  }, [loanDataForId.processing_fees, accountTypesData, editFee]);

  const setValue = (name: string, value: any) => {
    setIsDirty(true);
    const newArray = JSON.parse(JSON.stringify(loanData));
    const index = newArray.findIndex(
      (elem: LoanProps) => elem.loan_type_id + '' === id + ''
    );

    if (name === 'processing_fees') {
      const newValue = JSON.parse(JSON.stringify(value));
      newArray[index][name] = newValue;
      setLoanData(newArray);
    } else {
      newArray[index][name] = value;
      setLoanData(newArray);
    }
  };

  useEffect(() => {
    let isValid = true;
    if (LoanProdErr) {
      isValid = false;
    } else {
      for (let key in errors) {
        if (errors[key]) {
          isValid = false;
        }
      }
    }
    if (covError) {
      isValid = false;
    }

    setError(!isValid);
  }, [LoanProdErr, errors, covError]);

  //delete errors
  useEffect(() => {
    if (loanDataForId.processing_fees?.length) {
      setErrorData((prev: any) => ({
        ...prev,
        processing_fees: false,
      }));
    }
  }, [loanDataForId.processing_fees]);

  //delete errors
  useEffect(() => {
    if (
      loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
        .coverage_amount_min
    ) {
      setErrorData((prev: any) => ({
        ...prev,
        coverage_amount_min: false,
      }));
    }
    if (
      loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
        .coverage_amount_max
    ) {
      setErrorData((prev: any) => ({
        ...prev,
        coverage_amount_max: false,
      }));
    }
    if (
      loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
        .bank_fee
    ) {
      setErrorData((prev: any) => ({
        ...prev,
        bank_fee: false,
      }));
    }
  }, [
    loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
      .coverage_amount_min,
    loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
      .coverage_amount_max,
    loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
      .bank_fee,
  ]);

  useEffect(() => {
    if (
      loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
        .fico_ranges?.length
    ) {
      setErrorData((prev: any) => ({
        ...prev,
        fico_ranges: false,
      }));
    }
  }, [
    loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
      .fico_ranges?.length,
  ]);

  const currencyMaskFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);

    let value = e.target.value;
    const number = value.replace(/,/g, '');
    if (+number > 15) {
      e.target.value = '15.00';
    } else {
      const parts = number.split('.');
      if (parts.length === 2 && parts[1].length > 2) {
        e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }

    return e.target.value;
  };

  const currencyMaskTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value?.replace(/^0/, '');
    value = value?.replace(/\D/g, '');
    value = value?.replace(/(?<=\d)(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = value;
    const number = value?.replace(/,/g, '');
    if (+number > 500000) {
      e.target.value = '500,000';
    }
    return e.target.value;
  };

  function validateMinMax(min: number, max: number) {
    if (min > max) {
      setCovError(true);
      return 'Maximum value should be bigger than the minimum';
    } else {
      setCovError(false);
    }
    return null;
  }

  useEffect(() => {
    const min = loanData.find(
      (elem: LoanProps) => elem.loan_type_id + '' === id + ''
    ).coverage_amount_min;
    const minNumber = parseInt(min?.replace(/,/g, ''), 10);
    const max = loanData.find(
      (elem: LoanProps) => elem.loan_type_id + '' === id + ''
    ).coverage_amount_max;
    const maxNumber = parseInt(max?.replace(/,/g, ''), 10);
    validateMinMax(minNumber, maxNumber);
  }, [
    loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
      .coverage_amount_min,
    loanData.find((elem: LoanProps) => elem.loan_type_id + '' === id + '')
      .coverage_amount_max,
  ]);

  return (
    <>
      <div className={`partner-info`}>
        <div className="partner-info__row" style={{ margin: '20px 0' }}>
          <div className="item">
            <Checkbox
              className="checkbox-title"
              checked={
                loanData.find(
                  (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                ).personal
              }
              onChange={() => {
                setValue(
                  'personal',
                  !loanData.find(
                    (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                  ).personal
                );
              }}
            >
              Personal
            </Checkbox>
            <Checkbox
              className="checkbox-title"
              checked={
                loanData.find(
                  (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                ).commercial
              }
              onChange={() => {
                setValue(
                  'commercial',
                  !loanData.find(
                    (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                  ).commercial
                );
              }}
            >
              Commercial
            </Checkbox>
          </div>
          <div
            className="item"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Switch
              checked={
                loanData.find(
                  (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                ).secured
              }
              onChange={() => {
                setValue(
                  'secured',
                  !loanData.find(
                    (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                  ).secured
                );
              }}
            />{' '}
            <div style={{ margin: '0 0 0 10px' }} className="checkbox-title">
              {loanDataForId.secured ? 'Secured' : 'Unsecured'}
            </div>
          </div>
        </div>
        <div className="custom-field__title" style={{ padding: '0 0 1px 0 ' }}>
          Bank Fee <span className="error-text">*</span>
        </div>
        <CustomInput
          name=""
          className="input"
          onChange={(e) => {
            setValue('bank_fee', e.target.value);
          }}
          value={
            loanData.find(
              (elem: LoanProps) => elem.loan_type_id + '' === id + ''
            ).bank_fee
          }
          maxLength={5}
          onKeyPress={handleKeyPress}
          placeholder={`Indicate bank fee`}
          onInput={currencyMaskFee}
          onBlur={() => {
            if (
              !loanData.find(
                (elem: LoanProps) => elem.loan_type_id + '' === id + ''
              ).bank_fee
            ) {
              setErrorData((prev: any) => ({
                ...prev,
                bank_fee: true,
              }));
            } else {
              setErrorData((prev: any) => ({
                ...prev,
                bank_fee: false,
              }));
            }
          }}
          suffix={<div>%</div>}
          hasError={() => errors.bank_fee}
        />
        <DiscountFeeTable
          fees={loanDataForId.processing_fees}
          setOpenFeesModal={setOpenFeesModal}
          accountTypesData={accountTypesData?.data}
          error={errors.processing_fees}
          setEditFee={setEditFee}
          setFeesData={(data: ficoProps) => setValue('processing_fees', data)}
        />
        {errors.processing_fees && (
          <div className="error-text">
            Please add at least one discount fee to continue{' '}
          </div>
        )}
        <div
          className="item"
          style={{ margin: '13px 0 0 0', position: 'relative' }}
        >
          <div
            className="custom-field__title"
            style={{ padding: '0 0 1px 0 ' }}
          >
            Coverage Amounts <span className="error-text">*</span>
          </div>

          <div style={{ display: 'flex' }}>
            <CustomInput
              name=""
              className="input"
              onChange={(e) => {
                setValue('coverage_amount_min', e.target.value);
              }}
              value={
                loanData.find(
                  (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                ).coverage_amount_min
              }
              maxLength={100}
              onKeyPress={handleKeyPress}
              placeholder={`From`}
              validation={'onlyNumbers'}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                currencyMaskTo(e)
              }
              onBlur={() => {
                if (
                  !loanData.find(
                    (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                  ).coverage_amount_min
                ) {
                  setErrorData((prev: any) => ({
                    ...prev,
                    coverage_amount_min: true,
                  }));
                } else {
                  setErrorData((prev: any) => ({
                    ...prev,
                    coverage_amount_min: false,
                  }));
                }
              }}
              suffix={<div>$</div>}
              hasError={() => errors.coverage_amount_min}
            />

            <div className="members__from-to">From/To</div>

            <CustomInput
              name=""
              className="input"
              maxLength={100}
              onKeyPress={handleKeyPress}
              placeholder={`To`}
              validation={'onlyNumbers'}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                currencyMaskTo(e)
              }
              onChange={(e) => {
                setValue('coverage_amount_max', e.target.value);
              }}
              value={
                loanData.find(
                  (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                ).coverage_amount_max
              }
              onBlur={() => {
                if (
                  !loanData.find(
                    (elem: LoanProps) => elem.loan_type_id + '' === id + ''
                  ).coverage_amount_max
                ) {
                  setErrorData((prev: any) => ({
                    ...prev,
                    coverage_amount_max: true,
                  }));
                } else {
                  setErrorData((prev: any) => ({
                    ...prev,
                    coverage_amount_max: false,
                  }));
                }
              }}
              suffix={<div>$</div>}
              hasError={() => errors.coverage_amount_max}
            />
          </div>
          {covError && (
            <div
              className="error-text"
              style={{ position: 'absolute', bottom: '0' }}
            >
              Maximum value should be bigger than the minimum
            </div>
          )}
        </div>{' '}
        <Ficotable
          id={id}
          setIsFicoModal={setIsFicoModal}
          setFicoData={(data: ficoProps) => setValue('fico_ranges', data)}
          ficoData={
            loanData.find(
              (elem: LoanProps) => elem.loan_type_id + '' === id + ''
            )?.fico_ranges || []
          }
          setEditFico={setEditFico}
          setIsEditFico={setIsEditFico}
          error={errors.fico_ranges}
          required
        />
      </div>
      {isFicoModal && (
        <AddFicoModal
          id={id}
          openModal={isFicoModal}
          setOpenModal={setIsFicoModal}
          setFicoData={(data: ficoProps) => setValue('fico_ranges', data)}
          ficoData={
            loanData.find(
              (elem: LoanProps) => elem.loan_type_id + '' === id + ''
            ).fico_ranges
          }
        />
      )}
      {isEditFico ? (
        <AddFicoModal
          id={id}
          openModal={isEditFico}
          setOpenModal={setIsEditFico}
          setFicoData={(data: ficoProps) => setValue('fico_ranges', data)}
          editFico={editFico}
          ficoData={
            loanData.find(
              (elem: LoanProps) => elem.loan_type_id + '' === id + ''
            ).fico_ranges
          }
        />
      ) : (
        <></>
      )}

      {openFeesModal && (
        <AddFeesModal
          openModal={openFeesModal}
          setOpenModal={setOpenFeesModal}
          accountTypesData={LoanProductsOptions}
          setFeesData={(data: any) => setValue('processing_fees', data)}
          feeData={
            loanData.find(
              (elem: LoanProps) => elem.loan_type_id + '' === id + ''
            ).processing_fees
          }
          existedFees={existedFees}
          editFee={editFee}
        />
      )}
    </>
  );
};
