import { CustomInput } from '../../../customFields/CustomInput';
import searchIcon from '../../../assets/searchIcon.svg';
import { CustomSelect } from '../../../customFields/CustomSelect';
import cancel from '../../../assets/x-close.svg';
import plus from '../../../assets/plus.svg';
import { Button, DatePicker } from 'antd';
import { useAppSelector } from '../../../hooks';
import { selectTabs, setTicketTab } from '../../../reducers/tabs/tabsSlice';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { TicketListTable } from '../../../components/Tables/TicketListTable/TicketListTable';
import './index.scss';
import {
  useLazyGetTicketAdminsQuery,
  useLazyGetTicketsListQuery,
} from '../../../api/tickets';
import useDebounce from '../../../hooks/useDebounce';
import { disabledDateAfterToday } from '../../../helpers/common';
import {
  ADMIN,
  INBOX,
  MAIN_ACCOUNT,
  PARTNER,
  SUBMITTED,
  SUPER_ADMIN,
} from '../../../helpers/variables';
import { UserI } from '../../../reducers/profile/profileSlice';
import { useDispatch } from 'react-redux';
import { setOpenSubmitTicket as setOpenModal } from '../../../reducers/tabs/tabsSlice';
import { AnyAction } from '@reduxjs/toolkit';
import { TableSkeleton } from '../../../components/Skeletons/TableSkeleton';

export const TicketList = () => {
  const dispatch = useDispatch();
  const setOpenSubmitTicket = (payload: boolean): AnyAction =>
    dispatch(setOpenModal(payload));
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [getAdmins, { data: adminsData }] = useLazyGetTicketAdminsQuery();
  const [getTickets, { data, isLoading }] = useLazyGetTicketsListQuery();
  const { ticketTab } = useAppSelector(selectTabs);
  const [tab, setTab] = useState(INBOX);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const [rangeDates, setRangeDates] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const baseTableParams = {
    search: '',
    status: null,
    assigned_user_id: null,
    date_from: null,
    date_to: null,
    type: ticketTab,
    per_page: 50,
    page: 1,
  };
  const [tableParams, setTableParams] = useState(baseTableParams);

  const adminsOptions = adminsData?.data.map((item: any) => {
    return { label: item.id, value: `${item.first_name} ${item.last_name}` };
  });

  const clearFilters = () => {
    setSearch('');
    setRangeDates(null);
    setTableParams((prevState) => ({
      ...prevState,
      search: '',
      status: null,
      assigned_user_id: null,
      date_from: null,
      date_to: null,
    }));
  };

  useEffect(() => {
    dispatch(setTicketTab(tab));
  }, [tab]);

  useEffect(() => {
    if (user.role.key === SUPER_ADMIN || user.role.key === ADMIN) {
      getAdmins({
        per_page: 'all',
        search: '',
        order_by: '',
        order: 'desc',
        status_key: '',
        page: 1,
      });
    }
  }, [user.role.key]);

  useEffect(() => {
    if (user.role.key === MAIN_ACCOUNT || user.role.key === PARTNER) {
      dispatch(setTicketTab(SUBMITTED));
      setTableParams((prev) => {
        return {
          ...prev,
          type: ticketTab,
        };
      });
    }
  }, [user.role.key]);

  useEffect(() => {
    getTickets(tableParams);
  }, [tableParams]);

  useEffect(() => {
    if (!isLoading && data) {
      setLoading(false); // Отключаем индикатор загрузки после получения данных
    }
  }, [isLoading, data]);

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      search: debouncedSearch,
    }));
  }, [debouncedSearch]);

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      type: ticketTab,
    }));
  }, [ticketTab]);

  useEffect(() => {
    // @ts-ignore
    rangeDates &&
      setTableParams((prev) => ({
        ...prev,
        date_from: rangeDates?.startOf('day').toDate(),
        date_to: rangeDates?.endOf('day').toDate(),
      }));
  }, [rangeDates]);

  return (
    <div className="container">
      {isLoading || loading ? (
        <TableSkeleton />
      ) : (
        <>
          {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && (
            <div className="d-flex justify-between">
              <div className="d-flex">
                <Button
                  className={`tab-btn ${tab === INBOX && 'active'}`}
                  onClick={() => {
                    setLoading(true);
                    setTab(INBOX);
                  }}
                >
                  Inbox
                </Button>
                <Button
                  className={`tab-btn ${tab === SUBMITTED && 'active'}`}
                  onClick={() => {
                    setLoading(true);
                    setTab(SUBMITTED);
                  }}
                >
                  Submitted
                </Button>
              </div>
              <Button
                className="blue-btn"
                onClick={() => setOpenSubmitTicket(true)}
              >
                <img src={plus} alt="plus" />
                New Ticket
              </Button>
            </div>
          )}
          {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && (
            <div className="ticket_filters">
              <div style={{ width: '20%' }}>
                <CustomInput
                  value={search}
                  validation="onlyNumbers"
                  name="&nbsp;"
                  className="input-white-small"
                  placeholder="Search by ID"
                  prefix={<img src={searchIcon} alt="search" />}
                  onChange={(e) => setSearch(e.target.value)}
                  suffix={
                    search ? (
                      <img
                        style={{ margin: '0 0 0 0' }}
                        className="cancel-btn"
                        src={cancel}
                        onClick={() => setSearch('')}
                        alt="cancel"
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
              <div style={{ width: '20%' }}>
                <CustomSelect
                  value={tableParams.status}
                  name="Status"
                  options={[
                    { label: 'Open', value: 'Open' },
                    { label: 'Resolved', value: 'Resolved' },
                    { label: 'Closed', value: 'Closed' },
                  ]}
                  placeholder="All"
                  className="input-white-small"
                  onChange={(value) => {
                    // @ts-ignore
                    setTableParams((prev) => {
                      return { ...prev, status: value || '' };
                    });
                  }}
                />
              </div>
              <div style={{ width: '20%' }}>
                <CustomSelect
                  value={tableParams.assigned_user_id}
                  name="Assignee"
                  options={
                    (adminsOptions && [
                      { label: 'not_assigned', value: 'Not Assigned' },
                      ...adminsOptions,
                    ]) ||
                    []
                  }
                  placeholder="All"
                  className="input-white-small"
                  onChange={(value) => {
                    // @ts-ignore
                    setTableParams((prev) => {
                      return { ...prev, assigned_user_id: value || '' };
                    });
                  }}
                />
              </div>
              <div>
                <div className="custom-field__title">Date</div>
                <DatePicker
                  value={rangeDates}
                  style={{ width: '186px' }}
                  // value={rangeDates.date_from}
                  inputReadOnly
                  // onChange={onRangeChange}
                  onChange={(value) => setRangeDates(value)}
                  className="input-white-small"
                  disabledDate={disabledDateAfterToday}
                  allowClear={true}
                  clearIcon={
                    <img
                      className="cancel-btn"
                      style={{ margin: '0 0 0 0' }}
                      src={cancel}
                      onClick={() => {
                        setTableParams((prev) => ({
                          ...prev,
                          date_from: null,
                        }));
                      }}
                      alt="cancel"
                    />
                  }
                />
              </div>
              <div>
                <div className="custom-field__title">&nbsp;</div>
                <Button
                  className="blue-btn"
                  onClick={clearFilters}
                  disabled={
                    tableParams.search === '' &&
                    tableParams.status === null &&
                    tableParams.assigned_user_id === null &&
                    tableParams.date_from === null
                  }
                >
                  Clear all filters
                </Button>
              </div>
            </div>
          )}
          <TicketListTable
            data={data}
            setTableParams={setTableParams}
            tableParams={tableParams}
          />
        </>
      )}
    </div>
  );
};
