import {
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  SetStateAction,
} from 'react';

import { Button, FormInstance } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { ISelectOfferRes } from '../../../../../api/types/offer';
import success from '../../../../../assets/success.svg';
import { ArrowIcon } from '../../../../../components/icons/ArrowIcon';
import { HomeIcon } from '../../../../../components/icons/HomeIcon';
import { Spinner } from '../../../../../components/Spinner/Spinner';

import './index.scss';
import { LeaseToOwn } from './LeaseToOwn';
import { RevolvingLine } from './RevolvingLine';
import { GeneralItem } from './GeneralItem';
import { LoanTypesEnum } from '../OpensOffer/OpensOfferItem';
import { ConcoraItem } from './ConcoraItem';

interface SelectedOfferProps {
  selectedOfferData: ISelectOfferRes;
  isSelectedOfferLoading: boolean;
  isViewApplication?: boolean;
  setIsClickOnHome?: Dispatch<SetStateAction<boolean>>;
  setConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  form?: FormInstance<any>;
  goTo: () => void;
  buttonText: string;
  contextHolder: ReactElement<any, string | JSXElementConstructor<any>>;
}

export const SelectedOffer = ({
  selectedOfferData,
  isSelectedOfferLoading,
  isViewApplication,
  setIsClickOnHome,
  setConfirmationModal,
  form,
  goTo,
  buttonText,
  contextHolder,
}: SelectedOfferProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToHome = () => {
    navigate('/dashboard');
  };

  return (
    <>
      {contextHolder}
      <div className="container">
        {isViewApplication ? (
          <div className="new-member__breadscrumbs breadscrumbs">
            <div className="breadscrumbs__title" onClick={goToHome}>
              <HomeIcon fill="" />
            </div>
            <div className="breadscrumbs__separatop">
              <ArrowIcon fill="" />
            </div>
            <div
              className="breadscrumbs__title"
              onClick={() => {
                navigate('/applications');
              }}
            >
              Applications
            </div>
            <div className="breadscrumbs__separatop">
              <ArrowIcon fill="#4D4D56" />
            </div>
            <div className="breadscrumbs__title breadscrumbs__title-active">
              View Application
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  if (location.pathname.includes('edit')) {
                    navigate('/dashboard');
                  } else {
                    if (
                      form?.isFieldsTouched() &&
                      setIsClickOnHome &&
                      setConfirmationModal
                    ) {
                      setIsClickOnHome(true);
                      setConfirmationModal(true);
                    } else {
                      navigate('/dashboard');
                    }
                  }
                }}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <ArrowIcon fill="" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  if (location.pathname.includes('edit')) {
                    navigate('/applications');
                  } else {
                    if (
                      form?.isFieldsTouched() &&
                      setIsClickOnHome &&
                      setConfirmationModal
                    ) {
                      setIsClickOnHome(false);
                      setConfirmationModal(true);
                    } else {
                      navigate('/applications');
                    }
                  }
                }}
              >
                Applications
              </div>
              <div className="breadscrumbs__separatop">
                <ArrowIcon fill="#4D4D56" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                {location.pathname.includes('edit') ? 'Edit' : 'Create'}{' '}
                Application
              </div>
            </div>
            <div
              className="main__btns"
              style={{ padding: '8px 0 35px 0', margin: '0' }}
            ></div>
          </div>
        )}

        <div className="SelectedOffer__wrapper">
          <div className="SelectedOffer">
            <div className="SelectedOffer__offer">
              <div className="SelectedOffer__circle">
                <div className="SelectedOffer__icon">
                  <img
                    src={success}
                    alt="checked"
                    width={'84px'}
                    height={'84px'}
                  />
                </div>
              </div>
              <div className="SelectedOffer__description">
                <h2>Application is Awaiting for Signature </h2>
                <h3>Application is Awaiting for Signature </h3>
                <p className="SelectedOffer__description-text">
                  Contract was sent to the applicant’s email
                </p>
              </div>
              {selectedOfferData?.offer?.bank === 'genesis' && (
                <ConcoraItem selectedOfferData={selectedOfferData} />
              )}{' '}
              {selectedOfferData?.offer?.type === LoanTypesEnum.LeaseToOwn && (
                <LeaseToOwn selectedOfferData={selectedOfferData} />
              )}
              {selectedOfferData?.offer?.type === LoanTypesEnum.RevolvingLine &&
                selectedOfferData?.offer?.bank !== 'genesis' && (
                  <RevolvingLine selectedOfferData={selectedOfferData} />
                )}
              {selectedOfferData?.offer?.type !== LoanTypesEnum.RevolvingLine &&
                selectedOfferData?.offer?.bank !== 'genesis' &&
                selectedOfferData?.offer?.type !== LoanTypesEnum.LeaseToOwn && (
                  <GeneralItem selectedOfferData={selectedOfferData} />
                )}
              <Button
                className="blue-btn"
                type="primary"
                size="large"
                htmlType="submit"
                onClick={goTo}
                style={{ height: '45px', width: '100%', marginTop: '24px' }}
              >
                <span className="button-text">{buttonText}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
