import { Button, Modal } from 'antd';
import { FC } from 'react';
import attention from '../../assets/attention.svg';
import success from '../../assets/success.svg';
import close from '../../assets/x-close.svg';
import PhonePreview from '../../assets/PhonePreview.svg';
import './index.scss';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  closeIcon: boolean;
  maskClosable?: boolean;
  image: string;
}

export const ShowPreviewModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  closeIcon,
  maskClosable = true,
  image,
}) => {
  return (
    <Modal
      destroyOnClose
      className="showPreview-modal"
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={<></>}
      closeIcon={closeIcon ? <img src={close} /> : <></>}
      maskClosable={maskClosable}
    >
      <div className="showPreview-modal__container">
        <div className="showPreview-modal__title">
          Here is an example <br /> of how your uploaded logo fits the real
          mobile app.
        </div>
        <div className="showPreview-modal__subtitle">
          In case it does not seem suitable, be advised to edit your picture
          (make a transparent background or edit its aspect ratio)
        </div>
        <div className="showPreview-modal__image">
          <img src={PhonePreview} />
          <img src={image} className="showPreview-modal__image-preview" />
        </div>
        <Button
          className="blue-btn-lg w-100 showPreview-modal__btn"
          onClick={() => setOpenModal(false)}
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};
