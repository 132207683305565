import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { CustomInput } from '../../customFields/CustomInput';
import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { useAppSelector } from '../../hooks';
import useDebounce from '../../hooks/useDebounce';
import type { TimeRangePickerProps } from 'antd';
import type { Dayjs } from 'dayjs';
import { DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import { CustomerTable } from '../../components/Tables/CustomerTable';
import { useLazyGetCustomersListQuery } from '../../api/customers';
import { TableParamsCustomer, customerItem } from '../../api/types/customers';
import { isApiError } from '../../utils/general';
import { TableSkeleton } from '../../components/Skeletons/TableSkeleton';

const { RangePicker } = DatePicker;

export interface idsI {
  id: number;
  key: { status_key: number };
}

export interface TableAdminsDataI {
  length?: number;
  id: number;
  company_name: string;
  city: string;
  state: string;
  phone: string;
  status: string;
  logo: string;
}

export const Customers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [tableData, setTableData] = useState<customerItem[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    setTableParams((prev: TableParamsCustomer) => ({
      ...prev,
      search: debouncedSearch,
    }));
  }, [debouncedSearch]);

  const [tableParams, setTableParams] = useState<TableParamsCustomer>({
    per_page: 50,
    search: '',
    order_by: '',
    order: 'desc',
    status_key: '',
    page: 1,
    dates: {
      start: null,
      end: null,
    },
  });
  const [getList, { data, isLoading }] = useLazyGetCustomersListQuery();

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    setTableParams((prev: TableParamsCustomer) => ({ ...prev, page: 1 }));
  }, [tableParams.search, tableParams.status_key]);

  useEffect(() => {
    try {
      getList(tableParams);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Fetch applications failed');
    }
  }, [tableParams, trigger]);

  useEffect(() => {
    if (!data?.data) return;

    setTableData(data?.data);
  }, [data?.data]);

  const startOfThisWeek = dayjs().startOf('week');
  const startOfThisMonth = dayjs().startOf('month');
  const startOfThisYear = dayjs().startOf('year');

  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Yesterday',
      value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
    },
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs()],
    },
    {
      label: 'Week to date',
      value: [startOfThisWeek, dayjs()],
    },
    {
      label: 'Month to date',
      value: [startOfThisMonth, dayjs()],
    },
    {
      label: 'Year to date',
      value: [startOfThisYear, dayjs()],
    },
  ];

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const dateString0 = dateStrings[0];
      const dateString1 = dateStrings[1];
      let date0 = dayjs(dateString0);
      let date1 = dayjs(dateString1);
      const startOfDay = date0.startOf('day');
      const endOfDay = date1.endOf('day');

      setTableParams((prev: any) => ({
        ...prev,
        dates: {
          start: startOfDay ? startOfDay.toDate() : null,
          end: endOfDay ? endOfDay.toDate() : null,
        },
      }));
    } else {
      setTableParams((prev: any) => ({
        ...prev,
        dates: {
          start: null,
          end: null,
        },
      }));
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs();
  };

  return (
    <div className="container">
      {contextHolder}
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className="members">
          <div className="members__table">
            <div className="members__filters" style={{ margin: '0 0 0 0' }}>
              <CustomInput
                style={{ width: '350px', margin: '0 16px 0 0' }}
                maxLength={320}
                className="input-white-small"
                name=""
                placeholder={'Search by Name or Email'}
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                prefix={<img src={searchIcon} />}
                suffix={
                  search ? (
                    <img
                      className="cancel-btn"
                      style={{ margin: '0 0 0 0' }}
                      src={cancel}
                      onClick={() => setSearch('')}
                    />
                  ) : (
                    <></>
                  )
                }
              />

              <RangePicker
                style={{ width: '30%', margin: '-20px 0 0px 0' }}
                inputReadOnly
                presets={rangePresets}
                onChange={onRangeChange}
                className="input-white-small"
                disabledDate={disabledDate}
                allowClear={true}
                clearIcon={
                  <img
                    className="cancel-btn"
                    style={{ margin: '0 0 0 0' }}
                    src={cancel}
                    onClick={() => {
                      setTableParams((prev: any) => ({
                        ...prev,
                        dates: {
                          start: null,
                          end: null,
                        },
                      }));
                    }}
                  />
                }
              />
            </div>
            <CustomerTable
              data={tableData}
              setTableParams={setTableParams}
              tableParams={tableParams}
              metaData={data}
              setTrigger={setTrigger}
            />
          </div>
        </div>
      )}
    </div>
  );
};
