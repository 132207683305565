import logo from "../../assets/logo.svg";
import { ChangeEvent, useRef, useState, FocusEvent, useEffect } from "react";
import "./index.scss";
import { Button, message } from "antd";
import {
  useValidateCodeMutation,
  useValidateLinkMutation,
} from "../../api/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner/Spinner";
import Rectangle from "../../assets/Rectangle.svg";
import { CustomErrorType } from "../../types.ts/commonTypes";
import { isApiError } from "../../utils/general";
import {ExpiredLink} from "./ExpiredLink";

interface codeI {
  code: string;
  email: string;
}

interface linkI {
  email: string;
  token: string;
}

export const TokenPage = () => {
  const [inputValues, setInputValues] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
  ]);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState<string>("");
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [
    validateCode,
    {
      isError: validationCodeIsError,
      error: validationCodeError,
      isSuccess: validationCodeIsSuccess,
      isLoading: validationCodeLoading,
    },
  ] = useValidateCodeMutation();
  const [validateLink, { isError: validationLinkIsError, error: linkError}] =
    useValidateLinkMutation();
  const [searchParams] = useSearchParams();

  const errorMessage = (text: string) => {
    messageApi.open({
      type: "error",
      content: text,
      style: {
        marginTop: "90vh",
      },
    });
  };

  useEffect(() => {
    if (!searchParams) return;
    const data: linkI = {
      email: decodeURIComponent(searchParams.get("email") || ""),
      token: decodeURIComponent(searchParams.get("token") || ""),
    };
    validateLink(data);
  }, [searchParams]);

  useEffect(() => {
    if (validationCodeIsError) {
      if ((validationCodeError as CustomErrorType)?.data?.message) {
        setError("Code is invalid");
      }
    }
    // if (validationLinkIsError) {
    //   navigate("/expired");
    // }
    if (validationCodeIsSuccess) {
      navigate(
        `/reset_password?${window.location.search.substring(
          1
        )}&code=${inputValues.join("")}`
      );
    }
  }, [validationCodeIsError, validationLinkIsError, validationCodeIsSuccess]);

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setError("");
    const { value } = event.target;
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value !== "" && index < 4) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputBlur = () => {
    if (inputValues.some((value) => value === "")) {
      setError("This field is required");
    } else {
      setError("");
    }
  };

  const handleInputKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && inputValues[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const validateCodeFunc = async () => {
    const data: codeI = {
      email: decodeURIComponent(searchParams.get("email") || ""),
      code: inputValues.join(""),
    };
    try {
      await validateCode(data);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || "Validate code failed");
    }
  };

  const handleFormSubmit = () => {
    handleInputBlur();
    let isValid = true;
    if (inputValues.some((value) => value === "")) {
      isValid = false;
    }
    if (isValid) {
      validateCodeFunc();
    }
  };

  const handleInputPaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pastedText = event.clipboardData.getData("text");
    const newInputValues = [...inputValues];

    for (
      let i = 0;
      i < pastedText.length && index + i < newInputValues.length;
      i++
    ) {
      newInputValues[index + i] = pastedText[i];
    }

    setInputValues(newInputValues);
    setError("");
  };

  if (validationLinkIsError) {
    // @ts-ignore
    return <ExpiredLink title={linkError?.data?.message}/>
  }

  return (
    <>
      {contextHolder}
      <div className="token-page">
        <img src={Rectangle} className="token-page__rectangle" />

        <div className="token-page__logo-container">
          <img src={logo} />
        </div>
        <div className="token-page__code-container">
          <div className="token-page__title">
            Please enter
            <br /> your temporary code
          </div>
          <div className="token-page__code">
            {inputValues.map((value, index) => (
              <input
                className={`token-page__code-input ${error ? "error" : ""}`}
                key={index}
                ref={(el) =>
                  (inputRefs.current[index] = el as HTMLInputElement)
                }
                type="text"
                value={value}
                maxLength={1}
                onChange={(e) => handleInputChange(index, e)}
                onBlur={handleInputBlur}
                onKeyDown={(e) => handleInputKeyDown(index, e)}
                onPaste={(e) => handleInputPaste(index, e)}
              />
            ))}
            <div
              className="error-text"
              style={{ margin: "6px 0 0 0", height: "30px" }}
            >
              {error}
            </div>
          </div>
          <Button
            className={`blue-btn ${
              inputValues.some((value) => value === "") ? "disable" : ""
            }`}
            onClick={() => {
              if (inputValues.some((value) => value === "")) {
                return;
              } else {
                handleFormSubmit();
              }
            }}
          >
            Next {validationCodeLoading && <Spinner />}
          </Button>
        </div>
      </div>
    </>
  );
};
