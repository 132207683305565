import { Row, Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 55,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const dash = {
  width: 10,
  height: 5,
  margin: '10px 0 0 0',
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const stylesSmallLongInput = {
  width: 300,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const round = {
  width: 25,
  height: 25,
  borderRadius: 50,
};

export const ProfileSkeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__row">
          <Skeleton.Image active style={{ height: '60px' }} />

          <div className="skeleton__block">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          </div>
          <div className="skeleton__block">
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <Skeleton.Avatar size="small" style={round} active />
          <div className="skeleton__block">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          </div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
        </div>
        <div className="skeleton__row">
          <div className="skeleton__white-row">
            <div className="skeleton__row">
              <Skeleton.Input
                size="small"
                style={stylesSmallLongInput}
                active
              />{' '}
              <Skeleton.Button size="small" style={dash} active />
            </div>

            <div className="skeleton__row" style={{ margin: '0' }}>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Button style={stylesExtraSmallInput} active />

                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />{' '}
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />{' '}
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button style={stylesExtraSmallInput} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}></div>
              <div className="skeleton__row" style={{ margin: '0' }}></div>
              <div className="skeleton__row" style={{ margin: '0' }}></div>
            </div>
          </div>
        </div>
        <div className="skeleton__row" style={{ width: '100%' }}>
          <div className="skeleton__row" style={{ width: '63%' }}>
            <div className="skeleton__white-row">
              <div className="skeleton__row">
                <Skeleton.Input
                  size="small"
                  style={stylesSmallLongInput}
                  active
                />{' '}
                <Skeleton.Button size="small" style={dash} active />
              </div>

              <div className="skeleton__row" style={{ margin: '0' }}>
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div>
                    <Skeleton.Button style={stylesExtraSmallInput} active />

                    <div
                      className="skeleton__row"
                      style={{ justifyContent: 'start' }}
                    >
                      <Skeleton.Button
                        size="small"
                        style={stylesExtraSmallInput}
                        active
                      />{' '}
                      <Skeleton.Avatar size="small" style={round} active />
                    </div>
                  </div>
                </div>
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div>
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                    <div
                      className="skeleton__row"
                      style={{ justifyContent: 'start' }}
                    >
                      <Skeleton.Button style={stylesExtraSmallInput} active />
                    </div>
                  </div>
                </div>
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div>
                    <Skeleton.Button style={stylesExtraSmallInput} active />

                    <div
                      className="skeleton__row"
                      style={{ justifyContent: 'start' }}
                    >
                      <Skeleton.Button
                        size="small"
                        style={stylesExtraSmallInput}
                        active
                      />{' '}
                      <Skeleton.Avatar size="small" style={round} active />
                    </div>
                  </div>
                </div>
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div>
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                    <div
                      className="skeleton__row"
                      style={{ justifyContent: 'start' }}
                    >
                      <Skeleton.Button
                        size="small"
                        style={stylesExtraSmallInput}
                        active
                      />
                    </div>
                  </div>
                </div>
                <div className="skeleton__row" style={{ margin: '0' }}></div>
                <div className="skeleton__row" style={{ margin: '0' }}></div>
                <div className="skeleton__row" style={{ margin: '0' }}></div>
              </div>
            </div>
          </div>
          <div className="skeleton__row" style={{ width: '33%' }}>
            <div className="skeleton__white-row">
              <Skeleton.Input size="small" style={stylesSmallInput} active />
              <div className="skeleton__row">
                <Skeleton.Button active style={{ width: 140, height: 33 }} />
                <Skeleton.Avatar size="small" style={round} active />

                <Skeleton.Avatar size="small" style={dash} active />
              </div>
            </div>
          </div>
        </div>
        <div className="skeleton__row">
          <div className="skeleton__white-row">
            <div className="skeleton__row">
              <Skeleton.Input
                size="small"
                style={stylesSmallLongInput}
                active
              />{' '}
              <Skeleton.Button size="small" style={dash} active />
            </div>

            <div className="skeleton__row" style={{ margin: '0' }}>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />{' '}
                  <Skeleton.Avatar size="small" style={round} active />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}>
                <div>
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />{' '}
                  <Skeleton.Avatar size="small" style={round} active />
                  <div
                    className="skeleton__row"
                    style={{ justifyContent: 'start' }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={stylesExtraSmallInput}
                      active
                    />
                  </div>
                </div>
              </div>
              <div className="skeleton__row" style={{ margin: '0' }}></div>
              <div className="skeleton__row" style={{ margin: '0' }}></div>
              <div className="skeleton__row" style={{ margin: '0' }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
