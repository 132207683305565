import axios, { AxiosResponse, AxiosError } from 'axios';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrlSunctum = process.env.REACT_APP_SUNCTUN_URL;

axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;

export const downloadFileFunc = (data: any, reportType: string) => {
  const accountTypeIdsString = data.account_type_ids.join(',');
  axios.defaults.withCredentials = true;
  return axios({
    method: 'get',
    url: `${baseUrl}/reports/${reportType}/export`,
    params: {
      date_from: moment(data.date_from).format('YYYY-MM-DD HH:mm:ss'),
      date_to: moment(data.date_to).format('YYYY-MM-DD HH:mm:ss'),
      order: data.order,
      order_by: data.order_by,
      search: data.search,
      account_type_ids: data.account_type_ids.map((elem: any) => +elem),
      bank_ids: data?.bank_ids?.map((elem: any) => +elem),
      partners: data?.partners?.map((elem: any) => +elem),
      loan_products: data?.loan_products?.map((elem: any) => +elem),
      main_accounts: data?.main_accounts?.map((elem: any) => +elem),
      application_statuses: data.application_statuses.map((elem: any) => elem),
      transaction_statuses: data.transaction_statuses.map((elem: any) => elem),
    },
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
  });
};

export const downloadTicketFiles = (id: string | undefined) => {
  return axios({
    method: 'get',
    url: `${baseUrl}/ticket/${id}/download-files`,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
  });
};

export const beforeLogin: any = async (data: any) => {
  const api = axios.create({ baseURL: baseUrlSunctum });
  api.defaults.withCredentials = true;

  try {
    await api.get('/sanctum/csrf-cookie');
    const response = await api.post('/api/auth/login', data);
    return response;
  } catch (error: any) {
    return { error: error.response || error.message || error };
  }
};

export const beforeInit: any = async (data: any) => {
  const api = axios.create({ baseURL: baseUrlSunctum });
  api.defaults.withCredentials = true;

  try {
    await api.get('/sanctum/csrf-cookie');
    const response = await api.post('/api/auth/init', data);
    return response;
  } catch (error: any) {
    return { error: error.response || error.message || error };
  }
};

// export const validateInvitation: any = async (data: any) => {
//   const api = axios.create({ baseURL: baseUrlSunctum });
//   api.defaults.withCredentials = true;

//   try {
//     await api.get('/sanctum/csrf-cookie');
//     const response = await api.post('api/auth/invites/validate-token', data);
//     return response;
//   } catch (error: any) {
//     return { error: error.response || error.message || error };
//   }
// };
