import { iconI } from './types';

export const ReportsIcon = ({ fill, style }: iconI) => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
    <path d="M8.14871 7.06704C8.44509 6.86901 8.75628 6.69725 9.07872 6.55285C9.9933 6.14326 10.4506 5.93847 11.059 6.33261C11.6673 6.72675 11.6673 7.37328 11.6673 8.66634V9.66634C11.6673 10.9234 11.6673 11.552 12.0578 11.9425C12.4484 12.333 13.0769 12.333 14.334 12.333H15.334C16.6271 12.333 17.2736 12.333 17.6677 12.9414C18.0619 13.5497 17.8571 14.007 17.4475 14.9216C17.3031 15.2441 17.1313 15.5552 16.9333 15.8516C16.2374 16.8931 15.2482 17.7049 14.091 18.1842C12.9337 18.6636 11.6603 18.789 10.4317 18.5447C9.2032 18.3003 8.07471 17.6971 7.18898 16.8114C6.30325 15.9256 5.70005 14.7971 5.45568 13.5686C5.21131 12.34 5.33673 11.0666 5.81608 9.90935C6.29544 8.75208 7.1072 7.76295 8.14871 7.06704Z" stroke={fill} strokeWidth="1.2"/>
    <path d="M19.298 7.71202C18.7568 6.33822 17.6618 5.24321 16.288 4.702C15.2603 4.29714 14.334 5.22812 14.334 6.33269V8.99936C14.334 9.36755 14.6325 9.66603 15.0007 9.66603H17.6673C18.7719 9.66603 19.7029 8.73972 19.298 7.71202Z" stroke={fill} strokeWidth="1.2"/>
  </svg>

);
};
