import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  LoginRequest,
  LoginResponse,
  PasswordResponse,
  ResetPasswordMutationParams,
  SetPasswordMutationParams,
  ValidateRespons,
  validateCodeParams,
  validateLinkParams,
} from './types/auth';
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      const csrfToken = Cookies.get('XSRF-TOKEN');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (csrfToken) {
        headers.set('X-XSRF-TOKEN', csrfToken);
      }

      headers.set('Accept', 'application/json');
      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<PasswordResponse, string>({
      query: (email) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    resetPassword: builder.mutation<
      PasswordResponse,
      ResetPasswordMutationParams
    >({
      query: (credentials) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    setPassword: builder.mutation<PasswordResponse, SetPasswordMutationParams>({
      query: (credentials) => ({
        url: '/auth/invites/set-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    validateCode: builder.mutation<ValidateRespons, validateCodeParams>({
      query: (credentials) => ({
        url: '/auth/reset-password/validate-code',
        method: 'POST',
        body: credentials,
      }),
    }),
    validateLink: builder.mutation<ValidateRespons, validateLinkParams>({
      query: (credentials) => ({
        url: '/auth/reset-password/validate-token',
        method: 'POST',
        body: credentials,
      }),
    }),
    validateInvitation: builder.mutation<ValidateRespons, validateLinkParams>({
      query: (credentials) => ({
        url: '/auth/invites/validate-token',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useValidateCodeMutation,
  useValidateLinkMutation,
  useValidateInvitationMutation,
  useSetPasswordMutation,
} = authApi;
