import { Avatar, Col, Divider, Row, Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 55,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const stylesSmallLongInput = {
  width: 300,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
const stylesLargeInput = {
  width: 70,
  height: 40,
  margin: '10px',
  borderRadius: 40,
};
const stylesMediumInput = {
  width: 40,
  height: 27,
  margin: '10px',
  borderRadius: 40,
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const divider = {
  width: 600,
  height: 1,
  margin: '15px 20px 0 20px',
  borderRadius: 40,
};
const round = {
  width: 25,
  height: 25,
  borderRadius: 50,
};

const dash = {
  width: 10,
  height: 5,
  margin: '10px 0 0 0',
};
const MediumDash = {
  width: 15,
  height: 10,
  borderRadius: 40,
  margin: '10px 0 0 0',
};

export const AnotheTableSkeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__row table_filters">
          <div className="skeleton__row" style={{ width: '40%' }}>
            <Skeleton.Image active style={{ height: '60px' }} />
            <div>
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
            </div>
          </div>

          <Skeleton.Button active style={{ width: 140, height: 44 }} />
        </div>
        <div className="skeleton__row" style={{ width: '15%' }}>
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <Skeleton.Button size="small" style={dash} active />
        </div>

        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
        <div className="skeleton__item another-table_item">
          <div className="skeleton__column">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
          </div>
          <Skeleton.Input size="large" style={stylesMediumInput} active />
          <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
          <Skeleton.Input size="small" style={stylesSmallInput} active />
          <div className="skeleton__row" style={{ width: '7%', margin: '0' }}>
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
            <Skeleton.Avatar size="small" style={round} active />
          </div>
        </div>
      </div>
    </>
  );
};
