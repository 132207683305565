import {Pagination} from '../Pagination';
import {useNavigate} from 'react-router-dom';
import './index.scss';
import {useAppSelector} from '../../../hooks';
import moment from 'moment';
import {ADMIN, SUBMITTED, SUPER_ADMIN} from "../../../helpers/variables";
import {selectTabs} from "../../../reducers/tabs/tabsSlice";
import letter from "../../../assets/letter_48px.svg";
import {trimmer} from "../../../helpers/common";


interface PartnersTableProps {
    data: any
    setTableParams: React.Dispatch<React.SetStateAction<any>>;
    tableParams: any;
}



export const TicketListTable = ({
                                    data,
                                    setTableParams,
                                    tableParams,
                                }: PartnersTableProps) => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.profile);
    const { ticketTab } = useAppSelector(selectTabs)


    return (
        <>
            <div>
                <div className="ticket-table">
                    <div className="ticket-table__header">
                    <div className="ticket-table__header__item">Ticket ID</div>
                        {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && ticketTab !== SUBMITTED
                        && <div className="ticket-table__header__item">Reporter</div>}
                        <div className="ticket-table__header__item">Category</div>
                    <div className="ticket-table__header__item">Subject</div>
                        {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN)
                            && <div className="ticket-table__header__item">Assignee</div>}
                    <div className="ticket-table__header__item">Status</div>
                    <div className="ticket-table__header__item">Date Created</div>
                    </div>
                    {data?.data.length === 0 &&
                        <div className="ticket-table__empty-continer">
                        <div className="employees__empty">
                        <img src={letter} alt="team member"/>
                        <div className="employees__empty__title">
                            No tickets
                        </div>
                        <div className="employees__empty__text">
                            All your tickets will be shown here
                        </div>
                    </div>
                        </div>}
                    {data?.data?.map((item:any) => {
                        return (
                            <div
                                className={`ticket-table__row ${!item.is_read && 'active'}`}
                                onClick={() => {navigate(`/ticket/${item.id}`)}}
                            >
                                <div className="ticket-table__row__item">
                                    {item.item_id ?? item.id}
                                </div>
                                {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN)  && ticketTab !== SUBMITTED
                                    && <div className="ticket-table__row__item">
                                        {trimmer(item.user.name, 20)}
                                </div>}
                                <div className="ticket-table__row__item">
                                    {item.category.name}
                                </div>
                                <div className="ticket-table__row__item">
                                    {trimmer(item.name, 20)}
                                </div>
                                {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN)
                                    && <div className="ticket-table__row__item">
                                    {item.assigned_user?.name ?? 'Not assigned'}
                                </div>}
                                <div className="ticket-table__row__item">
                                    <div className={`team-table__status 
                                    ${item.status === "Resolved" && "active"}
                                    ${item.status === "Closed" && "paused"}
                                    ${item.status === "Open" && "pending"}
                                    `}>
                                        <div
                                            className={`team-table__dot 
                                            ${item.status === "Resolved" && "active"}
                                            ${item.status === "Closed" && "paused"}
                                            ${item.status === "Open" && "pending"}
                                            `}
                                        >&#8226;
                                        </div> {item.status}
                                    </div>
                                </div>
                                <div className="ticket-table__row__item">
                                    {moment(item.created_at).format("MM/DD/YYYY h:mma")}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {data?.data?.length > 0 && (
                <Pagination
                    setTableParams={setTableParams}
                    metaData={data}
                    tableParams={tableParams}
                />
            )}
        </>
    );
};
