import React, { FC, useEffect, useRef } from 'react';
import Dragger from 'antd/es/upload/Dragger';
import UploadIcon from '../assets/UploadIcon.svg';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import done from '../assets/done.svg';
import AttentionCircle from '../assets/AttentionCircle.svg';
import { Spinner } from '../components/Spinner/Spinner';
import './index.scss';

interface DraggerFileI {
  allowsFormat: string[];
  onChange: any;
  fileList: any;
  maxSizeBytes: number;
  text: string;
  hasError: () => boolean;
  setErr: React.Dispatch<React.SetStateAction<string | null>>;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  err: string | null;
  isLoading: boolean;
  name: string;
  isEdit?: boolean;
  faildFile: any;
  setFaildFile: React.Dispatch<React.SetStateAction<any>>;
}

export const DraggerFile: FC<DraggerFileI> = ({
  allowsFormat,
  onChange,
  fileList,
  maxSizeBytes,
  text,
  hasError,
  setErr,
  err,
  isLoading,
  name,
  isEdit,
  setIsEdit,
  faildFile,
  setFaildFile,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEdit) {
      ref?.current?.click();
      setIsEdit && setIsEdit(false);
    }
  }, [isEdit]);

  const handleFileChange = ({ fileList }: UploadChangeParam<any>) => {
    const file = fileList[fileList.length - 1];
    const maxSize = maxSizeBytes;
    let isValid = false;

    if (
      allowsFormat.find(
        (elem: string) => file?.name?.split('.').pop() === elem
      ) &&
      file?.size < maxSize
    ) {
      isValid = true;
    }

    if (fileList.length <= 0 || !isValid) {
      setErr(
        `Invalid format. Please upload a file with supported formats (e.g., ${allowsFormat.map(
          (elem: string) => elem.toUpperCase()
        )})`
      );
      onChange([]);
    } else {
      setErr('');
      onChange([file]);
    }
    setFaildFile([file]);
  };

  return (
    <>
      {isLoading && (
        <div className="uploaded-file">{/* <Spinner color="grey" /> */}</div>
      )}
      {name !== 'appLogo' && (
        <>
          {err?.includes('Invalid format') && !isLoading && (
            <>
              <Dragger
                fileList={fileList}
                onChange={handleFileChange}
                className={hasError() || err ? 'error' : ''}
              >
                <img src={AttentionCircle} className="image-dragger" />
                <div className="uploaded-file__name">
                  {faildFile?.[0]?.name}{' '}
                  <span style={{ color: 'var(--grey-400, #808086)' }}>
                    {faildFile
                      ? `${(faildFile?.[0]?.size / 1024 / 1024).toFixed(2)} MB`
                      : ''}
                  </span>
                </div>
                <div>
                  <span className="uploaded-file__bluetext">Choose </span>
                  another {name === 'logo' ? 'picture' : 'file'}
                </div>
              </Dragger>
              <div
                className="error-text"
                style={{ width: '125px', marginTop: '4px' }}
              >
                {err}
              </div>
            </>
          )}
          {err?.includes('required') && !isLoading && (
            <>
              <Dragger
                fileList={fileList}
                onChange={handleFileChange}
                className={hasError() || err ? 'error' : ''}
              >
                <img src={UploadIcon} className="image-dragger" />
                <div className="upload-text">
                  <span className="upload-text__click">Click to upload</span> or
                  drag and drop
                  <div className="upload-text__subtext">{text}</div>
                </div>
              </Dragger>
              <div
                className="error-text"
                style={{ width: '188px', marginTop: '4px' }}
              >
                {err}
              </div>
            </>
          )}
          {!err && fileList.length > 0 && !isLoading && (
            <Dragger
              fileList={fileList}
              onChange={handleFileChange}
              className={hasError() || err ? 'error' : ''}
            >
              <img src={done} style={{ width: '30px' }} />
              {fileList[0]?.name && (
                <div className="uploaded-file__name">
                  {fileList[0]?.name}{' '}
                  <span style={{ color: 'var(--grey-400, #808086)' }}>
                    {faildFile
                      ? `${(faildFile?.[0]?.size / 1024 / 1024).toFixed(2)} MB`
                      : ''}
                  </span>
                </div>
              )}
              <div style={{ margin: '0 0 0 0', fontSize: '10px' }}>
                <span className="uploaded-file__bluetext">Edit </span>
                or{' '}
                <span
                  className="uploaded-file__bluetext"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange([]);
                  }}
                >
                  Delete
                </span>{' '}
                {name === 'Logo' ? 'picture' : 'file'}
              </div>
            </Dragger>
          )}
          {!err && !fileList.length && !isLoading && (
            <Dragger
              fileList={fileList}
              onChange={handleFileChange}
              className={hasError() || err ? 'error' : ''}
            >
              <img src={UploadIcon} className="image-dragger" />
              <div className="upload-text">
                <span className="upload-text__click">Click to upload</span> or
                drag and drop
                <div className="upload-text__subtext">{text}</div>
              </div>
            </Dragger>
          )}
        </>
      )}
      {name === 'appLogo' && (
        <>
          {err?.includes('Invalid format') && !isLoading && (
            <>
              <Dragger
                fileList={fileList}
                onChange={handleFileChange}
                className={`${hasError() || err ? 'error' : ''} appLogo`}
              >
                <div className="error-wrapper">
                  <img src={AttentionCircle} className="image-dragger" />
                  <div>
                    <div className="uploaded-file__name">
                      {faildFile?.[0]?.name}{' '}
                      <span style={{ color: 'var(--grey-400, #808086)' }}>
                        {faildFile
                          ? `${(faildFile?.[0]?.size / 1024 / 1024).toFixed(
                              2
                            )} MB`
                          : ''}
                      </span>
                    </div>
                    <div>
                      <span className="uploaded-file__bluetext">Choose </span>
                      another {name === 'appLogo' ? 'picture' : 'file'}
                    </div>
                  </div>
                </div>
              </Dragger>
              <div
                className="error-text error-text-app-logo"
                style={{ width: '188px', marginTop: '4px' }}
              >
                {err}
              </div>
            </>
          )}
          {err?.includes('required') && !isLoading && (
            <>
              <Dragger
                fileList={fileList}
                onChange={handleFileChange}
                className={`${hasError() || err ? 'error' : ''} appLogo`}
              >
                <div className="appLogo__wrapper">
                  <div className="appLogo__image-wrapper">
                    <img src={UploadIcon} className="image-dragger" />
                    <div className="upload-text">
                      <div className="upload-text__click">Click to upload</div>
                      or drag and drop
                    </div>
                  </div>

                  <div className="upload-text">
                    <div className="upload-text__subtext">{text}</div>
                  </div>
                </div>
              </Dragger>

              <div
                className="error-text"
                style={{ width: '188px', marginTop: '4px' }}
              >
                {err}
              </div>
            </>
          )}
          {!err && fileList.length > 0 && !isLoading && (
            <Dragger
              fileList={fileList}
              onChange={handleFileChange}
              className={`${hasError() || err ? 'error' : ''} appLogo`}
            >
              <img src={done} />
              <div className="uploaded-file__name">
                {fileList[0]?.name}{' '}
                <span style={{ color: 'var(--grey-400, #808086)' }}>
                  {faildFile
                    ? `${(faildFile?.[0]?.size / 1024 / 1024).toFixed(2)} MB`
                    : ''}
                </span>
              </div>
              <div>
                <span className="uploaded-file__bluetext">Edit </span>
                or{' '}
                <span
                  className="uploaded-file__bluetext"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange([]);
                  }}
                >
                  Delete
                </span>{' '}
                {name === 'appLogo' ? 'picture' : 'file'}
              </div>
            </Dragger>
          )}
          {!err && !fileList.length && !isLoading && (
            <Dragger
              fileList={fileList}
              onChange={handleFileChange}
              className={`${hasError() || err ? 'error' : ''} appLogo`}
            >
              <div className="appLogo__wrapper">
                <div className="appLogo__image-wrapper">
                  <img src={UploadIcon} className="image-dragger" />
                  <div className="upload-text">
                    <div className="upload-text__click">Click to upload</div>
                    or drag and drop
                  </div>
                </div>

                <div className="upload-text">
                  <div className="upload-text__subtext">{text}</div>
                </div>
              </div>
            </Dragger>
          )}
        </>
      )}

      <Dragger
        fileList={fileList}
        onChange={handleFileChange}
        style={{ visibility: 'hidden', position: 'absolute', top: '0' }}
      >
        <div ref={ref}></div>
      </Dragger>
    </>
  );
};
