import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  MainAccoutResponse,
  AccountTypesResponse,
  MainAccountParams,
  MeResponse,
  BanksResponse,
} from './types/common';

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery,
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: (arg) => ({
        url: '/auth/logout',
        method: 'POST',
        body: arg,
      }),
    }),
    getAccountTypes: builder.query<AccountTypesResponse, void>({
      query: () => ({
        url: '/account-types/',
        method: 'GET',
      }),
    }),
    getMainAccounts: builder.query<MainAccoutResponse, MainAccountParams>({
      query: (params) => ({
        url: '/main-accounts/search',
        method: 'GET',
        params,
      }),
    }),
    getMe: builder.query<MeResponse, void>({
      query: () => ({
        url: '/auth/me',
        method: 'GET',
      }),
    }),
    getBanks: builder.query<
      BanksResponse,
      {
        name?: string;
        main_account_id?: string | number;
      }
    >({
      query: (params) => ({
        url: `/banks`,
        method: 'GET',
        params: params,
      }),
    }),
  }),
});

export const {
  useLogoutMutation,
  useLazyGetMeQuery,
  useGetAccountTypesQuery,
  useGetMainAccountsQuery,
  useGetBanksQuery,
  useLazyGetBanksQuery,
} = commonApi;
