import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const storedToken = localStorage.getItem('token');

const initialState = {
  isAuth: !!storedToken,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.isAuth = false;
    },
  },
});

export const selectAuthUser = (state: RootState) => state.auth;

export const { logout } = authSlice.actions;

export default authSlice;
