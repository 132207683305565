import { Avatar, Col, Divider, Row, Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 55,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
const avatar = {
  width: 25,
  height: 25,
  borderRadius: 50,
};
const stylesLargeInput = {
  width: 70,
  height: 40,
  margin: '10px',
  borderRadius: 40,
};
const round = {
  width: 25,
  height: 15,
  borderRadius: 50,
};
const stylesMediumInput = {
  width: 40,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const divider = {
  width: 600,
  height: 1,
  margin: '15px 20px 0 20px',
  borderRadius: 40,
};

const dash = {
  width: 10,
  height: 5,
  margin: '5px 0 0 0',
};
const stylesSmallLongInput = {
  width: 300,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
export const LendersFunnelSkeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__white-block">
          <div className="skeleton__row">
            <div className="skeleton__item filters">
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__item filters">
              <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            </div>
            <div className="skeleton__item filters">
              <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            </div>
            <div className="skeleton__item filters">
              <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            </div>
            <div className="skeleton__item filters"></div>
            <div className="skeleton__item filters"></div>
            <Skeleton.Input size="large" style={stylesLargeInput} active />
          </div>
          <div className="skeleton__row">
            <div className="skeleton__funnel">
              <div className="skeleton__row funnel">
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div
                    className="skeleton__row"
                    style={{ width: '85%', margin: '0' }}
                  >
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                  </div>
                  <div className="skeleton__row" style={{ width: '15%' }}>
                    <Skeleton.Avatar size="small" style={round} active />
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
            </div>
            <div className="skeleton__funnel">
              <div className="skeleton__row funnel">
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div
                    className="skeleton__row"
                    style={{ width: '85%', margin: '0' }}
                  >
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                  </div>
                  <div className="skeleton__row" style={{ width: '15%' }}>
                    <Skeleton.Avatar size="small" style={round} active />
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
            </div>
          </div>
          <div className="skeleton__row">
            <div className="skeleton__funnel">
              <div className="skeleton__row funnel">
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div
                    className="skeleton__row"
                    style={{ width: '85%', margin: '0' }}
                  >
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                  </div>
                  <div className="skeleton__row" style={{ width: '15%' }}>
                    <Skeleton.Avatar size="small" style={round} active />
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
            </div>
            <div className="skeleton__funnel">
              <div className="skeleton__row funnel">
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div
                    className="skeleton__row"
                    style={{ width: '85%', margin: '0' }}
                  >
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                  </div>
                  <div className="skeleton__row" style={{ width: '15%' }}>
                    <Skeleton.Avatar size="small" style={round} active />
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
            </div>
          </div>
          <div className="skeleton__row">
            <div className="skeleton__funnel">
              <div className="skeleton__row funnel">
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div
                    className="skeleton__row"
                    style={{ width: '85%', margin: '0' }}
                  >
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                  </div>
                  <div className="skeleton__row" style={{ width: '15%' }}>
                    <Skeleton.Avatar size="small" style={round} active />
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
            </div>
            <div className="skeleton__funnel">
              <div className="skeleton__row funnel">
                <div className="skeleton__row" style={{ margin: '0' }}>
                  <div
                    className="skeleton__row"
                    style={{ width: '85%', margin: '0' }}
                  >
                    <Skeleton.Input
                      size="small"
                      style={stylesSmallInput}
                      active
                    />
                  </div>
                  <div className="skeleton__row" style={{ width: '15%' }}>
                    <Skeleton.Avatar size="small" style={round} active />
                    <Skeleton.Button size="small" style={dash} active />
                  </div>
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
              <div className="skeleton__row funnel">
                <div className="skeleton__row-item">
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button style={stylesExtraSmallInput} active />{' '}
                  <Skeleton.Input
                    size="large"
                    style={stylesMediumInput}
                    active
                  />
                </div>
                <div>
                  <Skeleton.Avatar size="small" style={avatar} active />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
