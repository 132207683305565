import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {INBOX} from "../../helpers/variables";

export interface TabsI {
  ticketTab: string
  openSubmitTicket: boolean
  openCreatePartner: boolean
  openNotificationModal: boolean
}

const initialState: TabsI = {
  ticketTab: INBOX,
  openSubmitTicket: false,
  openCreatePartner: false,
  openNotificationModal: false
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setTicketTab: (state, action) => {
      state.ticketTab = action.payload;
    },
    setOpenSubmitTicket: (state, action) => {
      state.openSubmitTicket = action.payload;
    },
    setOpenCreatePartner: (state, action) => {
      state.openCreatePartner = action.payload;
    },
    setOpenNotificationModal: (state, action) => {
      state.openNotificationModal = action.payload;
    },
  },
});

export const selectTabs = (state: RootState) => state.tabs;

export const {
  setTicketTab,
  setOpenSubmitTicket,
    setOpenCreatePartner,
    setOpenNotificationModal
} = tabsSlice.actions;

export default tabsSlice;
