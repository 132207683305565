import { Button } from 'antd';
import { Employees } from '../Employees/Employees';
import './index.scss';
import { useState } from 'react';
import { ChangePasswordPage } from './ChangePasswordPage/ChangePasswordPage';
import { MyProfilePage } from './MyProfilePage/MyProfilePage';
import { CompanyProfilePage } from './CompanyProfilePage/CompanyProfilePage';
import {
  ADMIN,
  MAIN_ACCOUNT,
  PARTNER,
  SUPER_ADMIN,
} from '../../helpers/variables';
import { useAppSelector } from '../../hooks';
import { AdminsManagment } from './AdminsManagment/AdminsManagment';

export const Settings = () => {
  const user = useAppSelector((state) => state.profile);

  const TEAM = 'team';
  const PASSWORD = 'password';
  const MY_PROFILE = 'my_profile';
  const COMPANY_PROFILE = 'my_company';
  const [tab, setTab] = useState(PASSWORD);
  return (
    <div className="container-new">
      <div className="settings-tab__wrapper">
        <Button
          className={`settings-tab__btn mr-4 ${tab === PASSWORD && 'active'}`}
          onClick={() => {
            setTab(PASSWORD);
          }}
        >
          Password
        </Button>
        <Button
          className={`settings-tab__btn mr-4 ${tab === TEAM && 'active'}`}
          onClick={() => {
            setTab(TEAM);
          }}
        >
          Team
        </Button>
        <Button
          className={`settings-tab__btn mr-4 ${tab === MY_PROFILE && 'active'}`}
          onClick={() => {
            setTab(MY_PROFILE);
          }}
        >
          My profile
        </Button>
        {(user.role.key === PARTNER || user.role.key === MAIN_ACCOUNT) && (
          <Button
            className={`settings-tab__btn mr-4 ${
              tab === COMPANY_PROFILE && 'active'
            }`}
            onClick={() => {
              setTab(COMPANY_PROFILE);
            }}
          >
            Company profile
          </Button>
        )}
      </div>
      {tab === TEAM &&
        (user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && (
          <AdminsManagment />
        )}
      {tab === TEAM &&
        (user.role.key === PARTNER || user.role.key === MAIN_ACCOUNT) && (
          <Employees />
        )}
      {tab === PASSWORD && <ChangePasswordPage />}
      {tab === MY_PROFILE && <MyProfilePage />}
      {tab === COMPANY_PROFILE && <CompanyProfilePage />}
    </div>
  );
};
