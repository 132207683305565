export const PROFILE = 'profile'
export const FUNNEL = 'funnel'
export const INBOX = 'inbox'
export const SUBMITTED = 'submitted'
export const ADMIN = 'admin'
export const SUPER_ADMIN = 'super_admin'
export const MAIN_ACCOUNT = 'main_account'
export const PARTNER = 'partner'
export const STATUS_ACTIVE = 'Active'
export const STATUS_PAUSED = 'Paused'
export const STATUS_PENDING = 'Pending'
export const OKINUS = 'Okinus'
export const UMWSB = 'UMWSB'
