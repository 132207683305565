import { Table, Tooltip, message, Dropdown } from 'antd';
import { useState, useEffect, useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import Property from '../../assets/Property.svg';
import PropertyHover from '../../assets/PropertyHover.svg';
import noData from '../../assets/noData.svg';
import type { ColumnsType } from 'antd/es/table';
import { TableResponse } from '../../api/types/partners';
import { Pagination } from './Pagination';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { useNavigate } from 'react-router-dom';
import { idsI } from '../../pages/Partners/Partners';
import './index.scss';
import { LendersTableParams, TableLendersData } from '../../api/types/lenders';
import moment from 'moment';
import { useChangeLendersStatusMutation } from '../../api/lenders';
import pause from '../../assets/Stopwatch Pause.svg';
import activate from '../../assets/activate.svg';
import email from '../../assets/email.svg';
import view from '../../assets/view.svg';
import { ClockIcon } from '../icons/ClockIcon';

interface LendersTableProps {
  data: TableLendersData[];
  setTableParams: React.Dispatch<React.SetStateAction<LendersTableParams>>;
  metaData: TableResponse | undefined;
  tableParams: LendersTableParams;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LendersTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  setTrigger,
}: LendersTableProps) => {
  const ref = useRef(null);
  const [openActionId, setOpenActionId] = useState<number | null>(null);
  useClickOutside(ref, () => setOpenActionId(null));
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const sorting = (name: string) => {
    setTableParams((prev: LendersTableParams) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? prev.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const [changeStatus, { isSuccess }] = useChangeLendersStatusMutation();

  useEffect(() => {
    setTrigger((prev: boolean) => !prev);
    if (isSuccess) {
      success('Status changed successfully');
    }
  }, [isSuccess]);

  const changeStatusFunc = (id: number, status: number) => {
    const ids: idsI = {
      id: id,
      key: { status_key: status },
    };
    changeStatus(ids);
  };

  const columns: ColumnsType<TableLendersData> = [
    {
      title: (
        <div className="header-item">
          ID
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'id' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('id')}
          />
        </div>
      ),
      width: 70,
      dataIndex: 'id',
      key: 'id',
      render: (text) => (
        <div className="table-item">
          <div className="table-text blue">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Lender
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'name' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('name')}
          />
        </div>
      ),
      width: 160,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Participation Bonus
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'participation_bonus_percent' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('participation_bonus_percent')}
          />
        </div>
      ),
      width: 130,
      dataIndex: 'participation_bonus_percent',
      key: 'participation_bonus_percent',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text} %</div>
        </div>
      ),
    },
    {
      title: <div className="header-item">Loan Types </div>,
      dataIndex: 'loan_types',
      key: 'loan_type',
      width: 160,
      render: (text, record) => (
        <Tooltip
          title={record.loan_types?.map((elem: any) => elem.name).join(', ')}
        >
          <div className="table-text-acc-types">
            {record.loan_types?.length
              ? record.loan_types?.map((elem: any) => elem.name).join(', ')
              : '-'}
          </div>
        </Tooltip>
      ),
    },

    {
      title: (
        <div className="header-item">
          Date of Onboarding
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'created_at' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('created_at')}
          />
        </div>
      ),
      width: 160,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{moment(text).format('MM/DD/YYYY')}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Status
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'status' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('status')}
          />
        </div>
      ),
      width: 130,
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        const items = [
          {
            key: '1',
            label:
              text === 'Active' ? (
                <span className="table-dropdown-text">Pause</span>
              ) : (
                <span className="table-dropdown-text">Activate</span>
              ),
            icon:
              text === 'Active' ? (
                <img src={pause} alt="pause" />
              ) : (
                <img src={activate} alt="activate" />
              ),
          },
          {
            key: '2',
            label: <span className="table-dropdown-text">View</span>,
            icon: <img src={view} alt="view" />,
          },
        ];
        const handleDropdownClick = (
          key: string,
          e:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
        ) => {
          e.stopPropagation();
          if (key === '1') {
            text === 'Active' && changeStatusFunc(record.id, 0);
            text === 'Paused' && changeStatusFunc(record.id, 1);
          } else if (key === '2') {
            navigate(`/lenders/${record.id}`);
          }
        };
        return (
          <div className="table-item table-status">
            <div
              className={
                text === 'Active'
                  ? 'table-text status Approved'
                  : text === 'Paused'
                  ? 'table-text status noOffer'
                  : ''
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {text === 'Pending' && <ClockIcon fill="#7C24C2" />}
                {text}
              </div>
            </div>
            <Dropdown
              placement="bottomRight"
              menu={{
                items,
                onClick: (info) => handleDropdownClick(info.key, info.domEvent),
              }}
              onOpenChange={(open) => {
                open && setOpenActionId(record.id);
                !open && setOpenActionId(null);
              }}
            >
              <img
                src={record.id === openActionId ? PropertyHover : Property}
                style={{ cursor: 'pointer', margin: '0 0 0 15px' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                alt="dots"
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const customNoDataText = {
    emptyText: (
      <>
        {(tableParams.account_type_id ||
          tableParams.loan_type ||
          tableParams.type ||
          tableParams.status ||
          tableParams.search) && (
          <div className="no-data-found">
            <img src={noData} />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      <div>
        {contextHolder}
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => navigate(`/lenders/${record.id}`),
            };
          }}
        />
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
