import { Button, Form, Modal, Tooltip } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { FC, useEffect, useState } from 'react';
import close from '../../assets/x-close.svg';
import './index.scss';
import { yupSync } from '../../utils';
import * as Yup from 'yup';
import { ConfirmationModal } from './ConfirmationModal';
import { CustomInput } from '../../customFields/CustomInput';
import { Employee } from '../../api/types/common';
import { PhoneNumberInput } from '../../customFields/PhoneNumberInput';
import {
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
} from '../../api/employees';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  phone: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email is invalid')
    .required('This field is required'),
});

interface FormData {
  first_name: string;
  first_second: string;
  phone: string;
  email: string;
}

interface ModalI {
  openModal: boolean;
  isClose?: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  editEmployee: null | Employee;
  setEditEmployee: React.Dispatch<React.SetStateAction<Employee | null>>;
  createSuccess: (edit: boolean) => void;
}

export const AddEmployeeModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  className,
  editEmployee,
  setEditEmployee,
  createSuccess,
}) => {
  const [
    createEmployee,
    {
      reset: createReset,
      error: createErrorData,
      isLoading: createLoading,
      isError: createError,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateEmployeeMutation();
  const [
    editEmployeeRequest,
    {
      reset: editReset,
      error: editErrorData,
      isLoading: editLoading,
      isError: editError,
      isSuccess: isEditSuccess,
    },
  ] = useEditEmployeeMutation();
  const [form] = Form.useForm<FormData>();
  const [openCancelationModal, setOpenCancelationModal] = useState(false);
  const isLoading = createLoading || editLoading;

  const errorData = createErrorData || editErrorData;
  const isError = createError || editError;
  const reset = () => {
    createReset();
    editReset();
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };
  const closeModal = () => {
    setOpenModal(false);
    setEditEmployee(null);
    form.resetFields();
  };
  const closeCancelModal = () => {
    setOpenCancelationModal(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!editEmployee) {
          createEmployee(values);
        } else {
          editEmployeeRequest({ id: editEmployee.id, ...values });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isCreateSuccess) {
      createSuccess(false);
      closeModal();
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isEditSuccess) {
      createSuccess(true);
      closeModal();
    }
  }, [isEditSuccess]);

  useEffect(() => {
    editEmployee && form.setFieldsValue({ ...editEmployee });
  }, [editEmployee, form]);

  return (
    <div>
      <Modal
        destroyOnClose
        className={`add-employee-modal`}
        centered
        open={openModal}
        onCancel={closeModal}
        footer={<></>}
        closeIcon={<img src={close} alt="close" />}
        width={400}
        maskClosable={!isLoading}
      >
        <div className="add-bank-modal__title">
          {editEmployee ? 'Edit User' : 'Add User'}
        </div>

        <Form form={form}>
          <Form.Item
            name="first_name"
            rules={yupSync('first_name', validationSchema, true)}
          >
            <CustomInput
              name="First name"
              className="input"
              max={100}
              placeholder="Enter first name"
              onBlur={() => validateFormOnBlur('first_name')}
              // error={isError ? 'err' : ''}
              onChange={(e) => {}}
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            rules={yupSync('last_name', validationSchema, true)}
          >
            <CustomInput
              name="Last name"
              className="input"
              max={100}
              placeholder="Enter last name"
              onBlur={() => validateFormOnBlur('last_name')}
              // error={isError ? 'err' : ''}
              onChange={(e) => {}}
            />
          </Form.Item>
          <Form.Item
            className="input-wrapper"
            name="contact_number"
            rules={yupSync('phone', validationSchema, true)}
          >
            <PhoneNumberInput
              mask="(999) 999 9999"
              name="Contact number"
              // onChange={(
              //     e: React.ChangeEvent<HTMLInputElement>
              // ) => {
              //     // setContractNumber(e.target.value);
              //     // setPhoneError(false);
              // }}
              // className={`input ${phoneError ? 'error' : ''}`}
              value={undefined}
              onBlur={() => validateFormOnBlur('phone')}
              placeholder={'(555) 000-0000'}
              hasError={() => !!form.getFieldError('phone').length}
            />
          </Form.Item>
          <Form.Item
            name="contact_email"
            rules={yupSync('email', validationSchema, true)}
          >
            <CustomInput
              name="Contact email"
              className="input"
              max={240}
              placeholder="Enter email address"
              onBlur={() => validateFormOnBlur('email')}
              error={isError ? 'err' : ''}
              //@ts-ignore
              errorText={isError ? errorData?.data.message : ''}
              onChange={(e) => {
                reset();
              }}
            />
          </Form.Item>
          <div className="d-flex justify-between">
            <Button
              className="gray-btn-lg"
              onClick={() => setOpenCancelationModal(true)}
            >
              Cancel
            </Button>
            <Button
              className="blue-btn-lg"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {editEmployee ? 'Save changes' : 'Add User'}
            </Button>
          </div>
        </Form>
      </Modal>
      <ConfirmationModal
        openModal={openCancelationModal}
        setOpenModal={setOpenCancelationModal}
        title={
          editEmployee
            ? 'Are you sure you want to discard the User Editing?'
            : 'Are you sure you want to discard the User Adding?'
        }
        subtitle=""
        cancelText="No"
        confirmText="Yes"
        confirm={() => {
          closeModal();
          closeCancelModal();
        }}
        cancel={closeCancelModal}
        closeIcon={false}
        maskClosable={false}
      />
    </div>
  );
};
