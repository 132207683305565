import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { useEffect, useState } from 'react';
import edit from '../../../assets/edit.svg';
import moment from 'moment';
import { Button, message } from 'antd';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useAppSelector } from '../../../hooks';
import {
  useChangeStatusMutation,
  useLazyGetMainAccountQuery,
  useResendInvitationMutation,
} from '../../../api/mainAccounts';
import { idsI } from '../MainAccounts';
import { PartnerItem } from '../../../api/types/partners';
import { TableForViewUserPage } from '../../../components/Tables/TableForViewUserPage';
import { AccountTypesItem } from '../../../api/types/common';
import { useGetAccountTypesQuery } from '../../../api/common';
import { UserI } from '../../../reducers/profile/profileSlice';
import { useLazyGetProfileInfoQuery } from '../../../api/profile';
import { maskAccountNumber } from '../../../utils/maskAccountNumber';
import { AvailableBanksTable } from '../../../components/Tables/AvailableBanksTable';
import Bank from '../../../assets/Bank.svg';
import { LendersFunnel } from '../../../components/LendersFunnel/LendersFunnel';
import {
  ADMIN,
  FUNNEL,
  MAIN_ACCOUNT,
  PARTNER,
  PROFILE,
  STATUS_ACTIVE,
  STATUS_PAUSED,
  STATUS_PENDING,
  SUPER_ADMIN,
} from '../../../helpers/variables';
import { TicketSkeleton } from '../../../components/Skeletons/TicketSkeleton';
import { ProfileSkeleton } from '../../../components/Skeletons/ProfileSkeleton';

interface ViewMainAccountProps {
  isProfile?: boolean;
}

export const ViewMainAccount = ({ isProfile }: ViewMainAccountProps) => {
  const { id: mainAccId } = useParams();
  const [tab, setTab] = useState<string>(PROFILE);
  const location = useLocation();
  const [getMainAccount, { data, isLoading, isError: isGetMainAccountError }] =
    useLazyGetMainAccountQuery();
  const [
    getProfileInfo,
    {
      data: profileInfo,
      isLoading: isProfileInfoLoading,
      isError: isGetProfileInfoError,
    },
  ] = useLazyGetProfileInfoQuery();
  const [resetInvitation, { isSuccess: isSuccessResetInv }] =
    useResendInvitationMutation();
  const navigate = useNavigate();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [messageApi, contextHolder] = message.useMessage();
  const [changeStatus] = useChangeStatusMutation();
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const responseData = isProfile ? profileInfo?.data?.resource : data?.data;
  const successMessage = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (isGetMainAccountError) {
      errorMessage('Fetch main account failed');
    }
    if (isGetProfileInfoError) {
      errorMessage('Fetch profile info failed');
    }
  }, [isGetMainAccountError, isGetProfileInfoError]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key === PARTNER && !isProfile) {
      navigate('/403');
    }
  }, [user.email, isProfile]);

  useEffect(() => {
    if (isSuccessResetInv) {
      successMessage('Link resent successfully');
    }
  }, [isSuccessResetInv]);

  useEffect(() => {
    if (!location) return;
    if (isProfile) {
      getProfileInfo();
    } else {
      !!mainAccId && getMainAccount(mainAccId);
    }
  }, [location, isProfile]);

  const changeStatusFunc = () => {
    if (data?.data?.status_key === 1 || data?.data?.status_key === 2) {
      const ids: idsI = {
        id: data?.data?.id,
        key: { status_key: data?.data?.status_key === 1 ? 2 : 1 },
      };
      changeStatus(ids);
    }
    if (data?.data?.status_key === 0) {
      resetInvitation(data?.data?.id + '');
    }
    if (
      profileInfo?.data?.resource?.status_key === 1 ||
      profileInfo?.data?.resource?.status_key === 2
    ) {
      const ids: idsI = {
        id: profileInfo?.data?.resource?.id,
        key: {
          status_key: profileInfo?.data?.resource?.status_key === 1 ? 2 : 1,
        },
      };
      changeStatus(ids);
    }
    if (profileInfo?.data?.resource?.status_key === 0) {
      resetInvitation(profileInfo?.data?.resource?.id + '');
    }
  };

  const getLoanProducts = (id: number) => {
    const result: any = accountTypesData?.data
      ?.map((elem: any) => {
        if (elem.id === id) {
          const items = elem.loan_products.map((item: any) => {
            const matchingProduct = (
              profileInfo?.data?.resource
                ? profileInfo?.data?.resource
                : data?.data
            )?.loan_products?.find((prod: any) => prod.id === item.id);
            return matchingProduct ? item.name : undefined;
          });
          const filteredItems = items.filter((item: any) => item !== undefined);
          return filteredItems.join(', ');
        } else {
          return '';
        }
      })
      .filter((item) => item !== '');

    const finalResult = result?.join(', ');
    return finalResult ? finalResult : [];
  };

  return (
    <div className="container">
      {contextHolder}
      {isLoading || isProfileInfoLoading ? (
        <ProfileSkeleton />
      ) : (
        <div className="new-member">
          {!isProfile && (
            <div className="new-member_header">
              {(user.role.key === ADMIN || user.role.key === SUPER_ADMIN) && (
                <div className="new-member_tab">
                  <Button
                    className={`tab-btn ${tab === PROFILE && 'active'}`}
                    onClick={() => {
                      setTab(PROFILE);
                    }}
                  >
                    Profile info
                  </Button>
                  <Button
                    className={`tab-btn ${tab === FUNNEL && 'active'}`}
                    onClick={() => {
                      setTab(FUNNEL);
                    }}
                  >
                    Lenders Funnel
                  </Button>
                </div>
              )}
            </div>
          )}
          {tab === FUNNEL && !!mainAccId && (
            <LendersFunnel model_id={mainAccId} model_type={MAIN_ACCOUNT} />
          )}
          {tab === PROFILE && (
            <div className="view-member">
              <div className="view-member__block block block-main-acc">
                <div className="block__top">
                  <div className="block__logo-wrapper">
                    <img
                      src={
                        typeof responseData?.logo === 'string'
                          ? responseData?.logo
                          : ''
                      }
                      className="block__logo"
                      alt="logo"
                    />
                    <div className="block__logo-info">
                      <div className="block__name">
                        {responseData?.company_name}
                      </div>
                      <div className="block__id">
                        ID#{' '}
                        <span className="block__id-blue">
                          {responseData?.item_id}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="block__title">Main account info</div>
                  <div className="block__row">
                    <div className="block__item small">
                      <div className="block__item-title">First Name</div>
                      <div className="block__item-value">
                        {responseData?.first_name}
                      </div>
                    </div>
                    <div className="block__item small">
                      <div className="block__item-title">Last Name</div>
                      <div className="block__item-value">
                        {responseData?.last_name}
                      </div>
                    </div>
                  </div>
                  <div className="block__row">
                    <div className="block__item small">
                      <div className="block__item-title">Contact Number</div>
                      <div className="block__item-value">
                        {responseData?.contact_number}
                      </div>
                    </div>
                    <div className="block__item small">
                      <div className="block__item-title">Contact Email</div>
                      <div className="block__item-value">
                        {responseData?.contact_email}
                      </div>
                    </div>
                  </div>
                  {data?.data?.sub_domain && !isProfile && (
                    <div className="block__item">
                      <div className="block__item-title">Sub-domain Name</div>
                      <div className="block__item-value">
                        {data?.data?.sub_domain}
                      </div>
                    </div>
                  )}

                  <div className="block__row">
                    {!isProfile ? (
                      <div className="block__item small">
                        <div className="block__item-title">
                          MA Processing Fee
                        </div>
                        <div className="block__item-value">
                          {data?.data?.ma_processing_fee} %
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {responseData?.sub_domain && isProfile && (
                    <div className="block__item">
                      <div className="block__item-title">Sub-domain Name</div>
                      <div className="block__item-value">
                        {responseData?.sub_domain}
                      </div>
                    </div>
                  )}
                </div>
                <div className="block__item">
                  <TableForViewUserPage
                    title="Associated Partners"
                    total={responseData?.partners?.length}
                    value={responseData?.partners?.map((elem: PartnerItem) => {
                      return {
                        name: elem.company_name,
                        id: elem.id,
                      };
                    })}
                  />
                </div>
                {!isProfile && (
                  <div className="block__item">
                    <div className="bank-table__title bank-table__title__view">
                      Available Banks
                    </div>
                    {responseData?.banks.length ? (
                      <AvailableBanksTable
                        view
                        banks={responseData?.banks}
                        type="main_account"
                      />
                    ) : (
                      <div className="no-data-found">
                        <div>
                          <img src={Bank} alt="bank" />
                        </div>
                        <div className="no-data-found__text">
                          No banks available
                        </div>
                        <div className="no-data-found__subtext">
                          Please edit your profile to add banks
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="block__row">
                  <div className="block__item" style={{ width: '60%' }}>
                    <div className="block__item-title">Application logo</div>
                    {responseData?.application_logo ? (
                      <img
                        src={responseData?.application_logo}
                        className="block__application-logo"
                        alt="app-logo"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
              <div className="view-member__block block block-main-acc">
                <div className="block__top">
                  <div
                    className="block__title"
                    style={{ height: '30px' }}
                  ></div>
                  <div className="block__edit-wrapper">
                    <img
                      src={edit}
                      className="block__edit"
                      onClick={() =>
                        isProfile
                          ? navigate(
                              `/profile/edit/${profileInfo?.data?.resource?.id}`
                            )
                          : navigate(`/main_accounts/edit/${data?.data?.id}`)
                      }
                      alt="edit"
                    />
                  </div>
                  <div className="block__common-info">
                    {!isProfile && (
                      <div className="block__item-row">
                        <div className="block__item-row-title">Status</div>
                        <div className="block__item-row-value">
                          <span
                            style={
                              responseData?.status === STATUS_ACTIVE
                                ? { color: '#017130' }
                                : responseData?.status === STATUS_PENDING
                                ? { color: '#7C24C2' }
                                : responseData?.status === STATUS_PAUSED
                                ? { color: '#A80E1C' }
                                : {}
                            }
                          >
                            {responseData?.status}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="block__item-row">
                      <div className="block__item-row-title">White Labeled</div>
                      <div className="block__item-row-value">
                        {responseData?.white_labeled_account === 1
                          ? 'Yes'
                          : 'No'}
                      </div>
                    </div>
                    <div className="block__item-row">
                      <div className="block__item-row-title">Account Type</div>
                      <div className="block__item-row-value">
                        {responseData?.account_types
                          ?.map((elem: AccountTypesItem) => elem.name)
                          .join(', ')}
                      </div>
                    </div>

                    <div className="block__item-row">
                      <div className="block__item-row-title">
                        Onboarding Date
                      </div>
                      <div className="block__item-row-value">
                        {moment(responseData?.created_at).format(
                          'MMMM DD, YYYY'
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="block__common-info"
                    style={{ padding: '24px 0 0px 0' }}
                  >
                    <div className="block__title">Banking Information</div>
                    <div className="block__row">
                      <div className="block__item small">
                        <div className="block__item-title">Bank Name</div>
                        <div className="block__item-value">
                          {responseData?.bank_details?.bank_name}
                        </div>
                      </div>
                      <div className="block__item small">
                        <div className="block__item-title">
                          Legal Business Name
                        </div>
                        <div className="block__item-value">
                          {responseData?.bank_details?.legal_business_name}
                        </div>
                      </div>
                    </div>
                    <div className="block__row">
                      <div className="block__item small">
                        <div className="block__item-title">Routing Number</div>
                        <div className="block__item-value">
                          {responseData?.bank_details?.routing_number}
                        </div>
                      </div>
                      <div className="block__item small">
                        <div className="block__item-title">Account Number</div>
                        <div className="block__item-value">
                          {maskAccountNumber(
                            responseData?.bank_details?.accounting_number
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="block__item">
                      <div className="block__item-title">
                        Account Holder's Name
                      </div>
                      <div className="block__item-value">
                        {responseData?.bank_details?.account_holder_name}
                      </div>
                    </div>
                  </div>
                  <div
                    className="block__common-info"
                    style={{ padding: '0 0 16px 0' }}
                  >
                    <div className="block__item">
                      <div className="block__title">Products</div>
                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(1).length > 0 &&
                          accountTypesData?.data[0]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(1)}
                      </div>
                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(2).length > 0 &&
                          accountTypesData?.data[1]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(2)}
                      </div>
                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(3).length > 0 &&
                          accountTypesData?.data[2]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(3)}
                      </div>
                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(4).length > 0 &&
                          accountTypesData?.data[3]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(4)}
                      </div>

                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(5).length > 0 &&
                          accountTypesData?.data[4]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(5)}
                      </div>

                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(6).length > 0 &&
                          accountTypesData?.data[5]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(6)}
                      </div>

                      <div
                        className="block__block__item-title"
                        style={{ margin: '0 0 10px 0' }}
                      >
                        {getLoanProducts(7).length > 0 &&
                          accountTypesData?.data[6]?.name}
                      </div>
                      <div className="block__item-value-prod">
                        {getLoanProducts(7)}
                      </div>
                    </div>
                  </div>
                </div>

                {!isProfile && (
                  <>
                    {responseData?.status === STATUS_ACTIVE && (
                      <Button
                        className="red-btn block__pause-btn"
                        onClick={changeStatusFunc}
                      >
                        Pause
                      </Button>
                    )}
                    {responseData?.status === STATUS_PAUSED && (
                      <Button
                        className="blue-btn block__pause-btn"
                        onClick={changeStatusFunc}
                      >
                        Activate
                      </Button>
                    )}
                    {responseData?.status === STATUS_PENDING && (
                      <Button
                        className="violet-btn block__pause-btn"
                        onClick={changeStatusFunc}
                      >
                        Resend link
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
