import { Spinner } from '../../components/Spinner/Spinner';
import plus from '../../assets/plus.svg';
import team_member from '../../assets/team-member-new.svg';
import './index.scss';
import { Button, message } from 'antd';
import { EmployeesTable } from '../../components/Tables/EmploeesTable';
import { useCallback, useEffect, useState } from 'react';
import { Employee } from '../../api/types/common';
import { AddEmployeeModal } from '../../components/Modals/AddEmployeeModal';
import { useLazyGetEmployeesListQuery } from '../../api/employees';
import { useAppSelector } from '../../hooks';
import { UserI } from '../../reducers/profile/profileSlice';
import { successMessage } from '../../components/Messages/messages';
import { ADMIN, SUPER_ADMIN } from '../../helpers/variables';

export const Employees = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [getUserList, { data: users, isLoading }] =
    useLazyGetEmployeesListQuery();
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [editEmployee, setEditEmployee] = useState<Employee | null>(null);
  const [order, setOrder] = useState<string>('desc');

  const setFilters = useCallback(() => {
    order === 'desc' ? setOrder('asc') : setOrder('desc');
  }, [order]);

  useEffect(() => {
    getUserList({
      per_page: 'all',
      order_by: 'status',
      order: order,
    });
  }, [order]);

  const createSuccess = useCallback((edit: boolean) => {
    successMessage(
      messageApi,
      `User has been successfully ${edit ? 'saved' : 'added'}!`
    );
  }, []);

  if (user.role.key === ADMIN || user.role.key === SUPER_ADMIN) {
    return <></>;
  }
  return (
    <>
      {/* {isLoading && <Spinner size="large"/>} */}
      {contextHolder}
      <div className="employees__table">
        <div className="employees__main-user">
          <div>
            <img
              className="employees__main-user__logo"
              src={user.logo}
              alt="logo"
            />
          </div>
          <div>
            <div className="employees__name">
              {user?.model.first_name} {user?.model.last_name} (Account holder)
            </div>
            <div className="employees__id">#{user?.model.item_id}</div>
          </div>
        </div>
        <div className="employees__title_row">
          <div>
            <span className="employees__title">Team members</span>
            {!!users?.data.length && (
              <span className="employees__bage">
                {users?.data.length} users
              </span>
            )}
          </div>

          {user.sub_role === 'owner' && (
            <Button
              className="blue-btn h-40"
              onClick={() => setAddEmployeeModal(true)}
            >
              <img src={plus} alt="plus" className="mr-4" />
              Add User
            </Button>
          )}
        </div>
        {!!users?.data.length && (
          <EmployeesTable
            employees={users?.data || []}
            view={user.sub_role !== 'owner'}
            setAddEmployeeModal={setAddEmployeeModal}
            setEditEmployee={setEditEmployee}
            order={order}
            setFilters={setFilters}
          />
        )}
        {users?.data.length === 0 && (
          <div className="employees__empty">
            <img src={team_member} alt="team member" />
            <div className="employees__empty__title">No team members yet</div>
            <div className="employees__empty__text">
              Add new team members to start collaboration.
            </div>
          </div>
        )}
      </div>
      <AddEmployeeModal
        openModal={addEmployeeModal}
        setOpenModal={setAddEmployeeModal}
        editEmployee={editEmployee}
        setEditEmployee={setEditEmployee}
        createSuccess={createSuccess}
      />
    </>
  );
};
