import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';


export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery,
  tagTypes: ['Tickets', 'Category', 'Ticket', 'Files'],
  endpoints: (builder) => ({
    getTicketsList: builder.query({
      query: (params) => ({
        url: '/ticket',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Tickets'],
    }),
    getTicketsCategoryList: builder.query({
      query: () => ({
        url: '/ticket-category',
        method: 'GET',
      }),
      providesTags: ['Category'],
    }),
    getTicket: builder.query({
      query: (id) => ({
        url: `/ticket/${id}`,
        method: 'GET',
      }),
      providesTags: ['Ticket'],
    }),
    getTicketAdmins: builder.query({
      query: () => ({
        url: `/ticket-admins`,
        method: 'GET',
      }),
    }),
    // deleteEmployee: builder.mutation<number, string>({
    //   query: (id) => ({
    //     url: `/employees/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Tickets'],
    // }),
    // resendInvitation: builder.mutation<number, string>({
    //   query: (id) => ({
    //     url: `/employees/${id}/invitation`,
    //     method: 'PUT',
    //   }),
    // }),
    createTicket: builder.mutation({
      query: (arg) => ({
        url: '/ticket',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['Tickets'],
    }),
    sendMessage: builder.mutation({
      query: (arg) => ({
        url: '/ticket-message',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['Ticket', 'Files'],
    }),
    addFile: builder.mutation({
      query: (arg) => ({
        url: '/files',
        method: 'POST',
        body: arg,
      }),
    }),
    setRead:  builder.mutation({
      query: (id) => ({
        url: `/ticket/${id}/set-read`,
        method: 'PUT',
      }),
    }),
    setTicketStatus:  builder.mutation({
      query: ({status, id}) => ({
        url: `/ticket/${id}/set-status`,
        method: 'PUT',
        body: {status},
      }),
      invalidatesTags: ['Ticket'],
    }),
    updateTicket:  builder.mutation({
      query: ({body, id}) => ({
        url: `/ticket/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Ticket'],
    }),
    // getEmployee: builder.query({
    //   query: (id) => ({
    //     url: `/employees/${id}`,
    //     method: 'GET',
    //   }),
    // }),
    // editEmployee: builder.mutation({
    //   query: (data) => ({
    //     url: `/employees/${data.id}`,
    //     method: 'PUT',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Tickets'],
    // }),
    // changeStatus: builder.mutation({
    //   query: (data) => ({
    //     url: `/employees/${data.id}/status`,
    //     method: 'PUT',
    //     body: {status_key: data.key},
    //   }),
    //   invalidatesTags: ['Tickets'],
    // }),
    getFiles: builder.query({
      query: (id) => ({
        url: `/ticket/${id}/files`,
        method: 'GET',
      }),
      providesTags: ['Files'],
    }),
    getDownloadFiles: builder.query({
      query: (id) => ({
        url: `/ticket/${id}/download-files`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
    useLazyGetTicketAdminsQuery,
    useLazyGetTicketQuery,
    useLazyGetTicketsListQuery,
    useGetTicketsCategoryListQuery,
    useCreateTicketMutation,
    useAddFileMutation,
    useSetReadMutation,
    useSetTicketStatusMutation,
    useUpdateTicketMutation,
    useGetDownloadFilesQuery,
    useGetFilesQuery,
    useSendMessageMutation
} = ticketsApi;
