export const ConcoraItem = (props: any) => {
  const { selectedOfferData } = props;

  const addDecimal = (formattedStr: string) => {
    formattedStr = String(formattedStr);

    if (!formattedStr.includes('.')) {
      return formattedStr + '.00';
    }

    const parts = formattedStr.split('.');
    if (parts[1].length === 0) {
      return formattedStr + '00';
    } else if (parts[1].length === 1) {
      return formattedStr + '0';
    }
    return formattedStr;
  };

  return (
    <div className="SelectedOffer__offer">
      <div className="SelectedOffer__topitem">
        <div className="SelectedOffer__topitem-wrap">
          <img
            src={selectedOfferData?.offer?.bank_logo}
            className="SelectedOffer__logo"
            alt="bank logo"
          />
          {selectedOfferData?.offer?.bank_name}
        </div>
      </div>
      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Applicant Name</p>
        <p className="SelectedOffer__item-description">{`${selectedOfferData?.applicant?.first_name} ${selectedOfferData?.applicant?.last_name}`}</p>
      </div>
      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Prequalified credit limit</p>
        <p className="SelectedOffer__item-description">
          $ {addDecimal(selectedOfferData.offer?.data?.creditLine)}
        </p>
      </div>
      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Loan Type</p>
        <p className="SelectedOffer__item-description">
          {selectedOfferData?.offer?.type}
        </p>
      </div>

      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Estimated monthly payment</p>
        <p className="SelectedOffer__item-description">
          ${' '}
          {selectedOfferData.offer.monthly_payment
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </p>
      </div>
    </div>
  );
};
