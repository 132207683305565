import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Button, FormInstance } from 'antd';

import { CustomSelect } from '../../../customFields/CustomSelect';
import { SelectedOffer } from './components/SelectedOffer';
import { TrashIcon } from '../../../components/icons/TrashIcon';
import useOfferController from './offers.controller';

import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { OpensOfferItem } from './components/OpensOffer/OpensOfferItem';

import './index.scss';

interface OffersProps {
  customerInfo: any;
  isDirty: boolean;
  setIsClickOnHome: Dispatch<SetStateAction<boolean>>;
  setConfirmationModal: Dispatch<SetStateAction<boolean>>;
  applicantId?: number;
  form: FormInstance<any>;
  openItem?: number | null;
  selectOption?: any;
  setOpenItem?: any;
  setSelectOption?: any;
}

export const Offers = ({
  customerInfo,
  setConfirmationModal,
  setIsClickOnHome,
  applicantId,
  form,
}: OffersProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = customerInfo ?? {};
  const {
    options,
    selectedOffer,
    selectedValue,
    handleChange,
    handleOfferSelection,
    offers,
    offersMessage,
    isGetOffersLoading,
    selectedOfferDataResult: {
      handleOfferSend,
      selectedOfferData,
      isSelectedOfferError,
      isSelectedOfferLoading,
    },
    handleSendOffersByType,
    isWaitingModal,
    setIsWaitingModal,
    contextHolder,
    openItem,
    selectOption,
    setOpenItem,
    setSelectOption,
  } = useOfferController(data);

  if (selectedOfferData) {
    const goToList = () => {
      navigate('/applications');
    };

    return (
      <SelectedOffer
        selectedOfferData={selectedOfferData}
        isSelectedOfferLoading={isSelectedOfferLoading}
        setIsClickOnHome={setIsClickOnHome}
        setConfirmationModal={setConfirmationModal}
        form={form}
        goTo={goToList}
        buttonText="Go To Applications List"
        contextHolder={contextHolder}
      />
    );
  }

  return (
    <>
      {contextHolder}
      <div className="container">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            className="main__btns"
            style={{ padding: '8px 0 35px 0', margin: '0' }}
          ></div>
        </div>

        <div className="Offers">
          <div className="Offers__available-wrapper">
            <h4 className="Offers__list-label"></h4>
            <div className="Offers__available">
              <div className="Offers__available-for">
                <p className="Offers__available-for-label">
                  Offers Available For:
                </p>
                <p className="Offers__available-for-description">
                  {data?.first_name} {data?.last_name}
                </p>
              </div>
              <div className="Offers__available-send-offer-wrapper">
                <div className="Offers__available-send-offer">
                  <p className="Offers__available-for-label">
                    Send Offers To Applicant:
                  </p>
                  <CustomSelect
                    disabled={!offers?.length}
                    className="input"
                    placeholder="Select"
                    value={selectedValue}
                    options={options}
                    onChange={(value) => handleChange(value)}
                  />
                </div>
                <div className="Offers__available-actions">
                  <p className="Offers__available-for-label"></p>
                  <Button
                    className={`Offers__button ${
                      !offers?.length ? 'Offers__disabled-btn' : ''
                    }`}
                    disabled={!offers?.length}
                    onClick={() =>
                      handleSendOffersByType(applicantId ?? data.id)
                    }
                  >
                    <span>Send</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {!offersMessage && !isGetOffersLoading && (
            <>
              <div className="Offers__list-wrapper">
                <h4 className="Offers__list-label">Available offers</h4>
                <div className="Offers__list">
                  {offers?.length ? (
                    (offers || []).map((offer) => {
                      return (
                        <OpensOfferItem
                          key={offer.id}
                          offer={offer}
                          openItem={openItem}
                          selectOption={selectOption}
                          setOpenItem={setOpenItem}
                          setSelectOption={setSelectOption}
                        />
                      );
                    })
                  ) : (
                    <div className="Offers__list-no-data">
                      <div className="Offers__list-no-data-icon">
                        <TrashIcon />
                      </div>
                      <div className="Offers__list-no-data-description">
                        <p className="Offers__list-no-data-title">
                          No offers available
                        </p>
                        <p className="Offers__list-no-data-sub-title">
                          This applicant is not eligible for any offers
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="Offers__list-wrapper">
                <p></p>
                <div className="Offers__list-actions">
                  {!offers?.length ? (
                    <Button
                      className="blue-btn"
                      onClick={() => {
                        if (location.pathname.includes('edit')) {
                          navigate('/applications');
                        } else {
                          if (form.isFieldsTouched()) {
                            setIsClickOnHome(false);
                            setConfirmationModal(true);
                          } else {
                            navigate('/applications');
                          }
                        }
                      }}
                    >
                      Go To Applications List
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        {isWaitingModal && (
          <ConfirmationModal
            openModal={isWaitingModal}
            setOpenModal={setIsWaitingModal}
            isClose
            type="waiting"
            title={'We are processing your request...'}
            cancelText={''}
            confirmText={''}
            closeIcon={false}
            maskClosable={true}
            subtitle={
              <div style={{ width: '220px', textAlign: 'center' }}>
                This can take up to 5 minutes (300 seconds)
              </div>
            }
          />
        )}
      </div>
    </>
  );
};
