import { useState, useEffect } from "react";
import { YourChartComponent } from "../../components/Charts/Chart";
import PercentageBar from "../../components/Charts/LinerChart";
import { DotIcon } from "../../components/icons/Dot";
import { CustomSelect } from "../../customFields/CustomSelect";
import calendar from "../../assets/calendar-new.svg";
import Illustration from "../../assets/Illustration.svg";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { Button, DatePicker, message } from "antd";
import { SelectOption } from "../../types.ts/commonTypes";
import {
  useGetFirstQuery,
  useLazyGetFeesQuery,
  useLazyGetFoundingQuery,
  useLazyGetStatusesQuery,
  useLazyGetTopsQuery,
  useLazyGetTotalsQuery,
} from "../../api/dashboard";
import {
  StatusI,
  TableParamsGetTotals,
  TopItem,
} from "../../api/types/dashboard";
import "./Dashboard.scss";
import {
  formatNumberToCurrency,
  calcPercent,
  filtersI,
  updateFilters,
  setComparedPeriodParams,
  setValuesFromTo,
  updateCustomFilters,
} from "./features";
import { useAppSelector } from "../../hooks";
import { Spinner } from "../../components/Spinner/Spinner";
import { CardSmall } from "./components/CardSmall";
import { PeriodText } from "./components/PeriodText";
import { trimmer } from "../../helpers/common";
import { DashboardSkeleton } from "../../components/Skeletons/DashboardSkeleton";

const { RangePicker } = DatePicker;

interface BarChartI {
  percent: number;
  color: string;
}

export const Dashboard = () => {
  const user = useAppSelector((state) => state.profile);

  const today = dayjs();
  const [messageApi, contextHolder] = message.useMessage();
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([today.startOf("year"), today.endOf("day")]);

  const [filters, setFilters] = useState<filtersI>({
    period: "Year to date",
    comparedPeriod: "Previous period",
    breakdown: "Daily",
  });

  const [paramsCurrent, setParamsCurrent] = useState<TableParamsGetTotals>({
    date_from: dayjs(dateRange[0]).toDate(),
    date_to: dayjs(dateRange[1]).toDate(),
    breakdown: "day",
  });

  const previousYearStart = dayjs(dateRange[0]).subtract(1, "year");
  const previousYearEnd = dayjs(dateRange[1]).subtract(1, "year");
  const [paramsCompared, setParamsCompared] = useState<TableParamsGetTotals>({
    date_from: previousYearStart.toDate(),
    date_to: previousYearEnd.toDate(),
    breakdown: "day",
  });

  const [getCurrentTotals, { data: dataCurrentTotals }] =
    useLazyGetTotalsQuery();
  const [getComparedTotals, { data: dataComparedTotals }] =
    useLazyGetTotalsQuery();

  const [getCurrentFounding, { data: dataCurrentFounding }] =
    useLazyGetFoundingQuery();
  const [getComparedFounding, { data: dataComparedFounding }] =
    useLazyGetFoundingQuery();

  const [getCurrentFees, { data: dataCurrentFees }] = useLazyGetFeesQuery();
  const [getComparedFees, { data: dataComparedFees }] = useLazyGetFeesQuery();

  const [getTops, { data: dataTops }] = useLazyGetTopsQuery();
  const [getStatuses, { data: dataStatuses }] = useLazyGetStatusesQuery();

  //@ts-ignore
  const { data: dataFirst } = useGetFirstQuery();

  const [noCompApplied, setNoCompApplied] = useState(false);

  const [dataFounding, setDataFounding] = useState([]);
  const [dataFees, setDataFees] = useState([]);
  const [fromToFounding, setfromToFounding] = useState<string[]>([]);

  const [prevComp, setPrevCopm] = useState("");

  const [comparedOptions, setComparedOptions] = useState<SelectOption[]>([
    { value: "Previous period", label: "Previous period" },
    { value: "Previous month", label: "Previous month" },
    {
      value: "Previous year",
      label: "Previous year",
    },
    { value: "No comparison", label: "No comparison" },
  ]);

  const [breakdownOptions, setBreakdownOptions] = useState([
    { value: "Hourly", label: "Hourly" },
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
  ]);

  const [barDataApplicationStatuses, setBarDataApplicationStatuses] = useState<
    BarChartI[]
  >([]);
  const [barDataTransactionStatuses, setBarDataTransactionStatuses] = useState<
    BarChartI[]
  >([]);

  const [noApplicationStatuses, setNoApplicationStatuses] = useState(false);
  const [noTransactionStatuses, setNoTransactionStatuses] = useState(false);
  const [applyDisabled, setApplyDisabled] = useState(true);
  const [loader, setLoader] = useState(true);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: "error",
      content: text,
      style: {
        marginTop: "90vh",
      },
    });
  };

  useEffect(() => {
    setComparedPeriodParams(filters, dateRange, setParamsCompared);
  }, [filters.comparedPeriod, dateRange]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const dateString0 = dateStrings[0];
      const dateString1 = dateStrings[1];
      let date0 = dayjs(dateString0);
      let date1 = dayjs(dateString1);
      const startOfDay = date0.startOf("day");
      const endOfDay = date1.endOf("day");
      setDateRange([startOfDay, endOfDay]);
      setFilters((prev: filtersI) => ({ ...prev, period: "Custom" }));
      setApplyDisabled(false);
    }
  };

  useEffect(() => {
    if (filters.period !== "Custom") {
      updateFilters(
        filters,
        setFilters,
        setBreakdownOptions,
        setComparedOptions,
        setDateRange,
        dateRange,
        setParamsCurrent,
        dataFirst?.data
      );
    }
  }, [filters.period, filters.breakdown]);

  useEffect(() => {
    if (filters.period === "Custom") {
      updateCustomFilters(
        filters,
        setFilters,
        setBreakdownOptions,
        setComparedOptions,
        dateRange,
        setParamsCurrent
      );
    }
  }, [filters.breakdown, dateRange]);

  useEffect(() => {
    if (dataCurrentFounding?.data && dataComparedFounding?.data) {
      let current: any = [];
      dataCurrentFounding?.data?.forEach((elem: any) => {
        current.push({
          CurValue: elem.value,
          CurDate: elem.date,
          ComValue: "",
          ComDate: "",
        });
      });

      dataComparedFounding?.data?.forEach((elem: any, index: number) => {
        if (current[index]) {
          if (noCompApplied) {
            current[index].ComValue = null;
          } else {
            current[index].ComValue = elem.value;
            current[index].ComDate = elem.date;
          }
        }
      });

      setDataFounding(current);
    }
  }, [dataCurrentFounding, dataComparedFounding, noCompApplied]);

  useEffect(() => {
    if (dataCurrentFees?.data && dataComparedFounding?.data) {
      let current: any = [];
      dataCurrentFees?.data?.forEach((elem: any) => {
        current.push({
          CurValue: elem.value,
          CurDate: elem.date,
          ComValue: "",
          ComDate: "",
        });
      });

      dataComparedFees?.data?.forEach((elem: any, index: number) => {
        if (current[index]) {
          if (noCompApplied) {
            current[index].ComValue = null;
          } else {
            current[index].ComValue = elem.value;
            current[index].ComDate = elem.date;
          }
        }
      });

      setDataFees(current);
    }
  }, [dataCurrentFees, dataComparedFees, noCompApplied]);

  const ApplyFilters = async () => {
    try {
      setLoader(true);
      await Promise.all([
        getComparedTotals(paramsCompared),
        getComparedFounding(paramsCompared),
        getComparedFees(paramsCompared),

        getCurrentTotals(paramsCurrent),
        getCurrentFounding(paramsCurrent),
        getCurrentFees(paramsCurrent),
        getTops(paramsCurrent),
        getStatuses(paramsCurrent),
      ]);

      setLoader(false);

      setValuesFromTo(filters, fromToFounding, dateRange, setfromToFounding);

      if (filters.comparedPeriod === "No comparison") {
        setNoCompApplied(true);
      } else {
        setNoCompApplied(false);
      }
    } catch (error) {
      errorMessage("Apply filters failed");
    } finally {
    }
  };

  useEffect(() => {
    if (!dataStatuses) return;
    let noApp = true;
    let noTra = true;
    const result: BarChartI[] = dataStatuses?.data?.application_statuses?.map(
      (elem: StatusI) => {
        if (elem.percent) {
          noApp = false;
        }
        return {
          percent: elem.percent,
          color:
            elem.name === "Approved"
              ? "#09BF55"
              : elem.name === "Offer Made" && !elem.timed
              ? "#1F44DD"
              : elem.name === "Offer Made" && elem.timed
              ? "#ADE5EA"
              : elem.name === "Awaiting Signature" && !elem.timed
              ? "#D3A9F5"
              : elem.name === "Awaiting Signature" && elem.timed
              ? "#FFCB44"
              : elem.name === "No Offer"
              ? "#F97B81"
              : elem.name === "Awaiting Down Payment"
              ? "#F670C7"
              : "",
        };
      }
    );

    const result2: BarChartI[] = dataStatuses?.data?.transaction_statuses?.map(
      (elem: StatusI) => {
        if (elem.percent) {
          noTra = false;
        }

        return {
          percent: elem.percent,
          color:
            elem.name === "Approved"
              ? "#09BF55"
              : elem.name === "In Progress"
              ? "#1F44DD"
              : elem.name === "Withdrawn"
              ? "#F97B81"
              : elem.name === "Timed Out"
              ? "#FFCB44"
              : elem.name === "Awaiting Down Payment"
              ? "#F670C7"
              : "",
        };
      }
    );

    setNoApplicationStatuses(noApp);
    setNoTransactionStatuses(noTra);

    setBarDataApplicationStatuses(result);
    setBarDataTransactionStatuses(result2);
  }, [dataStatuses]);

  useEffect(() => {
    setLoader(true);
    if (!user?.role?.key) return;
    if (user?.role?.key === "main_account" || user?.role?.key === "partner") {
      getComparedTotals(paramsCompared);
      getComparedFounding(paramsCompared);

      getCurrentTotals(paramsCurrent);
      getCurrentFounding(paramsCurrent);
      getTops(paramsCurrent);
      getStatuses(paramsCurrent);

      setValuesFromTo(filters, fromToFounding, dateRange, setfromToFounding);
    } else {
      getComparedTotals(paramsCompared);
      getComparedFounding(paramsCompared);
      getComparedFees(paramsCompared);

      getCurrentTotals(paramsCurrent);
      getCurrentFounding(paramsCurrent);
      getCurrentFees(paramsCurrent);
      getTops(paramsCurrent);
      getStatuses(paramsCurrent);

      setValuesFromTo(filters, fromToFounding, dateRange, setfromToFounding);
    }
    setLoader(false);
  }, [user?.role?.key]);

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs();
  };

  return (
    <>
      {contextHolder}
      {loader ? (
        <DashboardSkeleton />
      ) : (
        <div className="container">
          <div className="dashboard">
            <div className="dashboard__filters">
              <div className="dashboard__filters-container">
                <div style={{ width: "170px", margin: "0 8px -19px 0" }}>
                  <CustomSelect
                    name=""
                    className="input-white-small"
                    placeholder="Show All"
                    value={filters.period}
                    options={[
                      { value: "Today", label: "Today" },
                      { value: "Last 7 days", label: "Last 7 days" },
                      {
                        value: "Last 4 weeks",
                        label: "Last 4 weeks",
                      },
                      { value: "Last 3 months", label: "Last 3 months" },
                      { value: "Last 12 months", label: "Last 12 months" },
                      { value: "Month to date", label: "Month to date" },
                      { value: "Quarter to date", label: "Quarter to date" },
                      { value: "Year to date", label: "Year to date" },
                      { value: "All time", label: "All time" },
                    ]}
                    onChange={(value: any) => {
                      if (value === "All time") {
                        setPrevCopm(filters.comparedPeriod);
                      }
                      setFilters((prev: filtersI) => ({
                        ...prev,
                        period: value,
                      }));
                      setApplyDisabled(false);
                    }}
                    isSearchSelect={false}
                  />
                </div>

                <div style={{ margin: "0 42px 0 0" }}>
                  <RangePicker
                    suffixIcon={<img src={calendar} alt="calendar" />}
                    value={dateRange}
                    inputReadOnly
                    disabledDate={disabledDate}
                    onChange={onRangeChange}
                    className="input-white-small"
                    allowClear={false}
                    format="MM-DD-YYYY"
                  />
                </div>
                <div
                  style={{
                    color: "#838388",
                    fontSize: "16px",
                    textAlign: "center",
                    margin: "0 42px 0 0",
                    width: "120px",
                  }}
                >
                  Compared to
                </div>
                <div style={{ width: "170px", margin: "0px 8px -15px 0" }}>
                  <CustomSelect
                    name=""
                    className="input-white-small"
                    placeholder="Show All"
                    value={filters.comparedPeriod}
                    options={comparedOptions}
                    onChange={(value: any) => {
                      if (value === "No comparison") {
                        setPrevCopm(filters.comparedPeriod);
                      }
                      setFilters((prev: filtersI) => ({
                        ...prev,
                        comparedPeriod: value,
                      }));
                      setApplyDisabled(false);
                    }}
                    isSearchSelect={false}
                    allowClear={false}
                    suffixIcon={false}
                  />
                </div>

                <div style={{ width: "170px", margin: "0px 40px -15px 0" }}>
                  <CustomSelect
                    name=""
                    className="input-white-small"
                    placeholder="Show All"
                    value={filters.breakdown}
                    options={breakdownOptions}
                    onChange={(value: any) => {
                      setFilters((prev: filtersI) => ({
                        ...prev,
                        breakdown: value,
                      }));
                      setApplyDisabled(false);
                    }}
                    isSearchSelect={false}
                    allowClear={false}
                    suffixIcon={false}
                  />
                </div>
                <Button
                  className="blue-btn"
                  style={{ height: "32px", margin: "0px 0 5px 0" }}
                  disabled={applyDisabled}
                  onClick={() => {
                    ApplyFilters();
                    setApplyDisabled(true);
                  }}
                >
                  Apply filters
                </Button>
              </div>
              <div className="dashboard__filters-btn"></div>
            </div>

            <div className="dashboard__row">
              <CardSmall
                title={"Customers"}
                dataCurrentTotals={dataCurrentTotals?.data?.customers}
                dataComparedTotals={dataComparedTotals?.data?.customers}
                noCompApplied={noCompApplied}
                filters={filters}
                prevComp={prevComp}
              />
              <CardSmall
                title={"Applications"}
                dataCurrentTotals={dataCurrentTotals?.data?.applications}
                dataComparedTotals={dataComparedTotals?.data?.applications}
                noCompApplied={noCompApplied}
                filters={filters}
                prevComp={prevComp}
              />
              <CardSmall
                title={"Funding"}
                dataCurrentTotals={dataCurrentTotals?.data?.funding}
                dataComparedTotals={dataComparedTotals?.data?.funding}
                noCompApplied={noCompApplied}
                filters={filters}
                prevComp={prevComp}
                formatted
              />
              {(user?.role?.key === "admin" ||
                user?.role?.key === "super_admin") && (
                <CardSmall
                  title={"Processing Fees"}
                  dataCurrentTotals={dataCurrentTotals?.data?.fees}
                  dataComparedTotals={dataComparedTotals?.data?.fees}
                  noCompApplied={noCompApplied}
                  filters={filters}
                  prevComp={prevComp}
                  formatted
                />
              )}
            </div>
            <div className="dashboard__row">
              <div
                className="dashboard__graphs graphs"
                style={
                  user?.role?.key === "main_account" ? { width: "100%" } : {}
                }
              >
                <div className="graphs__values">
                  <div className="graphs__wrapper">
                    <div className="graphs__title">Approved Funding</div>
                    <div className="graphs__value">
                      {!noCompApplied &&
                        calcPercent(
                          dataCurrentFounding?.totals ?? 0,
                          dataComparedFounding?.totals ?? 0
                        )}
                    </div>
                  </div>
                  <div className="graphs__wrapper justify-between">
                    <div className="graphs__number">
                      {formatNumberToCurrency(
                        dataCurrentFounding?.totals
                          ? dataCurrentFounding?.totals
                          : 0
                      )}
                    </div>
                    <div className="graphs__period">
                      <span className="graphs__period__currency">
                        {!noCompApplied &&
                          formatNumberToCurrency(
                            dataComparedFounding?.totals
                              ? dataComparedFounding?.totals
                              : 0
                          )}
                      </span>{" "}
                      <PeriodText
                        noCompApplied={noCompApplied}
                        comparedPeriod={filters.comparedPeriod}
                        prevComp={prevComp}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", height: "70%" }}>
                  <YourChartComponent
                    data={dataFounding}
                    values={fromToFounding}
                    breakdown={filters.breakdown}
                    noCompApplied={noCompApplied}
                    type={"Approved Funding"}
                  />
                </div>
              </div>
              {user?.role?.key === "admin" ||
              user?.role?.key === "super_admin" ? (
                <div className="dashboard__graphs graphs">
                  <div className="graphs__values">
                    <div className="graphs__wrapper">
                      <div className="graphs__title">Processing Fees</div>
                      <div className="graphs__value">
                        {!noCompApplied &&
                          calcPercent(
                            dataCurrentFees?.totals ?? 0,
                            dataComparedFees?.totals ?? 0
                          )}
                      </div>
                    </div>{" "}
                    <div className="graphs__wrapper justify-between">
                      <div className="graphs__number">
                        {formatNumberToCurrency(
                          dataCurrentFees?.totals ? dataCurrentFees?.totals : 0
                        )}{" "}
                      </div>
                      <div className="graphs__period">
                        {!noCompApplied && (
                          <>
                            <span className="graphs__period__currency">
                              {formatNumberToCurrency(
                                dataComparedFees?.totals
                                  ? dataComparedFees?.totals
                                  : 0
                              )}
                            </span>{" "}
                            <PeriodText
                              noCompApplied={noCompApplied}
                              comparedPeriod={filters.comparedPeriod}
                              prevComp={prevComp}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", height: "70%" }}>
                    <YourChartComponent
                      data={dataFees}
                      values={fromToFounding}
                      breakdown={filters.breakdown}
                      noCompApplied={noCompApplied}
                      type={"Processing Fees"}
                    />
                  </div>
                </div>
              ) : (
                user?.role?.key === "partner" && (
                  <div className="dashboard__table">
                    <div className="dashboard__table__title">
                      Top Products by Funding
                    </div>
                    <div className="dashboard__table__header header">
                      <div className="header__item title">Product</div>
                      <div className="header__item">Applications</div>
                      <div className="header__item">Total $</div>
                    </div>
                    {dataTops?.data?.products.length ? (
                      dataTops?.data?.products?.map((elem: TopItem) => {
                        return (
                          <div
                            className="dashboard__table__row row"
                            key={elem.id}
                          >
                            <div className="row__item title">{elem.name}</div>
                            <div className="row__item">{elem.applicants}</div>
                            <div className="row__item">
                              $
                              {elem.value
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="dashboard__table__nodata">
                        <img src={Illustration} alt="no data" />
                        No data to display
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
            {user?.role?.key !== "partner" && (
              <>
                <div className="dashboard__row">
                  {user?.role?.key !== "main_account" && (
                    <div className="dashboard__table">
                      <div className="dashboard__table__title">
                        Top Main Accounts
                      </div>
                      <div className="dashboard__table__header header">
                        <div className="header__item title">Account name</div>
                        <div className="header__item">Applications</div>
                        <div className="header__item">Total $</div>
                      </div>
                      {dataTops?.data?.main_accounts.length ? (
                        dataTops?.data?.main_accounts?.map((elem: TopItem) => {
                          return (
                            <div
                              className="dashboard__table__row row"
                              key={elem.id}
                            >
                              <div className="row__item title">
                                <img
                                  src={elem.logo}
                                  alt="logo"
                                  className="dashboard__table__logo"
                                />
                                {trimmer(elem.company_name, 25)}
                              </div>
                              <div className="row__item">{elem.applicants}</div>
                              <div className="row__item">
                                $
                                {elem.value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="dashboard__table__nodata">
                          <img src={Illustration} alt="no data" />
                          No data to display
                        </div>
                      )}
                    </div>
                  )}
                  <div className="dashboard__table">
                    <div className="dashboard__table__title">
                      {user?.role?.key === "admin" ||
                      user?.role?.key === "super_admin"
                        ? "Top Partner Accounts"
                        : "Top Partner Accounts by Funding"}
                    </div>
                    <div className="dashboard__table__header header">
                      <div className="header__item title">Account name</div>
                      <div className="header__item">Applications</div>
                      <div className="header__item">Total $</div>
                    </div>
                    {dataTops?.data?.partners.length ? (
                      dataTops?.data?.partners?.map((elem: TopItem) => {
                        return (
                          <div
                            className="dashboard__table__row row"
                            key={elem.id}
                          >
                            <div className="row__item title">
                              <img
                                src={elem.logo}
                                alt="logo"
                                className="dashboard__table__logo"
                              />
                              {trimmer(elem.company_name, 25)}
                            </div>
                            <div className="row__item">{elem.applicants}</div>
                            <div className="row__item">
                              $
                              {elem.value
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="dashboard__table__nodata">
                        <img src={Illustration} alt="no data" />
                        No data to display
                      </div>
                    )}
                  </div>
                  {user?.role?.key === "main_account" && (
                    <div className="dashboard__table">
                      <div className="dashboard__table__title">
                        Top Loan Products by Funding
                      </div>
                      <div className="dashboard__table__header header">
                        <div className="header__item title">Product</div>
                        <div className="header__item">Applications</div>
                        <div className="header__item">Total $</div>
                      </div>
                      {dataTops?.data?.products.length ? (
                        dataTops?.data?.products?.map((elem: TopItem) => {
                          return (
                            <div
                              className="dashboard__table__row row"
                              key={elem.id}
                            >
                              <div className="row__item title">{elem.name}</div>
                              <div className="row__item">{elem.applicants}</div>
                              <div className="row__item">
                                $
                                {elem.value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="dashboard__table__nodata">
                          <img src={Illustration} alt="no data" />
                          No data to display
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {user?.role?.key !== "main_account" && (
                  <div className="dashboard__row">
                    <div className="dashboard__table">
                      <div className="dashboard__table__title">
                        Top Loan Products
                      </div>
                      <div className="dashboard__table__header header">
                        <div className="header__item title">Product</div>
                        <div className="header__item">Applications</div>
                        <div className="header__item">Total $</div>
                      </div>
                      {dataTops?.data?.products.length ? (
                        dataTops?.data?.products?.map((elem: TopItem) => {
                          return (
                            <div
                              className="dashboard__table__row row"
                              key={elem.id}
                            >
                              <div className="row__item title">{elem.name}</div>
                              <div className="row__item">{elem.applicants}</div>
                              <div className="row__item">
                                $
                                {elem.value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="dashboard__table__nodata">
                          <img src={Illustration} alt="no data" />
                          No data to display
                        </div>
                      )}
                    </div>
                    <div className="dashboard__table">
                      <div className="dashboard__table__title">Top Banks</div>
                      <div className="dashboard__table__header header">
                        <div className="header__item title">Bank</div>
                        <div className="header__item">Applications</div>
                        <div className="header__item">Total $</div>
                      </div>
                      {dataTops?.data?.banks.length ? (
                        dataTops?.data?.banks?.map((elem: TopItem) => {
                          return (
                            <div
                              className="dashboard__table__row row"
                              key={elem.id}
                            >
                              <div className="row__item title">
                                <img
                                  src={elem.logo}
                                  alt="logo"
                                  className="dashboard__table__logo"
                                />
                                {trimmer(elem.name, 25)}
                              </div>
                              <div className="row__item">{elem.applicants}</div>
                              <div className="row__item">
                                $
                                {elem.value
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="dashboard__table__nodata">
                          <img src={Illustration} alt="no data" />
                          No data to display
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="dashboard__row">
              <div className="dashboard__progress progress">
                {loader ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner color="grey" size="medium" />
                  </div>
                ) : (
                  <>
                    <div className="progress__title">
                      Application statuses analytics
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {noApplicationStatuses ? (
                        <div className="progress__noData">
                          <img
                            src={Illustration}
                            style={{ width: "20px", margin: "0 10px 0 0" }}
                            alt="no data"
                          />
                          No data to display
                        </div>
                      ) : (
                        ""
                      )}
                      <PercentageBar barsData={barDataApplicationStatuses} />
                    </div>
                    <div className="progress-table">
                      <div className="progress-table__header">
                        <div className="progress-table__header-item main">
                          Status
                        </div>
                        <div className="progress-table__header-item">
                          Applications
                        </div>
                        <div className="progress-table__header-item">%</div>
                      </div>
                      {dataStatuses?.data?.application_statuses.length ? (
                        dataStatuses?.data?.application_statuses?.map(
                          (elem: StatusI) => {
                            return (
                              <div
                                className="progress-table__row"
                                key={elem.name}
                              >
                                <div className="progress-table__row-item main">
                                  <DotIcon
                                    fill={
                                      elem.name === "Approved"
                                        ? "#09BF55"
                                        : elem.name === "Offer Made"
                                        ? "#1F44DD"
                                        : elem.name === "Offer Made/Timed Out"
                                        ? "#ADE5EA"
                                        : elem.name === "Awaiting Signature"
                                        ? "#D3A9F5"
                                        : elem.name ===
                                          "Awaiting Sign./Timed Out"
                                        ? "#FFCB44"
                                        : elem.name === "No Offer"
                                        ? "#F97B81"
                                        : elem.name === "Awaiting Down Payment"
                                        ? "#F670C7"
                                        : ""
                                    }
                                    style={{ margin: "0 5px 0 0" }}
                                  />{" "}
                                  {elem.name}
                                </div>
                                <div className="progress-table__row-item">
                                  {elem.value ? elem.value : "--"}
                                </div>
                                <div className="progress-table__row-item">
                                  {elem.percent ? `${elem.percent}%` : "--"}
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="dashboard__table__nodata">
                          <img src={Illustration} alt="no data" />
                          No data to display
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="dashboard__progress progress">
                {loader ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner color="grey" size="medium" />
                  </div>
                ) : (
                  <>
                    <div className="progress__title">
                      Transaction statuses analytics
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {noTransactionStatuses ? (
                        <div className="progress__noData">
                          <img
                            src={Illustration}
                            style={{ width: "20px", margin: "0 10px 0 0" }}
                            alt="no data"
                          />
                          No data to display
                        </div>
                      ) : (
                        ""
                      )}
                      <PercentageBar barsData={barDataTransactionStatuses} />
                    </div>
                    <div className="progress-table">
                      <div className="progress-table__header">
                        <div className="progress-table__header-item main">
                          Status
                        </div>
                        <div className="progress-table__header-item">
                          Applications
                        </div>
                        <div className="progress-table__header-item">%</div>
                      </div>
                      {dataStatuses?.data?.transaction_statuses.length ? (
                        dataStatuses?.data?.transaction_statuses?.map(
                          (elem: StatusI) => {
                            return (
                              <div
                                className="progress-table__row"
                                key={elem.name}
                              >
                                <div className="progress-table__row-item main">
                                  <DotIcon
                                    fill={
                                      elem.name === "Approved"
                                        ? "#09BF55"
                                        : elem.name === "In Progress"
                                        ? "#1F44DD"
                                        : elem.name === "Withdrawn"
                                        ? "#F97B81"
                                        : elem.name === "Timed Out"
                                        ? "#FFCB44"
                                        : elem.name === "Awaiting Down Payment"
                                        ? "#F670C7"
                                        : ""
                                    }
                                    style={{ margin: "0 5px 0 0" }}
                                  />{" "}
                                  {elem.name}
                                </div>
                                <div className="progress-table__row-item">
                                  {elem.value ? elem.value : "--"}
                                </div>
                                <div className="progress-table__row-item">
                                  {elem.percent ? `${elem.percent}%` : "--"}
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="dashboard__table__nodata">
                          <img src={Illustration} alt="no data" />
                          No data to display
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
