import { iconI } from './types';

export const MainAccountIcon = ({ fill, style }: iconI) => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9199_25698)">
      <path d="M15.334 18C15.334 16.7575 15.334 16.1362 15.131 15.6462C14.8603 14.9928 14.3412 14.4736 13.6878 14.203C13.1978 14 12.5765 14 11.334 14H9.33398C8.09147 14 7.47022 14 6.98016 14.203C6.32675 14.4736 5.80762 14.9928 5.53697 15.6462C5.33398 16.1362 5.33398 16.7575 5.33398 18M13.0007 8.66667C13.0007 10.1394 11.8067 11.3333 10.334 11.3333C8.86123 11.3333 7.66732 10.1394 7.66732 8.66667C7.66732 7.19391 8.86123 6 10.334 6C11.8067 6 13.0007 7.19391 13.0007 8.66667Z" stroke={fill} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.4306 4.68168C17.684 4.22723 17.8106 4 18 4C18.1894 4 18.316 4.22722 18.5694 4.68167L18.6349 4.79925C18.7069 4.92839 18.7429 4.99296 18.799 5.03556C18.8551 5.07817 18.925 5.09398 19.0648 5.12561L19.1921 5.15441C19.684 5.26571 19.93 5.32136 19.9885 5.50955C20.047 5.69773 19.8793 5.89381 19.544 6.28598L19.4572 6.38744C19.3619 6.49889 19.3143 6.55461 19.2928 6.62354C19.2714 6.69248 19.2786 6.76682 19.293 6.91551L19.3061 7.05088C19.3568 7.57412 19.3822 7.83574 19.229 7.95204C19.0758 8.06835 18.8455 7.96231 18.3849 7.75023L18.2657 7.69537C18.1348 7.6351 18.0694 7.60497 18 7.60497C17.9306 7.60497 17.8652 7.6351 17.7343 7.69537L17.6151 7.75023C17.1545 7.96231 16.9242 8.06835 16.771 7.95204C16.6178 7.83574 16.6432 7.57412 16.6939 7.05088L16.707 6.91551C16.7214 6.76682 16.7286 6.69248 16.7072 6.62354C16.6857 6.55461 16.6381 6.49889 16.5428 6.38744L16.456 6.28599C16.1206 5.89381 15.953 5.69773 16.0115 5.50955C16.07 5.32136 16.316 5.26571 16.8079 5.15441L16.9352 5.12561C17.075 5.09398 17.1449 5.07817 17.201 5.03556C17.2571 4.99296 17.2931 4.92839 17.3651 4.79925L17.4306 4.68168Z" fill="#667085"/>
    </g>
    <defs>
      <clipPath id="clip0_9199_25698">
        <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>
  );
};
