import moment from "moment";
import React, { FC } from "react";

interface PropsI {
  item: any;
  status: string;
  applicationData: any;
}

export const ConcoraOnlyOffer: FC<PropsI> = (props) => {
  const { item, status, applicationData } = props;

  const addDecimal = (formattedStr: string) => {
    const number = parseFloat(formattedStr);
    const roundedNumber = number.toFixed(2);

    let resultStr = String(roundedNumber);

    const parts = resultStr.split(".");
    if (parts[1]?.length === 0) {
      return resultStr + "00";
    } else if (parts[1]?.length === 1) {
      return resultStr + "0";
    }
    return resultStr;
  };

  const UpdatedRefund =
    item?.offer_value - applicationData?.data?.refund_amount;
  console.log("status", status);
  return (
    <>
      {status === "Refunded" && (
        <div className="OfferItem__option">
          <div className="OfferItem__row">
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Available Credit</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item?.data?.availableCredit)}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Transaction Amount</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item?.offer_value)}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Est. Monthly Payment</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item.monthly_payment)}
              </p>
            </div>
          </div>
          <div className="OfferItem__row">
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Refund Amount </p>
              <p className="OfferItem__value bold">
                $ {addDecimal(applicationData?.data?.refund_amount)}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Upd. Transaction Amount</p>
              <p className="OfferItem__value bold">
                ${addDecimal(UpdatedRefund + "")}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Refund Requested </p>
              <p className="OfferItem__value bold">
                {moment(applicationData?.data?.refund_requested_at).format(
                  "DD/MM/YYYY HH:MM A"
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {status === "Approved-concora" && (
        <div className="OfferItem__option">
          <div className="OfferItem__row">
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Available Credit</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item?.data?.availableCredit)}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Transaction Amount</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item?.offer_value)}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Est. Monthly Payment</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item.monthly_payment)}
              </p>
            </div>
          </div>
        </div>
      )}
      {!status && (
        <div className="OfferItem__option">
          <div className="d-flex">
            {item.status === "Expired" && (
              <div className="w-50">
                <div className="OfferItem__row-title">&nbsp;</div>
              </div>
            )}
            {item.status === "Expired" && (
              <div className="w-50">
                <div className="OfferItem__row-expired">Expired</div>
              </div>
            )}
            {item.status === "Declined" && (
              <div className="w-50">
                <div className="OfferItem__row-title">&nbsp;</div>
              </div>
            )}
            {item.status === "Declined" && (
              <div className="w-50">
                <div className="OfferItem__row-declined">Declined</div>
              </div>
            )}
            {item.status === "Pending" && (
              <div className="w-50">
                <div className="OfferItem__row-title">&nbsp;</div>
              </div>
            )}
            {item.status === "Pending" && (
              <div className="w-50">
                <div className="OfferItem__row-pending">Pending</div>
              </div>
            )}
          </div>
          <div className="OfferItem__row">
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Prequalified credit limit</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item?.data?.creditLine)}
              </p>
            </div>
            <div className="OfferItem__wrapper">
              <p className="OfferItem__name">Estimated monthly payment</p>
              <p className="OfferItem__value bold">
                $ {addDecimal(item.monthly_payment)}
              </p>
            </div>
          </div>
          <div className="OfferItem__line"></div>
        </div>
      )}
    </>
  );
};
