import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../icons/HomeIcon';
import { PartnersIcon } from '../icons/PartnersIcon';
import { CustomersIcon } from '../icons/CustomersIcon';
import { ApplicationsIcon } from '../icons/ApplicationsIcon';
import { MainAccountIcon } from '../icons/MainAccountIcon';
import logout from '../../assets/logout-icon.svg';
import logout_red from '../../assets/logout-icon-red.svg';
import { useAppSelector } from '../../hooks';
import { ReportsIcon } from '../icons/ReportsIcon';
import { LendersIcon } from '../icons/LendersIcon';
import './index.scss';
import { useLogoutMutation } from '../../api/common';
import { NavigationSkeleton } from '../Skeletons/NavigationSkeleton';

export const Navigation = ({ accountLogo }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutMut, {}] = useLogoutMutation();
  const user = useAppSelector((state) => state.profile);
  const [logoutHover, setLogoutHover] = useState(false);

  const logoutFunc = async () => {
    try {
      await logoutMut('').unwrap();
      localStorage.removeItem('token');
      navigate('/login');
    } catch (error) {}
  };

  return (
    <div className="navigation">
      <div>
        <div className="navigation__main">
          {!user.role.key || !accountLogo ? (
            <NavigationSkeleton />
          ) : (
            <>
              <img src={accountLogo} className="navigation__logo" alt="logo" />
              <div
                className={`navigation__item ${
                  location.pathname === '/dashboard' ? 'active' : ''
                }`}
                onClick={() => {
                  navigate('/dashboard');
                }}
              >
                <HomeIcon
                  fill={
                    location.pathname === '/dashboard' ? 'white' : '#808086'
                  }
                  style={{ margin: '0 5 0 0' }}
                />
                Dashboard
              </div>
              {(user.role.key === 'super_admin' ||
                user.role.key === 'admin') && (
                <div
                  className={`navigation__item ${
                    location.pathname.includes('main_accounts') ||
                    location.pathname === '/new-main-accounts' ||
                    location.pathname === '/new-main-account'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    navigate('/main_accounts');
                  }}
                >
                  <MainAccountIcon
                    fill={
                      location.pathname.includes('main_accounts') ||
                      location.pathname === '/new-main-accounts' ||
                      location.pathname === '/new-main-account'
                        ? 'white'
                        : '#808086'
                    }
                    style={{ margin: '0 5 0 0' }}
                  />
                  Main accounts
                </div>
              )}
              {user.role.key === 'partner' ? (
                <></>
              ) : (
                <div
                  className={`navigation__item ${
                    location.pathname.includes('partners') ||
                    location.pathname === '/new-partner'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    navigate('/partners');
                  }}
                >
                  <PartnersIcon
                    fill={
                      location.pathname.includes('partners') ||
                      location.pathname === '/new-partner'
                        ? 'white'
                        : '#808086'
                    }
                    style={{ margin: '0 5px 0 0' }}
                  />
                  Partners
                </div>
              )}
              <div
                className={`navigation__item ${
                  location.pathname === '/applications' ? 'active' : ''
                }`}
                onClick={() => {
                  navigate('/applications');
                }}
              >
                <ApplicationsIcon
                  fill={
                    location.pathname === '/applications' ? 'white' : '#808086'
                  }
                  style={{ margin: '0 5px 0 0' }}
                />
                Applications
              </div>

              <div
                className={`navigation__item ${
                  location.pathname === '/customers' ? 'active' : ''
                }`}
                onClick={() => {
                  navigate('/customers');
                }}
              >
                <CustomersIcon
                  fill={
                    location.pathname === '/customers' ? 'white' : '#808086'
                  }
                  style={{ margin: '0 5px 0 0' }}
                />
                Customers
              </div>
              {(user.role.key === 'admin' ||
                user.role.key === 'super_admin') && (
                <div
                  className={`navigation__item ${
                    location.pathname.includes('lenders') ? 'active' : ''
                  }`}
                  onClick={() => {
                    navigate('/lenders');
                  }}
                >
                  <LendersIcon
                    fill={
                      location.pathname.includes('lenders')
                        ? 'white'
                        : '#808086'
                    }
                    style={{ margin: '0 5px 0 0' }}
                  />
                  Lenders
                </div>
              )}
              <div
                className={`navigation__item ${
                  location.pathname === '/reports' ? 'active' : ''
                }`}
                onClick={() => {
                  navigate('/reports');
                }}
              >
                <ReportsIcon
                  fill={location.pathname === '/reports' ? 'white' : '#808086'}
                  style={{ margin: '0 5px 0 0' }}
                />
                Reports
              </div>
            </>
          )}
        </div>
      </div>
      <div className="navigation__logout">
        <span>{user.name}</span>
        <img
          className="navigation__logout-img"
          src={logoutHover ? logout_red : logout}
          onMouseEnter={() => setLogoutHover(true)}
          onMouseLeave={() => setLogoutHover(false)}
          onClick={logoutFunc}
        />
      </div>
    </div>
  );
};
