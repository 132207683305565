import React from 'react';

interface BarData {
  percent: number;
  color: string;
}

interface PercentageBarProps {
  barsData: BarData[];
}

const PercentageBar: React.FC<PercentageBarProps> = ({ barsData }) => {
  const containerStyle: React.CSSProperties = {
    width: '100%',
    height: '8px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
  };

  const barSections = barsData.map((bar, index) => {
    const sectionStyle: React.CSSProperties = {
      height: '100%',
      backgroundColor: bar.color,
      width: `${bar.percent}%`,
      float: 'left',
      borderRadius: '4px',
      margin: '0 0.5px',
    };

    return <div key={index} style={sectionStyle}></div>;
  });

  return <div style={containerStyle}>{barSections}</div>;
};

export default PercentageBar;
