import './index.scss';
import pen from '../../../assets/edit-new.svg';
import paperclip from '../../../assets/Paperclip.svg';
import paperclip_blue from '../../../assets/Paperclip-blue.svg';
import send_message from '../../../assets/paper-airplane.svg';
import download from '../../../assets/download-cloud.svg';
import arrow_right from '../../../assets/arrow-right.svg';
import x_close from '../../../assets/x-close-new.svg';

import { useNavigate, useParams } from 'react-router-dom';
import { Button, message as antMessage, Tooltip, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  useAddFileMutation,
  useGetFilesQuery,
  useLazyGetTicketQuery,
  useSendMessageMutation,
  useSetReadMutation,
  useSetTicketStatusMutation,
  useUpdateTicketMutation,
} from '../../../api/tickets';
import moment from 'moment';
import { useAppSelector } from '../../../hooks';
import { CustomInput } from '../../../customFields/CustomInput';
import picture from '../../../assets/picture_blue_lg.svg';
import file from '../../../assets/file_blue_lg.svg';
import { trimmer } from '../../../helpers/common';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { successMessage } from '../../../components/Messages/messages';
import { ChangeTicketCategoryModal } from '../../../components/Modals/ChangeTicketCategoryModal';
import { ChangeTicketAssigneeModal } from '../../../components/Modals/ChangeTicketAssigneeModal';
import {
  ADMIN,
  MAIN_ACCOUNT,
  PARTNER,
  SUPER_ADMIN,
} from '../../../helpers/variables';
import { downloadTicketFiles } from '../../../api/axiosCrud';
import { TicketSkeleton } from '../../../components/Skeletons/TicketSkeleton';

export const TicketView = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [messageApi, contextHolder] = antMessage.useMessage();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);
  const { id: ticketId } = useParams();
  const [getTicket, { data: ticket, isLoading: isTicketLoading }] =
    useLazyGetTicketQuery();
  const [setRead, {}] = useSetReadMutation();
  const [setStatus, { isSuccess: isSetStatusSuccess, data: newStatus }] =
    useSetTicketStatusMutation();
  const [updateTicket, { isLoading: isTicketUpdating }] =
    useUpdateTicketMutation();
  const [sendMessage, {}] = useSendMessageMutation();
  const [addFile, { data: fileData, isLoading: isFileLoading }] =
    useAddFileMutation();
  const { data: allFiles } = useGetFilesQuery(ticketId);
  const [message, setMessage] = useState('');
  const [category, setCategory] = useState<string | number | undefined>();
  const [assignee, setAssignee] = useState<string | number | undefined>();
  const [openCloseConfirm, setOpenCloseConfirm] = useState(false);
  const [openResolveConfirm, setOpenResolveConfirm] = useState(false);
  const [openDownloadConfirm, setOpenDownloadConfirm] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openAssignee, setOpenAssignee] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const scrollRef = useRef(null);

  const viewProfile = () => {
    if (ticket?.data?.user?.role === PARTNER)
      navigate(`/partners/${ticket?.data?.user?.model_id}`);
    if (ticket?.data?.user?.role === MAIN_ACCOUNT)
      navigate(`/main_accounts/${ticket?.data?.user?.model_id}`);
    if (ticket?.data?.user?.role === ADMIN)
      navigate(`/admins/${ticket?.data?.user?.model_id}`);
  };

  const handleMessageSend = () => {
    sendMessage({
      text: message,
      ticket_id: ticketId,
      files: fileList.map((item) => item.fileId),
    })
      .then(() => {
        setMessage('');
        setFileList([]);
      })
      .catch(() => {});
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleMessageSend();
    }
  };

  const changeStatus = (status: string) => {
    setStatus({ id: ticketId, status: status });
  };
  const changeCategory = async () => {
    await updateTicket({ id: ticketId, body: { category_id: category } })
      .then(() => {
        setOpenCategory(false);
        successMessage(messageApi, 'Ticket category has been updated!');
      })
      .catch(() => {});
  };
  const changeAssignee = async () => {
    await updateTicket({ id: ticketId, body: { assigned_user_id: assignee } })
      .then(() => {
        setOpenAssignee(false);
        successMessage(
          messageApi,
          'Ticket assignee has been successfully changed!'
        );
      })
      .catch(() => {});
  };

  const onChangeFiles = async (info: any) => {
    const formData = new FormData();
    formData.append('model', 'ticket');
    info?.file && formData.append('field', info?.file);
    await addFile(formData)
      .unwrap()
      .then((data) => {
        setFileList((prev) => [
          ...prev,
          {
            fileId: data?.data?.id,
            name: info?.file?.name,
            size: info?.file?.size,
            type: info?.file?.type,
            uid: info?.file?.uid,
          },
        ]);
      })
      .catch(() => {});
  };

  const deleteFile = (uid: string) => {
    setFileList((prev) => {
      return prev.filter((item) => item.uid !== uid);
    });
  };

  const downloadAllFiles = () => {
    downloadTicketFiles(ticketId).then((res: any) => {
      const zipBlob = new Blob([res.data], { type: 'application/zip' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(zipBlob);
      // link.download = reportType;
      link.target = '_blank';

      const el = document.body;
      el.appendChild(link);

      link.click();
    });
  };

  const fileUpload = (
    <div className="ticket-view__upload">
      <Upload
        style={{ height: '20px' }}
        accept=".pdf,image/png,image/jpeg"
        beforeUpload={() => false}
        disabled={isFileLoading}
        multiple={false}
        fileList={fileList}
        onChange={(info) => onChangeFiles(info)}
      >
        <img
          src={!!fileList.length ? paperclip_blue : paperclip}
          alt="paperclip"
          className="cursor-pointer"
        />
      </Upload>
    </div>
  );

  useEffect(() => {
    if (ticketId) {
      getTicket(ticketId);
      setRead(ticketId);
    }
  }, [ticketId]);

  useEffect(() => {
    if (isSetStatusSuccess) {
      successMessage(
        messageApi,
        `Ticket #${ticketId} has been ${newStatus?.status}!`
      );
    }
  }, [isSetStatusSuccess]);

  useEffect(() => {
    if (ticket) {
      setCategory(ticket.data.category.id);
      setAssignee(ticket?.data?.assigned_user?.id);
    }
  }, [ticket]);

  useEffect(() => {
    !!scrollRef &&
      // @ts-ignore
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [isTicketLoading, ticket]);

  if (isTicketLoading) return <TicketSkeleton />;

  return (
    <div className="ticket-view__container">
      {contextHolder}
      <div className="ticket-view__title-container">
        <div className="ticket-view__title">
          Ticket #{ticket?.data?.item_id ?? ticket?.data?.id}
        </div>
        {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && (
          <>
            {ticket?.data?.status === 'Open' && (
              <div className="d-flex">
                <Button
                  className="gray-btn-lg h-40 w-auto"
                  style={{ marginRight: '12px' }}
                  onClick={() => setOpenCloseConfirm(true)}
                >
                  Close
                </Button>
                <Button
                  className="blue-btn-lg h-40"
                  onClick={() => setOpenResolveConfirm(true)}
                >
                  Mark as resolved
                </Button>
              </div>
            )}
            {ticket?.data?.status && ticket?.data?.status !== 'Open' && (
              <div className="d-flex">
                <Button
                  className="blue-btn-lg h-40"
                  onClick={() => {
                    changeStatus('open');
                  }}
                >
                  Reopen
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <div className="d-flex">
        <div className="ticket-view__edit-block">
          <div className="mr-4">
            <div className="ticket-view__edit-title">Category</div>
            <div className="ticket-view__edit-text">
              {ticket?.data?.category?.name}
            </div>
          </div>
          {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) &&
            ticket?.data?.status === 'Open' && (
              <Tooltip title="Change category">
                <div
                  className="team-table__icon-wrapper"
                  onClick={() => setOpenCategory(true)}
                >
                  <img src={pen} />
                </div>
              </Tooltip>
            )}
        </div>
        <div className="ticket-view__edit-block">
          <div className="mr-4">
            <div className="ticket-view__edit-title">Assignee</div>
            <div className="ticket-view__edit-text">
              {ticket?.data?.assigned_user?.name ?? 'Not Assigned'}
            </div>
          </div>
          {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) &&
            ticket?.data?.status === 'Open' && (
              <Tooltip title="Change assignee">
                <div
                  className="team-table__icon-wrapper"
                  onClick={() => setOpenAssignee(true)}
                >
                  <img src={pen} />
                </div>
              </Tooltip>
            )}
        </div>
        <div className="ticket-view__edit-block">
          <div className="mr-4">
            <div className="ticket-view__edit-title">Status</div>
            <div
              className={`team-table__status 
                                    ${
                                      ticket?.data?.status === 'Resolved' &&
                                      'active'
                                    }
                                    ${
                                      ticket?.data?.status === 'Closed' &&
                                      'paused'
                                    }
                                    ${
                                      ticket?.data?.status === 'Open' &&
                                      'pending'
                                    }
                                    `}
            >
              {ticket?.data?.status}
            </div>
          </div>
        </div>
        <div className="ticket-view__edit-block">
          <div className="mr-4">
            <div className="ticket-view__edit-title">Date Created</div>
            <div className="ticket-view__edit-text">
              {moment(ticket?.data?.created_at).format('MM/DD/YYYY h:mma')}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          className={`ticket-view__messages_container ${
            !allFiles?.length && 'w-100'
          }`}
        >
          <div className="ticket-view__messages-header">
            <div className="ticket-view__messages-header__title">
              {ticket?.data?.name}
            </div>
            <div>
              {(user?.role?.key === ADMIN || user?.role?.key === SUPER_ADMIN) &&
                ticket?.data?.user?.role !== SUPER_ADMIN &&
                ticket?.data?.user?.id !== user?.id && (
                  <Button className="gray-btn-lg h-40" onClick={viewProfile}>
                    View Profile
                  </Button>
                )}
            </div>
          </div>
          <div className="ticket-view__messages-body">
            {ticket?.data?.messages?.map((item: any) => {
              if (item.type === 'category_admin_change') {
                return (
                  <div className="ticket-view__status-container">
                    <div className="ticket-view__status-line"></div>
                    <div className="ticket-view__status-wrapper">
                      <div className="ticket-view__status-text">
                        Assignee changed on{' '}
                        {moment(item?.created_at).format('MM/DD/YYYY h:mma')}
                      </div>
                      <div className="d-flex justify-center align-center">
                        <div className="ticket-view__category-text">
                          {item?.data?.old}
                        </div>
                        <img src={arrow_right} alt="arrow-right" />
                        <div className="ticket-view__category-text">
                          {item?.data?.new}
                        </div>
                      </div>
                    </div>
                    <div className="ticket-view__status-line"></div>
                  </div>
                );
              }

              if (item.type === 'category_change') {
                return (
                  <div className="ticket-view__status-container">
                    <div className="ticket-view__status-line"></div>
                    <div className="ticket-view__category-wrapper">
                      <div className="ticket-view__status-text">
                        Category changed on{' '}
                        {moment(item?.created_at).format('MM/DD/YYYY h:mma')}
                      </div>
                      <div className="d-flex justify-center align-center">
                        <div className="ticket-view__category-text">
                          {item?.data?.old}
                        </div>
                        <img src={arrow_right} alt="arrow-right" />
                        <div className="ticket-view__category-text">
                          {item?.data?.new}
                        </div>
                      </div>
                    </div>
                    <div className="ticket-view__status-line"></div>
                  </div>
                );
              }

              if (item.type === 'status_change') {
                return (
                  <div className="ticket-view__status-container">
                    <div className="ticket-view__status-line"></div>
                    <div className="ticket-view__status-wrapper">
                      <div className="ticket-view__status-text">
                        Status changed on{' '}
                        {moment(item?.created_at).format('MM/DD/YYYY h:mma')}
                      </div>
                      <div
                        className={`team-table__status 
                                    ${
                                      item?.data?.old_status === 'Resolved' &&
                                      'active'
                                    }
                                    ${
                                      item?.data?.old_status === 'Closed' &&
                                      'paused'
                                    }
                                    ${
                                      item?.data?.old_status === 'Open' &&
                                      'pending'
                                    }
                                    `}
                      >
                        {item?.data?.old_status}
                      </div>
                      <img src={arrow_right} alt="arrow-right" />
                      <div
                        className={`team-table__status 
                                    ${
                                      item?.data?.new_status === 'Resolved' &&
                                      'active'
                                    }
                                    ${
                                      item?.data?.new_status === 'Closed' &&
                                      'paused'
                                    }
                                    ${
                                      item?.data?.new_status === 'Open' &&
                                      'pending'
                                    }
                                    `}
                      >
                        {item?.data?.new_status}
                      </div>
                    </div>
                    <div className="ticket-view__status-line"></div>
                  </div>
                );
              }

              return (
                <div
                  className={`ticket-view__messages-wrapper ${
                    item.user.id === user.id && 'self'
                  }`}
                >
                  <div className="ticket-view__messages-title-wrapper">
                    <div className="ticket-view__messages-title">
                      {item.user.id === user.id ? 'You' : item.user.name}
                    </div>
                    <div className="ticket-view__messages-date">
                      {moment(item?.created_at).format('MM/DD/YYYY h:mma')}
                    </div>
                  </div>
                  {!!item.text && (
                    <div
                      className={`ticket-view__messages-sander ${
                        item.user.id === user.id && 'self'
                      }`}
                    >
                      {item.text}
                    </div>
                  )}
                  <div
                    className={`ticket-view__attachment-container ${
                      item.user.id === user.id && 'self'
                    }  ${item?.files?.length === 1 && 'w-100'}`}
                  >
                    {item?.files?.map((attachment: any) => {
                      const image = attachment?.type?.includes('image');
                      return (
                        <a
                          target="_blank"
                          href={attachment.path}
                          download={true}
                          className={`ticket-view__messages-attachment ${
                            item?.files?.length === 1 && 'w-100'
                          }`}
                        >
                          <img src={image ? picture : file} />
                          <div className="ml-5">
                            <div
                              className={`ticket-view__messages-file-title ${
                                item?.files?.length === 1 && 'w-100'
                              }`}
                            >
                              {item?.files?.length === 1
                                ? attachment.name
                                : trimmer(attachment.name, 20)}
                            </div>
                            <div className="submit-ticket-upload__text">
                              {attachment.size}
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div ref={scrollRef}></div>
          </div>
          <div className="ticket-view__file-list-container">
            {fileList.map((item: any) => {
              return (
                <div className="d-flex align-center justify-center">
                  <div className="ticket-view__file-list-wrapper">
                    {trimmer(item.name, 25)}
                  </div>
                  <img
                    src={x_close}
                    alt="delete"
                    className="cursor-pointer mr-3"
                    onClick={() => deleteFile(item.uid)}
                  />
                </div>
              );
            })}
          </div>
          <Tooltip
            title={
              ticket?.data?.status === 'Open'
                ? ''
                : `Messaging is disabled for ${ticket?.data?.status} tickets`
            }
          >
            <div className="ticket-view__messages-input">
              <div className="flex-1">
                <CustomInput
                  value={message}
                  name=" "
                  className="input-white"
                  max={1000}
                  placeholder="Reply to ticket..."
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={handleKeyPress}
                  suffix={fileUpload}
                  disabled={ticket?.data?.status !== 'Open'}
                />
              </div>
              <button
                className="ticket-view__messages-send"
                disabled={
                  ticket?.data?.status !== 'Open' ||
                  (!message && !fileList.length)
                }
              >
                <img
                  onClick={handleMessageSend}
                  src={send_message}
                  alt="Send message"
                  className="ticket-view__messages-send-icon"
                />
              </button>
            </div>
          </Tooltip>
        </div>
        {!!allFiles?.data?.length && (
          <div className="ticket-view__attahments-container">
            <div className="ticket-view__attahments-header">
              <div className="ticket-view__attahments-title">Attachments</div>
              <Tooltip title="Download all files">
                <div
                  className="ticket-view__attahments-image"
                  onClick={() => setOpenDownloadConfirm(true)}
                >
                  <img src={download} alt="download" />
                </div>
              </Tooltip>
            </div>
            <div className="ticket-view__attahments-body">
              {allFiles?.data?.map((item: any) => {
                const image = item?.type?.includes('image');
                return (
                  <div
                    className="ticket-view__file-container"
                    key={item.created_at}
                  >
                    <div className="d-flex">
                      <img src={image ? picture : file} />
                      <div className="ml-5">
                        <a target="_blank" href={item.path} download={true}>
                          <div className="submit-ticket-upload__file-title">
                            {trimmer(item.name, 23)}
                          </div>
                        </a>
                        <div className="submit-ticket-upload__text">
                          {item.size}
                        </div>
                      </div>
                    </div>
                    <div className="ticket-view__file-upload-block">
                      <a
                        target="_blank"
                        href={item?.download_link}
                        download={item?.download_link?.split('/').pop()}
                      >
                        <img src={download} alt="download" />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <ConfirmationModal
        type="alert"
        openModal={openCloseConfirm}
        setOpenModal={setOpenCloseConfirm}
        cancel={() => {
          setOpenCloseConfirm(false);
        }}
        confirm={() => {
          changeStatus('closed');
          setOpenCloseConfirm(false);
        }}
        title="Close ticket"
        subtitle="Are you sure you want to close this ticket?"
        cancelText="Cancel"
        confirmText="Close"
        closeIcon={false}
      />
      <ConfirmationModal
        type="success"
        openModal={openResolveConfirm}
        setOpenModal={setOpenResolveConfirm}
        cancel={() => {
          setOpenResolveConfirm(false);
        }}
        confirm={() => {
          changeStatus('resolved');
          setOpenResolveConfirm(false);
        }}
        title="Mark as resolved"
        subtitle="Are you sure you want to mark this ticket as resolved?"
        cancelText="Cancel"
        confirmText="Resolve"
        closeIcon={false}
      />
      <ConfirmationModal
        type="success"
        openModal={openDownloadConfirm}
        setOpenModal={setOpenDownloadConfirm}
        cancel={() => {
          setOpenDownloadConfirm(false);
        }}
        confirm={() => {
          downloadAllFiles();
          setOpenDownloadConfirm(false);
        }}
        title="Download attachments"
        subtitle="Are you sure you want to download all the attachments to your computer?"
        cancelText="Cancel"
        confirmText="Download"
        closeIcon={false}
      />
      {category && (
        <ChangeTicketCategoryModal
          openModal={openCategory}
          setOpenModal={setOpenCategory}
          category={category}
          setCategory={setCategory}
          changeCategory={changeCategory}
          loading={isTicketUpdating}
        />
      )}
      <ChangeTicketAssigneeModal
        openModal={openAssignee}
        setOpenModal={setOpenAssignee}
        assignee={assignee}
        setAssignee={setAssignee}
        changeAssignee={changeAssignee}
        loading={isTicketUpdating}
      />
    </div>
  );
};
