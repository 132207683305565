import {TimeRangePickerProps} from "antd";
import dayjs, {Dayjs} from "dayjs";
export const subdomain = window.location.hostname.split(".")[0];

export const handleFeeInput = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    if (parseFloat(inputValue) > 15) {
        inputValue = '15';
    }
    const matches = inputValue.match(/^(\d*\.\d{0,2})|\d*$/);
    if (matches) {
        inputValue = matches[0];
    }
    e.target.value = inputValue;
};

export const trimmer = (string: string | undefined, trimFrom: number) =>
    !!string && string.length > trimFrom ? string.slice(0, trimFrom) + "..." : string

const startOfThisWeek = dayjs().startOf('week');
const startOfThisMonth = dayjs().startOf('month');
const startOfThisYear = dayjs().startOf('year');
export const rangePresets: TimeRangePickerProps['presets'] = [
    {
        label: 'Yesterday',
        value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
    },
    {
        label: 'Today',
        value: [dayjs().startOf('day'), dayjs()],
    },
    {
        label: 'Week to date',
        value: [startOfThisWeek, dayjs()],
    },
    {
        label: 'Month to date',
        value: [startOfThisMonth, dayjs()],
    },
    {
        label: 'Year to date',
        value: [startOfThisYear, dayjs()],
    },
];

export const disabledDateAfterToday = (current: Dayjs) => {
    return current && current > dayjs();
};

export const maskDigits = (str:string) => {
    if (!str) return null
    const maskLength = str.length - 4;
    return '•'.repeat(maskLength) + str.slice(-4);
}

export const appTooltip = (record: any) => {
    if (record.application_status === 'Draft' && !record.transaction_status) {
        return 'The application needs to be completed';
    }
    if (
        record.application_status === 'No Offer' &&
        record.transaction_status === 'Withdrawn'
    ) {
        return 'The System could not present any offers for this applicant';
    }
    if (
        record.application_status === 'Offer Made' &&
        record.transaction_status === 'In Progress'
    ) {
        return 'The offer needs to be selected to finish the application process';
    }
    if (
        record.application_status === 'Offer Made' &&
        record.transaction_status === 'Timed Out'
    ) {
        return 'The application expired';
    }
    if (
        record.application_status === 'Offer Made' &&
        record.transaction_status === 'Withdrawn'
    ) {
        return 'The application has been withdrawn';
    }
    if (
        record.application_status === 'Awaiting Signature' &&
        !record.additional_application_status &&
        record.transaction_status === 'In Progress'
    ) {
        return 'The documents need to be signed  to finish the application process';
    }
    if (
        record.application_status === 'Awaiting Signature' &&
        !!record.additional_application_status &&
        record.transaction_status === 'Timed Out'
    ) {
        return 'The application expired';
    }
    if (
        record.application_status === 'Approved' &&
        record.transaction_status === 'Approved'
    ) {
        return 'The application has been successfully approved';
    }
    if (
        record.application_status === 'Approved' &&
        record.transaction_status === 'In Progress'
    ) {
        return 'The funds should be released to Partner to finish the application process';
    }
    if (
        record.application_status === 'Awaiting Signature' &&
        record.additional_application_status === 'Awaiting Approval' &&
        record.transaction_status === 'In Progress'
    ) {
        return 'Delivery of products needs to be confirmed to finish the application process';
    }
    if (
        record.application_status === 'Awaiting Signature' &&
        record.additional_application_status === 'Awaiting Approval' &&
        record.transaction_status === 'Timed Out'
    ) {
        return 'The application expired';
    }
    if (
        !record.application_status &&
        record.transaction_status === 'Timed Out'
    ) {
        return 'The application expired';
    }
    if (
        !record.application_status &&
        record.transaction_status === 'Withdrawn'
    ) {
        return 'The application has been withdrawn';
    }
    if (
        record.application_status === 'Awaiting Down Payment' &&
        record.transaction_status === 'In Progress'
    ) {
        return 'Down payment needs to be made to finish the application process';
    }
    if (
        record.application_status === 'Awaiting Down Payment' &&
        record.transaction_status === 'Timed Out'
    ) {
        return 'The application expired';
    }
    return '';
};