import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  useChangeStatusMutation,
  useLazyGetPartnerQuery,
  useLazyRegenerateCodeQuery,
  useResetInvitationMutation,
} from "../../../api/partners";
import copyLinkIcon from "../../../assets/ci_copy.svg";
import doneicon from "../../../assets/doneicon.svg";
import sign from "../../../assets/sign.svg";
import edit from "../../../assets/edit.svg";
import moment from "moment";
import { Button, message } from "antd";
import { Spinner } from "../../../components/Spinner/Spinner";
import { idsI } from "../Partners";
import { useAppSelector } from "../../../hooks";
import { useGetAccountTypesQuery } from "../../../api/common";
import { AccountTypesItem } from "../../../api/types/common";
import { UserI } from "../../../reducers/profile/profileSlice";
import { CustomErrorType } from "../../../types.ts/commonTypes";
import { useLazyGetProfileInfoQuery } from "../../../api/profile";

import "./index.scss";
import { isApiError } from "../../../utils/general";
import { AvailableBanksTable } from "../../../components/Tables/AvailableBanksTable";
import Bank from "../../../assets/Bank.svg";
import { LendersFunnel } from "../../../components/LendersFunnel/LendersFunnel";
import {
  ADMIN,
  FUNNEL,
  MAIN_ACCOUNT,
  PARTNER,
  PROFILE,
  STATUS_ACTIVE,
  STATUS_PAUSED,
  STATUS_PENDING,
  SUPER_ADMIN,
} from "../../../helpers/variables";
import { BankDetailsView } from "../../../components/BankDetailsView/BankDetailsView";
import { CopyLink } from "../../../components/LayoutBlocks/CopyLink";
import { ProfileSkeleton } from "../../../components/Skeletons/ProfileSkeleton";

interface ViewPartnerProps {
  isProfile?: boolean;
}

export const ViewPartner = ({ isProfile }: ViewPartnerProps) => {
  const location = useLocation();
  const { id: partnerId } = useParams();

  const [getPartner, { data, isLoading, isError: isErrorGetPartner, error }] =
    useLazyGetPartnerQuery();
  const [
    getProfileInfo,
    {
      data: profileInfo,
      isLoading: isProfileInfoLoading,
      isError: isProfileInfoError,
      error: profileInfoError,
    },
  ] = useLazyGetProfileInfoQuery();
  const [tab, setTab] = useState<string>(PROFILE);
  const ref = useRef<HTMLAnchorElement>(null);
  const [copiedCode, setCopiedCode] = useState<boolean>(false);
  const [changeStatus] = useChangeStatusMutation();
  const [resetInvitation, { isSuccess: isSuccessResetInv }] =
    useResetInvitationMutation();
  const navigate = useNavigate();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [messageApi, contextHolder] = message.useMessage();
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [regenerateCode] = useLazyRegenerateCodeQuery();
  const responseData = isProfile ? profileInfo?.data?.resource : data?.data;
  const isGenesis = responseData?.genesis_application_link;

  useEffect(() => {
    if (isErrorGetPartner) {
      if ((error as CustomErrorType).status === 403) {
        navigate("/403");
      }
    }
  }, [isErrorGetPartner, error, navigate]);

  useEffect(() => {
    if (isProfileInfoError) {
      if ((profileInfoError as CustomErrorType).status === 403) {
        navigate("/403");
      }
    }
  }, [isProfileInfoError, navigate, profileInfoError]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key === PARTNER && !isProfile) {
      navigate("/403");
    }
  }, [user.email, isProfile, navigate, user.role.key]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeStatusFunc = () => {
    if (data?.data?.status_key === 1 || data?.data?.status_key === 2) {
      const ids: idsI = {
        id: data?.data?.id,
        key: { status_key: data?.data?.status_key === 1 ? 2 : 1 },
      };
      changeStatus(ids);
    }
    if (data?.data?.status_key === 0) {
      resetInvitation(data?.data?.id);
    }
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: "error",
      content: text,
      style: {
        marginTop: "90vh",
      },
    });
  };

  useEffect(() => {
    const successMessage = (text: string) => {
      messageApi.open({
        type: "success",
        content: text,
        style: {
          marginTop: "90vh",
        },
      });
    };
    if (isSuccessResetInv) {
      successMessage("Link resent successfully");
    }
  }, [isSuccessResetInv, messageApi]);

  useEffect(() => {
    if (!location) return;
    if (isProfile) {
      getProfileInfo();
    } else {
      const id: string = location.pathname.split("/")[2];
      if (!id) return;
      getPartner(id);
    }
  }, [location, isProfile, getPartner, getProfileInfo]);

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(
        (isProfile
          ? profileInfo?.data.resource.referral_code
          : data?.data?.referral_code) + "" || ""
      )
      .then(() => {
        setCopiedCode(true);
      })
      .catch(() => {
        console.error("Failed to copy text");
      });
  };

  const getLoanProducts = (id: number) => {
    const result: any = accountTypesData?.data
      ?.map((elem: any) => {
        if (elem.id === id) {
          const items = elem.loan_products.map((item: any) => {
            const matchingProduct = (
              profileInfo?.data?.resource
                ? profileInfo?.data?.resource
                : data?.data
            )?.loan_products.find((prod: any) => prod.id === item?.id);
            return matchingProduct ? item?.name : undefined;
          });
          const filteredItems = items.filter((item: any) => item !== undefined);
          return filteredItems.join(", ");
        } else {
          return "";
        }
      })
      .filter((item) => item !== "");

    const finalResult = result?.join(", ");
    return finalResult ? finalResult : [];
  };

  const RegenerateCodeFunc = async () => {
    try {
      const id: number | string = location.pathname.split("/")[2];
      await regenerateCode(isProfile ? profileInfo?.data?.resource?.id : id);
      isProfile ? await getProfileInfo() : await getPartner(id);
      await setCopiedCode(false);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || "Regenerate code failed");
    }
  };

  const goToPartnerEdit = () => {
    if (data?.data?.external) {
      navigate(`/partners/edit/external_partner/${data?.data?.id}`);
    } else {
      if (data?.data?.main_account_id) {
        navigate(`/partners/edit/related_partner/${data?.data?.id}`);
      } else {
        navigate(`/partners/edit/partner/${data?.data?.id}`);
      }
    }
  };

  const goToProfileEdit = () => {
    if (profileInfo?.data?.resource.main_account_id) {
      navigate(
        `/profile/edit/related_partner/${profileInfo?.data?.resource.id}`
      );
    } else {
      navigate(`/profile/edit/partner/${profileInfo?.data?.resource.id}`);
    }
  };

  return (
    <div className="container">
      {contextHolder}
      {isLoading || isProfileInfoLoading ? (
        <ProfileSkeleton />
      ) : (
        <div className="new-member">
          {!isProfile && (
            <div className="new-member_header">
              {(user.role.key === ADMIN || user.role.key === SUPER_ADMIN) && (
                <div className="new-member_tab">
                  <Button
                    className={`tab-btn ${tab === PROFILE && "active"}`}
                    onClick={() => {
                      setTab(PROFILE);
                    }}
                  >
                    Profile info
                  </Button>
                  <Button
                    className={`tab-btn ${tab === FUNNEL && "active"}`}
                    onClick={() => {
                      setTab(FUNNEL);
                    }}
                  >
                    Lenders Funnel
                  </Button>
                </div>
              )}
            </div>
          )}
          {tab === FUNNEL && !!partnerId && (
            <LendersFunnel model_id={partnerId} model_type={PARTNER} />
          )}
          {tab === PROFILE && (
            <div className="view-member">
              <div className="view-member__block block">
                <div className="block__logo-wrapper">
                  <img
                    src={
                      typeof responseData?.logo === "string"
                        ? responseData?.logo
                        : ""
                    }
                    className="block__logo"
                    alt="logo"
                  />
                  <div className="block__logo-info">
                    <div className="block__name">
                      {responseData?.company_name}
                    </div>
                    <div className="block__id">
                      ID#{" "}
                      <span className="block__id-blue">
                        {responseData?.item_id}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="block__title">Company Info</div>
                <div className="block__item">
                  <div className="block__item-title">Website Address</div>
                  <div className="block__item-value">
                    {responseData?.website_address}
                  </div>
                </div>
                <div className="block__row">
                  <div className="block__item small">
                    <div className="block__item-title">First Name</div>
                    <div className="block__item-value">
                      {responseData?.first_name}
                    </div>
                  </div>
                  <div className="block__item small">
                    <div className="block__item-title">Last Name</div>
                    <div className="block__item-value">
                      {responseData?.last_name}
                    </div>
                  </div>
                </div>
                <div className="block__row">
                  <div className="block__item small">
                    <div className="block__item-title">Contact Number</div>
                    <div className="block__item-value">
                      {responseData?.contact_number}
                    </div>
                  </div>
                  <div className="block__item small">
                    <div className="block__item-title">Contact Email</div>
                    <div className="block__item-value">
                      {responseData?.contact_email}
                    </div>
                  </div>
                </div>

                <div className="block__item">
                  <div className="block__item-title">Company Address</div>
                  <div className="block__item-value">
                    {responseData?.company_address}
                  </div>
                </div>
                <div className="block__row">
                  <div className="block__item small">
                    <div className="block__item-title">City</div>
                    <div className="block__item-value">
                      {responseData?.city}
                    </div>
                  </div>
                  <div className="block__item small">
                    <div className="block__item-title">State</div>
                    <div className="block__item-value">
                      {responseData?.state?.name}
                    </div>
                  </div>
                </div>
                <div className="block__row">
                  <div className="block__item ">
                    <div className="block__item-title">Zip Code</div>
                    <div className="block__item-value">
                      {responseData?.zip_code}
                    </div>
                  </div>
                  <div className="block__item ">
                    <div className="block__item-title">Signing Email</div>
                    <div className="block__item-value">
                      {responseData?.signing_email}
                    </div>
                  </div>
                </div>

                {!isProfile && responseData?.bank_details && (
                  <BankDetailsView bank_details={responseData?.bank_details} />
                )}
                {!isProfile &&
                  (user.role.key === SUPER_ADMIN ||
                    user.role.key === ADMIN) && (
                    <div className="block__item">
                      <div className="bank-table__title bank-table__title__view">
                        Available Banks
                      </div>
                      {responseData?.banks.length ? (
                        <AvailableBanksTable view banks={responseData?.banks} />
                      ) : (
                        <div className="no-data-found">
                          <div>
                            <img src={Bank} alt="bank" />
                          </div>
                          <div className="no-data-found__text">
                            No banks available
                          </div>
                          <div className="no-data-found__subtext">
                            Please edit your profile to add banks
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                {responseData?.main_account_id &&
                  user.role.key !== MAIN_ACCOUNT && (
                    <div className="block__item ">
                      <div className="block__item-title">
                        Associated Main Account
                      </div>
                      <div className="block__item-value block__main_account">
                        <img
                          src={responseData?.main_account?.logo}
                          className="block__main_account-image"
                          alt="main accaunt logo"
                        />
                        <div className="block__main_account-column">
                          <div>{responseData?.main_account?.company_name}</div>
                          {responseData?.main_account?.item_id && (
                            <div className="block__main_account-id">
                              (ID:
                              {responseData?.main_account?.item_id})
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="view-member__block block">
                <div className="block__title" style={{ height: "30px" }}></div>
                {user?.role?.key === MAIN_ACCOUNT ? (
                  <></>
                ) : (
                  <div className="block__edit-wrapper">
                    <img
                      src={edit}
                      className="block__edit"
                      onClick={isProfile ? goToProfileEdit : goToPartnerEdit}
                      alt="edit"
                    />
                  </div>
                )}
                <div className="block__common-info">
                  {!isProfile && (
                    <div className="block__item-row">
                      <div className="block__item-row-title">Status</div>
                      <div className="block__item-row-value">
                        <span
                          style={
                            responseData?.status === STATUS_ACTIVE
                              ? { color: "#017130" }
                              : responseData?.status === STATUS_PENDING
                              ? { color: "#7C24C2" }
                              : responseData?.status === STATUS_PAUSED
                              ? { color: "#A80E1C" }
                              : {}
                          }
                        >
                          {responseData?.status}
                        </span>
                      </div>
                    </div>
                  )}
                  {responseData?.account_types && (
                    <div className="block__item-row">
                      <div className="block__item-row-title">Account Type</div>
                      <div className="block__item-row-value">
                        {responseData?.account_types
                          ?.map((elem: AccountTypesItem) => elem?.name)
                          .join(", ")}
                      </div>
                    </div>
                  )}
                  <div className="block__item-row">
                    <div className="block__item-row-title">Onboarding Date</div>
                    <div className="block__item-row-value">
                      {moment(responseData?.created_at).format("MMMM DD, YYYY")}
                    </div>
                  </div>
                  <div className="block__item-row">
                    <div className="block__item-row-title">
                      Require 5-digit code?
                    </div>
                    <div className="block__item-row-value">
                      {responseData?.code_required ? "true" : "false"}
                    </div>
                  </div>
                </div>
                <div
                  className="block__common-info"
                  style={{ padding: "0 0 16px 0" }}
                >
                  <div className="block__item">
                    <div className="block__title">Products</div>
                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(1).length > 0 &&
                        accountTypesData?.data[0]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(1)}
                    </div>
                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(2).length > 0 &&
                        accountTypesData?.data[1]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(2)}
                    </div>
                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(3).length > 0 &&
                        accountTypesData?.data[2]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(3)}
                    </div>
                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(4).length > 0 &&
                        accountTypesData?.data[3]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(4)}
                    </div>
                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(5).length > 0 &&
                        accountTypesData?.data[4]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(5)}
                    </div>

                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(6).length > 0 &&
                        accountTypesData?.data[5]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(6)}
                    </div>

                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(7).length > 0 &&
                        accountTypesData?.data[6]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(7)}
                    </div>

                    <div
                      className="block__item-title"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {getLoanProducts(8).length > 0 &&
                        accountTypesData?.data[7]?.name}
                    </div>
                    <div className="block__item-value-prod">
                      {getLoanProducts(8)}
                    </div>
                  </div>
                </div>
                {isProfile && responseData?.bank_details && (
                  <BankDetailsView bank_details={responseData?.bank_details} />
                )}
                <div className="block__title" style={{ padding: "20px 0 0 0" }}>
                  Other Info
                </div>
                {responseData?.code_required &&
                  responseData?.status === STATUS_ACTIVE &&
                  !responseData?.external && (
                    <div className="block__common-info">
                      <div
                        className="block__item block__other"
                        style={{ margin: "0" }}
                      >
                        <div className="block__item" style={{ width: "33%" }}>
                          <div className="block__item-title">Referral Code</div>
                          <div className="block__item-value">
                            {responseData?.referral_code}
                          </div>
                        </div>
                        <Button
                          className="violet-btn block__btn-link"
                          onClick={() => RegenerateCodeFunc()}
                        >
                          Regenerate code
                        </Button>
                        <Button
                          className="blue-btn block__btn-link"
                          onClick={handleCopyCode}
                        >
                          {copiedCode && (
                            <img
                              src={doneicon}
                              className="block__btn-image"
                              alt="done"
                            />
                          )}
                          {!copiedCode && (
                            <img
                              src={copyLinkIcon}
                              className="block__btn-image"
                              alt="copy"
                            />
                          )}
                          {copiedCode && "Copied"}
                          {!copiedCode && "Copy code"}
                        </Button>
                      </div>
                    </div>
                  )}
                {user?.role?.key !== PARTNER &&
                  responseData?.status === STATUS_ACTIVE &&
                  !responseData?.external &&
                  !isGenesis && (
                    <CopyLink
                      title={"Partner's Application Link"}
                      link={responseData?.application_link}
                    />
                  )}
                {user?.role?.key !== PARTNER &&
                  responseData?.status === STATUS_ACTIVE &&
                  !responseData?.external &&
                  isGenesis && (
                    <>
                      {responseData?.application_link && (
                        <CopyLink
                          title={"Installment & LTO"}
                          link={responseData?.application_link}
                        />
                      )}
                      <CopyLink
                        title={"Credit Card Account"}
                        link={responseData?.genesis_application_link}
                      />
                    </>
                  )}

                <div className="block__row">
                  {(user?.role?.key === SUPER_ADMIN ||
                    user?.role?.key === ADMIN) && (
                    <div className="block__item" style={{ width: "66%" }}>
                      <div className="block__item-title">
                        Additional Information
                      </div>
                      <div className="block__item-value block__add-info">
                        {responseData?.additional_information || "-"}
                      </div>
                    </div>
                  )}
                  <div className="block__item" style={{ width: "32%" }}>
                    <div className="block__item-title">Contract</div>
                    <a
                      href={
                        typeof responseData?.contract === "string"
                          ? responseData?.contract
                          : ""
                      }
                      className="link"
                      ref={ref}
                      target="_blanc"
                    ></a>
                    <div
                      className="block__link"
                      onClick={() => ref?.current?.click()}
                    >
                      <img src={sign} alt="sign" />
                      <div className="block__link-text">
                        <span style={{ color: "var(--primary-100, #1F44DD)" }}>
                          Click to View <br />
                        </span>{" "}
                        Contract PDF
                      </div>
                    </div>
                  </div>
                </div>
                {!isProfile && (
                  <>
                    {data?.data?.status === STATUS_ACTIVE && (
                      <Button
                        className="red-btn block__pause-btn"
                        onClick={changeStatusFunc}
                      >
                        Pause
                      </Button>
                    )}
                    {data?.data?.status === STATUS_PAUSED && (
                      <Button
                        className="blue-btn block__pause-btn"
                        onClick={changeStatusFunc}
                      >
                        Activate
                      </Button>
                    )}
                    {data?.data?.status === STATUS_PENDING && (
                      <Button
                        className="violet-btn block__pause-btn"
                        onClick={changeStatusFunc}
                      >
                        Resend link
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
