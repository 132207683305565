import { Col, Form, Row } from 'antd';
import { LocationSearchInput } from './location-search';

const SomeFunctionalComponent = ({ setAddress, address }: any) => {
  const clearAddress = () => {
    // Очистить адрес с помощью this.props.form.setFieldsValue();
  };

  const handleAddressChange = () => {
    // Делать что-то с адресом
  };

  const handleAddressSelect = (address: any) => {
    setAddress(address);
  };

  return (

    <Form>
        <div
            className="custom-field__title"
            style={{ margin: '15px 0 0 0' }}
        >
            Address
        </div>
      <Form.Item
        name="property_status"
        labelCol={{ span: 24 }}
      >

        <LocationSearchInput
          address={address}
          clearAddress={clearAddress}
          onChange={handleAddressChange}
          onAddressSelect={handleAddressSelect}
        />
      </Form.Item>
    </Form>
  );
};

export default SomeFunctionalComponent;
