import { Avatar, Col, Divider, Row, Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 45,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const stylesLargeInput = {
  width: 70,
  height: 40,
  margin: '10px',
  borderRadius: 40,
};
const stylesExtraLargeInput = {
  width: 800,
  height: 40,
  margin: '30px 0 0 0',
  borderRadius: 40,
};
const dash = {
  width: 10,
  height: 5,
  margin: '10px 0 0 0',
};
const stylesMediumInput = {
  width: 70,
  height: 27,
  margin: '10px',
  borderRadius: 40,
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const divider = {
  width: 40,
  height: 1,
  margin: '15px 20px 0 20px',
  borderRadius: 40,
};

const stylesSmallLongInput = {
  width: 200,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
const stylesSmallExtraLongInput = {
  width: 350,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const round = {
  width: 25,
  height: 25,
  borderRadius: 50,
};
const avatar = {
  width: 50,
  height: 50,
  borderRadius: 50,
};

export const TicketSkeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__row">
          <Skeleton.Input size="large" style={stylesExtraLargeInput} active />
          <Skeleton.Button style={stylesExtraSmallInput} active />
          <Skeleton.Button active style={{ width: 140, height: 33 }} />
        </div>
        <Divider />
        <div className="skeleton__row">
          <div className="skeleton__block">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          </div>
          <Skeleton.Button active style={{ width: 44, height: 44 }} />
          <div className="skeleton__block">
            <Skeleton.Button
              size="small"
              style={stylesExtraSmallInput}
              active
            />
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <Skeleton.Avatar size="small" style={round} active />
          <div className="skeleton__block">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Button size="small" style={stylesSmallLongInput} active />
          </div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
          <div className="skeleton__block"></div>
        </div>
        <div className="skeleton__row" style={{ width: '100%' }}>
          <div className="skeleton__big-white-block" style={{ width: '62%' }}>
            <div className="skeleton__row">
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Input size="small" style={stylesSmallInput} active />
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />{' '}
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="skeleton__grey-block" style={{ width: '60%' }}>
              <Skeleton.Button
                size="small"
                style={stylesSmallExtraLongInput}
                active
              />
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
            </div>
            <div className="skeleton__row">
              <Skeleton.Input size="small" style={divider} active />
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
              <Skeleton.Input size="large" style={stylesMediumInput} active />
            </div>
            <div
              className="skeleton__grey-block"
              style={{ margin: '0 0 0 30%' }}
            >
              <Skeleton.Button
                size="small"
                style={stylesSmallExtraLongInput}
                active
              />
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
            </div>
            <div className="skeleton__row" style={{ margin: '30px 0 0 0' }}>
              <Skeleton.Button
                size="small"
                style={stylesSmallExtraLongInput}
                active
              />
              <Skeleton.Avatar size="small" style={round} active />
              <Skeleton.Avatar size="small" style={round} active />
              <Skeleton.Button active style={{ width: 44, height: 44 }} />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button
                size="small"
                style={stylesSmallLongInput}
                active
              />
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
          </div>
          <div className="skeleton__big-white-block" style={{ width: '36%' }}>
            <Skeleton.Input size="small" style={stylesSmallInput} active />
            <div className="skeleton__row">
              <Skeleton.Avatar size="small" style={avatar} active />
              <div>
                <Skeleton.Button
                  size="small"
                  style={stylesSmallLongInput}
                  active
                />
                <div
                  className="skeleton__row"
                  style={{ justifyContent: 'start' }}
                >
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button
                    size="small"
                    style={stylesExtraSmallInput}
                    active
                  />
                </div>
              </div>
              <Skeleton.Button size="small" style={dash} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Avatar size="small" style={avatar} active />
              <div>
                <Skeleton.Button
                  size="small"
                  style={stylesSmallLongInput}
                  active
                />
                <div
                  className="skeleton__row"
                  style={{ justifyContent: 'start' }}
                >
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button
                    size="small"
                    style={stylesExtraSmallInput}
                    active
                  />
                </div>
              </div>
              <Skeleton.Button size="small" style={dash} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Avatar size="small" style={avatar} active />
              <div>
                <Skeleton.Button
                  size="small"
                  style={stylesSmallLongInput}
                  active
                />
                <div
                  className="skeleton__row"
                  style={{ justifyContent: 'start' }}
                >
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button
                    size="small"
                    style={stylesExtraSmallInput}
                    active
                  />
                </div>
              </div>
              <Skeleton.Button size="small" style={dash} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Avatar size="small" style={avatar} active />
              <div>
                <Skeleton.Button
                  size="small"
                  style={stylesSmallLongInput}
                  active
                />
                <div
                  className="skeleton__row"
                  style={{ justifyContent: 'start' }}
                >
                  <Skeleton.Input
                    size="small"
                    style={stylesSmallInput}
                    active
                  />
                  <Skeleton.Button
                    size="small"
                    style={stylesExtraSmallInput}
                    active
                  />
                </div>
              </div>
              <Skeleton.Button size="small" style={dash} active />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
