import React, { FC } from 'react';

interface PropsI {
  openItem: number | null | undefined;
  offer: any;
  selectOption: number | null;
  item: any;
  elem: any;
  index: number;
  setSelectOption: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConcoraOpensOffer: FC<PropsI> = (props) => {
  const { openItem, offer, selectOption, item, elem, index, setSelectOption } =
    props;

  const addDecimal = (formattedStr: string) => {
    formattedStr = String(formattedStr);

    if (!formattedStr.includes('.')) {
      return formattedStr + '.00';
    }

    const parts = formattedStr.split('.');
    if (parts[1].length === 0) {
      return formattedStr + '00';
    } else if (parts[1].length === 1) {
      return formattedStr + '0';
    }
    return formattedStr;
  };

  return openItem === offer.id && selectOption === item.id ? (
    <div className="OfferItem__option-selected">
      <div className="d-flex">
        {elem.offers.length > 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">Option {index + 1}</div>
          </div>
        )}{' '}
        {item.status === 'Expired' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Expired' && (
          <div className="w-50">
            <div className="OfferItem__row-expired">Expired</div>
          </div>
        )}
        {item.status === 'Declined' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Declined' && (
          <div className="w-50">
            <div className="OfferItem__row-declined">Declined</div>
          </div>
        )}
        {item.status === 'Pending' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Pending' && (
          <div className="w-50">
            <div className="OfferItem__row-pending">Pending</div>
          </div>
        )}
      </div>
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Prequalified credit limit</p>
          <p className="OfferItem__value bold">
            $ {addDecimal(item?.data?.creditLine)}
          </p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Estimated monthly payment</p>
          <p className="OfferItem__value bold">$ {item.monthly_payment}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="OfferItem__option" onClick={() => setSelectOption(item.id)}>
      <div className="d-flex">
        {elem.offers.length > 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">Option {index + 1}</div>
          </div>
        )}
        {item.status === 'Expired' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Expired' && (
          <div className="w-50">
            <div className="OfferItem__row-expired">Expired</div>
          </div>
        )}
        {item.status === 'Declined' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Declined' && (
          <div className="w-50">
            <div className="OfferItem__row-declined">Declined</div>
          </div>
        )}
        {item.status === 'Pending' && elem.offers.length === 1 && (
          <div className="w-50">
            <div className="OfferItem__row-title">&nbsp;</div>
          </div>
        )}
        {item.status === 'Pending' && (
          <div className="w-50">
            <div className="OfferItem__row-pending">Pending</div>
          </div>
        )}
      </div>
      <div className="OfferItem__row">
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Prequalified credit limit</p>
          <p className="OfferItem__value bold">
            $ {addDecimal(item?.data?.creditLine)}
          </p>
        </div>
        <div className="OfferItem__wrapper">
          <p className="OfferItem__name">Estimated monthly payment</p>
          <p className="OfferItem__value bold">$ {item.monthly_payment}</p>
        </div>
      </div>
    </div>
  );
};
