import { Input, Select, Divider, Form, Checkbox } from 'antd';
import Alert from '../assets/alert-circle.svg';
import searchIcon from '../assets/searchIcon.svg';
import arrow from '../assets/arrow.svg';
import { FC } from 'react';
import { SelectOption } from '../types.ts/commonTypes';
import './index.scss';

interface FloatInputProps {
  name?: string;
  error?: string;
  options: SelectOption[] | undefined;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isWithLogo?: boolean;
  defaultValue?: { value: string; label: string };
  suffixIcon?: React.ReactNode;
  allowClear?: boolean;
  placeholder: string;
  className: string;
  onChange: React.Dispatch<React.SetStateAction<string[]>>;
  value?: any;
  isSearchSelect: boolean;
  searchValue?: string;
  hasError?: () => boolean;
  required?: boolean;
  onBlur?: any;
}

export const MultiSelect: FC<FloatInputProps> = ({
  placeholder,
  className,
  name,
  options,
  error,
  onChange,
  onSearch,
  defaultValue,
  suffixIcon,
  allowClear,
  value,
  isSearchSelect,
  searchValue,
  hasError,
  required,
  onBlur,
}) => {
  const handleCheck = (id: number | string | undefined) => {
    if (id === 'allstates') return;
    if (value?.includes?.(id)) {
      onChange((prev: string[]) => prev.filter((item) => item !== id));
    } else {
      onChange((prev: string[]) => [...prev, id + '']);
    }
  };

  return (
    <div className="custom-field multiSelect">
      <div className="custom-field__title">
        {name}
        {required && (
          <span className="error-text" style={{ margin: '0 0 0 3px' }}>
            *
          </span>
        )}
      </div>
      <Select
        maxTagCount={'responsive'}
        mode="multiple"
        showSearch={false}
        allowClear={allowClear}
        clearIcon={allowClear ? suffixIcon : false}
        filterOption={false}
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={(value: any) => {
          onChange && onChange(value);
        }}
        onBlur={onBlur}
        value={value}
        className={`${className} ${hasError && hasError() ? 'error' : ''}`}
        defaultValue={defaultValue}
        suffixIcon={
          suffixIcon ? (
            <></>
          ) : (
            <img
              src={arrow}
              style={className === 'input-white-small' ? { width: '15px' } : {}}
              alt="arrow"
            />
          )
        }
        dropdownRender={(menu) => {
          return (
            <>
              <div style={{ padding: '0 8px 4px' }}>
                {isSearchSelect && (
                  <>
                    <Form.Item name={name} className="wrapper-search-input">
                      <Input
                        allowClear
                        className="search-input"
                        prefix={<img src={searchIcon} alt="search" />}
                        placeholder="Search by name"
                        value={searchValue ? searchValue : name}
                        onChange={onSearch}
                        maxLength={100}
                      />
                    </Form.Item>
                    <Divider style={{ margin: '8px 0' }} />
                  </>
                )}
              </div>

              {menu}
            </>
          );
        }}
      >
        {options?.map((option: SelectOption) => (
          <Select.Option
            key={option.label?.toString() || option.id?.toString()}
          >
            <div className="option-select">
              <Checkbox
                checked={value?.includes?.(option.label + '')}
                onClick={() => {
                  if (name === 'Available Locations') return;
                  handleCheck(option.label);
                }}
              />
              <div>{option?.value}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
      {hasError && hasError() ? (
        <div className="error-text">This field is required</div>
      ) : (
        ''
      )}
      {error && <img src={Alert} className="alert" alt="alert" />}
    </div>
  );
};
