import { Button, Form, Modal } from 'antd';
import { FC, useEffect } from 'react';
import './index.scss';
import { yupSync } from '../../../utils';
import * as Yup from 'yup';
import { CustomInput } from '../../../customFields/CustomInput';
import { Employee } from '../../../api/types/common';
import { PhoneNumberInput } from '../../../customFields/PhoneNumberInput';
import { useUpdateMyProfileInfoMutation } from '../../../api/profile';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  phone: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email is invalid')
    .required('This field is required'),
});

interface FormData {
  first_name: string;
  last_name: string;
  contact_number: string;
  contact_email: string;
}

interface ModalI {
  openModal: boolean;
  isClose?: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  editEmployee: null | Employee;
  createSuccess: (edit: boolean) => void;
}

export const MyProfileEditModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  editEmployee,
  createSuccess,
}) => {
  const [
    editUserRequest,
    {
      reset: editReset,
      error: editErrorData,
      isLoading: editLoading,
      isError: editError,
      isSuccess: isEditSuccess,
    },
  ] = useUpdateMyProfileInfoMutation();
  const [form] = Form.useForm<FormData>();
  const isLoading = editLoading;

  const errorData = editErrorData;
  const isError = editError;
  const reset = () => {
    editReset();
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        editEmployee && editUserRequest({ id: editEmployee.id, ...values });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isEditSuccess) {
      createSuccess(true);
      closeModal();
    }
  }, [isEditSuccess]);

  useEffect(() => {
    editEmployee && form.setFieldsValue({ ...editEmployee });
  }, [editEmployee, form, openModal]);

  return (
    <div>
      <Modal
        destroyOnClose
        centered
        open={openModal}
        onCancel={closeModal}
        footer={<></>}
        closeIcon={false}
        width={400}
        maskClosable={!isLoading}
      >
        <div className="add-bank-modal__title">Edit information</div>

        <Form form={form}>
          <Form.Item
            name="first_name"
            rules={yupSync('first_name', validationSchema, true)}
          >
            <CustomInput
              name="First name"
              className="input"
              max={100}
              placeholder="Enter first name"
              onBlur={() => validateFormOnBlur('first_name')}
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            rules={yupSync('last_name', validationSchema, true)}
          >
            <CustomInput
              name="Last name"
              className="input"
              max={100}
              placeholder="Enter last name"
              onBlur={() => validateFormOnBlur('last_name')}
            />
          </Form.Item>
          <Form.Item
            className="input-wrapper"
            name="contact_number"
            rules={yupSync('phone', validationSchema, true)}
          >
            <PhoneNumberInput
              mask="(999) 999 9999"
              name="Contact number"
              value={undefined}
              onBlur={() => validateFormOnBlur('phone')}
              placeholder={'(555) 000-0000'}
              hasError={() => !!form.getFieldError('phone').length}
            />
          </Form.Item>
          <Form.Item
            name="contact_email"
            rules={yupSync('email', validationSchema, true)}
          >
            <CustomInput
              name="Contact email"
              className="input"
              max={240}
              placeholder="Enter email address"
              onBlur={() => validateFormOnBlur('email')}
              error={isError ? 'err' : ''}
              //@ts-ignore
              errorText={isError ? errorData?.data.message : ''}
              onChange={() => {
                reset();
              }}
            />
          </Form.Item>
          <div className="d-flex justify-between">
            <Button className="gray-btn-lg" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button
              className="blue-btn-lg"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Save changes
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
