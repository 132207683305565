import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  useLoginMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useValidateInvitationMutation,
} from '../../api/auth';
import image from '../../assets/mainLoginImage.svg';
import './index.scss';
import { Button, Checkbox, Form, Spin, message } from 'antd';
import { CustomInput } from '../../customFields/CustomInput';
import { yupSync } from '../../utils';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { ForgotPassword } from './ForgotPassword';
import { Spinner } from '../../components/Spinner/Spinner';
import { SuccessPopup } from './SuccessPopup';
import {
  ResetPasswordMutationParams,
  SetPasswordMutationParams,
} from '../../api/types/auth';
import { CustomErrorType } from '../../types.ts/commonTypes';
import { isApiError } from '../../utils/general';
import { ExpiredLink } from './ExpiredLink';
import { subdomain } from '../../helpers/common';
import logo_wl from '../../assets/login-wl-image.svg';
import Favicon from 'react-favicon';
import faviconWL from '../../assets/WLFavicon_192x192.svg';
import faviconNoLable from '../../assets/Favicon192x192.svg';
import { beforeInit } from '../../api/axiosCrud';

interface FormData {
  password: string;
  password_confirmation: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required')
    .min(6, 'Password should be at least 6 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-]).+$/,
      'Password should contain at least 1 lowercase, 1 uppercase, 1 special symbol, 1 number'
    ),
  password_confirmation: Yup.string().required('This field is required'),
});

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormData>();
  const [isOpenForgotPasswordModal, setIsOpenForgotPasswordModal] =
    useState<boolean>(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);

  const [resetPassword, { isError, error }] = useResetPasswordMutation();
  const [
    setPassword,
    { isError: isErrorSetPassword, error: errorSetPassword },
  ] = useSetPasswordMutation();
  const { handleKeyPress } = usePreventSpaceTyping();
  const [searchParams] = useSearchParams();
  const [credError, setCredError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [validateLink, { isError: validateLinkIsError, error: linkError }] =
    useValidateInvitationMutation();
  const [isResetPassword, setIsResetPassword] = useState<boolean>(true);
  const [initData, setInitData] = useState<any>();

  useEffect(() => {
    if (!subdomain || !location) return;
    beforeInit({ subdomain: subdomain })
      .then((response: any) => {
        setInitData(response?.data);
      })
      .then(() => {
        if (location.pathname.includes('invitation')) {
          setIsResetPassword(false);
          validateLink({
            email: decodeURIComponent(searchParams.get('email') || ''),
            token: decodeURIComponent(searchParams.get('token') || ''),
          });
        }
      });
  }, [subdomain, location]);

  useEffect(() => {
    if (initData?.company_name) {
      if (subdomain === 'magwitch') {
        document.title = 'Magwitch';
      } else {
        document.title = initData?.company_name;
      }
    }
  }, [initData, subdomain]);

  const successMessage = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (isError) {
      errorMessage((error as CustomErrorType).data.message);
    }
    if (isErrorSetPassword) {
      errorMessage((errorSetPassword as CustomErrorType)?.data.message);
    }
  }, [isError, isErrorSetPassword]);

  const resetPasswordFunc = async (params: ResetPasswordMutationParams) => {
    setIsLoading(true);
    try {
      await resetPassword(params).unwrap();
      successMessage('Password has been successfully changed');
      navigate('/login');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Reset password failed');
    } finally {
      setIsLoading(false);
    }
  };

  const setPasswordFunc = async (params: SetPasswordMutationParams) => {
    setIsLoading(true);
    try {
      await setPassword(params).unwrap();
      successMessage('Password has been successfully created');
      navigate('/login');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Set password failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values: FormData) => {
        if (isResetPassword) {
          const formData: ResetPasswordMutationParams = {
            email: decodeURIComponent(searchParams.get('email') || ''),
            token: decodeURIComponent(searchParams.get('token') || ''),
            password: values.password,
            password_confirmation: values.password_confirmation,
            code: decodeURIComponent(searchParams.get('code') || ''),
          };
          resetPasswordFunc(formData);
        } else {
          const formData: SetPasswordMutationParams = {
            email: decodeURIComponent(searchParams.get('email') || ''),
            token: decodeURIComponent(searchParams.get('token') || ''),
            password: values.password,
            password_confirmation: values.password_confirmation,
          };
          setPasswordFunc(formData);
        }
      })
      .catch(() => {});
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
    if (name === 'password') {
      setCredError('');
    }
  };

  // if (isInitLoading) {
  //   // return <Spinner size="large"/>
  // }

  if (validateLinkIsError) {
    // @ts-ignore
    return <ExpiredLink title={linkError?.data?.message} />;
  }

  return (
    <>
      {contextHolder}
      <Favicon
        url={initData?.wl ? faviconWL : faviconNoLable}
        sizes="192x192"
      />
      <div className="auth">
        <div className="auth__block block">
          <div className="block__login">
            {isResetPassword && (
              <div className="block__title">Enter new password</div>
            )}
            {!isResetPassword && (
              <div className="block__title">Create a password</div>
            )}
            <div className="block__subtitle"></div>
            <div className="block__form">
              <Form form={form} onFinish={handleFormSubmit}>
                <Form.Item
                  className="input-wrapper"
                  name="password"
                  rules={yupSync('password', validationSchema, true)}
                >
                  <CustomInput
                    name={`${
                      isResetPassword ? 'Enter new password' : 'Enter password'
                    }`}
                    className="input"
                    type="password"
                    maxLength={32}
                    onKeyPress={handleKeyPress}
                    placeholder="********"
                    onBlur={() => validateFormOnBlur('password')}
                    error={credError}
                    onChange={() => setCredError('')}
                  />
                </Form.Item>
                <Form.Item
                  className="input-wrapper"
                  name="password_confirmation"
                  rules={[
                    ...yupSync('password_confirmation', validationSchema),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Passwords do not match. Try again')
                        );
                      },
                    }),
                  ]}
                  dependencies={['password']}
                >
                  <CustomInput
                    name="Re-enter password"
                    className="input"
                    maxLength={32}
                    type="password"
                    onKeyPress={handleKeyPress}
                    placeholder="********"
                    onBlur={() => validateFormOnBlur('password_confirmation')}
                    error={credError}
                    onChange={() => setCredError('')}
                  />
                </Form.Item>
                <div
                  className="error-text"
                  style={{ margin: '-20px 0 15px 0' }}
                >
                  {credError}
                </div>

                <div className="block__info-wrap info-wrap">
                  <Button className="blue-btn" htmlType="submit">
                    Submit {isLoading && <Spinner />}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="auth__block block">
          {!initData && <div className="block__image"></div>}
          {initData && initData?.wl && (
            <img src={logo_wl} className="block__image" />
          )}
          {initData && !initData?.wl && (
            <img src={image} className="block__image" />
          )}
        </div>
      </div>

      {isOpenForgotPasswordModal && (
        <ForgotPassword
          openModal={isOpenForgotPasswordModal}
          setOpenModal={setIsOpenForgotPasswordModal}
          setIsSuccessPopup={setIsSuccessPopup}
        />
      )}
      {isSuccessPopup && (
        <SuccessPopup
          openModal={isSuccessPopup}
          setOpenModal={setIsSuccessPopup}
        />
      )}
    </>
  );
};
