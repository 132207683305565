import { Button, Modal } from 'antd';
import { FC } from 'react';
import attention from '../../assets/alert-new-48.svg';
import alert from '../../assets/alert-triangle-48-new.svg';
import success from '../../assets/success-lg.svg';
import close from '../../assets/x-close.svg';
import upload_blue from '../../assets/upload-cloud-blue.svg';
import './index.scss';
import { Spinner } from '../Spinner/Spinner';

interface ModalI {
  openModal: boolean;
  isClose?: boolean;
  setOpenModal: any;
  type?: string;
  title: string;
  subtitle?: string | React.ReactNode;
  cancelText: string;
  confirmText: string | React.ReactNode;
  cancel?: () => void;
  confirm?: () => void;
  closeIcon: boolean;
  maskClosable?: boolean;
  className?: string;
}

export const ConfirmationModal: FC<ModalI> = ({
  openModal,
  isClose,
  setOpenModal,
  type,
  title,
  subtitle,
  cancelText,
  confirmText,
  cancel,
  confirm,
  closeIcon,
  maskClosable = true,
  className,
}) => {
  return (
    <Modal
      destroyOnClose
      className={`confirmation-modal ${className}`}
      centered
      open={openModal}
      onCancel={() => (!isClose ? setOpenModal(false) : {})}
      footer={<></>}
      closeIcon={closeIcon ? <img src={close} alt="close" /> : <></>}
      width={400}
      maskClosable={maskClosable}
    >
      <div className="confirmation-modal__container">
        {/* {type === 'waiting' && <Spinner size="medium" color="grey" />} */}
        {type === 'warning' && title !== 'Request refund' && (
          <img
            src={attention}
            className="confirmation-modal__image"
            alt="attention"
          />
        )}
        {type === 'alert' && (
          <img src={alert} className="confirmation-modal__image" alt="alert" />
        )}
        {type === 'success' && (
          <img
            src={success}
            className="confirmation-modal__image"
            alt="success"
          />
        )}
        {type === 'download' && (
          <img
            src={upload_blue}
            className="confirmation-modal__image"
            alt="download"
          />
        )}
        <div className="confirmation-modal__title">{title}</div>
        <div className="confirmation-modal__subtitle">{subtitle}</div>
        <div className="confirmation-modal__btns">
          {cancelText && (
            <Button
              className="gray-btn-lg"
              style={{ marginRight: '12px' }}
              onClick={cancel}
            >
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button
              className={type === 'warning' ? 'red-btn-lg' : 'blue-btn-lg'}
              onClick={confirm}
            >
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
