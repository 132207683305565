import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';


export const employeesApi = createApi({
  reducerPath: 'employeesApi',
  baseQuery,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getEmployeesList: builder.query({
      query: (params) => ({
        url: '/employees/',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Users'],
    }),
    deleteEmployee: builder.mutation<number, string>({
      query: (id) => ({
        url: `/employees/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    resendInvitation: builder.mutation<number, string>({
      query: (id) => ({
        url: `/employees/${id}/invitation`,
        method: 'PUT',
      }),
    }),
    createEmployee: builder.mutation({
      query: (arg) => ({
        url: '/employees',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['Users'],
    }),
    getEmployee: builder.query({
      query: (id) => ({
        url: `/employees/${id}`,
        method: 'GET',
      }),
    }),
    editEmployee: builder.mutation({
      query: (data) => ({
        url: `/employees/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    changeStatus: builder.mutation({
      query: (data) => ({
        url: `/employees/${data.id}/status`,
        method: 'PUT',
        body: {status_key: data.key},
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useLazyGetEmployeesListQuery,
  useDeleteEmployeeMutation,
  useResendInvitationMutation,
  useCreateEmployeeMutation,
  useLazyGetEmployeeQuery,
  useEditEmployeeMutation,
    useChangeStatusMutation
} = employeesApi;
