import { useEffect, useState } from 'react';
import { message } from 'antd';

import { Spinner } from '../../components/Spinner/Spinner';
import { CustomInput } from '../../customFields/CustomInput';
import { CustomSelect } from '../../customFields/CustomSelect';
import useDebounce from '../../hooks/useDebounce';

import {
  LendersTableParams,
  LoanType,
  TableLendersData,
} from '../../api/types/lenders';
import { useGetAccountTypesQuery } from '../../api/common';
import { AccountTypesItem } from '../../api/types/common';

import searchIcon from '../../assets/searchIcon.svg';
import cancel from '../../assets/x-close.svg';
import { useAppSelector } from '../../hooks';
import { UserI } from '../../reducers/profile/profileSlice';
import {
  useLazyGetLendersListQuery,
  useLazyGetLoanTypesQuery,
} from '../../api/lenders';
import { LendersTable } from '../../components/Tables/LendersTable';
import { useNavigate } from 'react-router-dom';
import { TableSkeleton } from '../../components/Skeletons/TableSkeleton';

export const LendersPageList = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [trigger, setTrigger] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableLendersData[]>([]);
  const [tableParams, setTableParams] = useState<LendersTableParams>({
    per_page: 50,
    search: '',
    account_type_id: null,
    order_by: '',
    order: 'desc',
    type: '',
    status: '',
    loan_type: '',
    page: 1,
  });
  const [getList, { data, isLoading, isError }] = useLazyGetLendersListQuery();
  const [
    getLoanTypes,
    {
      data: loanTypes,
      isLoading: isLoanTypesLoading,
      isError: isLoanTypesError,
    },
  ] = useLazyGetLoanTypesQuery();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const debouncedSearchInput = useDebounce(searchInput);
  const navigate = useNavigate();

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (!user.email) return;
    if (!(user.role.key === 'admin' || user.role.key === 'super_admin')) {
      navigate('/403');
    }
  }, [user.email, user.role.key]);

  useEffect(() => {
    if (isError) {
      errorMessage('Fetch lenders failed');
    }
    if (isLoanTypesError) {
      errorMessage('Fetch loan types failed');
    }
  }, [isError, isLoanTypesError]);

  useEffect(() => {
    setTableParams((prev: LendersTableParams) => ({
      ...prev,
      search: debouncedSearchInput,
    }));
  }, [debouncedSearchInput]);

  useEffect(() => {
    getList(tableParams);
  }, [tableParams, trigger]);

  useEffect(() => {
    getLoanTypes();
  }, []);

  useEffect(() => {
    if (!data?.data) return;
    const res: TableLendersData[] = data?.data.map((elem: TableLendersData) => {
      return {
        id: elem.id,
        name: elem.name,
        processing_fee: elem.processing_fee,
        account_types: elem.account_types,
        loan_types: elem.loan_types,
        city: elem.city,
        coverage_amount_max: elem.coverage_amount_max,
        coverage_amount_min: elem.coverage_amount_min,
        type: elem.type,
        logo: elem.logo,
        created_at: elem.created_at,
        status: elem.status,
        states: elem.states,
        participation_bonus_percent: elem.participation_bonus_percent,
      };
    });
    setTableData(res);
  }, [data?.data]);

  return (
    <div className="container">
      {contextHolder}
      {isLoading || isLoanTypesLoading ? (
        <TableSkeleton />
      ) : (
        <div className="members">
          <div className="members__table">
            <div className="members__filters" style={{ margin: '0 0 10px 0' }}>
              <CustomInput
                style={{ width: '350px', margin: '0 16px 0 0' }}
                className="input-white-small"
                name="Search"
                placeholder="Search by Lender or ID"
                value={searchInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchInput(e.target.value)
                }
                prefix={<img src={searchIcon} />}
                suffix={
                  tableParams.search ? (
                    <img
                      className="cancel-btn"
                      style={{ margin: '0 0 0 0' }}
                      src={cancel}
                      onClick={() => {
                        setSearchInput('');
                        setTableParams((prev: LendersTableParams) => ({
                          ...prev,
                          search: '',
                        }));
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />

              <div
                className="members__select"
                style={{ width: '27%', margin: '0 16px 0 0' }}
              >
                <CustomSelect
                  allowClear
                  options={loanTypes?.data?.map((elem: LoanType) => {
                    return { value: elem.name, label: elem.id };
                  })}
                  name="Loan type"
                  className="input-white-small"
                  placeholder="Show All"
                  onChange={(value: any) =>
                    setTableParams((prev: LendersTableParams) => ({
                      ...prev,
                      loan_type: value,
                    }))
                  }
                  suffixIcon={
                    tableParams.loan_type ? (
                      <img
                        style={{
                          width: '20px',
                          margin: '-5px 0 0 0',
                          cursor: 'pointer',
                        }}
                        src={cancel}
                        onClick={(e) => {
                          e.stopPropagation();
                          setTableParams((prev: LendersTableParams) => ({
                            ...prev,
                            loan_type: '',
                          }));
                        }}
                      />
                    ) : (
                      false
                    )
                  }
                />
              </div>

              <div className="members__select" style={{ width: '140px' }}>
                <CustomSelect
                  allowClear
                  options={[
                    { value: 'Active', label: 1 },
                    { value: 'Paused', label: 0 },
                  ]}
                  name="Status"
                  className="input-white-small"
                  placeholder="Show All"
                  onChange={(value: any) =>
                    setTableParams((prev: LendersTableParams) => ({
                      ...prev,
                      status: value,
                    }))
                  }
                  suffixIcon={
                    tableParams.status ? (
                      <img
                        style={{
                          width: '20px',
                          margin: '-5px 0 0 0',
                          cursor: 'pointer',
                        }}
                        src={cancel}
                        onClick={(e) => {
                          e.stopPropagation();
                          setTableParams((prev: LendersTableParams) => ({
                            ...prev,
                            status: '',
                          }));
                        }}
                      />
                    ) : (
                      false
                    )
                  }
                />
              </div>
            </div>
            <LendersTable
              data={tableData}
              setTableParams={setTableParams}
              tableParams={tableParams}
              metaData={data}
              setTrigger={setTrigger}
            />
          </div>
        </div>
      )}
    </div>
  );
};
