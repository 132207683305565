import {configureStore} from '@reduxjs/toolkit';
import {authApi} from '../api/auth';
import {setupListeners} from '@reduxjs/toolkit/query';
import authReducer from './auth/authSlice';
import tabsReducer from "./tabs/tabsSlice";
import profileReducer from './profile/profileSlice';
import commonReducer from './common/commonSlice';
import {commonApi} from '../api/common';
import {partnersApi} from '../api/partners';
import {adminsApi} from '../api/admins';
import {employeesApi} from '../api/employees'
import {ticketsApi} from '../api/tickets'
import {mainAccountsApi} from '../api/mainAccounts';
import {applicantsApi} from '../api/applicants';
import {offersApi} from '../api/offer';
import {customersApi} from '../api/customers';
import {dashboardApi} from '../api/dashboard';
import {reportsApi} from '../api/reports';
import {profileApi} from '../api/profile';
import {lendersApi} from '../api/lenders';
import {lendersFunnelApi} from '../api/lendersFunnel';
import {notifApi} from '../api/notif';

export const store = configureStore({
    reducer: {
        [authReducer.name]: authReducer.reducer,
        [profileReducer.name]: profileReducer.reducer,
        [commonReducer.name]: commonReducer.reducer,
      [tabsReducer.name]: tabsReducer.reducer,
      [authApi.reducerPath]: authApi.reducer,
        [commonApi.reducerPath]: commonApi.reducer,
        [partnersApi.reducerPath]: partnersApi.reducer,
        [adminsApi.reducerPath]: adminsApi.reducer,
        [employeesApi.reducerPath]: employeesApi.reducer,
        [ticketsApi.reducerPath]: ticketsApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [mainAccountsApi.reducerPath]: mainAccountsApi.reducer,
        [applicantsApi.reducerPath]: applicantsApi.reducer,
        [offersApi.reducerPath]: offersApi.reducer,
        [customersApi.reducerPath]: customersApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [lendersApi.reducerPath]: lendersApi.reducer,
        [lendersFunnelApi.reducerPath]: lendersFunnelApi.reducer,
        [notifApi.reducerPath]: notifApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(commonApi.middleware)
            .concat(partnersApi.middleware)
            .concat(adminsApi.middleware)
            .concat(employeesApi.middleware)
            .concat(ticketsApi.middleware)
            .concat(profileApi.middleware)
            .concat(mainAccountsApi.middleware)
            .concat(applicantsApi.middleware)
            .concat(offersApi.middleware)
            .concat(customersApi.middleware)
            .concat(dashboardApi.middleware)
            .concat(reportsApi.middleware)
            .concat(lendersApi.middleware)
            .concat(lendersFunnelApi.middleware)
            .concat(notifApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
