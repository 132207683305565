import './index.scss'
import {Button, Form, message} from "antd";
import {yupSync} from "../../../utils";
import {CustomInput} from "../../../customFields/CustomInput";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useChangePasswordMutation} from "../../../api/profile";
import {successMessage} from "../../../components/Messages/messages";

const validationSchema = Yup.object().shape({
    password: Yup.string().required('This field is required')
        .min(6, 'Password should be at least 6 characters long')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-]).+$/,
            'Password should contain at least 1 lowercase, 1 uppercase, 1 special symbol, 1 number'
        ),
    current_password: Yup.string().required('This field is required')
        .min(6, 'Password should be at least 6 characters long')
    ,
    password_confirmation: Yup.string().required('This field is required'),
});

interface FormData {
    current_password: string;
    password: string;
    password_confirmation: string;
}

export const ChangePasswordPage = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm<FormData>();
    const [updatePassword, {error, isLoading, isError, isSuccess, reset}] = useChangePasswordMutation()
    const [credError, setCredError] = useState<string>('');
    //@ts-ignore

    const validateFormOnBlur = (name: string) => {
        form.validateFields([name]);
        if (name === 'password') {
            setCredError('');
        }
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            updatePassword({
                old_password: values.current_password,
                password: values.password,
                password_confirmation: values.password_confirmation
            })
        })}

    useEffect(()=> {
        if (isSuccess) {
            form.resetFields()
            successMessage(messageApi, "Your password has been successfully changed")
        }
    },[isSuccess])

    useEffect(()=> {
        if (isError) {
            form.validateFields()
        }
    },[isError])

    return (
        <div className="change-password__container">
            {contextHolder}
            <div className="change-password__block">
                <div className="change-password__title">
                    Password
                </div>
                <div className="change-password__text">
                    Please enter your current password to change your password.
                </div>
            </div>
            <Form form={form} onFinish={handleSubmit}>
            <div className="change-password__block-input">
                <div className="change-password__text-second">
                    Current password
                </div>
                <div className="change-password__input-container">
                    <Form.Item
                        className="input-wrapper"
                        name="current_password"
                        rules={[...yupSync('current_password', validationSchema, true),
                            () => ({
                                validator() {
                                    if (isError) {
                                        return Promise.reject(
                                            //@ts-ignore
                                            new Error(error?.data?.message))
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <CustomInput
                            inputSize="lg"
                            className="input-lg"
                            type="password"
                            placeholder={"\u2022\u2022\u2022\u2022\u2022\u2022"}
                            onBlur={() => validateFormOnBlur('current_password')}
                            error={credError}
                            onChange={() => reset()}
                            disabled={isLoading}
                        />
                    </Form.Item>
                </div>
            </div>
                <div className="change-password__block-input">
                    <div className="change-password__text-second">
                        New password
                    </div>
                    <div className="change-password__input-container">
                        <Form.Item
                            className="input-wrapper"
                            name="password"
                            rules={yupSync('password', validationSchema, true)}
                        >
                            <CustomInput
                                inputSize="lg"
                                className="input-lg"
                                type="password"
                                placeholder={"\u2022\u2022\u2022\u2022\u2022\u2022"}
                                onBlur={() => validateFormOnBlur('password')}
                                error={credError}
                                onChange={() => setCredError('')}
                                disabled={isLoading}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="change-password__block-input">
                    <div className="change-password__text-second">
                        Confirm new password
                    </div>
                    <div className="change-password__input-container">
                        <Form.Item
                            className="input-wrapper"
                            name="password_confirmation"
                            rules={[
                                ...yupSync('password_confirmation', validationSchema),
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Passwords do not match. Try again')
                                        );
                                    },
                                }),
                            ]}
                            dependencies={['password']}
                        >
                            <CustomInput
                                inputSize="lg"
                                className="input-lg"
                                type="password"
                                placeholder={"\u2022\u2022\u2022\u2022\u2022\u2022"}
                                onBlur={() => validateFormOnBlur('password_confirmation')}
                                error={credError}
                                onChange={() => setCredError('')}
                                disabled={isLoading}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="change-password__btn-block">
                    <Button
                        className="blue-btn-lg h-40"
                        htmlType="submit"
                        disabled={isLoading || isError}
                    >Update password
                    </Button>
                </div>
            </Form>
        </div>
    )
}