import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LoanProducts } from "../../api/types/partners";
import { MainAccItem } from "../../api/types/mainAcc";

export interface UserI {
  email: string;
  id: number | null;
  logo: string;
  application_logo: string;
  name: string;
  role: {
    key: string;
    name: string;
  };
  model: {
    genesis_application_link: string | null;
    external: boolean;
    code_required: boolean;
    id: null | number;
    item_id: number | null;
    loan_products: LoanProducts[];
    main_account: MainAccItem | null;
    referral_code: number | null;
    application_link: string;
    white_labeled_account: number;
    first_name: string;
    last_name: string;
    user_id: string | number;
  };
  notifications: number;
  need_bill_2fa: boolean;
  sub_role: string;
}

const initialState: UserI = {
  email: "",
  id: null,
  logo: "",
  application_logo: "",
  name: "",
  role: {
    key: "",
    name: "",
  },
  model: {
    id: null,
    item_id: null,
    loan_products: [],
    main_account: null,
    referral_code: null,
    application_link: "",
    code_required: false,
    white_labeled_account: 0,
    first_name: "",
    last_name: "",
    user_id: 0,
    external: false,
    genesis_application_link: null,
  },
  notifications: 0,
  need_bill_2fa: false,
  sub_role: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.logo = action.payload.logo;
      state.application_logo = action.payload.application_logo ?? "";
      state.name = action.payload.name;
      state.role.key = action.payload.role.key;
      state.role.name = action.payload.role.name;
      state.sub_role = action.payload.sub_role ?? "owner";
      state.model.id = action.payload.model.id;
      state.model.item_id = action.payload.model.item_id;
      state.model.loan_products = action.payload.model.loan_products;
      state.model.main_account = action.payload.model.main_account;
      state.model.referral_code = action.payload.model.referral_code;
      state.model.application_link = action.payload.model.application_link;
      state.model.code_required = action.payload.model.code_required;
      state.model.white_labeled_account =
        action.payload.model.white_labeled_account ?? 0;
      state.model.first_name = action.payload.model.first_name;
      state.model.last_name = action.payload.model.last_name;
      state.model.user_id = action.payload.model.user_id;
      state.model.external = action.payload.model.external;
      state.notifications = action.payload.notifications;
      state.need_bill_2fa = action.payload.need_bill_2fa;
      state.model.genesis_application_link =
        action.payload.model.genesis_application_link;
    },
  },
});

export const selectAuthUser = (state: RootState) => state.auth;

export const { setUser } = profileSlice.actions;

export default profileSlice;
