interface PropsI {
  fill: string;
}

export const NotifIcon = ({ fill }: PropsI) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M12.8746 22.2C13.4387 22.6979 14.1797 23 14.9912 23C15.8028 23 16.5438 22.6979 17.1079 22.2M19.7912 11.8C19.7912 10.527 19.2855 9.30606 18.3853 8.40589C17.4852 7.50571 16.2643 7 14.9912 7C13.7182 7 12.4973 7.50571 11.5971 8.40589C10.6969 9.30606 10.1912 10.527 10.1912 11.8C10.1912 14.2721 9.5676 15.9648 8.87096 17.0843C8.28333 18.0287 7.98951 18.5009 8.00029 18.6326C8.01221 18.7785 8.04311 18.8341 8.16065 18.9213C8.26679 19 8.7453 19 9.70231 19H20.2801C21.2372 19 21.7157 19 21.8218 18.9213C21.9393 18.8341 21.9702 18.7785 21.9822 18.6326C21.9929 18.5009 21.6991 18.0287 21.1115 17.0843C20.4149 15.9648 19.7912 14.2721 19.7912 11.8Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
