import './index.scss'
import Wl_mark from "../../../assets/WL-mark.svg";
import {message, Switch} from "antd";
import eye from "../../../assets/eye-icon.svg";

import file from "../../../assets/file_blue_lg.svg";
import {maskDigits, trimmer} from "../../../helpers/common";
import download from "../../../assets/download-cloud.svg";
import React, {useEffect, useState} from "react";
import {
    useChangeCodeRequiredMutation,
    useLazyGetProfileInfoQuery
} from "../../../api/profile";
import {PARTNER} from "../../../helpers/variables";
import moment from "moment/moment";
import {useAppSelector} from "../../../hooks";
import {ShowPreviewModal} from "../../../components/Modals/ShowPreviewModal";
import {successMessage} from "../../../components/Messages/messages";


export const CompanyProfilePage = () => {
    const user = useAppSelector((state) => state.profile);
    const [messageApi, contextHolder] = message.useMessage();

    const [getProfile, {data: profileData}] = useLazyGetProfileInfoQuery()

    const [changeCodeRequired, {isSuccess, reset}] = useChangeCodeRequiredMutation()

    const [showPreview, setShowPreview] = useState(false)

    function getNamesById(data: any, id: number) {
        const filteredData = data.filter((item: any) => item.account_type_id === id);

        const namesArray = filteredData.map((item: any) => item.name);

        const namesString = namesArray.join(', ');

        return namesString;
    }
    const formatDate = (inputDate: string) => {
        const date = moment(inputDate, 'YYYY-MM-DD HH:mm:ss');
        const formattedDate = date.format('MMMM D, YYYY');
        return formattedDate;
    }

    useEffect(() => {
        getProfile()
    },[])

    useEffect(() => {
        if (isSuccess) {
            successMessage(messageApi, profileData?.data?.resource?.code_required ? "5-digit code is not required now!" : "5-digit code is required now!")
            reset()
        }
    },[isSuccess])

    return (
        <div className="company-profile__container">
            {contextHolder}
            <div className="d-flex align-center">
                <div className="d-flex company-profile__block">
                    <img className="mr-6 company-profile__logo" src={profileData?.data?.resource?.logo} alt="user"/>
                    <div>
                        <div className="my-profile__name">
                            {profileData?.data?.resource?.company_name}
                        </div>
                        <div className="my-profile__id">
                            # {profileData?.data?.resource?.item_id}
                        </div>
                    </div>
                </div>
                {profileData?.data?.role?.key === PARTNER &&
                    <div className="company-profile__block">
                    <div className="company-profile__block__title">
                        Type
                    </div>
                    <div className="company-profile__block__text">
                        {profileData?.data?.resource?.external ?
                            'External partner' :
                            profileData?.data?.resource?.main_account_id ?
                                "Related partner" : "Partner"}
                    </div>
                </div>}
                {(!!profileData?.data?.resource?.white_labeled_account ||
                    !!profileData?.data?.resource?.main_account?.white_labeled_account) &&
                    <div className="company-profile__block">
                    <img src={Wl_mark} alt="white labled"/>
                </div>}
                <div className="company-profile__block">
                    <div className="company-profile__block__title">
                        Onboarding date
                    </div>
                    <div className="company-profile__block__text">
                        {moment(profileData?.data?.resource?.created_at).format("MM/DD/YYYY h:mma")}
                    </div>
                </div>
            </div>
            {(user.role.key === PARTNER || !!profileData?.data?.resource?.white_labeled_account) && <div className="d-flex">
                <div className="my-profile__body flex-2">
                    <div className="my-profile__header">
                        <div className="my-profile__header-title">General information</div>
                        {user.role.key === PARTNER && !user.model.external && <div className="d-flex align-center">
                            <div className="company-profile__block__title mr-6">Require 5-digit code?</div>
                            <Switch
                                checked={profileData?.data?.resource?.code_required}
                                onChange={() => changeCodeRequired({code_required:!profileData?.data?.resource?.code_required})}
                            />
                        </div>}
                    </div>
                    {user.role.key === PARTNER && <div className="my-profile__body-info" >
                        <div className="my-profile__body-info__block">
                            <div className="my-profile__body-info__title">
                                Company address
                            </div>
                            <div className="my-profile__body-info__text">
                                {profileData?.data?.resource?.company_address}
                            </div>
                        </div>
                        <div className="my-profile__body-info__block">
                            <div className="my-profile__body-info__title">
                                City
                            </div>
                            <div className="my-profile__body-info__text">
                                {profileData?.data?.resource?.city}
                            </div>
                        </div>
                        <div className="my-profile__body-info__block">
                            <div className="my-profile__body-info__title">
                                State
                            </div>
                            <div className="my-profile__body-info__text">
                                {profileData?.data?.resource?.state?.name}
                            </div>
                        </div>
                        <div className="my-profile__body-info__block">
                            <div className="my-profile__body-info__title">
                                ZIP code
                            </div>
                            <div className="my-profile__body-info__text">
                                {profileData?.data?.resource?.zip_code}
                            </div>
                        </div>
                        <div className="my-profile__body-info__block">
                            <div className="my-profile__body-info__title">
                                Company email
                            </div>
                            <div className="my-profile__body-info__text">
                                {profileData?.data?.resource?.contact_email}
                            </div>
                        </div>
                    </div>}
                    {!!profileData?.data?.resource?.white_labeled_account &&
                        <div className="my-profile__body-info" >
                        <div className="my-profile__body-info__block">
                            <div className="my-profile__body-info__title">
                                Sub-domain name
                            </div>
                            <div className="my-profile__body-info__text">
                                {profileData?.data?.resource?.sub_domain}
                            </div>
                        </div>
                    </div>}
                </div>
                {!!profileData?.data?.resource?.main_account && <div className="my-profile__body flex-1 ml-12">
                    <div className="my-profile__header">
                        <div className="my-profile__header-title">Associated main account</div>
                    </div>
                    <div className="my-profile__body-info" >
                        <div className="d-flex company-profile__block">
                            <img className="mr-6 company-profile__logo" src={profileData?.data?.resource?.main_account?.logo} alt="user"/>
                            <div>
                                <div className="my-profile__name">
                                    {profileData?.data?.resource?.main_account?.company_name}
                                </div>
                                <div className="my-profile__id">
                                    # {profileData?.data?.resource?.main_account?.item_id}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {!!profileData?.data?.resource?.white_labeled_account && <div className="my-profile__body flex-1 ml-12">
                    <div className="my-profile__header">
                        <div className="my-profile__header-title">Application logo</div>
                    </div>
                    <div className="my-profile__body-info" >
                        <div className="d-flex align-center justify-between flex-1">
                            <div>
                                <img
                                    className="mr-6 company-profile__logo"
                                    src={profileData?.data?.resource?.application_logo}
                                    alt="user"/>
                            </div>
                            <div
                                className="ticket-view__file-upload-block"
                                onClick={() => setShowPreview(true)}
                            >
                                    <img src={eye} alt="download"/>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>}
            <div className="my-profile__body flex-1">
                <div className="my-profile__header">
                    <div className="my-profile__header-title">Banking information</div>
                </div>
                <div className="my-profile__body-info" >
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Bank name
                        </div>
                        <div className="my-profile__body-info__text">
                            {profileData?.data?.resource?.bank_details?.bank_name || '-'}
                        </div>
                    </div>
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Legal business name
                        </div>
                        <div className="my-profile__body-info__text">
                            {profileData?.data?.resource?.bank_details?.legal_business_name || '-'}
                        </div>
                    </div>
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Routing number
                        </div>
                        <div className="my-profile__body-info__text">
                            {profileData?.data?.resource?.bank_details?.routing_number || '-'}
                        </div>
                    </div>
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Account number
                        </div>
                        <div className="my-profile__body-info__text">
                            {maskDigits(profileData?.data?.resource?.bank_details?.accounting_number) || '-'}
                        </div>
                    </div>
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Account holder's name
                        </div>
                        <div className="my-profile__body-info__text">
                            {profileData?.data?.resource?.bank_details?.account_holder_name || '-'}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className="my-profile__body flex-2">
                    <div className="my-profile__header">
                        <div className="my-profile__header-title">Account types & Products</div>
                    </div>
                    <div className="company-profile__body-info justify-between flex-wrap flex-1" >
                        {profileData?.data?.resource?.account_types?.map((item: any) => {
                            return (
                                <div className={`company-profile__block-product`}>
                                    <div className="my-profile__body-info__title">
                                        {item.name}
                                    </div>
                                    <div className="my-profile__body-info__text">
                                        {getNamesById(profileData?.data?.resource?.loan_products, item.id)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {user.role.key === PARTNER && <div className="company-profile__body flex-1 ml-12">
                    <div className="my-profile__header">
                        <div className="my-profile__header-title">Contract</div>
                    </div>
                    <div className="my-profile__body-info" >
                        <div className="ticket-view__file-container mt-0 flex-1"
                        >
                            <div className="d-flex">
                                <a className="d-flex" target="_blank" href={profileData?.data?.resource?.contract}>
                                    <img src={file}/>
                                    <div className="ml-5">
                                            <div className="submit-ticket-upload__file-title">
                                                Contract
                                            </div>
                                        <div className="d-flex">
                                            <div className="submit-ticket-upload__text">
                                                {formatDate(profileData?.data?.resource?.contract_uploaded_at)}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div
                                className="ticket-view__file-upload-block"
                            >
                                <a target="_blank" href={profileData?.data?.resource?.contract} download
                                >
                                    <img src={download} alt="download"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {showPreview && (
                <ShowPreviewModal
                    openModal={showPreview}
                    setOpenModal={setShowPreview}
                    closeIcon={false}
                    image={profileData?.data?.resource?.application_logo}
                />
            )}
        </div>
    )
}