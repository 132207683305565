import { Button, message } from "antd";
import { FC, useState } from "react";
import doneIconBlue from "../../assets/doneIconBlue.svg";
import copyLinkIcon from "../../assets/ci_copy.svg";
import "../Notifications/index.scss";
import { useAppSelector } from "../../hooks";
import { useLazyRegenerateCodeQuery } from "../../api/partners";
import { useLazyGetMeQuery } from "../../api/common";
import { isApiError } from "../../utils/general";
import { Spinner } from "../Spinner/Spinner";
import { successMessage } from "../Messages/messages";

interface Props {
  refInfo: any;
  open: boolean;
  errorMessage: any;
}

export const InfoBlock: FC<Props> = ({ refInfo, open, errorMessage }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const user = useAppSelector((state) => state.profile);
  const [regenerateCode] = useLazyRegenerateCodeQuery();
  const [getMe] = useLazyGetMeQuery();
  const [isLoading, setIsLoading] = useState(false);

  const RegenerateCodeFunc = async () => {
    const id: number | null = user.model.id;
    if (!id) return;
    try {
      setIsLoading(true);
      await regenerateCode(id);
      await getMe();
      setIsLoading(false);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || "Regenerate code failed");
    }
  };

  const handleCopyLink = (link: string, type: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        if (type === "code") {
          successMessage(messageApi, "Code copied!");
        }
        if (type === "link") {
          successMessage(messageApi, "Link copied!");
        }
      })
      .catch(() => {
        console.error("Failed to copy text");
      });
  };

  console.log(
    "user?.model?.genesis_application_link",
    user?.model?.genesis_application_link
  );

  return (
    <div ref={refInfo}>
      {contextHolder}
      <div className={`notif__container info ${open ? "open" : ""}`}>
        <div
          className="notif__main _info"
          style={
            user && user?.model?.code_required
              ? { width: "515px" }
              : { width: "270px" }
          }
        >
          {user && user?.model?.code_required && (
            <div className="_info__item info-item">
              <div className="info-item__title">Referral code</div>
              <div className="info-item__subtitle">
                {user?.model?.referral_code}
              </div>
              <div className="info-item__btn-wrapper">
                <div>
                  {isLoading ? (
                    <div className="spinner-wrapper-regenerate">
                      <Spinner size="small" color="#1f44dd" />
                    </div>
                  ) : (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        RegenerateCodeFunc();
                      }}
                    >
                      Regenerate
                    </span>
                  )}
                </div>
                <Button
                  className="copy-link-btn"
                  onClick={() =>
                    handleCopyLink(user?.model?.referral_code + "", "code")
                  }
                >
                  <img src={doneIconBlue} alt="done" />
                </Button>
              </div>
            </div>
          )}
          {!user?.model?.genesis_application_link ? (
            <div className="_info__item info-item">
              <div className="info-item__title">Application link</div>
              <div className="info-item__subtitle">
                {user?.model?.application_link}
              </div>
              <Button
                className="copy-link-btn"
                onClick={() =>
                  handleCopyLink(user?.model?.application_link, "link")
                }
                style={{ width: "100%" }}
              >
                <span>
                  <img src={doneIconBlue} alt="done" />
                </span>
                Copy link
              </Button>
            </div>
          ) : (
            <div className="d-flex f-d-column">
              {user?.model?.application_link && (
                <div className="_info__item info-item">
                  <div className="info-item__title">Installment & LTO</div>
                  <div className="info-item__subtitle">
                    {user?.model?.application_link}
                  </div>
                  <Button
                    className="copy-link-btn"
                    onClick={() =>
                      handleCopyLink(user?.model?.application_link, "link")
                    }
                    style={{ width: "100%" }}
                  >
                    <span>
                      <img src={doneIconBlue} alt="done" />
                    </span>
                    Copy link
                  </Button>
                </div>
              )}
              <div className="_info__item info-item">
                <div className="info-item__title">Credit Card Account</div>
                <div className="info-item__subtitle">
                  {user?.model?.genesis_application_link}
                </div>
                <Button
                  className="copy-link-btn"
                  onClick={() => {
                    if (user?.model?.genesis_application_link) {
                      handleCopyLink(
                        user?.model?.genesis_application_link,
                        "link"
                      );
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <span>
                    <img src={doneIconBlue} alt="done" />
                  </span>
                  Copy link
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
