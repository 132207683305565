interface PeriodTextI {
    noCompApplied: boolean
    comparedPeriod: string
    prevComp: string
}

export const PeriodText = ({noCompApplied, comparedPeriod, prevComp}: PeriodTextI) => {
    return (
        <>
            {!noCompApplied &&
            comparedPeriod !== 'No comparison'
                ? comparedPeriod === 'Previous period'
                    ? 'Prev. Period'
                    : comparedPeriod === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                : ''}
            {!noCompApplied &&
            comparedPeriod === 'No comparison'
                ? prevComp === 'Previous period'
                    ? 'Prev. Period'
                    : prevComp === 'Previous year'
                        ? 'Prev. Year'
                        : ''
                : ''}
        </>
    )
}