import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { useEffect, useState } from 'react';
import edit from '../../../assets/edit.svg';
import info from '../../../assets/info-circle-blue.svg';
import moment from 'moment';
import { Button, message, Tooltip } from 'antd';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useAppSelector } from '../../../hooks';
import { useGetAccountTypesQuery } from '../../../api/common';
import { UserI } from '../../../reducers/profile/profileSlice';
import {
  useChangeLendersStatusMutation,
  useLazyGetLenderQuery,
} from '../../../api/lenders';
import './index.scss';
import { idsI } from '../../MainAccounts/MainAccounts';
import { isApiError } from '../../../utils/general';
import { Ficotable } from '../EditLenders/Ficotable';
import { LoanTypeView } from './LoanTypeView';
import { distributionTooltipLayout } from '../tooltips';
import { TitleRecepientInfo } from '../../../components/LayoutBlocks/TitleRecepientInfo';
import { ProfileSkeleton } from '../../../components/Skeletons/ProfileSkeleton';

export const ViewLenders = () => {
  const location = useLocation();
  const [getLender, { data, isLoading, isError }] = useLazyGetLenderQuery();
  const navigate = useNavigate();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [messageApi, contextHolder] = message.useMessage();
  const [changeStatus] = useChangeLendersStatusMutation();
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [tab, setTab] = useState('general');

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (isError) {
      errorMessage('Fetch main account failed');
    }
  }, [isError]);

  useEffect(() => {
    if (!user.email) return;
    if (!(user.role.key === 'admin' || user.role.key === 'super_admin')) {
      navigate('/403');
    }
  }, [user.email, user.role.key]);

  useEffect(() => {
    if (!location) return;
    const id: string = location.pathname.split('/')[2];
    if (!id) return;
    getLender(id);
  }, [location]);

  const changeStatusFunc = async (status: number) => {
    try {
      const ids: idsI = {
        id: data?.data.id as number,
        key: { status_key: status },
      };
      await changeStatus(ids);
      getLender(data?.data.id as number);
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Change status failed');
    }
  };

  return (
    <div className="container">
      {contextHolder}
      {isLoading ? (
        <ProfileSkeleton />
      ) : (
        <div className="new-member">
          <div className="new-member__row">
            <div className="main__tab-row">
              <Button
                className={`tab-btn ${tab === 'general' ? 'active' : ''}`}
                onClick={() => setTab('general')}
              >
                General info
              </Button>
              {data?.data.loan_types?.map((elem: any) => {
                return (
                  <Button
                    className={`tab-btn ${tab === elem.name ? 'active' : ''}`}
                    onClick={() => setTab(elem.name)}
                  >
                    {elem.name}
                  </Button>
                );
              })}
            </div>
          </div>

          {tab === 'general' && (
            <div className="view-member">
              <div className="view-member__block block block-main-acc">
                <div className="block__top">
                  <div className="lender-info-border">
                    <div className="block__logo-wrapper">
                      <img
                        src={data?.data?.logo}
                        className="block__logo"
                        alt="logo"
                      />
                      <div className="block__logo-info">
                        <div className="block__name">{data?.data?.name}</div>
                        <div className="block__id">
                          ID#{' '}
                          <span className="block__id-blue">
                            {data?.data?.id}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="lender-info">
                      <div className="block__row">
                        <div className="block__item small">
                          <div className="block__item-title">
                            Available Locations
                          </div>
                          <div className="block__item-value">
                            {data?.data?.states.length
                              ? data?.data?.states
                                  ?.map((elem: any) => elem.name)
                                  .join(', ')
                              : 'All'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="title-recepient-info">
                      <div className="block__title">Contact Information</div>
                      <div className="lender-info">
                        <div className="block__row">
                          {data?.data.address && (
                            <div className="block__item small">
                              <div className="block__item-title">
                                First Name
                              </div>
                              <div className="block__item-value">
                                {data?.data?.first_name}
                              </div>
                            </div>
                          )}
                          {data?.data.city && (
                            <div className="block__item small">
                              <div className="block__item-title">Last Name</div>
                              <div className="block__item-value">
                                {data?.data?.last_name}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="block__row">
                          {data?.data.state && (
                            <div className="block__item small">
                              <div className="block__item-title">
                                Contact Number
                              </div>
                              <div className="block__item-value">
                                {data?.data?.contact_number}
                              </div>
                            </div>
                          )}
                          {data?.data.zip_code && (
                            <div className="block__item small">
                              <div className="block__item-title">
                                Contact Email
                              </div>
                              <div className="block__item-value">
                                {data?.data?.contact_email}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="block__row">
                          {data?.data.addressee && (
                            <div className="block__item small">
                              <div className="block__item-title">
                                Additional Information
                              </div>
                              <div className="block__item-value">
                                {data?.data?.additional_information
                                  ? data?.data?.additional_information
                                  : '-'}
                              </div>
                            </div>
                          )}
                          {data?.data.email && (
                            <div className="block__item small">
                              <div className="block__item-title">Email</div>
                              <div className="block__item-value">
                                {data?.data?.email}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-member__block block block-main-acc">
                <div className="block__top" style={{ minHeight: 'auto' }}>
                  <div
                    className="block__title"
                    style={{ height: '30px' }}
                  ></div>
                  <div className="block__edit-wrapper">
                    <img
                      src={edit}
                      className="block__edit"
                      onClick={() =>
                        navigate(`/lenders/edit/${data?.data?.id}`)
                      }
                      alt="edit"
                    />
                  </div>
                  <div className="block__common-info">
                    <div className="block__item-row">
                      <div className="block__item-row-title">Status</div>
                      <div className="block__item-row-value">
                        <span
                          style={
                            data?.data?.status === 'Active'
                              ? { color: '#017130' }
                              : data?.data?.status === 'Paused'
                              ? { color: '#A80E1C' }
                              : {}
                          }
                        >
                          {data?.data?.status}
                        </span>
                      </div>
                    </div>
                    <div className="block__item-row">
                      <div className="block__item-row-title">
                        Participation Bonus
                      </div>
                      <div className="block__item-row-value">
                        {data?.data.participation_bonus_percent} %
                      </div>
                    </div>
                    <div className="block__item-row">
                      <div className="block__item-row-title d-flex">
                        <span className="mr-2">Lending Funds Distribution</span>
                        <Tooltip title={distributionTooltipLayout}>
                          <img src={info} alt="info" />
                        </Tooltip>
                      </div>
                      <div className="block__item-row-value">
                        {data?.data.fund_distribution_model === 1
                          ? 'Model 1'
                          : 'Model 2'}
                      </div>
                    </div>
                    <div className="block__item-row">
                      <div className="block__item-row-title">
                        Onboarding Date
                      </div>
                      <div className="block__item-row-value">
                        {moment(data?.data.created_at).format('MMMM DD, YYYY')}
                      </div>
                    </div>
                  </div>

                  {data?.data.fico_ranges?.length ? (
                    <div className="block__item">
                      <div className="block__item-title">
                        FICO Buckets (reference)
                      </div>
                      <Ficotable
                        ficoData={data?.data.fico_ranges ?? []}
                        //@ts-ignore
                        id={tab === 'Lease-to-own' ? 3 : 1}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <TitleRecepientInfo bank={data?.data} />
                </div>
              </div>
            </div>
          )}

          {tab !== 'general' && <LoanTypeView data={data} tab={tab} />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: ' 24px 0 24px 0',
            }}
          >
            {data?.data.status === 'Active' && (
              <Button
                className="red-btn lender__pause-btn"
                onClick={() => changeStatusFunc(0)}
              >
                Pause
              </Button>
            )}
            {data?.data?.status === 'Paused' && (
              <Button
                className="blue-btn lender__pause-btn"
                style={{ height: '45px' }}
                onClick={() => changeStatusFunc(1)}
              >
                Activate
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
