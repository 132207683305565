import { Avatar, Col, Divider, Row, Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 55,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
const stylesLargeInput = {
  width: 40,
  height: 40,
  margin: '10px',
  borderRadius: 40,
};
const stylesMediumInput = {
  width: 50,
  height: 27,
  margin: '10px',
  borderRadius: 40,
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};

const divider = {
  width: 350,
  height: 1,
  margin: '15px 20px 0 20px',
  borderRadius: 40,
};

export const DashboardSkeleton = () => {
  return (
    <>
      <div className="skeleton">
        <div className="skeleton__row">
          <div className="skeleton__item filters">
            <Skeleton.Input size="small" style={stylesSmallInput} active />
          </div>
          <div className="skeleton__item filters">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          <div className="skeleton__item filters">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <div className="skeleton__item filters">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <Skeleton.Button active style={{ width: 140, height: 33 }} />
        </div>
        <div className="skeleton__row">
          <div className="skeleton__item top-row-item">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Input size="large" style={stylesLargeInput} active />{' '}
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <div className="skeleton__item top-row-item">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Input size="large" style={stylesLargeInput} active />{' '}
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <div className="skeleton__item top-row-item">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Input size="large" style={stylesLargeInput} active />{' '}
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
          <div className="skeleton__item top-row-item">
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
            <Skeleton.Input size="large" style={stylesLargeInput} active />{' '}
            <Skeleton.Input size="small" style={stylesSmallInput} active />{' '}
          </div>
        </div>
        <div className="skeleton__row">
          <div className="skeleton__item large-block">
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesLargeInput} active />
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Button size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
          </div>
          <div className="skeleton__item large-block">
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesLargeInput} active />
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Button size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
          </div>
        </div>
        <div className="skeleton__row">
          <div className="skeleton__item large-block">
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesLargeInput} active />
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Button size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
          </div>
          <div className="skeleton__item large-block">
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Input size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Input size="large" style={stylesLargeInput} active />
              <Skeleton.Input size="large" style={stylesMediumInput} active />
              <Skeleton.Button size="small" style={stylesSmallInput} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
            <div className="skeleton__row">
              <Skeleton.Button style={stylesExtraSmallInput} active />
              <Skeleton.Input size="small" style={divider} active />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
