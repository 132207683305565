import { Button, Form, UploadFile, message, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { yupSync } from '../../../utils';
import { isApiError } from '../../../utils/general';
import useDebounce from '../../../hooks/useDebounce';
import {
  setIsDirtyAction,
  setMessage,
} from '../../../reducers/common/commonSlice';
import * as Yup from 'yup';

//icons
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import deleteIcon from '../../../assets/TrashBinMinimalistic.svg';
import plus from '../../../assets/plusicon.svg';
import info from '../../../assets/info-circle-blue.svg';

//components
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { DraggerFile } from '../../../customFields/DraggerFile';
import { CustomInput } from '../../../customFields/CustomInput';
import { MultiSelect } from '../../../customFields/MultiSelect';
import { PhoneNumberInput } from '../../../customFields/PhoneNumberInput';
import { AddLoanModal } from './AddLoanModal';
import { LoanProps, LoanTypeTab, ficoProps } from './LoanTypeTab';
import { CustomSelect } from '../../../customFields/CustomSelect';

//api
import { StatesItem } from '../../../api/types/partners';
import { LoanType } from '../../../api/types/lenders';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { useAddLogoMutation, useGetStatesQuery } from '../../../api/partners';
import {
  useEditLenderMutation,
  useLazyGetLenderQuery,
  useLazyGetLoanTypesQuery,
} from '../../../api/lenders';
import { AccountTypesItem } from '../../../api/types/common';
import { CustomTextArea } from '../../../customFields/CustomTextArea';
import { unstable_usePrompt } from 'react-router-dom';
import { distributionTooltipLayout } from '../tooltips';
import { handleFeeInput } from '../../../helpers/common';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  addressee: Yup.string().matches(/^[A-Za-z\s]*$/, 'Only letters are allowed'),
  city: Yup.string().matches(/^[A-Za-z\s`-]*$/, 'Only letters are allowed'),
  email: Yup.string().email('Email is invalid'),

  zip_code: Yup.string().matches(/^[^_]*$/, 'Please, enter a valid zip code'),
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  contact_number: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'Please, enter a valid Phone Number'),
  contact_email: Yup.string()
    .email('Email is invalid')
    .required('This field is required'),
  participation_bonus_percent: Yup.string().required('This field is required'),
  distribution: Yup.string().required('This field is required'),
});

interface FormData {
  name: string;
  logo: string | number | undefined;
  email: string;
  loan_types: string[];
  address: string;
  city: string;
  addressee: string;
  state: string | null;
  zip_code: string;
  states: string[];
  additional_information: string;
  contact_number: string;
  contact_email: string;
  last_name: string;
  first_name: string;
  participation_bonus_percent: string | number;
  fund_distribution_model: string | number;
}

export interface selectOption {
  value: string;
  label?: string;
  id?: string;
}

const initialValues = {
  logo: undefined,
  application_logo: undefined,
  company_name: undefined,
  addressee: undefined,
  last_name: undefined,
  email: undefined,
  white_labeled_account: false,
  loan_types: [],
};

export const EditLenders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm<FormData>();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAppSelector((state) => state.profile);
  const { handleKeyPress } = usePreventSpaceTyping();
  const dispatch = useAppDispatch();

  //logo
  const [logo, setLogo] = useState<any[]>([]);
  const [logoErr, setLogoErr] = useState<string | null>(null);
  const [IdLogo, setIdLogo] = useState<number | null>(null);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [logoHover, setLogoHover] = useState<boolean>(false);
  const [faildFileLogo, setFaildFileLogo] = useState<UploadFile>();

  //modals
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [confirmationDeleteModal, setConfirmationDeleteModal] =
    useState<boolean>(false);
  const [addLoanModal, setAddLoanModal] = useState(false);

  //requests
  const [getLender, { data: lenderData }] = useLazyGetLenderQuery();
  const [addLogo, { data: LogoId }] = useAddLogoMutation();
  const [getLoanTypes, { data: loanTypes }] = useLazyGetLoanTypesQuery();
  const [editLender, { isSuccess: isSuccessEditLenders, isError, error }] =
    useEditLenderMutation();

  const [isClickOnHome, setIsClickOnHome] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [id, setId] = useState<null | number>(null);

  // loan types tabs
  const [selectLoanTypes, setSelectLoanTypes] = useState<number[]>([]);
  const [tab, setTab] = useState('general');
  const [noLoan, setNoLoan] = useState(false);
  const [deleteIdLoan, setDeleteIdLoan] = useState<number | null>(null);

  //loan types errors
  const [generalError, setGeneralError] = useState(false);
  const [InstallmentError, setInstallmentError] = useState(false);
  const [RevolvingLineError, setRevolvingLineError] = useState(false);
  const [LeaseToOwnLineError, setLeaseToOwnLineError] = useState(false);
  const [PromotionalError, setPromotionalError] = useState(false);
  const [CreditCardAccountError, setCreditCardAccountError] = useState(false);

  const [InstallmentDataError, setInstallmentDataError] = useState({});
  const [RevolvingLineDataError, setRevolvingLineDataError] = useState({});
  const [LeaseToOwnLineDataError, setLeaseToOwnLineDataError] = useState({});
  const [PromotionalDataError, setPromotionalDataError] = useState({});
  const [CreditCardAccountDataError, setCreditCardAccountDataError] = useState(
    {}
  );

  //states
  const [searchState, setSearchState] = useState('');
  const debouncedSearchState = useDebounce(searchState);
  const { data: statesData } = useGetStatesQuery({
    name: debouncedSearchState,
  });
  const [statesOptions, setStatesOptions] = useState<StatesItem[]>([]);
  //
  const [searchMultiState, setSearchMultiState] = useState('');
  const debouncedSearchMultiState = useDebounce(searchMultiState);
  const { data: statesDataMulti } = useGetStatesQuery({
    name: debouncedSearchMultiState,
  });

  //data
  const [data, setData] = useState<any>({});
  const [loanData, setLoanData] = useState<LoanProps[]>([]);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [clickOnSave, setClickOnSave] = useState(false);

  unstable_usePrompt({
    message: 'You have unsaved changes. Do you want to leave this page?',
    //@ts-ignore
    when: ({ currentLocation, nextLocation }) =>
      isDirty &&
      currentLocation !== nextLocation &&
      nextLocation.pathname !== '/login' &&
      !clickOnSave,
  });

  const beforeUnLoad = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    e.returnValue = '';
  };

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [isDirty]);

  useEffect(() => {
    setNoLoan(false);
  }, [selectLoanTypes]);

  useEffect(() => {
    if (!statesDataMulti) return;
    const newArray: any = [...statesDataMulti?.data];
    newArray.unshift({ name: 'All', id: 'allstates' });
    setStatesOptions(newArray);
  }, [statesDataMulti]);

  useEffect(() => {
    getLoanTypes();
  }, []);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (!LogoId) {
      setIdLogo(null);
    } else {
      setIdLogo(LogoId.data.id);
    }
    if (!logo.length) {
      setIdLogo(null);
    }
  }, [LogoId, logo]);

  function transformData(input: any[]): LoanProps[] {
    return input.map((item) => {
      const newFico: ficoProps[] = item?.fico_ranges?.map((elem: ficoProps) => {
        return {
          fico_range_from: elem.fico_range_from,
          fico_range_to: elem.fico_range_to,
          apr: elem.apr,
        };
      });

      return {
        personal: item.personal === 1,
        commercial: item.commercial === 1,
        secured: item.secured === 1,
        coverage_amount_min:
          item.coverage_amount_min > 0
            ? item.coverage_amount_min.toString()
            : '',
        coverage_amount_max:
          item.coverage_amount_max > 0
            ? item.coverage_amount_max.toString()
            : '',
        fico_ranges: newFico,
        loan_type_id: item.id.toString(),
        id: item.id,
        processing_fees: JSON.parse(JSON.stringify(item.processing_fees)) ?? [],
        bank_fee: item.bank_fee,
      };
    });
  }

  useEffect(() => {
    if (!lenderData) return;

    form.setFieldsValue({
      logo: lenderData?.data?.logo,
      name: lenderData.data.name,
      email: lenderData.data.email ? lenderData.data.email : '',
      loan_types: lenderData.data.loan_types.map((elem: any) => elem.id + ''),
      address: lenderData.data.address ? lenderData.data.address : '',
      city: lenderData.data.city ? lenderData.data.city : '',
      addressee: lenderData.data.addressee ? lenderData.data.addressee : '',
      state: lenderData.data.state ? lenderData.data.state.id + '' : null,
      zip_code: lenderData.data.zip_code ? lenderData.data.zip_code : '',
      states: lenderData.data.states.length
        ? lenderData.data.states.map((elem: any) => elem.id + '')
        : ['allstates'],
      first_name: lenderData.data.first_name ?? '',
      last_name: lenderData.data.last_name ?? '',
      contact_email: lenderData.data.contact_email ?? '',
      contact_number: lenderData.data.contact_number ?? '',
      additional_information: lenderData.data.additional_information ?? '',
      participation_bonus_percent:
        lenderData.data.participation_bonus_percent ?? '',
      fund_distribution_model:
        lenderData.data.fund_distribution_model === 1 ? 'Model 1' : 'Model 2',
    });

    const newLoan = transformData(lenderData?.data?.loan_types);

    setData({
      logo: lenderData?.data?.logo,
      name: lenderData.data.name,
      email: lenderData.data.email ? lenderData.data.email : '',
      loan_types: lenderData.data.loan_types.map((elem: any) => elem.id + ''),
      address: lenderData.data.address ? lenderData.data.address : '',
      city: lenderData.data.city ? lenderData.data.city : '',
      addressee: lenderData.data.addressee ? lenderData.data.addressee : '',
      state: lenderData.data.state ? lenderData.data.state.id + '' : null,
      zip_code: lenderData.data.zip_code ? lenderData.data.zip_code : '',
      states: lenderData.data.states.length
        ? lenderData.data.states.map((elem: any) => elem.id + '')
        : ['allstates'],
      first_name: lenderData.data.first_name ?? '',
      last_name: lenderData.data.last_name ?? '',
      contact_email: lenderData.data.contact_email ?? '',
      contact_number: lenderData.data.contact_number ?? '',
      additional_information: lenderData.data.additional_information ?? '',
      participation_bonus_percent:
        lenderData.data.participation_bonus_percent ?? '',
      fund_distribution_model: lenderData.data.fund_distribution_model ?? '',
    });

    setLoanData(newLoan);
    const loantypesarray: number[] = lenderData?.data?.loan_types.map(
      (elem: LoanProps) => +elem.id
    );
    setSelectLoanTypes(loantypesarray);
    setId(lenderData?.data?.id);
    setLogoUrl(
      typeof lenderData?.data?.logo === 'string' ? lenderData?.data?.logo : ''
    );
    setLogo([lenderData?.data?.logo]);
  }, [lenderData]);

  useEffect(() => {
    if (!location) return;

    const id: any = location.pathname?.split('/').pop();
    if (id) {
      getLender(id);
    }
  }, [location]);

  useEffect(() => {
    if (!logo.length) {
      setLogoUrl('');
      form.resetFields(['logo']);
    } else {
      if (logo[0]?.originFileObj) {
        const imageUrl = URL.createObjectURL(logo[0]?.originFileObj);
        setLogoUrl(imageUrl);
      }

      const formData = new FormData();
      formData.append('model', 'bank/logo');
      logo[0]?.originFileObj &&
        formData.append('field', logo[0]?.originFileObj);
      if (logo[0]?.originFileObj) {
        addLogo(formData);
      }
    }
  }, [logo]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key === 'partner' || user.role.key === 'main_account') {
      navigate('/403');
    }
  }, [user.email]);

  useEffect(() => {
    if (isSuccessEditLenders) {
      navigate(`/lenders/${lenderData?.data?.id}`);
    }
    if (isError) {
      //@ts-ignore
      const errorData = error.data.errors;
      for (let key in errorData) {
        if (
          key === 'first_name' ||
          key === 'last_name' ||
          key === 'contact_email' ||
          key === 'contact_number' ||
          key === 'name' ||
          key === 'logo'
        ) {
          setTab('general');
          setGeneralError(true);
          scrollToElementById('general');
        }
      }
    }
  }, [isSuccessEditLenders, isError]);

  const editLenderFunc = async () => {
    const updatedData = loanData.map((item: LoanProps) => ({
      ...item,
      coverage_amount_max: parseFloat(
        item.coverage_amount_max.replace(',', '')
      ),
      coverage_amount_min: parseFloat(
        item.coverage_amount_min.replace(',', '')
      ),
    }));

    const dataToPost: any = {
      logo: IdLogo,
      name: data.name,
      address: data.address,
      city: data.city,
      email: data.email,
      addressee: data.addressee,
      state_id: data.state,
      zip_code: data.zip_code,
      states: data.states?.includes('allstates') ? [] : data.states,
      loan_types: updatedData,
      first_name: data.first_name,
      last_name: data.last_name,
      contact_email: data.contact_email,
      contact_number: data.contact_number,
      additional_information: data.additional_information,
      participation_bonus_percent: data.participation_bonus_percent,
      fund_distribution_model: data.fund_distribution_model,
    };
    if (typeof logo[0] !== 'string') {
      dataToPost.logo = IdLogo;
    }

    try {
      await editLender({
        id: lenderData?.data?.id,
        data: dataToPost,
      }).unwrap();
      await dispatch(setMessage('Changes have been successfully saved'));
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Edit failed');
    }
  };

  const scrollToElementById = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onValuesChange = () => {
    setIsDirty(true);
    setGeneralError(false);
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  const setStates = (value: any) => {
    if (!value.length) {
      form.setFieldsValue({
        states: ['allstates'],
      });
      setData((prev: any) => ({ ...prev, states: ['allstates'] }));
    }
    if (value.length) {
      const data = [...value];
      const newArray = data.filter((elem: any) => elem !== 'allstates');
      form.setFieldsValue({
        states: newArray,
      });
      setData((prev: any) => ({ ...prev, states: newArray }));
    }
  };

  const deleteLoan = (id: number) => {
    setConfirmationDeleteModal(true);
    setDeleteIdLoan(id);
  };

  const validateFunc = () => {
    let isValidForm = true;
    for (let key in data) {
      if (
        (key === 'first_name' ||
          key === 'last_name' ||
          key === 'contact_email' ||
          key === 'contact_number' ||
          key === 'name' ||
          key === 'logo') &&
        !(data as FormData)[key]
      ) {
        setErrors({
          [key]: true,
        });
        setTab('general');
        isValidForm = false;
      }
    }
    selectLoanTypes.forEach((elem: any) => {
      let isValid = true;
      const errors: any = {};
      const data = loanData?.find(
        (item: any) => elem + '' === item.loan_type_id + ''
      );

      if (!data?.coverage_amount_max) {
        errors.coverage_amount_max = true;
        isValid = false;
      }
      if (!data?.coverage_amount_min) {
        errors.coverage_amount_min = true;
        isValid = false;
      }
      if (!data?.fico_ranges?.length) {
        errors.fico_ranges = true;
        isValid = false;
      }
      if (!data?.processing_fees?.length) {
        errors.processing_fees = true;
        isValid = false;
      }
      if (!data?.bank_fee?.length) {
        errors.bank_fee = true;
        isValid = false;
      }

      if (elem === 1) {
        setInstallmentDataError(errors);
        if (!isValid) {
          setInstallmentError(true);
          setTab('Installment');
          isValidForm = false;
        }
      }

      if (elem === 2) {
        setRevolvingLineDataError(errors);

        if (!isValid) {
          setRevolvingLineError(true);
          setTab('Revolving line');
          isValidForm = false;
        }
      }

      if (elem === 3) {
        setLeaseToOwnLineDataError(errors);

        if (!isValid) {
          setLeaseToOwnLineError(true);
          setTab('Lease-to-own');
          isValidForm = false;
        }
      }

      if (elem === 4) {
        setPromotionalDataError(errors);

        if (!isValid) {
          setPromotionalError(true);
          setTab('Promotional');
          isValidForm = false;
        }
      }
    });
    return isValidForm;
  };

  const handleFormSubmit = () => {
    let isValid = true;

    isValid = validateFunc();

    if (
      InstallmentError ||
      RevolvingLineError ||
      LeaseToOwnLineError ||
      PromotionalError ||
      CreditCardAccountError
    ) {
      isValid = false;
    }

    if (!selectLoanTypes.length) {
      isValid = false;

      setNoLoan(true);
    }

    if (!logo.length) {
      isValid = false;
      setTab('general');
      setLogoErr('This field is required');
      scrollToElementById('logo');
    }

    form
      .validateFields()
      .then((values: FormData) => {
        if (isValid) {
          editLenderFunc();
        }
      })
      .catch((error: { errorFields: [{ name: string[] }] }) => {
        const name = error.errorFields[0].name[0];
        scrollToElementById(name);
      });
  };

  const distributionLabel = (
    <div className="d-flex">
      <span className="mr-2">Lending Funds Distribution</span>
      <Tooltip title={distributionTooltipLayout}>
        <img src={info} alt="info" />
      </Tooltip>
    </div>
  );

  return (
    <>
      {contextHolder}

      <div className="container">
        <div className="new-member">
          <Form
            form={form}
            initialValues={initialValues}
            onSubmitCapture={handleFormSubmit}
            onValuesChange={onValuesChange}
          >
            <div className="new-member__main main">
              <div className="main__container">
                <div className="main__title-block">
                  <div className="main__title">Lender Account info</div>
                  <div className="main__subtitle">
                    {location.pathname?.includes('edit') ? 'Edit' : 'Create'}{' '}
                    Lender details
                  </div>
                </div>
                <div className="main__form-block">
                  <div className="main__tab-row">
                    <div className="main__loan-wrapper" id="general">
                      {' '}
                      <Button
                        className={`tab-btn ${
                          tab === 'general' ? 'active' : ''
                        } ${generalError ? 'err' : ''}`}
                        onClick={() => setTab('general')}
                      >
                        General info
                      </Button>
                      {generalError && (
                        <div className="error-text">
                          Please, check all fields again
                        </div>
                      )}
                    </div>

                    {selectLoanTypes?.map((elem: number) => {
                      const element = loanTypes?.data?.find(
                        (item: any) => +item.id === +elem
                      );
                      if (element) {
                        return (
                          <Button
                            className={`tab-btn ${
                              tab === element.name ? 'active' : ''
                            }`}
                            onClick={() => setTab(element.name)}
                          >
                            {element.name}
                            <img
                              src={deleteIcon}
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteLoan(element.id);
                              }}
                            />
                          </Button>
                        );
                      }
                    })}
                    {selectLoanTypes.length ? (
                      <></>
                    ) : (
                      <div className="main__loan-wrapper">
                        <div
                          className={`main__add-loan ${noLoan ? 'err' : ''}`}
                          onClick={() => setAddLoanModal(true)}
                        >
                          <img src={plus} /> Loan type
                        </div>
                        {noLoan && (
                          <div className="error-text">
                            Please add to continue{' '}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {tab === 'general' && (
                    <div className={`partner-info`}>
                      {id && (
                        <div className="partner-info__id">
                          Lender ID:{' '}
                          <span style={{ color: '#67666E' }}>{id}</span>
                        </div>
                      )}
                      <div
                        className="partner-info__row"
                        style={{
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          minHeight: '140px',
                        }}
                      >
                        <div style={{ margin: '0 16px 0 0' }} id="logo">
                          {logoUrl && (
                            <div style={{ position: 'relative' }}>
                              <img
                                src={logoUrl}
                                className="default-avatar"
                                onMouseOver={() => setLogoHover(true)}
                                onMouseLeave={() => setLogoHover(false)}
                              />

                              {logoHover && (
                                <div
                                  onMouseOver={() => setLogoHover(true)}
                                  onMouseLeave={() => setLogoHover(false)}
                                >
                                  <div className="default-avatar__wrapper"></div>
                                  <div className="default-avatar__hover">
                                    <div
                                      className="default-avatar__edit"
                                      onClick={() => setIsEdit(true)}
                                    >
                                      Edit{' '}
                                    </div>
                                    or
                                    <div
                                      className="default-avatar__delete"
                                      onClick={() => {
                                        setIsDirty(true);
                                        setLogo([]);
                                      }}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <Form.Item
                            name="logo"
                            style={logoUrl ? { opacity: '0' } : {}}
                          >
                            <DraggerFile
                              name="logo"
                              allowsFormat={['png', 'jpg', 'jpeg']}
                              onChange={setLogo}
                              fileList={logo}
                              maxSizeBytes={5242880}
                              text="PNG or JPG for Logo"
                              hasError={() =>
                                !!form.getFieldError(['logo']).length
                              }
                              setErr={setLogoErr}
                              err={logoErr}
                              isLoading={false}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                              faildFile={faildFileLogo}
                              setFaildFile={setFaildFileLogo}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="partner-info__row">
                        <div className="item" id="name">
                          <Form.Item
                            className="input-wrapper"
                            name="name"
                            rules={yupSync('name', validationSchema, true)}
                          >
                            <CustomInput
                              name="Lender"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter Lender Name`}
                              onBlur={() => validateFormOnBlur('name')}
                              onChange={(e) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  name: e.target.value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  name: false,
                                }));
                              }}
                              hasError={() => false}
                              value={data.name}
                              required
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="partner-info__row">
                        <div className="item" id="participation_bonus_percent">
                          <Form.Item
                            className="input-wrapper"
                            name="participation_bonus_percent"
                            rules={yupSync(
                              'participation_bonus_percent',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomInput
                              name="Participation Bonus"
                              className="input"
                              maxLength={5}
                              placeholder={`Enter Participation Bonus`}
                              suffix={<div className="procent">%</div>}
                              onBlur={() =>
                                validateFormOnBlur(
                                  'participation_bonus_percent'
                                )
                              }
                              onInput={handleFeeInput}
                              onChange={(e) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  participation_bonus_percent: e.target.value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  participation_bonus_percent: false,
                                }));
                              }}
                              hasError={() => false}
                              value={data.name}
                              required
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="distribution">
                          <Form.Item
                            className="input-wrapper"
                            name="fund_distribution_model"
                            rules={yupSync(
                              'distribution',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomSelect
                              name="Lending Funds Distribution"
                              customLabel={distributionLabel}
                              options={[
                                { label: 1, value: 'Model 1' },
                                { label: 2, value: 'Model 2' },
                              ]}
                              placeholder="Select Lending Funds Distribution Model"
                              className="input input-white-small"
                              onChange={(value) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  fund_distribution_model: value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  fund_distribution_model: false,
                                }));
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="partner-info__row">
                        <Form.Item className="input-wrapper" name="states">
                          <MultiSelect
                            isSearchSelect={false}
                            allowClear={false}
                            value={data?.states}
                            name="Available Locations"
                            searchValue={searchMultiState}
                            className="input input-white-small"
                            placeholder="All"
                            options={statesOptions?.map((elem: any) => {
                              return { value: elem.name, label: elem.id };
                            })}
                            onChange={setStates}
                            onSearch={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchMultiState(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="members__subtitle with-border">
                        Contact Info{' '}
                      </div>
                      <div
                        className="partner-info__row"
                        style={{ margin: '5px 0 0 0' }}
                      >
                        <div className="item" id="first_name">
                          <Form.Item
                            className="input-wrapper"
                            name="first_name"
                            rules={yupSync(
                              'first_name',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomInput
                              name="First Name"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter Partner’s First Name`}
                              onBlur={() => validateFormOnBlur('first_name')}
                              validation={'noNumbers'}
                              required
                              hasError={() => false}
                              onChange={(e) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  first_name: e.target.value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  first_name: false,
                                }));
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="last_name">
                          <Form.Item
                            className="input-wrapper"
                            name="last_name"
                            rules={yupSync('last_name', validationSchema, true)}
                          >
                            <CustomInput
                              name="Last Name"
                              className="input"
                              maxLength={100}
                              hasError={() => false}
                              onKeyPress={handleKeyPress}
                              placeholder={`Enter Partner’s Last Name`}
                              onBlur={() => validateFormOnBlur('last_name')}
                              validation={'noNumbers'}
                              required
                              onChange={(e) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  last_name: e.target.value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  last_name: false,
                                }));
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div
                        className="partner-info__row"
                        style={{ margin: '5px 0 0 0' }}
                      >
                        <div className="item" id="contact_number">
                          <Form.Item
                            className="input-wrapper"
                            name="contact_number"
                            rules={yupSync(
                              'contact_number',
                              validationSchema,
                              true
                            )}
                          >
                            <PhoneNumberInput
                              mask="(099) 999 9999"
                              name="Contact Number"
                              className="input"
                              value={undefined}
                              onBlur={() =>
                                validateFormOnBlur('contact_number')
                              }
                              placeholder={`Enter Partner’s Contact Number`}
                              hasError={() => false}
                              required
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  contact_number: e.target.value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  contact_number: false,
                                }));
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="contact_email">
                          <Form.Item
                            className="input-wrapper"
                            name="contact_email"
                            rules={yupSync(
                              'contact_email',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomInput
                              name="Contact Email"
                              className="input"
                              maxLength={240}
                              onKeyPress={handleKeyPress}
                              placeholder={`Enter Partner’s Contact Email`}
                              onBlur={() => validateFormOnBlur('contact_email')}
                              error={emailError ? 'err' : ''}
                              required
                              onChange={(e) => {
                                setData((prev: any) => ({
                                  ...prev,
                                  contact_email: e.target.value,
                                }));
                                setErrors((prev: any) => ({
                                  ...prev,
                                  contact_email: false,
                                }));
                                setEmailError(false);
                              }}
                              hasError={() => false}
                            />
                          </Form.Item>
                          {emailError && (
                            <div
                              className="error-text"
                              style={{
                                margin: '-20px 0px 0px 4px',
                                height: '32px',
                              }}
                            >
                              A user with such Email already exists
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="partner-info__row"
                        style={{ margin: '5px 0 25px 0' }}
                      >
                        <Form.Item
                          className="input-wrapper"
                          name="additional_information"
                        >
                          <CustomTextArea
                            name="Additional Information"
                            placeholder="Enter Additional Information"
                            maxLength={400}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setData((prev: any) => ({
                                ...prev,
                                additional_information: e.target.value,
                              }));
                            }}
                          />
                        </Form.Item>
                      </div>

                      <div className="members__subtitle with-border">
                        Title Recepient Info
                      </div>
                      <div className="partner-info__row" id="bank_name">
                        <div className="item" id="legal_business_name">
                          <Form.Item className="input-wrapper" name="address">
                            <CustomInput
                              name="Address"
                              className="input"
                              maxLength={255}
                              onBlur={() => validateFormOnBlur('address')}
                              placeholder={`Enter Address`}
                              onChange={(e) =>
                                setData((prev: any) => ({
                                  ...prev,
                                  address: e.target.value,
                                }))
                              }
                              value={data.address}
                            />
                          </Form.Item>
                        </div>{' '}
                        <div className="item" id="city">
                          <Form.Item
                            className="input-wrapper"
                            name="city"
                            rules={yupSync('city', validationSchema, true)}
                          >
                            <CustomInput
                              name="City"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter City`}
                              validation="noNumbers"
                              onChange={(e) =>
                                setData((prev: any) => ({
                                  ...prev,
                                  city: e.target.value,
                                }))
                              }
                              value={data.city}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="partner-info__row">
                        <div className="item" id="legal_business_name">
                          <Form.Item className="input-wrapper" name="state">
                            <CustomSelect
                              name="State"
                              className="input"
                              placeholder="Enter Company Location State"
                              searchValue={searchState}
                              options={statesData?.data?.map(
                                (elem: StatesItem) => {
                                  return { value: elem.name, label: elem.id };
                                }
                              )}
                              onSearch={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setSearchState(e.target.value)}
                              isSearchSelect={true}
                              onChange={(value) =>
                                setData((prev: any) => ({
                                  ...prev,
                                  state: value,
                                }))
                              }
                              value={data.state}
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="accounting_number">
                          <Form.Item
                            className="input-wrapper"
                            name="zip_code"
                            rules={yupSync('zip_code', validationSchema, true)}
                          >
                            <PhoneNumberInput
                              mask="99999"
                              name="ZIP Code"
                              className="input"
                              onBlur={() => validateFormOnBlur('zip_code')}
                              placeholder={`Enter Company ZIP Code`}
                              hasError={() => false}
                              onChange={(e: any) =>
                                setData((prev: any) => ({
                                  ...prev,
                                  zip_code: e.target.value,
                                }))
                              }
                              value={data.zip_code}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="partner-info__row">
                        <div className="item" id="addressee">
                          <Form.Item
                            className="input-wrapper"
                            name="addressee"
                            rules={yupSync('addressee', validationSchema, true)}
                          >
                            <CustomInput
                              name="Addressee"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter Addressee`}
                              onBlur={() => validateFormOnBlur('addressee')}
                              validation={'noNumbers'}
                              onChange={(e: any) =>
                                setData((prev: any) => ({
                                  ...prev,
                                  addressee: e.target.value,
                                }))
                              }
                              value={data.addressee}
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="email">
                          <Form.Item
                            className="input-wrapper"
                            name="email"
                            rules={yupSync('email', validationSchema, true)}
                          >
                            <CustomInput
                              name="Email"
                              className="input"
                              maxLength={240}
                              onKeyPress={handleKeyPress}
                              placeholder={`Enter Addresse Contact Email`}
                              onChange={(e: any) =>
                                setData((prev: any) => ({
                                  ...prev,
                                  email: e.target.value,
                                }))
                              }
                              value={data.email}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === 'Installment' && (
                    <LoanTypeTab
                      id={1}
                      setLoanData={setLoanData}
                      loanData={loanData}
                      setError={setInstallmentError}
                      errors={InstallmentDataError}
                      setErrorData={setInstallmentDataError}
                      setIsDirty={setIsDirty}
                    />
                  )}
                  {tab === 'Revolving line' && (
                    <LoanTypeTab
                      id={2}
                      setLoanData={setLoanData}
                      loanData={loanData}
                      setError={setRevolvingLineError}
                      errors={RevolvingLineDataError}
                      setErrorData={setRevolvingLineDataError}
                      setIsDirty={setIsDirty}
                    />
                  )}
                  {tab === 'Lease-to-own' && (
                    <LoanTypeTab
                      id={3}
                      setLoanData={setLoanData}
                      loanData={loanData}
                      setError={setLeaseToOwnLineError}
                      errors={LeaseToOwnLineDataError}
                      setErrorData={setLeaseToOwnLineDataError}
                      setIsDirty={setIsDirty}
                    />
                  )}
                  {tab === 'Promotional' && (
                    <LoanTypeTab
                      id={4}
                      setLoanData={setLoanData}
                      loanData={loanData}
                      setError={setPromotionalError}
                      errors={PromotionalDataError}
                      setErrorData={setPromotionalDataError}
                      setIsDirty={setIsDirty}
                    />
                  )}
                  {tab === 'Credit Card Account' && (
                    <LoanTypeTab
                      id={5}
                      setLoanData={setLoanData}
                      loanData={loanData}
                      setError={setCreditCardAccountError}
                      errors={CreditCardAccountDataError}
                      setErrorData={setCreditCardAccountDataError}
                      setIsDirty={setIsDirty}
                    />
                  )}
                </div>
              </div>

              <div className="main__btns">
                <Button
                  className="gray-btn"
                  style={{ margin: '0 16px 0 0' }}
                  onClick={() => {
                    setClickOnSave(true);
                    if (location.pathname?.includes('edit')) {
                      if (isDirty) {
                        setConfirmationModal(true);
                      } else {
                        navigate('/lenders');
                      }
                    } else {
                      if (form.isFieldsTouched()) {
                        setConfirmationModal(true);
                      } else {
                        navigate('/lenders');
                      }
                    }
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="blue-btn"
                  htmlType="submit"
                  onClick={() => setClickOnSave(true)}
                >
                  {location.pathname?.includes('edit')
                    ? 'Save Changes'
                    : 'Create'}
                </Button>
              </div>
            </div>{' '}
          </Form>
        </div>
      </div>

      {confirmationModal && (
        <ConfirmationModal
          openModal={confirmationModal}
          setOpenModal={setConfirmationModal}
          type="warning"
          title={'Are you sure you want to discard the Lenders Editing?'}
          cancelText="No"
          confirmText="Yes"
          cancel={() => {
            setClickOnSave(false);
            setConfirmationModal(false);
          }}
          confirm={() => {
            if (isClickOnHome) {
              navigate('/dashboard');
            } else {
              navigate('/lenders');
            }
          }}
          closeIcon={true}
        />
      )}

      {addLoanModal && (
        <AddLoanModal
          openModal={addLoanModal}
          setOpenModal={setAddLoanModal}
          setSelectLoanTypes={setSelectLoanTypes}
          selectLoanTypes={selectLoanTypes}
          loanTypes={loanTypes}
          setLoanData={setLoanData}
        />
      )}
      {confirmationDeleteModal && (
        <ConfirmationModal
          openModal={confirmationDeleteModal}
          setOpenModal={setConfirmationDeleteModal}
          type="warning"
          title={`Are you sure you want to delete ${
            loanTypes?.data.find((elem: LoanType) => elem.id === deleteIdLoan)
              ?.name
          } loan type?`}
          cancelText="No"
          confirmText="Yes"
          cancel={() => setConfirmationDeleteModal(false)}
          confirm={() => {
            setClickOnSave(true);
            const newData = [...loanData];
            const index = newData.findIndex(
              (elem: LoanProps) => elem.loan_type_id === deleteIdLoan + ''
            );

            if (index !== -1) {
              newData.splice(index, 1);
              setLoanData(newData);

              const newArray = [...selectLoanTypes];
              const res = newArray.filter((elem: any) => elem !== deleteIdLoan);
              setSelectLoanTypes(res);

              if (deleteIdLoan === 1) {
                setInstallmentError(false);
              }
              if (deleteIdLoan === 2) {
                setRevolvingLineError(false);
              }
              if (deleteIdLoan === 3) {
                setLeaseToOwnLineError(false);
              }
              if (deleteIdLoan === 4) {
                setPromotionalError(false);
              }
            }
            setTab('general');
            setConfirmationDeleteModal(false);
          }}
          closeIcon={true}
        />
      )}
    </>
  );
};
