import "./index.scss"
import lightning from "../../../assets/lightning-blue-icon.svg"
import closeIcon from "../../../assets/x-close-new.svg"
import {AnyAction} from "@reduxjs/toolkit";
import {setOpenNotificationModal} from "../../../reducers/tabs/tabsSlice";
import {useDispatch} from "react-redux";

export const NotificationModal = () => {
    const dispatch = useDispatch()
    const setOpenNotification = (payload: boolean): AnyAction => dispatch(setOpenNotificationModal(payload))

    return (
        <div className="notification__container">
            <div className="mr-8">
            <img src={lightning} alt="lightning"/>
            </div>
            <div>
                <div className="notification__title">
                    Here is a new application
                </div>
                <div className="notification__text">
                    Please adjust financing amount and product to rerun the application
                </div>
            </div>
            <div
                className="cursor-pointer"
                onClick={() => {
                    setOpenNotification(false)
                }}
            >
                <img src={closeIcon} alt="close"/>
            </div>
        </div>
    )
}