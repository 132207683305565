import { Button, Modal } from 'antd';
import React, { FC, useEffect } from 'react';
import './index.scss';
import { useLazyGetTicketAdminsQuery } from '../../api/tickets';
import { CustomSelect } from '../../customFields/CustomSelect';
import { useAppSelector } from '../../hooks';
import { UserI } from '../../reducers/profile/profileSlice';
import { ADMIN, SUPER_ADMIN } from '../../helpers/variables';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirm?: () => void;
  maskClosable?: boolean;
  className?: string;
  assignee: string | number | undefined;
  setAssignee: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  changeAssignee: () => void;
  loading: boolean;
}

export const ChangeTicketAssigneeModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  maskClosable = true,
  className,
  assignee,
  setAssignee,
  changeAssignee,
  loading,
}) => {
  const user = useAppSelector((state: { profile: UserI }) => state.profile);

  const [getAdmins, { data: adminsData }] = useLazyGetTicketAdminsQuery();
  const adminsOptions = adminsData?.data.map((item: any) => {
    return { label: item.id, value: `${item.first_name} ${item.last_name}` };
  });

  function swapLabelWithValue(option: any) {
    return {
      label: option?.value,
      value: option?.label,
    };
  }

  useEffect(() => {
    if (user.role.key === SUPER_ADMIN || user.role.key === ADMIN) {
      getAdmins({
        per_page: 'all',
        search: '',
        order_by: '',
        order: 'desc',
        status_key: '',
        page: 1,
      });
    }
  }, [user.role.key]);

  return (
    <Modal
      destroyOnClose
      className={`confirmation-modal ${className}`}
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={<></>}
      closeIcon={<></>}
      width={400}
      maskClosable={maskClosable}
    >
      <div className="confirmation-modal__container">
        <div className="confirmation-modal__title">Change assignee</div>
        <div className="confirmation-modal__subtitle">
          Choose an employee to assign this ticket to
        </div>
        <div className="w-100 mb-16">
          <CustomSelect
            name="Assignee"
            options={adminsOptions || []}
            placeholder="Please choose assignee"
            className="input-white-small"
            onChange={(value) => {
              setAssignee(value);
            }}
            //@ts-ignore
            defaultValue={
              assignee === undefined
                ? null
                : swapLabelWithValue(
                    adminsOptions?.find((item: any) => {
                      return !!assignee && item.label === +assignee;
                    })
                  )
            }
          />
        </div>
        <div className="confirmation-modal__btns">
          <Button
            className="gray-btn-lg"
            style={{ marginRight: '12px' }}
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={'blue-btn-lg'}
            onClick={changeAssignee}
            disabled={!assignee || loading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
