import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { useEffect, useState } from 'react';
import edit from '../../../assets/edit.svg';
import moment from 'moment';
import { Button, message } from 'antd';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  useDeleteAdminMutation,
  useLazyGetAdminQuery,
  useResendInvitationMutation,
} from '../../../api/admins';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import './index.scss';
import { setMessage } from '../../../reducers/common/commonSlice';
import { UserI } from '../../../reducers/profile/profileSlice';
import { useLazyGetProfileInfoQuery } from '../../../api/profile';
import { isApiError } from '../../../utils/general';

interface ViewAdminProps {
  isProfile?: boolean;
}

export const ViewAdmin = ({ isProfile }: ViewAdminProps) => {
  const location = useLocation();
  const [
    getAdmin,
    {
      data: adminInfo,
      isLoading: isAdminInfoLoading,
      isError: isGetAdminError,
    },
  ] = useLazyGetAdminQuery();
  const [
    getProfileInfo,
    {
      data: profileInfo,
      isLoading: isProfileInfoLoading,
      isError: isGetProfileInfoError,
    },
  ] = useLazyGetProfileInfoQuery();
  const [resetInvitation, { isSuccess: isSuccessResetInv }] =
    useResendInvitationMutation();
  const navigate = useNavigate();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [messageApi, contextHolder] = message.useMessage();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [deleteAdmin] = useDeleteAdminMutation();
  const dispatch = useAppDispatch();
  const responseData = isProfile
    ? profileInfo?.data?.resource
    : adminInfo?.data;
  useEffect(() => {
    if (isGetAdminError) {
      errorMessage('Fetch admin failed');
    }
    if (isGetProfileInfoError) {
      errorMessage('Fetch profile info failed');
    }
  }, [isGetAdminError, isGetProfileInfoError]);

  useEffect(() => {
    if (!user.email) return;
    if (!isProfile && user.role.key !== 'super_admin') {
      navigate('/403');
    }
  }, [isProfile, user.email]);

  const successMessage = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const deleteAdminFunc = async () => {
    const id: string = location.pathname.split('/')[2];
    try {
      await deleteAdmin(id).unwrap();
      dispatch(setMessage('The user has been successfully deleted'));
      navigate('/admins');
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Delete failed');
    }
  };

  useEffect(() => {
    if (isSuccessResetInv) {
      successMessage('Link resent successfully');
    }
  }, [isSuccessResetInv]);

  useEffect(() => {
    if (!location) return;
    if (isProfile) {
      getProfileInfo();
    } else {
      const id: string = location.pathname.split('/')[2];
      if (!id) return;
      getAdmin(id);
    }
  }, [location, isProfile]);

  return (
    <div className="container">
      {contextHolder}
      {/* {(isAdminInfoLoading || isProfileInfoLoading) && <Spinner size="large" />} */}
      <div className="new-member">
        {!isProfile && (
          <div className="new-member__breadscrumbs breadscrumbs">
            <div
              className="breadscrumbs__title"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              <HomeIcon fill="" />
            </div>
            <div className="breadscrumbs__separatop">
              <ArrowIcon fill="" />
            </div>
            <div
              className="breadscrumbs__title"
              onClick={() => {
                navigate('/admins');
              }}
            >
              Admins
            </div>
            <div className="breadscrumbs__separatop">
              <ArrowIcon fill="#4D4D56" />
            </div>
            <div className="breadscrumbs__title breadscrumbs__title-active">
              Admin Profile
            </div>
          </div>
        )}
        <div className="view-member">
          <div className="view-member__block block block__admin">
            <div className="block__logo-wrapper">
              <div className="block__logo-info">
                <div className="block__name">
                  {responseData?.first_name} {responseData?.last_name}
                </div>
                <div className="block__id">
                  ID#{' '}
                  <span className="block__id-blue">
                    {responseData?.item_id}
                  </span>
                </div>
              </div>
            </div>
            <div className="block__title">Contact info</div>
            <div className="block__row">
              <div className="block__item">
                <div className="block__item-title">Contact Number</div>
                <div className="block__item-value">
                  {responseData?.contact_number}
                </div>
              </div>
              <div className="block__item">
                <div className="block__item-title">Contact Email</div>
                <div className="block__item-value">
                  {responseData?.contact_email}
                </div>
              </div>
            </div>
          </div>
          <div className="view-member__block block">
            <div className="block__title" style={{ height: '30px' }}></div>
            <div className="block__edit-wrapper">
              <img
                src={edit}
                className="block__edit"
                onClick={() =>
                  isProfile
                    ? navigate(
                        `/profile/edit/${profileInfo?.data?.resource?.id}`
                      )
                    : navigate(`/admins/edit/${adminInfo?.data?.id}`)
                }
                alt="edit icon"
              />
            </div>
            <div className="block__common-info" style={{ border: 'none' }}>
              {!isProfile && (
                <div className="block__item-row">
                  <div className="block__item-row-title">Status:</div>
                  <div className="block__item-row-value">
                    <span
                      style={
                        responseData?.status === 'Active'
                          ? { color: '#017130' }
                          : responseData?.status === 'Pending'
                          ? { color: '#7C24C2' }
                          : responseData?.status === 'Paused'
                          ? { color: '#A80E1C' }
                          : {}
                      }
                    >
                      {responseData?.status}
                    </span>
                  </div>
                </div>
              )}

              <div className="block__item-row">
                <div className="block__item-row-title">Onboarding Date:</div>
                <div className="block__item-row-value">
                  {moment(responseData?.created_at).format('MMMM DD, YYYY')}
                </div>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {!isProfile && adminInfo?.data?.status === 'Active' && (
                <Button
                  style={{ marginLeft: '24px' }}
                  className="red-btn block__pause-btn"
                  onClick={() => setConfirmDeleteModal(true)}
                >
                  Delete
                </Button>
              )}

              {!isProfile && adminInfo?.data?.status === 'Pending' && (
                <>
                  <Button
                    className="violet-btn block__pause-btn"
                    onClick={() => resetInvitation(adminInfo?.data?.id + '')}
                  >
                    Resend link
                  </Button>
                  <Button
                    style={{ marginLeft: '24px' }}
                    className="red-btn block__pause-btn"
                    onClick={() => setConfirmDeleteModal(true)}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {confirmDeleteModal && (
        <ConfirmationModal
          openModal={confirmDeleteModal}
          setOpenModal={setConfirmDeleteModal}
          type="warning"
          title="Are you sure you want to delete the Admin?"
          subtitle={``}
          cancelText="No"
          confirmText="Yes"
          confirm={() => {
            deleteAdminFunc();
          }}
          cancel={() => setConfirmDeleteModal(false)}
          closeIcon={false}
          maskClosable={false}
        />
      )}
    </div>
  );
};
