import { SyntheticEvent } from 'react';

interface InfoIconProps {
  fill: string;
  width?: string;
  height?: string;
  cursor?: string;
  onClick?: (e: SyntheticEvent) => void;
}

export const PlusIcon = ({ fill, width, height, cursor }: InfoIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      cursor={cursor}
    >
      <path
        d="M16 10H10M10 10H4M10 10V4M10 10V16"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
