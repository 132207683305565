import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./general";
import { IProfileRes } from "./types/profile";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getProfileInfo: builder.query<IProfileRes, void>({
      query: () => ({
        url: "/my-profile",
        method: "GET",
      }),
      providesTags: ['User'],
    }),
    editProfileInfo: builder.mutation({
      query: (data) => ({
        url: `/my-profile`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ['User'],
    }),
    updateMyProfileInfo: builder.mutation({
      query: (data) => ({
        url: `/my-profile/info`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    changeCodeRequired: builder.mutation({
      query: (data) => ({
        url: `/my-profile/code_required`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/my-profile/password`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetProfileInfoQuery,
  useEditProfileInfoMutation ,
    useChangePasswordMutation,
    useUpdateMyProfileInfoMutation,
    useChangeCodeRequiredMutation,
} = profileApi;
