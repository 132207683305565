import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import {
  IOfferRes,
  ISelectOfferReq,
  ISelectOfferRes,
  ISendOfferTypeReq,
  ISendOfferTypeRes,
  ISendWithDrawReq,
} from './types/offer';

export const offersApi = createApi({
  reducerPath: 'offersApi',
  baseQuery,
  endpoints: (builder) => ({
    getOffers: builder.query<IOfferRes, number>({
      query: (id: number) => ({
        url: `partners/applicants/${id}/offers`,
        method: 'GET',
      }),
    }),
    selectOffer: builder.mutation<ISelectOfferRes, ISelectOfferReq>({
      query: (data) => {
        return {
          url: `partners/applicants/${data.applicant_id}/offers`,
          method: 'POST',
          body: data.offer,
        };
      },
    }),
    sendOffersByType: builder.mutation<ISendOfferTypeRes, ISendOfferTypeReq>({
      query: (data) => {
        return {
          url: `partners/applicants/${data.applicant_id}/offers/send-offers`,
          method: 'PUT',
          body: data.type,
        };
      },
    }),
    finalizeApplication: builder.mutation<ISendOfferTypeRes, ISendOfferTypeReq>(
      {
        query: (data) => {
          return {
            url: `partners/applicants/${data.applicant_id}/offers/finalize`,
            method: 'POST',
            body: data.type,
          };
        },
      }
    ),
    sendWithDraw: builder.mutation<void, ISendWithDrawReq>({
      query: (data) => {
        return {
          url: `partners/applicants/${data.applicant_id}/offers/withdrawn`,
          method: 'POST',
          body: {},
        };
      },
    }),
  }),
});

export const {
  useLazyGetOffersQuery,
  useGetOffersQuery,
  useSelectOfferMutation,
  useSendOffersByTypeMutation,
  useSendWithDrawMutation,
  useFinalizeApplicationMutation,
} = offersApi;
