import { Button, Form, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import close from '../../../assets/x-close.svg';
import check from '../../../assets/CheckGreen.svg';
import alert from '../../../assets/alert-circle-small.svg';
import './index.scss';
import { CustomInput } from '../../../customFields/CustomInput';
import { CustomSelect } from '../../../customFields/CustomSelect';
import { CodeInput } from './CodeInput';
import { useAppSelector } from '../../../hooks';
import { useSendVarificationCodeMutation } from '../../../api/applicants';
import { useNewCustomerPurchaseMutation } from '../../../api/customers';
import { useParams } from 'react-router-dom';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessPurchase: React.Dispatch<React.SetStateAction<boolean | string>>;
  className?: string;
  phone: string | undefined;
  email: string | undefined;
  availableAmount: number;
}

interface FormData {
  amount: string;
  product: string;
}

export const SubsequentPurchaseModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  setSuccessPurchase,
  className,
  phone,
  email,
  availableAmount,
}) => {
  const { id } = useParams();
  const [form] = Form.useForm<FormData>();
  const user = useAppSelector((state) => state.profile);
  const [
    newPurchase,
    {
      data: purchaseData,
      isError: isErrorPurchase,
      isLoading: isLoadingPurchase,
      isSuccess: isPurchaseSuccess,
      reset: resetPurchase,
    },
  ] = useNewCustomerPurchaseMutation();
  const [sendByEmail, setSendByEmail] = useState(false);
  const isVerifyError = isErrorPurchase;
  const isVerifyLoading = isLoadingPurchase;
  const isVerifySuccess = isPurchaseSuccess;
  const products = user?.model.loan_products?.map((item) => ({
    label: item.id,
    value: item.name,
  }));
  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  const handleClose = () => {
    form.resetFields();
    resetPurchase();
    setSendByEmail(false);
    setOpenModal(false);
  };

  const currencyMaskAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/^0/, '');
    value = value.replace(/[^\d.]/g, '');
    value = value.replace(/\.{2,}/g, '.');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = value;
    const number = value.replace(/,/g, '');
    if (+number > 300000) {
      e.target.value = '300,000';
    } else {
      const parts = number?.split('.');
      if (parts.length === 2 && parts[1].length > 2) {
        e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }
    return e.target.value;
  };

  const handlePurchase = () => {
    resetPurchase();
    const data: any = {
      id,
      payload: {
        type: form.getFieldValue('type'),
        amount: parseFloat(form.getFieldValue('amount')?.split(',').join('')),
        loan_product_id: form.getFieldValue('product'),
      },
    };
    if (sendByEmail) {
      data.payload.email = email;
      newPurchase(data);
    } else {
      data.payload.phone = phone;
      newPurchase(data);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        handlePurchase();
      })
      .catch((e) => {});
  };

  useEffect(() => {
    if (isVerifySuccess) {
      setSuccessPurchase(purchaseData?.item_id);
      handleClose();
    }
  }, [isVerifySuccess]);

  return (
    <Modal
      destroyOnClose={true}
      className={`purchase-modal ${className}`}
      centered
      open={openModal}
      onCancel={handleClose}
      footer={<></>}
      width={600}
      closeIcon={<img src={close} alt="close" />}
      maskClosable={!isVerifyLoading}
    >
      <p className="purchase-modal__title">Make Purchase</p>
      <Form form={form}>
        <Form.Item
          className="input-wrapper"
          name="product"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <CustomSelect
            name="Select  Product"
            options={products || []}
            placeholder="Select  Product"
            className="input input-white-small"
            onChange={() => {}}
            disabled={isVerifyLoading}
          />
        </Form.Item>
        <Form.Item
          className="input-wrapper"
          name="amount"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
            () => ({
              validator(_, value) {
                if (parseFloat(value?.split(',').join('')) > availableAmount) {
                  return Promise.reject(
                    'Purchase amount cannot exceed available balance'
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <CustomInput
            name="Enter Desired Amount"
            className="input"
            placeholder="00,000.00"
            maxLength={256}
            prefix={<div className="procent">$</div>}
            onBlur={() => validateFormOnBlur('amount')}
            onInput={currencyMaskAmount}
            onChange={() => {}}
            hasError={() => false}
            disabled={isVerifyLoading}
          />
        </Form.Item>
        <Form.Item
          className="input-wrapper"
          name="type"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <CustomSelect
            name="Send request to applicant by"
            options={[
              {
                label: 'email',
                value: 'Email',
              },
              {
                label: 'phone',
                value: 'Phone',
              },
            ]}
            placeholder="Please select"
            className="input input-white-small"
            onChange={() => {}}
            disabled={isVerifyLoading}
          />
        </Form.Item>
      </Form>

      <div className="purchase-modal__btns">
        <Button className="gray-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="blue-btn"
          disabled={isVerifyLoading}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
};
