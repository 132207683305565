import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLazyGetApplicationQuery } from '../../../api/applicants';
import { Spinner } from '../../../components/Spinner/Spinner';
import { CreateApplications } from '../CreateApplications/CreateApplications';
import { ViewApplication } from '../ViewApplication/ViewApplication';
import { message } from 'antd';

export const ApplicationContainer = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const [getApplication, { data, isLoading, isError, error }] =
    useLazyGetApplicationQuery();
  const [trigger, setTrigger] = useState(false);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const renewApplication = () => {
    getApplication(location.pathname.split('/')[3]);
  };

  useEffect(() => {
    if (isError) {
      errorMessage('Fetch applications failed');
    }
  }, [isError]);

  const isDraftStatus = data?.data.application_status === 'Draft';

  useEffect(() => {
    if (isError) {
      //@ts-ignore
      if (error?.status === 404) {
        navigate('/404');
      }
    }
  }, [isError]);

  useEffect(() => {
    if (!location) return;
    if (location.pathname.includes('edit')) {
      getApplication(location.pathname.split('/')[3]);
    }
  }, [location, trigger]);

  if (isLoading) {
    // return <Spinner size="large" />;
  }

  return (
    <>
      {contextHolder}
      {isDraftStatus ? (
        <CreateApplications ApplicationData={data} />
      ) : (
        <ViewApplication
          applicationData={data}
          renewApplication={renewApplication}
          setTrigger={setTrigger}
          trigger={trigger}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
