// import {
//   fetchBaseQuery,
//   BaseQueryFn,
//   FetchArgs,
//   FetchBaseQueryError,
// } from '@reduxjs/toolkit/query/react';
// import { stringify } from 'qs';
// import { logout } from '../reducers/auth/authSlice';

// const baseUrl = process.env.REACT_APP_BASE_URL;

// const _baseQuery = fetchBaseQuery({
//   baseUrl,
//   prepareHeaders: (headers, { getState }) => {
//     if (localStorage.getItem('token')) {
//       headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
//     }
//     headers.set('Accept', 'application/json');
//     return headers;
//   },
//   paramsSerializer: (params) => {
//     return stringify(params, {
//       indices: false,
//       arrayFormat: 'brackets',
//       encode: false,
//     });
//   },
// });

// export const baseQuery: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   let result = await _baseQuery(args, api, extraOptions);

//   if (result.error && result.error.status === 401) {
//     localStorage.removeItem('token');
//     window.location.replace(`/login`)
//   }

//   if (result.error && result.error.status === 403) {
//     window.location.replace(`/403`)
//   }

//   if (result.error && result.error.status === 404) {
//     window.location.replace(`/404`)
//   }

//   if (result.error && result.error.status === 500) {
//     window.location.replace(`/500`)
//   }

//   return result;
// };

import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { stringify } from 'qs';
import Cookies from 'js-cookie';
import { logout } from '../reducers/auth/authSlice';

const baseUrl = process.env.REACT_APP_BASE_URL;

const _baseQuery = fetchBaseQuery({
  credentials: 'include',
  baseUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    const csrfToken = Cookies.get('XSRF-TOKEN');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (csrfToken) {
      headers.set('X-XSRF-TOKEN', csrfToken);
    }

    headers.set('Accept', 'application/json');
    return headers;
  },
  paramsSerializer: (params) => {
    return stringify(params, {
      indices: false,
      arrayFormat: 'brackets',
      encode: false,
    });
  },
});

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await _baseQuery(args, api, extraOptions);

  if (result.error) {
    switch (result.error.status) {
      case 401:
        localStorage.removeItem('token');
        window.location.replace(`/login`);
        break;
      case 403:
        window.location.replace(`/403`);
        break;
      case 404:
        window.location.replace(`/404`);
        break;
      case 500:
        window.location.replace(`/500`);
        break;
      default:
        break;
    }
  }

  return result;
};
