import logo from '../../assets/logo.svg';
import attention from '../../assets/attention.svg';
import { ChangeEvent, useRef, useState, FocusEvent, useEffect } from 'react';
import './index.scss';
import { Button } from 'antd';
import { useValidateCodeMutation } from '../../api/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '../../components/Spinner/Spinner';
import RectangleErr from '../../assets/RectangleErr.svg';

export const ExpiredLink = ({title}: any) => {
  const navigate = useNavigate();
  return (
    <div className="token-page">
      <img className="token-page__rectangle rectangle" src={RectangleErr} />
      <div className="token-page__logo-container">
        <img src={logo} />
      </div>
      <div className="token-page__code-container">
        <img src={attention} className="token-page__image" />
        <div className="token-page__title expired-title">
            Sorry, the link has expired!
        </div>
        <div className="token-page__subtitle">
            {title ? title : "This link is not valid."}
        </div>
        <Button className="blue-btn" onClick={() => navigate('/login')}>
          Sign In
        </Button>
      </div>
    </div>
  );
};
