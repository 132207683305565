import { Table, message } from 'antd';
import { useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import moment from 'moment';
import { customerItem, TableParamsCustomer } from '../../api/types/customers';
import './index.scss';

interface PartnersTableProps {
  data: any[];
  setTableParams: React.Dispatch<React.SetStateAction<any>>;
  metaData: any;
  tableParams: any;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomerTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  setTrigger,
}: PartnersTableProps) => {
  const ref = useRef(null);

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);

  const sorting = (name: string) => {
    setTableParams((prev: TableParamsCustomer) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? tableParams.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const columns: ColumnsType<customerItem> = [
    {
      title: (
        <div className="header-item">
          Name
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'name' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('name')}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Partner
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'partner' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('partner.company_name')}
          />
        </div>
      ),
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record) => (
        <div className="table-item">
          <div className="table-text">{record.partner.company_name}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Email
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'contact_email' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('contact_email')}
          />
        </div>
      ),
      dataIndex: 'contact_email',
      key: 'contact_email',
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: <div className="header-item">Phone Number</div>,
      dataIndex: 'contact_number',
      key: 'contact_number',
      width: 120,
      render: (text) => (
        <div className="table-item">
          <div className="table-text">{text}</div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item">
          Created
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'created_at' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            onClick={() => sorting('created_at')}
          />
        </div>
      ),
      width: 120,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => (
        <div className="table-item">
          {' '}
          <div className="table-text">{moment(text).format('MM/DD/YYYY')}</div>
        </div>
      ),
    },
  ];

  const customNoDataText = {
    emptyText: (
      <>
        {data && data?.length === 0 && (
          <>
            {!tableParams.dates.start && !tableParams.search && (
              <div className="no-data-found">
                <img src={NoUser} />
                <div className="no-data-found__text">No customers yet</div>
                {user.role.key === 'partner' && (
                  <div className="no-data-found__subtext">
                    Add new customer to start work with them
                  </div>
                )}
              </div>
            )}
            {(tableParams.dates.start || tableParams.search) && (
              <div className="no-data-found">
                <img src={noData} />
                <div className="no-data-found__text">No results found</div>
                <div className="no-data-found__subtext">
                  Try adjusting your search to find what you are looking for
                </div>
              </div>
            )}
          </>
        )}
      </>
    ),
  };

  if (user.role.key === 'partner') {
    const roleColumnIndex2 = columns.findIndex(
      (column) => column.key === 'partner'
    );
    if (roleColumnIndex2 !== -1) {
      columns.splice(roleColumnIndex2, 1);
    }
  }

  return (
    <>
      <div>
        <Table
          ref={ref}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={customNoDataText}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => navigate(`/customers/${record.id}`),
            };
          }}
        />
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
