import { Button, Form, FormInstance, Switch } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { FC, useEffect, useState } from 'react';
import { DraggerFile } from '../../customFields/DraggerFile';
import { CustomInput } from '../../customFields/CustomInput';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { CustomTextArea } from '../../customFields/CustomTextArea';
import { CustomSelect } from '../../customFields/CustomSelect';
import { LoanProduct } from '../../customFields/LoanProduct';
import { yupSync } from '../../utils';
import { useGetMainAccountsQuery } from '../../api/common';
import {
  useAddContractMutation,
  useAddLogoMutation,
  useGetStatesQuery,
} from '../../api/partners';
import {
  AccountTypesItem,
  BankTableItem,
  MainAccountItem,
} from '../../api/types/common';
import { LoanProducts, StatesItem } from '../../api/types/partners';
import './index.scss';
import { PhoneNumberInput } from '../../customFields/PhoneNumberInput';
import { useLocation } from 'react-router-dom';
import { MultiSelect } from '../../customFields/MultiSelect';
import useDebounce from '../../hooks/useDebounce';
import { useAppSelector } from '../../hooks';
import { validationSchema } from '../../pages/Partners/CreatePartner/CreatePartner';
import { FormDataPartner } from '../../pages/Partners/CreatePartner/CreatePartner';
import plusicon from '../../assets/plusicon.svg';
import { AvailableBanksTable } from '../Tables/AvailableBanksTable';

interface PartnerInfoFormI {
  setLogo: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setContract: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  logo: UploadFile[];
  contract: UploadFile[];
  form: FormInstance<FormDataPartner>;
  setLogoErr: React.Dispatch<React.SetStateAction<string | null>>;
  setContractErr: React.Dispatch<React.SetStateAction<string | null>>;
  logoErr: string | null;
  contractErr: string | null;
  setContractNumber: React.Dispatch<React.SetStateAction<string>>;
  accountType: string[];
  setAccountType: React.Dispatch<React.SetStateAction<string[]>>;
  setCheckedLoanProducts: React.Dispatch<React.SetStateAction<number[]>>;
  checkedLoanProducts: number[];
  setIdLogo: React.Dispatch<React.SetStateAction<number | null>>;
  setIs5DigitCode: React.Dispatch<React.SetStateAction<boolean>>;
  setIdContract: React.Dispatch<React.SetStateAction<number | null>>;
  setCompanyError: React.Dispatch<React.SetStateAction<boolean>>;
  companyError: boolean;
  setEmailError: React.Dispatch<React.SetStateAction<boolean>>;
  emailError: boolean;
  loanErr: any;
  accountTypesData: AccountTypesItem[] | undefined;
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>;
  logoUrl: string;
  ContractNumber: string;
  id: null | number;
  setLoanErr: React.Dispatch<React.SetStateAction<boolean>>;
  typeOfPartner: string;
  isProfile?: boolean;
  is5digitCode?: boolean;
  banks: BankTableItem[] | undefined;
  setBanks: React.Dispatch<React.SetStateAction<BankTableItem[]>>;
  setEditBank: React.Dispatch<React.SetStateAction<BankTableItem | null>>;
  setAddBankModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMainAccId: React.Dispatch<
    React.SetStateAction<string | number | undefined>
  >;
  setDeleteLogo: React.Dispatch<React.SetStateAction<boolean>>;
  mainAccId: number | string | null | undefined;
}

export const PartnerInfoForm: FC<PartnerInfoFormI> = ({
  setLogo,
  logo,
  setContract,
  contract,
  form,
  setLogoErr,
  logoErr,
  setContractErr,
  contractErr,
  setContractNumber,
  accountType,
  setAccountType,
  setCheckedLoanProducts,
  checkedLoanProducts,
  setIdLogo,
  setIdContract,
  setCompanyError,
  companyError,
  setEmailError,
  emailError,
  loanErr,
  accountTypesData,
  setLogoUrl,
  logoUrl,
  id,
  setLoanErr,
  typeOfPartner,
  isProfile,
  setIs5DigitCode,
  is5digitCode,
  banks,
  setBanks,
  setEditBank,
  setAddBankModal,
  setMainAccId,
  setDeleteLogo,
  mainAccId,
}) => {
  const { handleKeyPress } = usePreventSpaceTyping();
  const [searchMainAcc, setSearchMainAcc] = useState('');
  const debouncedSearchMainAcc = useDebounce(searchMainAcc);
  const [
    addLogo,
    { data: LogoId, isError: isErrorLogo, isLoading: isLoadingLogo },
  ] = useAddLogoMutation();
  const [
    addContract,
    {
      data: ContractId,
      isError: isErrorContract,
      isLoading: isLoadingContract,
    },
  ] = useAddContractMutation();
  const { data: mainAccounts } = useGetMainAccountsQuery({
    search: debouncedSearchMainAcc,
  });
  const [logoHover, setLogoHover] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [faildFileLogo, setFaildFileLogo] = useState<UploadFile>();
  const [faildFileContract, setFaildFileContract] = useState<UploadFile>();
  const location = useLocation();
  const [searchState, setSearchState] = useState('');
  const debouncedSearchState = useDebounce(searchState);
  const { data: statesData } = useGetStatesQuery({
    name: debouncedSearchState,
  });
  const user = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (isErrorLogo) {
      setLogo([]);
      setLogoErr(
        'Invalid format. Please upload a file with supported formats (e.g., PNG,JPG,JPEG)'
      );
    }
    if (isErrorContract) {
      setContract([]);
      setContractErr(
        'Invalid format. Please upload a file with supported formats (e.g., PDF)'
      );
    }
  }, [isErrorLogo, isErrorContract]);

  useEffect(() => {
    if (!LogoId) {
      setIdLogo(null);
    } else {
      setIdLogo(LogoId.data.id);
    }
  }, [LogoId]);

  useEffect(() => {
    if (!ContractId) {
      setIdContract(null);
    } else {
      setIdContract(ContractId.data.id);
    }
  }, [ContractId]);

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  useEffect(() => {
    if (!contract.length) {
      form.resetFields(['contract']);
    } else {
      const formData = new FormData();
      formData.append('model', 'partner/contract');
      contract[0]?.originFileObj &&
        formData.append('field', contract[0]?.originFileObj);
      if (contract[0]?.originFileObj) {
        addContract(formData);
      }
    }
  }, [contract, form, addContract]);

  useEffect(() => {
    if (!logo.length) {
      setLogoUrl('');
      form.resetFields(['logo']);
    } else {
      if (logo[0].originFileObj) {
        const imageUrl = URL.createObjectURL(logo[0].originFileObj);
        setLogoUrl(imageUrl);
      }

      const formData = new FormData();
      formData.append('model', 'partner/logo');
      logo[0].originFileObj && formData.append('field', logo[0].originFileObj);
      if (logo[0].originFileObj) {
        addLogo(formData);
      }
    }
  }, [logo]);

  const ManageLoanProducts = (accountArray: any) => {
    let copyArray = [...checkedLoanProducts];
    accountTypesData?.forEach((elem: any) => {
      const isChecked = accountArray?.includes(elem.id + '');
      if (isChecked) {
        const allLoan = elem.loan_products.map((elem: any) => +elem?.id);

        for (let i = 0; i < allLoan.length; i++) {
          if (copyArray.includes(allLoan[i])) return;
          copyArray.push(allLoan[i]);
        }
      } else {
        const allLoan = elem.loan_products.map((elem: any) => +elem?.id);
        copyArray = copyArray.filter((item) => !allLoan.includes(item));
      }
    });
    setCheckedLoanProducts(copyArray);
  };

  return (
    <div className="partner-info">
      {id && (
        <div className="partner-info__id">
          Partner ID: <span style={{ color: '#67666E' }}>{id}</span>
        </div>
      )}
      {!isProfile && (
        <div
          className="partner-info__row"
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            minHeight: '140px',
            width: '100%',
            gap: '16px',
          }}
          id="logo"
        >
          {!isProfile && (
            <div>
              {logoUrl && (
                <div style={{ position: 'relative' }}>
                  <img
                    src={logoUrl}
                    className="default-avatar"
                    onMouseOver={() => setLogoHover(true)}
                    onMouseLeave={() => setLogoHover(false)}
                    alt="logo"
                  />
                  {logoHover && (
                    <div
                      onMouseOver={() => setLogoHover(true)}
                      onMouseLeave={() => setLogoHover(false)}
                    >
                      <div className="default-avatar__wrapper"></div>
                      <div className="default-avatar__hover">
                        <div
                          className="default-avatar__edit"
                          onClick={() => setIsEdit(true)}
                        >
                          Edit{' '}
                        </div>
                        or
                        <div
                          className="default-avatar__delete"
                          onClick={() => {
                            setLogo([]);
                            setDeleteLogo(true);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <Form.Item name="logo" style={logoUrl ? { opacity: '0' } : {}}>
                <DraggerFile
                  name="logo"
                  allowsFormat={['png', 'jpg', 'jpeg']}
                  onChange={setLogo}
                  fileList={logo}
                  maxSizeBytes={5242880}
                  text="PNG or JPG for Logo"
                  hasError={() => !!form.getFieldError(['logo']).length}
                  setErr={setLogoErr}
                  err={logoErr}
                  isLoading={isLoadingLogo}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  faildFile={faildFileLogo}
                  setFaildFile={setFaildFileLogo}
                />
              </Form.Item>
            </div>
          )}
          {!isProfile && (
            <div id="contract">
              <Form.Item name="contract">
                <DraggerFile
                  name="contract"
                  allowsFormat={['pdf']}
                  onChange={setContract}
                  fileList={contract}
                  maxSizeBytes={20971520}
                  text="PDF of Contract (max. 20MB)"
                  hasError={() => !!form.getFieldError(['contract']).length}
                  setErr={setContractErr}
                  err={contractErr}
                  isLoading={isLoadingContract}
                  faildFile={faildFileContract}
                  setFaildFile={setFaildFileContract}
                />
              </Form.Item>
            </div>
          )}
        </div>
      )}
      {typeOfPartner !== 'external_partner' && (
        <div
          className="switcher-wrapper"
          style={isProfile ? { height: '55px' } : {}}
        >
          <div className="custom-field__title">Require 5-digit code?</div>
          <Switch
            checked={is5digitCode}
            onChange={() => setIs5DigitCode((prev: boolean) => !prev)}
          />
        </div>
      )}

      {!isProfile && (
        <div
          className="partner-info__row partner-info__sub-row"
          style={{
            marginTop: contract || logo ? '48px' : '0px',
          }}
        >
          <div className="item" id="company_name">
            <Form.Item
              className="input-wrapper"
              name="company_name"
              rules={yupSync('company_name', validationSchema, true)}
            >
              <CustomInput
                name="Company Name"
                className="input "
                maxLength={240}
                placeholder={`Enter ${
                  isProfile ? 'Your' : 'Partner’s'
                } Company Name`}
                onBlur={() => validateFormOnBlur('company_name')}
                error={companyError ? 'err' : ''}
                onChange={() => setCompanyError(false)}
              />
            </Form.Item>
            {companyError && (
              <div
                className="error-text"
                style={{ margin: '-20px 0px 0px 4px', height: '32px' }}
              >
                A Partner with such Company Name already exists
              </div>
            )}
          </div>
          {!isProfile && (
            <div className="item" id="website_address">
              <Form.Item
                className="input-wrapper"
                name="website_address"
                rules={yupSync('website_address', validationSchema, true)}
              >
                <CustomInput
                  name="Website address"
                  className="input"
                  maxLength={240}
                  placeholder={`Enter ${
                    isProfile ? 'Your' : 'Partner’s'
                  } Company Website Address`}
                  onBlur={() => validateFormOnBlur('website_address')}
                  onKeyPress={handleKeyPress}
                />
              </Form.Item>
            </div>
          )}
        </div>
      )}
      <div className="partner-info__row" style={{ margin: '15px 0 0 0' }}>
        <div className="item" id="first_name">
          <Form.Item
            className="input-wrapper"
            name="first_name"
            rules={yupSync('first_name', validationSchema, true)}
          >
            <CustomInput
              name="First Name"
              className="input"
              maxLength={100}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } First Name`}
              onBlur={() => validateFormOnBlur('first_name')}
              validation={'noNumbers'}
            />
          </Form.Item>
        </div>
        <div className="item" id="last_name">
          <Form.Item
            className="input-wrapper"
            name="last_name"
            rules={yupSync('last_name', validationSchema, true)}
          >
            <CustomInput
              name="Last Name"
              className="input"
              maxLength={100}
              onKeyPress={handleKeyPress}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } Last Name`}
              onBlur={() => validateFormOnBlur('last_name')}
              validation={'noNumbers'}
            />
          </Form.Item>
        </div>
      </div>
      <div className="partner-info__row partner-info__sub-row">
        <div className="item" id="contact_number">
          <Form.Item
            className="input-wrapper"
            name="contact_number"
            rules={yupSync('contact_number', validationSchema, true)}
          >
            <PhoneNumberInput
              mask="(999) 999 9999"
              name="Contact Number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setContractNumber(e.target.value)
              }
              className="input"
              value={undefined}
              onBlur={() => validateFormOnBlur('contact_number')}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } Contact Number`}
              hasError={() => !!form.getFieldError('contact_number').length}
            />
          </Form.Item>
        </div>
        <div className="item" id="contact_email">
          <Form.Item
            className="input-wrapper"
            name="contact_email"
            rules={yupSync('contact_email', validationSchema, true)}
          >
            <CustomInput
              name="Contact Email"
              className="input"
              maxLength={240}
              onKeyPress={handleKeyPress}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } Contact Email`}
              onBlur={() => validateFormOnBlur('contact_email')}
              onChange={() => setEmailError(false)}
              error={emailError ? 'err' : ''}
            />
          </Form.Item>
          {emailError && (
            <div
              className="error-text"
              style={{ margin: '-20px 0px 0px 4px', height: '32px' }}
            >
              A user with such Email already exists
            </div>
          )}
        </div>
      </div>
      <div className="partner-info__row">
        <div className="item" id="company_address">
          <Form.Item
            className="input-wrapper"
            name="company_address"
            rules={yupSync('company_address', validationSchema, true)}
          >
            <CustomInput
              name="Address"
              className="input"
              maxLength={255}
              onBlur={() => validateFormOnBlur('company_address')}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } Company Address`}
            />
          </Form.Item>
        </div>
        <div className="item" id="city">
          <Form.Item
            className="input-wrapper"
            name="city"
            rules={yupSync('city', validationSchema, true)}
          >
            <CustomInput
              name="City"
              className="input"
              maxLength={100}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } Company Location City`}
              onBlur={() => validateFormOnBlur('city')}
              validation="noNumbers"
            />
          </Form.Item>
        </div>
      </div>
      <div className="partner-info__row">
        <div className="item" id="state">
          <Form.Item
            className="input-wrapper"
            name="state"
            rules={yupSync('state', validationSchema, true)}
          >
            <CustomSelect
              name="State"
              className="input"
              placeholder="Select State"
              options={statesData?.data?.map((elem: StatesItem) => {
                return { value: elem.name, label: elem.id };
              })}
              onChange={() => {}}
              onSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchState(e.target.value)
              }
              isSearchSelect={true}
            />
          </Form.Item>
        </div>
        <div className="item" id="zip_code">
          <Form.Item
            className="input-wrapper"
            name="zip_code"
            rules={yupSync('zip_code', validationSchema, true)}
          >
            <PhoneNumberInput
              mask="99999"
              name="ZIP Code"
              onChange={setContractNumber}
              className="input"
              value={undefined}
              onBlur={() => validateFormOnBlur('zip_code')}
              placeholder={`Enter ${
                isProfile ? 'Your' : 'Partner’s'
              } Company ZIP Code`}
              hasError={() => !!form.getFieldError('zip_code').length}
            />
          </Form.Item>
        </div>
      </div>

      {
        <>
          <div className="partner-info__row" id="account_type_id">
            {(typeOfPartner === 'partner' ||
              typeOfPartner === 'external_partner') &&
              !isProfile &&
              !mainAccId && (
                <div className="item">
                  <Form.Item
                    className="input-wrapper"
                    name="account_types"
                    rules={yupSync('account_types', validationSchema, true)}
                  >
                    <MultiSelect
                      value={accountType}
                      name="Account Type"
                      className="input input-white-small"
                      placeholder="Select Account Type"
                      options={accountTypesData?.map(
                        (elem: AccountTypesItem) => {
                          return { value: elem.name, label: elem.id };
                        }
                      )}
                      onChange={(value) => {
                        setAccountType(value);
                        ManageLoanProducts(value);
                      }}
                      isSearchSelect={false}
                    />
                  </Form.Item>
                </div>
              )}
            <div className="item" id="signing_email">
              <Form.Item
                className="input-wrapper"
                name="signing_email"
                rules={yupSync('signing_email', validationSchema, true)}
              >
                <CustomInput
                  name="Signing Email"
                  className="input"
                  maxLength={240}
                  onKeyPress={handleKeyPress}
                  placeholder={`Enter ${
                    isProfile ? 'Your' : 'Partner’s'
                  } Signing Email`}
                  onBlur={() => validateFormOnBlur('signing_email')}
                  onChange={() => setEmailError(false)}
                  error={emailError ? 'err' : ''}
                />
              </Form.Item>
              {/* {emailError && (
                  <div
                    className="error-text"
                    style={{ margin: '-20px 0px 0px 4px', height: '32px' }}
                  >
                    A user with such Email already exists
                  </div>
                )} */}
            </div>
          </div>
          {!isProfile &&
            !mainAccId &&
            accountType?.map((item: string) => {
              return (
                <div key={item}>
                  <div className="partner-info__row" id="loan_product">
                    <LoanProduct
                      title={
                        <div>
                          Loan Products{' '}
                          <span
                            style={{
                              color: 'var(--gray-400, #808086)',
                              fontSize: '12px',
                            }}
                          >
                            (for{' '}
                            {
                              accountTypesData?.find(
                                (elem: AccountTypesItem) =>
                                  elem.id + '' === item
                              )?.name
                            }
                            )
                          </span>
                        </div>
                      }
                      options={
                        (accountTypesData?.find(
                          (elem: AccountTypesItem) => elem.id + '' === item
                        )?.loan_products || []) as LoanProducts[]
                      }
                      onChange={setCheckedLoanProducts}
                      values={checkedLoanProducts}
                      error={loanErr[item]}
                    />
                  </div>

                  {loanErr[item] && (
                    <div
                      className="error-text"
                      style={{ margin: '0 0 15px 0' }}
                    >
                      Please select at least one product
                    </div>
                  )}
                </div>
              );
            })}
        </>
      }
      {typeOfPartner === 'related_partner' && !isProfile && (
        <>
          <div
            className="partner-info__row"
            style={{ height: '100px' }}
            id="main_account_id"
          >
            <div className="item" style={{ width: '100%' }}>
              <Form.Item
                name="main_account_id"
                rules={yupSync('main_account_id', validationSchema, true)}
              >
                <CustomSelect
                  disabled={
                    location.pathname.includes('edit') ||
                    user.role.key === 'main_account'
                  }
                  value={''}
                  className="input"
                  name="Main Account To Associate With"
                  options={mainAccounts?.data?.map((elem: MainAccountItem) => {
                    return {
                      value: elem.name,
                      label: elem.id,
                      item_id: elem.item_id,
                    };
                  })}
                  placeholder="Select Main Account"
                  onSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchMainAcc(e.target.value)
                  }
                  onChange={(value) => {
                    setMainAccId(value);
                    setBanks([]);
                  }}
                  isSearchSelect={true}
                />
              </Form.Item>
            </div>
          </div>
        </>
      )}
      {!isProfile && (
        <div className="main-account-info__row main-account-info__row__between">
          <div className="bank-table__title">Available Banks</div>
          {accountType.length ? (
            <Button
              className="gray-btn button__plus"
              onClick={() => setAddBankModal(true)}
              disabled={!accountType.length}
            >
              <img src={plusicon} className="button__plus__img" alt="plus" />{' '}
              Add Bank
            </Button>
          ) : (
            <Button
              className="gray-btn button__plus"
              disabled={!accountType.length}
            >
              {location.pathname.includes('new-partner/related_partner')
                ? 'Please select Main Account Type first'
                : 'Please add Account Type first'}
            </Button>
          )}
        </div>
      )}
      {!isProfile && (
        <AvailableBanksTable
          banks={banks}
          setBanks={setBanks}
          setEditBank={setEditBank}
          setAddBankModal={setAddBankModal}
          type="partner"
        />
      )}

      {isProfile ? (
        <></>
      ) : (
        <>
          {(user?.role?.key === 'admin' ||
            user?.role?.key === 'super_admin') && (
            <div className="partner-info__row" style={{ margin: '25px 0 0 0' }}>
              <Form.Item
                className="input-wrapper"
                name="additional_information"
              >
                <CustomTextArea
                  name="Additional Information (optional)"
                  placeholder="Enter Additional Business Information"
                  maxLength={400}
                />
              </Form.Item>
            </div>
          )}
        </>
      )}
    </div>
  );
};
