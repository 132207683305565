import ticket_list_blue from "../../assets/ticket-list-blue.svg";
import ticket_list from "../../assets/ticket-list.svg";
import submit_ticket from "../../assets/submit-ticket.svg";
import submit_ticket_blue from "../../assets/submit-ticker-blue.svg";

import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {AnyAction} from "@reduxjs/toolkit";
import {setOpenSubmitTicket as setOpenModal} from "../../reducers/tabs/tabsSlice";


export const TicketingMenu = (props:any) => {
    const {refTickets, setOpenTickets} = props
    const dispatch = useDispatch()
    const setOpenSubmitTicket = (payload: boolean): AnyAction => dispatch(setOpenModal(payload))
    const [isListHovered, setIsListHovered] = useState(false);
    const [isSubmitHovered, setIsSubmitListHovered] = useState(false);


    const location = useLocation()
    const navigation = useNavigate()
    return (
        <div
            className="ticket-menu__container"
            ref={refTickets}
        >
            <div
                className={`ticket-menu__item ${location.pathname === '/ticket-list' && "color_blue"}`}
                onClick={() => {
                    navigation('/ticket-list')
                    setOpenTickets(false)
                }}
                onMouseEnter={() => {setIsListHovered(true)}}
                onMouseLeave={() => {setIsListHovered(false)}}
            >
                <img
                    className="mr-4"
                    src={(location.pathname === '/ticket-list' || isListHovered)? ticket_list_blue :ticket_list}
                    alt="ticket list"
                />
                All Tickets</div>
            <div className="ticket-menu__item"
                 onClick={() => {
                setOpenSubmitTicket(true)
                setOpenTickets(false)}}
                 onMouseEnter={() => {setIsSubmitListHovered(true)}}
                 onMouseLeave={() => {setIsSubmitListHovered(false)}}
            >
                <img
                    className="mr-4"
                    src={isSubmitHovered ?submit_ticket_blue : submit_ticket}
                    alt="submit ticket"
                />
                Submit Ticket</div>
        </div>
    )
}