import { MessageInstance } from 'antd/lib/message/interface';

export const successMessage = (messageApi: MessageInstance, text: string) => {
  messageApi.open({
    type: 'success',
    content: text,
    style: {
      marginTop: '90vh',
    },
  });
};

export const errorMessage = (messageApi: MessageInstance, text: string) => {
  messageApi.open({
    type: 'error',
    content: text,
  });
};
