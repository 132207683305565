import { Button, Form, message, UploadFile } from 'antd';
import { useEffect, useState } from 'react';
import {
  useGetAccountTypesQuery,
  useGetBanksQuery,
  useLazyGetMeQuery,
} from '../../../api/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/icons/HomeIcon';
import { ArrowIcon } from '../../../components/icons/ArrowIcon';
import * as Yup from 'yup';
import { ConfirmationModal } from '../../../components/Modals/ConfirmationModal';
import { Spinner } from '../../../components/Spinner/Spinner';
import { LoanProducts } from '../../../api/types/partners';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { DraggerFile } from '../../../customFields/DraggerFile';
import { yupSync } from '../../../utils';
import { CustomInput } from '../../../customFields/CustomInput';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { PhoneNumberInput } from '../../../customFields/PhoneNumberInput';
import {
  AccountTypesItem,
  BankI,
  BankTableItem,
} from '../../../api/types/common';
import { LoanProduct } from '../../../customFields/LoanProduct';
import { CustomSwitcher } from '../../../customFields/CustomSwitcher';
import { MultiSelect } from '../../../customFields/MultiSelect';
import { ShowPreviewModal } from '../../../components/Modals/ShowPreviewModal';
import {
  useCreateMainAccountMutation,
  useEditMainAccountMutation,
  useLazyGetMainAccountQuery,
} from '../../../api/mainAccounts';
import { useAddLogoMutation } from '../../../api/partners';
import {
  setIsDirtyAction,
  setMessage,
} from '../../../reducers/common/commonSlice';
import { CustomErrorType } from '../../../types.ts/commonTypes';
import {
  useEditProfileInfoMutation,
  useLazyGetProfileInfoQuery,
} from '../../../api/profile';
import { isApiError } from '../../../utils/general';
import plusicon from '../../../assets/plusicon.svg';
import { AddBankModal } from '../../../components/Modals/AddBankModal';
import { AvailableBanksTable } from '../../../components/Tables/AvailableBanksTable';
import { unstable_usePrompt } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  sub_domain: Yup.string()
    .required('This field is required')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    })
    .matches(
      /^[a-z0-9-]+$/,
      'Only lowercase letters, numbers, and hyphen are allowed'
    ),
  first_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  last_name: Yup.string()
    .required('This field is required')
    .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed')
    .test('no-only-spaces', 'This field is required', (value) => {
      return value.trim() !== '';
    }),
  contact_number: Yup.string()
    .required('This field is required')
    .matches(/^[^_]*$/, 'This field is required'),
  contact_email: Yup.string()
    .email('Email is invalid')
    .required('This field is required'),
  account_types: Yup.array().min(1, 'This field is required'),
  available_banks: Yup.array().min(1, 'This field is required'),
  accounting_number: Yup.string()
    .nullable()
    .test('minLength', 'Accounting Number is not valid', (value: any) => {
      return !value || (value && value.length >= 8);
    }),
  routing_number: Yup.string(),
});

interface FormData {
  ma_processing_fee: string;
  logo: string | number | undefined;
  application_logo: string | number | undefined;
  company_name: string;
  first_name: string;
  last_name: string;
  contact_number: string;
  contact_email: string;
  account_types: string[];
  white_labeled_account: boolean | undefined | number;
  available_banks: string[];
  sub_domain: string;
  bank_name: string;
  legal_business_name: string;
  accounting_number: number;
  account_holder_name: string;
  routing_number: number;
}

export interface selectOption {
  value: string;
  label?: string;
  id?: string;
}

interface CreateMainAccountProps {
  isProfile?: boolean;
}

export const CreateMainAccount = ({ isProfile }: CreateMainAccountProps) => {
  const { data: accountTypesData, isLoading: getAccTypeLoading } =
    useGetAccountTypesQuery();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormData>();
  const [logo, setLogo] = useState<any[]>([]);
  const [appLogo, setAppLogo] = useState<any[]>([]);
  const [logoErr, setLogoErr] = useState<string | null>(null);
  const [appLogoErr, setAppLogoErr] = useState<string | null>(null);
  const [ContractNumber, setContractNumber] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [whiteLabeledAccount, setWhiteLabeledAccount] =
    useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const user = useAppSelector((state) => state.profile);
  const [IdLogo, setIdLogo] = useState<number | null>(null);
  const [deleteLogo, setDeleteLogo] = useState(false);
  const [IdAppLogo, setIdAppLogo] = useState<number | null>(null);
  const [companyError, setCompanyError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [subDomainError, setSubDomainError] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isClickOnHome, setIsClickOnHome] = useState<boolean>(false);
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [appLogoUrl, setAppLogoUrl] = useState<string>('');
  const [getMe] = useLazyGetMeQuery();
  const [getMainAccount, { data: MainAccData, isSuccess: isSuccessMainData }] =
    useLazyGetMainAccountQuery();
  const [getProfileInfo, { data: profileInfo }] = useLazyGetProfileInfoQuery();
  const [id, setId] = useState<null | number>(null);
  const [logoHover, setLogoHover] = useState<boolean>(false);
  const [AppLogoHover, setAppLogoHover] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEditAppLogo, setIsEditAppLogo] = useState<boolean>(false);
  const [faildFileLogo, setFaildFileLogo] = useState<UploadFile>();
  const [faildFileAppLogo, setFaildFileAppLogo] = useState<UploadFile>();
  const { handleKeyPress } = usePreventSpaceTyping();
  const [addLogo, { data: LogoId }] = useAddLogoMutation();
  const [addAppLogo, { data: appLogoId }] = useAddLogoMutation();
  const [accountType, setAccountType] = useState<string[]>([]);
  const [tableBanks, setTableBanks] = useState<BankTableItem[]>([]);
  const [showPewview, setShowPreview] = useState<boolean>(false);
  const [subDomainFocus, setSubDomainFocus] = useState(false);
  const dispatch = useAppDispatch();
  const { data: dataBanks } = useGetBanksQuery({ name: '' });
  const [addBankModal, setAddBankModal] = useState<boolean>(false);
  const [editBank, setEditBank] = useState<BankTableItem | null>(null);
  const [createMainAccount, { data, isSuccess, isError, error }] =
    useCreateMainAccountMutation();

  const [checkedLoanProducts, setCheckedLoanProducts] = useState<number[]>([]);
  const [loanErr, setLoanErr] = useState<any>({});

  const [LoanProdErr, setLoanProdErr] = useState<any>(false);
  const [clickOnSave, setClickOnSave] = useState(false);

  unstable_usePrompt({
    message: 'You have unsaved changes. Do you want to leave this page?',
    //@ts-ignore
    when: ({ currentLocation, nextLocation }) =>
      isDirty &&
      currentLocation !== nextLocation &&
      nextLocation.pathname !== '/login' &&
      !clickOnSave,
  });

  const beforeUnLoad = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    e.returnValue = '';
  };

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', beforeUnLoad);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [isDirty]);

  useEffect(() => {
    const hasError = Object.values(loanErr).some((value) => value);

    if (hasError) {
      setLoanProdErr(true);
    } else {
      setLoanProdErr(false);
    }
  }, [loanErr]);

  const [
    editMainAccount,
    {
      isSuccess: isSuccessEditMainAcc,
      isError: isErrorEditMainAcc,
      error: errorMainAcc,
    },
  ] = useEditMainAccountMutation();

  const [
    editProfile,
    {
      isSuccess: isSuccessEditProfile,
      isError: isErrorEditProfile,
      error: errorEditProfile,
    },
  ] = useEditProfileInfoMutation();

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const responseData = isProfile
    ? profileInfo?.data?.resource
    : MainAccData?.data;

  useEffect(() => {
    if (!LogoId) {
      setIdLogo(null);
    } else {
      setIdLogo(LogoId.data.id);
    }
    if (!logo.length) {
      setIdLogo(null);
    }
  }, [LogoId, logo]);

  useEffect(() => {
    if (!appLogoId) {
      setIdAppLogo(null);
    } else {
      setIdAppLogo(appLogoId.data.id);
    }
    if (!appLogo.length) {
      setIdAppLogo(null);
    }
  }, [appLogoId, appLogo]);

  useEffect(() => {
    if (!MainAccData) return;
    form.setFieldsValue({
      logo: MainAccData?.data?.logo,
      application_logo: MainAccData?.data?.application_logo,
      company_name: MainAccData?.data?.company_name,
      first_name: MainAccData?.data?.first_name,
      last_name: MainAccData?.data?.last_name,
      contact_number: MainAccData?.data?.contact_number,
      contact_email: MainAccData?.data?.contact_email,
      white_labeled_account: MainAccData?.data?.white_labeled_account,
      account_types: MainAccData?.data?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      ),
      sub_domain: MainAccData?.data?.sub_domain,
      ma_processing_fee: MainAccData?.data?.ma_processing_fee,
      bank_name: MainAccData?.data?.bank_details?.bank_name,
      legal_business_name: MainAccData?.data?.bank_details?.legal_business_name,
      accounting_number: MainAccData?.data?.bank_details?.accounting_number,
      account_holder_name: MainAccData?.data?.bank_details?.account_holder_name,
      routing_number: MainAccData?.data?.bank_details?.routing_number,
    });
    setId(MainAccData?.data?.item_id);
    setWhiteLabeledAccount(MainAccData?.data?.white_labeled_account === 1);
    setCheckedLoanProducts(
      MainAccData?.data.loan_products.map((elem: any) => elem.id)
    );
    setLogoUrl(
      typeof MainAccData?.data?.logo === 'string' ? MainAccData?.data?.logo : ''
    );
    setAppLogoUrl(
      typeof MainAccData?.data?.application_logo === 'string'
        ? MainAccData?.data?.application_logo
        : ''
    );
    setLogo([MainAccData?.data?.logo]);
    MainAccData?.data?.application_logo &&
      setAppLogo([MainAccData?.data?.application_logo]);
    setTableBanks(MainAccData?.data?.banks);
    setAccountType(
      MainAccData?.data?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      )
    );
  }, [MainAccData]);

  useEffect(() => {
    if (!profileInfo) return;
    form.setFieldsValue({
      logo: profileInfo?.data?.resource?.logo,
      application_logo: profileInfo?.data?.resource?.application_logo,
      company_name: profileInfo?.data?.resource?.company_name,
      first_name: profileInfo?.data?.resource?.first_name,
      last_name: profileInfo?.data?.resource?.last_name,
      contact_number: profileInfo?.data?.resource?.contact_number,
      contact_email: profileInfo?.data?.resource?.contact_email,
      white_labeled_account: profileInfo?.data?.resource?.white_labeled_account,
      available_banks: profileInfo?.data?.resource?.banks.map(
        (elem: BankI) => elem.id + ''
      ),
      account_types: profileInfo?.data?.resource?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      ),
      sub_domain: profileInfo?.data?.resource?.sub_domain,
      ma_processing_fee: profileInfo?.data?.resource?.ma_processing_fee,
    });
    setId(profileInfo?.data?.resource?.item_id);
    setWhiteLabeledAccount(
      profileInfo?.data?.resource?.white_labeled_account === 1
    );

    setLogoUrl(
      typeof profileInfo?.data?.resource?.logo === 'string'
        ? profileInfo?.data?.resource?.logo
        : ''
    );
    setAppLogoUrl(
      typeof profileInfo?.data?.resource?.application_logo === 'string'
        ? profileInfo?.data?.resource?.application_logo
        : ''
    );
    setLogo([profileInfo?.data?.resource?.logo]);
    profileInfo?.data?.resource?.application_logo &&
      setAppLogo([profileInfo?.data?.resource?.application_logo]);
    setTableBanks(profileInfo?.data?.resource?.banks);
    setAccountType(
      profileInfo?.data?.resource?.account_types?.map(
        (elem: AccountTypesItem) => elem.id + ''
      )
    );
  }, [profileInfo]);

  useEffect(() => {
    if (!location) return;
    if (isProfile) {
      getProfileInfo();
    } else {
      if (location.pathname.includes('edit')) {
        getMainAccount(location.pathname.split('/')[3]);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!logo.length) {
      setLogoUrl('');
      form.resetFields(['logo']);
    } else {
      if (logo[0]?.originFileObj) {
        const imageUrl = URL.createObjectURL(logo[0]?.originFileObj);
        setLogoUrl(imageUrl);
      }

      const formData = new FormData();
      formData.append('model', 'main_account/logo');
      logo[0]?.originFileObj &&
        formData.append('field', logo[0]?.originFileObj);
      if (logo[0]?.originFileObj) {
        addLogo(formData);
      }
    }
  }, [logo]);

  useEffect(() => {
    if (!appLogo.length) {
      setAppLogoUrl('');
      form.resetFields(['appLogo']);
    } else {
      if (appLogo[0]?.originFileObj) {
        const imageUrl = URL.createObjectURL(appLogo[0]?.originFileObj);
        setAppLogoUrl(imageUrl);
      }

      const formData = new FormData();
      formData.append('model', 'main_account/application_logo');
      appLogo[0]?.originFileObj &&
        formData.append('field', appLogo[0]?.originFileObj);
      if (appLogo[0]?.originFileObj) {
        addAppLogo(formData);
      }
    }
  }, [appLogo]);

  useEffect(() => {
    if (!user.email) return;
    if (user.role.key === 'partner' && !isProfile) {
      navigate('/403');
    }
  }, [user.email, isProfile]);

  const initialValues = {
    logo: undefined,
    application_logo: undefined,
    company_name: undefined,
    first_name: undefined,
    last_name: undefined,
    contact_number: undefined,
    contact_email: undefined,
    account_types: [],
    white_labeled_account: false,
    available_banks: [],
    ma_processing_fee: undefined,
  };

  useEffect(() => {
    if (isSuccessEditMainAcc) {
      navigate(`/main_accounts/${MainAccData?.data?.id}`);
      window.scrollTo(0, 0);
    }
  }, [isSuccessEditMainAcc]);

  useEffect(() => {
    if (isSuccessEditProfile) {
      navigate('/profile');
      window.scrollTo(0, 0);
    }
  }, [isSuccessEditProfile]);

  useEffect(() => {
    if (isError) {
      for (let prop in (error as CustomErrorType)?.data?.errors) {
        if (
          (error as CustomErrorType)?.data?.errors[prop].includes(
            'A Main Account with such Company Name already exists'
          )
        ) {
          setCompanyError(true);
          scrollToElementById('company_name');
        }
        if (
          (error as CustomErrorType)?.data?.errors[prop].includes(
            'A user with such Email already exists'
          )
        ) {
          setEmailError(true);
          scrollToElementById('contract_email');
        }
        if (
          (error as CustomErrorType)?.data?.errors[prop].includes(
            'The sub domain has already been taken'
          )
        ) {
          setSubDomainError(true);
        }
      }
    }
    if (isErrorEditMainAcc) {
      for (let prop in (errorMainAcc as CustomErrorType)?.data?.errors) {
        if (
          (errorMainAcc as CustomErrorType)?.data?.errors[prop].includes(
            'A Main Account with such Company Name already exists'
          )
        ) {
          setCompanyError(true);
          scrollToElementById('company_name');
        }

        if (
          (errorMainAcc as CustomErrorType)?.data?.errors[prop].includes(
            'A user with such Email already exists'
          )
        ) {
          setEmailError(true);
          scrollToElementById('contact_email');
        }
      }
    }

    if (isErrorEditProfile) {
      for (let prop in (errorEditProfile as CustomErrorType)?.data?.errors) {
        if (
          (errorEditProfile as CustomErrorType)?.data?.errors[prop].includes(
            'A Main Account with such Company Name already exists'
          )
        ) {
          setCompanyError(true);
          scrollToElementById('company_name');
        }

        if (
          (errorEditProfile as CustomErrorType)?.data?.errors[prop].includes(
            'A user with such Email already exists'
          )
        ) {
          setEmailError(true);
          scrollToElementById('contact_email');
        }
      }
    }

    if (isSuccess) {
      setSuccessPopup(true);
    }
  }, [
    isSuccess,
    isError,
    isErrorEditMainAcc,
    errorMainAcc,
    isErrorEditProfile,
    errorEditProfile,
  ]);

  //set loan products errors
  useEffect(() => {
    accountTypesData?.data?.forEach((elem: any) => {
      const isChecked = accountType?.includes(elem.id + '');
      if (isChecked) {
        const loanProducts = elem.loan_products;
        const isAtLeastOne = loanProducts.some((loan: any) =>
          checkedLoanProducts.includes(loan.id)
        );
        if (!isAtLeastOne) {
          setLoanErr((prev: any) => ({ ...prev, [elem.id]: true }));
        } else {
          setLoanErr((prev: any) => ({ ...prev, [elem.id]: false }));
        }
      }
    });
  }, [checkedLoanProducts]);

  const ManageLoanProducts = (accountArray: any) => {
    let copyArray = [...checkedLoanProducts];
    accountTypesData?.data?.forEach((elem: any) => {
      const isChecked = accountArray?.includes(elem.id + '');
      if (isChecked) {
        const allLoan = elem.loan_products.map((elem: any) => +elem?.id);

        for (let i = 0; i < allLoan.length; i++) {
          if (copyArray.includes(allLoan[i])) return;
          copyArray.push(allLoan[i]);
        }
      } else {
        const allLoan = elem.loan_products.map((elem: any) => +elem?.id);
        copyArray = copyArray.filter((item) => !allLoan.includes(item));
      }
    });
    setCheckedLoanProducts(copyArray);
  };

  const editMainAccountFunc = async (params: FormData) => {
    const data: any = {
      id: MainAccData?.data?.id,
      company_name: params.company_name ?? responseData.company_name,
      first_name: params.first_name.trim(),
      last_name: params.last_name.trim(),
      contact_number: params.contact_number,
      contact_email: params.contact_email,
      account_type_ids: accountType,
      loan_products: [...checkedLoanProducts],
      white_labeled_account: whiteLabeledAccount,
      banks: tableBanks,
      sub_domain: params.sub_domain,
      ma_processing_fee: params.ma_processing_fee,

      bank_name: params.bank_name,
      legal_business_name: params.legal_business_name,
      accounting_number: params.accounting_number,
      account_holder_name: params.account_holder_name,
      routing_number: params.routing_number,
    };

    if (typeof logo[0] !== 'string') {
      data.logo = IdLogo;
    }
    if (!data.logo && deleteLogo) {
      data.logo_delete = 1;
    }
    if (typeof appLogo[0] !== 'string' && whiteLabeledAccount) {
      data.application_logo = IdAppLogo;
    }

    try {
      isProfile
        ? await editProfile({ data: data }).unwrap()
        : await editMainAccount({
            id: MainAccData?.data?.id,
            data: data,
          }).unwrap();
      isProfile && getMe();
      await dispatch(setMessage('Changes have been successfully saved'));
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Edit failed');
    }
  };

  const creactePartnerFunc = async (params: FormData) => {
    const data: any = {
      logo: IdLogo,
      company_name: params.company_name,
      first_name: params.first_name.trim(),
      last_name: params.last_name.trim(),
      contact_number: params.contact_number,
      contact_email: params.contact_email,
      account_type_ids: params.account_types,
      loan_products: checkedLoanProducts,
      white_labeled_account: whiteLabeledAccount,
      banks: tableBanks,
      sub_domain: params.sub_domain,
      ma_processing_fee: params.ma_processing_fee,
      bank_name: params.bank_name,
      legal_business_name: params.legal_business_name,
      accounting_number: params.accounting_number,
      account_holder_name: params.account_holder_name,
      routing_number: params.routing_number,
    };
    if (whiteLabeledAccount) {
      data.application_logo = IdAppLogo;
    }

    try {
      await createMainAccount(data).unwrap();
    } catch (error) {
      const message = isApiError(error) && (error.data.message as string);
      errorMessage(message || 'Create main account failed');
    }
  };

  const handleFormSubmit = () => {
    let isValid = true;

    if (whiteLabeledAccount && !appLogo.length) {
      setAppLogoErr('This field is required');
      scrollToElementById('appLogo');
      isValid = false;
    }

    if (LoanProdErr || emailError || companyError || subDomainError) {
      isValid = false;
      if (LoanProdErr) {
        scrollToElementById('loan_product');
      }
      if (emailError) {
        scrollToElementById('contact_email');
      }
      if (companyError) {
        scrollToElementById('company_name');
      }
    }
    form
      .validateFields()
      .then((values: FormData) => {
        if (isValid) {
          if (location.pathname.includes('edit')) {
            editMainAccountFunc(values);
          } else {
            creactePartnerFunc(values);
          }
        }
      })
      .catch((error: { errorFields: [{ name: string[] }] }) => {
        if (whiteLabeledAccount && !appLogo.length) {
          scrollToElementById('appLogo');
        } else if (loanErr || emailError || companyError) {
          if (loanErr) {
            scrollToElementById('loan_product');
          }
          if (emailError) {
            scrollToElementById('contact_email');
          }
          if (companyError) {
            scrollToElementById('company_name');
          }
        } else {
          const name = error.errorFields[0].name[0];
          scrollToElementById(name);
        }
      });
  };

  const scrollToElementById = (elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onValuesChange = () => {
    setIsDirty(true);
  };

  const validateFormOnBlur = (name: string) => {
    form.validateFields([name]);
  };

  const currencyMaskFee = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const number = value.replace(/,/g, '');
    if (+number > 15) {
      e.target.value = '15.00';
    }
    return e.target.value;
  };

  function onBlurHandler(e: React.FocusEvent<HTMLInputElement>) {
    const inputValue = e.target.value;
    if (inputValue.endsWith('.')) {
      e.target.value = inputValue + '00';
      form.setFieldsValue({
        ma_processing_fee: e.target.value,
      });
    }
  }

  return (
    <>
      {contextHolder}
      {getAccTypeLoading ? (
        <></>
      ) : (
        // <Spinner size="large" />
        <div className="container">
          <div className="new-member">
            {/*{!isProfile && (*/}
            {/*  <div className="new-member__breadscrumbs breadscrumbs">*/}
            {/*    <div*/}
            {/*      className="breadscrumbs__title"*/}
            {/*      onClick={() => {*/}
            {/*        setClickOnSave(true);*/}
            {/*        if (location.pathname.includes('edit')) {*/}
            {/*          if (isDirty) {*/}
            {/*            setIsClickOnHome(true);*/}
            {/*            setConfirmationModal(true);*/}
            {/*          } else {*/}
            {/*            navigate('/dashboard');*/}
            {/*          }*/}
            {/*        } else {*/}
            {/*          if (form.isFieldsTouched()) {*/}
            {/*            setIsClickOnHome(true);*/}
            {/*            setConfirmationModal(true);*/}
            {/*          } else {*/}
            {/*            navigate('/dashboard');*/}
            {/*          }*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <HomeIcon fill="" />*/}
            {/*    </div>*/}
            {/*    <div className="breadscrumbs__separatop">*/}
            {/*      <ArrowIcon fill="" />*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*      className="breadscrumbs__title"*/}
            {/*      onClick={() => {*/}
            {/*        if (location.pathname.includes('edit')) {*/}
            {/*          setClickOnSave(true);*/}
            {/*          if (isDirty) {*/}
            {/*            setIsClickOnHome(false);*/}
            {/*            setConfirmationModal(true);*/}
            {/*          } else {*/}
            {/*            navigate('/main_accounts');*/}
            {/*          }*/}
            {/*        } else {*/}
            {/*          if (form.isFieldsTouched()) {*/}
            {/*            setIsClickOnHome(false);*/}
            {/*            setConfirmationModal(true);*/}
            {/*          } else {*/}
            {/*            navigate('/main_accounts');*/}
            {/*          }*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Main Accounts*/}
            {/*    </div>*/}
            {/*    <div className="breadscrumbs__separatop">*/}
            {/*      <ArrowIcon fill="#4D4D56" />*/}
            {/*    </div>*/}
            {/*    <div className="breadscrumbs__title breadscrumbs__title-active">*/}
            {/*      {location.pathname.includes('edit') ? 'Edit' : 'Create'} Main*/}
            {/*      Account*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            <div className="new-member__main main">
              <Form
                form={form}
                initialValues={initialValues}
                onSubmitCapture={handleFormSubmit}
                onValuesChange={onValuesChange}
              >
                <div className="main__container">
                  <div className="main__title-block">
                    <div className="main__title">Main account info</div>
                    <div className="main__subtitle">
                      {location.pathname.includes('edit') ? 'Edit' : 'Create'}{' '}
                      Main Account logo and details
                    </div>
                  </div>
                  <div className="main__form-block">
                    <div className="partner-info">
                      {id && (
                        <div className="partner-info__id">
                          Main Account ID:{' '}
                          <span style={{ color: '#67666E' }}>{id}</span>
                        </div>
                      )}
                      {!isProfile && (
                        <div
                          className="partner-info__row"
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            minHeight: '140px',
                          }}
                        >
                          <div style={{ margin: '0 16px 0 0' }} id="logo">
                            {logoUrl && (
                              <div style={{ position: 'relative' }}>
                                <img
                                  src={logoUrl}
                                  className="default-avatar"
                                  onMouseOver={() => setLogoHover(true)}
                                  onMouseLeave={() => setLogoHover(false)}
                                  alt="logo"
                                />
                                {logoHover && (
                                  <div
                                    onMouseOver={() => setLogoHover(true)}
                                    onMouseLeave={() => setLogoHover(false)}
                                  >
                                    <div className="default-avatar__wrapper"></div>
                                    <div className="default-avatar__hover">
                                      <div
                                        className="default-avatar__edit"
                                        onClick={() => setIsEdit(true)}
                                      >
                                        Edit{' '}
                                      </div>
                                      or
                                      <div
                                        className="default-avatar__delete"
                                        onClick={() => {
                                          setIsDirty(true);
                                          setLogo([]);
                                          setDeleteLogo(true);
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            <Form.Item
                              name="logo"
                              style={logoUrl ? { opacity: '0' } : {}}
                            >
                              <DraggerFile
                                name="logo"
                                allowsFormat={['png', 'jpg', 'jpeg']}
                                onChange={setLogo}
                                fileList={logo}
                                maxSizeBytes={5242880}
                                text="PNG or JPG for Logo"
                                hasError={() =>
                                  !!form.getFieldError(['logo']).length
                                }
                                setErr={setLogoErr}
                                err={logoErr}
                                // isLoading={isLoadingLogo}
                                isLoading={false}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                faildFile={faildFileLogo}
                                setFaildFile={setFaildFileLogo}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      )}

                      {!isProfile && (
                        <div className="partner-info__row" id="company_name">
                          <div className="item">
                            <Form.Item
                              className="input-wrapper"
                              name="company_name"
                              rules={yupSync(
                                'company_name',
                                validationSchema,
                                true
                              )}
                            >
                              <CustomInput
                                name="Company Name"
                                className="input"
                                maxLength={240}
                                placeholder={`Enter ${
                                  isProfile ? 'Your' : 'Main Account’s'
                                }  Company Name`}
                                onBlur={() =>
                                  validateFormOnBlur('company_name')
                                }
                                error={companyError ? 'err' : ''}
                                onChange={() => setCompanyError(false)}
                              />
                            </Form.Item>
                          </div>
                          <div className="item">
                            {' '}
                            <Form.Item
                              className="input-wrapper"
                              name="ma_processing_fee"
                            >
                              <CustomInput
                                name="MA Processing Fee"
                                className="input"
                                maxLength={5}
                                onKeyPress={handleKeyPress}
                                placeholder="Enter MA Processing Fee"
                                suffix={<div className="procent">%</div>}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => currencyMaskFee(e)}
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => onBlurHandler(e)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      )}
                      {companyError && (
                        <div
                          className="error-text"
                          style={{
                            margin: '-34px 0px 0px 4px',
                            height: '32px',
                          }}
                        >
                          A Main Account with such Company Name already exists
                        </div>
                      )}

                      <div className="partner-info__row">
                        <div className="item" id="first_name">
                          <Form.Item
                            className="input-wrapper"
                            name="first_name"
                            rules={yupSync(
                              'first_name',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomInput
                              name="First Name"
                              className="input"
                              maxLength={100}
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Main Account’s'
                              } First Name`}
                              onBlur={() => validateFormOnBlur('first_name')}
                              validation={'noNumbers'}
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="last_name">
                          <Form.Item
                            className="input-wrapper"
                            name="last_name"
                            rules={yupSync('last_name', validationSchema, true)}
                          >
                            <CustomInput
                              name="Last Name"
                              className="input"
                              maxLength={100}
                              onKeyPress={handleKeyPress}
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Main Account’s'
                              } First Name`}
                              onBlur={() => validateFormOnBlur('last_name')}
                              validation={'noNumbers'}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="partner-info__sub-row partner-info__sub-row">
                        <div className="item" id="contact_number">
                          <Form.Item
                            className="input-wrapper"
                            name="contact_number"
                            rules={yupSync(
                              'contact_number',
                              validationSchema,
                              true
                            )}
                          >
                            <PhoneNumberInput
                              mask="(999) 999 9999"
                              name="Contact Number"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setContractNumber(e.target.value)}
                              className="input"
                              value={undefined}
                              onBlur={() =>
                                validateFormOnBlur('contact_number')
                              }
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Main Account’s'
                              } Contact Number`}
                              hasError={() =>
                                !!form.getFieldError('contact_number').length
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="item" id="contact_email">
                          <Form.Item
                            className="input-wrapper"
                            name="contact_email"
                            rules={yupSync(
                              'contact_email',
                              validationSchema,
                              true
                            )}
                          >
                            <CustomInput
                              name="Contact Email"
                              className="input"
                              maxLength={240}
                              onKeyPress={handleKeyPress}
                              placeholder={`Enter ${
                                isProfile ? 'Your' : 'Main Account’s'
                              } Contact Email`}
                              onBlur={() => validateFormOnBlur('contact_email')}
                              onChange={() => setEmailError(false)}
                              error={emailError ? 'err' : ''}
                            />
                          </Form.Item>
                          {emailError && (
                            <div
                              className="error-text"
                              style={{
                                margin: '-20px 0px 0px 4px',
                                height: '32px',
                              }}
                            >
                              A user with such Email already exists
                            </div>
                          )}
                        </div>
                      </div>

                      {!isProfile && (
                        <div className="partner-info__row" id="account_type_id">
                          <Form.Item
                            className="input-wrapper"
                            name="account_types"
                            rules={yupSync(
                              'account_types',
                              validationSchema,
                              true
                            )}
                          >
                            <MultiSelect
                              value={[]}
                              name="Account Type"
                              className="input input-white-small"
                              placeholder="Select Account Type"
                              options={accountTypesData?.data?.map(
                                (elem: AccountTypesItem) => {
                                  return { value: elem.name, label: elem.id };
                                }
                              )}
                              onChange={(value) => {
                                setAccountType(value);
                                ManageLoanProducts(value);
                              }}
                              isSearchSelect={false}
                            />
                          </Form.Item>
                        </div>
                      )}
                      {!isProfile &&
                        accountType?.map((item: string) => {
                          return (
                            <div key={item}>
                              <div
                                className="partner-info__row"
                                id="loan_product"
                              >
                                <LoanProduct
                                  title={
                                    <div>
                                      Loan Products{' '}
                                      <span
                                        style={{
                                          color: 'var(--gray-400, #808086)',
                                          fontSize: '12px',
                                        }}
                                      >
                                        (for{' '}
                                        {
                                          accountTypesData?.data?.find(
                                            (elem: AccountTypesItem) =>
                                              elem.id + '' === item
                                          )?.name
                                        }
                                        )
                                      </span>
                                    </div>
                                  }
                                  options={
                                    (accountTypesData?.data?.find(
                                      (elem: AccountTypesItem) =>
                                        elem.id + '' === item
                                    )?.loan_products || []) as LoanProducts[]
                                  }
                                  onChange={setCheckedLoanProducts}
                                  values={checkedLoanProducts}
                                  error={loanErr[item]}
                                />
                              </div>

                              {loanErr[item] && (
                                <div
                                  className="error-text"
                                  style={{ margin: '0 0 15px 0' }}
                                >
                                  Please select at least one product
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!isProfile && (
                        <div className="main-account-info__row main-account-info__row__between">
                          <div className="bank-table__title">
                            Available Banks
                          </div>
                          {accountType.length ? (
                            <Button
                              className="gray-btn button__plus"
                              onClick={() => setAddBankModal(true)}
                              disabled={!accountType.length}
                            >
                              <img
                                src={plusicon}
                                className="button__plus__img"
                                alt="plus"
                              />{' '}
                              Add Bank
                            </Button>
                          ) : (
                            <Button
                              className="gray-btn button__plus"
                              disabled={!accountType.length}
                            >
                              Please add Account Type first
                            </Button>
                          )}
                        </div>
                      )}
                      {!isProfile && (
                        <AvailableBanksTable
                          banks={tableBanks}
                          setBanks={setTableBanks}
                          setEditBank={setEditBank}
                          setAddBankModal={setAddBankModal}
                          type="main_account"
                        />
                      )}

                      {!isProfile && (
                        <div
                          className="partner-info__row"
                          style={{
                            margin: '10px 0 0 0',
                            height: '180px',
                          }}
                        >
                          <div
                            className="item"
                            style={{
                              height: '150px',
                              width: '30%',
                            }}
                          >
                            <Form.Item name="associated_main_account">
                              <CustomSwitcher
                                disabled={location.pathname.includes('edit')}
                                name="White Labeled Account"
                                onClick={setWhiteLabeledAccount}
                                chacked={whiteLabeledAccount}
                              />
                            </Form.Item>
                            {appLogoUrl && whiteLabeledAccount && (
                              <Button
                                className="grey-blue-btn"
                                onClick={() => setShowPreview(true)}
                              >
                                Preview logo
                              </Button>
                            )}
                          </div>
                          {whiteLabeledAccount && (
                            <div className="item" style={{ width: '435px' }}>
                              <div
                                style={{ width: '100%', height: '167px' }}
                                id="appLogo"
                              >
                                {appLogoUrl && (
                                  <div style={{ position: 'relative' }}>
                                    <img
                                      src={appLogoUrl}
                                      className="default-avatar-app-logo"
                                      onMouseOver={() => setAppLogoHover(true)}
                                      onMouseLeave={() =>
                                        setAppLogoHover(false)
                                      }
                                      alt="logo"
                                    />

                                    {AppLogoHover && (
                                      <div
                                        onMouseOver={() =>
                                          setAppLogoHover(true)
                                        }
                                        onMouseLeave={() =>
                                          setAppLogoHover(false)
                                        }
                                      >
                                        <div className="default-avatar-app-logo__wrapper"></div>
                                        <div className="default-avatar-app-logo__hover">
                                          <div
                                            className="default-avatar-app-logo__edit"
                                            onClick={() =>
                                              setIsEditAppLogo(true)
                                            }
                                          >
                                            Edit
                                          </div>
                                          or
                                          <div
                                            className="default-avatar-app-logo__delete"
                                            onClick={() => {
                                              setIsDirty(true);
                                              setAppLogo([]);
                                            }}
                                          >
                                            Delete
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                <Form.Item
                                  name="appLogo"
                                  style={appLogoUrl ? { opacity: '0' } : {}}
                                >
                                  <DraggerFile
                                    name="appLogo"
                                    allowsFormat={['png', 'jpg', 'jpeg']}
                                    onChange={setAppLogo}
                                    fileList={appLogo}
                                    maxSizeBytes={5242880}
                                    text=" PNG or JPG for Logo (Aspect ratio 4:1, max. 5MB)"
                                    hasError={() =>
                                      !!form.getFieldError(['appLogo']).length
                                    }
                                    setErr={setAppLogoErr}
                                    err={appLogoErr}
                                    // isLoading={isLoadingLogo}
                                    isLoading={false}
                                    isEdit={isEditAppLogo}
                                    setIsEdit={setIsEditAppLogo}
                                    faildFile={faildFileAppLogo}
                                    setFaildFile={setFaildFileAppLogo}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {whiteLabeledAccount && !isProfile && (
                        <>
                          <Form.Item
                            className="input-wrapper"
                            name="sub_domain"
                            rules={yupSync(
                              'sub_domain',
                              validationSchema,
                              true
                            )}
                            id="sub_domain"
                          >
                            <CustomInput
                              onFocus={() => setSubDomainFocus(true)}
                              disabled={location.pathname.includes('edit')}
                              name="Subdomain Name"
                              className="input"
                              maxLength={240}
                              onKeyPress={handleKeyPress}
                              placeholder="Enter subdomain name"
                              onBlur={() => {
                                setSubDomainFocus(false);
                                validateFormOnBlur('sub_domain');
                              }}
                              onChange={() => setSubDomainError(false)}
                              error={subDomainError ? 'err' : ''}
                            />
                          </Form.Item>
                          {subDomainError && (
                            <div
                              className="error-text"
                              style={{
                                margin: '-34px 0px 0px 4px',
                                height: '32px',
                              }}
                            >
                              The sub domain has already been taken
                            </div>
                          )}
                          {subDomainFocus && (
                            <div className="subdomain-helptext">
                              Please note that only a-z, 0-9, and hyphen are
                              allowed{' '}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {!isProfile && (
                  <div
                    className="main__container"
                    style={{ marginTop: ' 24px' }}
                  >
                    <div className="main__title-block">
                      <div className="main__title">Banking Information</div>
                      <div className="main__subtitle">
                        Update Partner billing details
                      </div>
                    </div>
                    <div className="main__form-block">
                      <div className="partner-info">
                        <div className="partner-info__row" id="bank_name">
                          <Form.Item className="input-wrapper" name="bank_name">
                            <CustomInput
                              name="Bank Name"
                              className="input"
                              maxLength={240}
                              placeholder="Enter Bank Name"
                              onBlur={() => validateFormOnBlur('bank_name')}
                              // error={credError}
                              // onChange={() => setCredError('')}
                            />
                          </Form.Item>
                        </div>
                        <div className="partner-info__row">
                          <div className="item" id="legal_business_name">
                            <Form.Item
                              className="input-wrapper"
                              name="legal_business_name"
                            >
                              <CustomInput
                                name="Legal Business Name"
                                placeholder="Enter Legal Business Name"
                                onBlur={() =>
                                  validateFormOnBlur('legal_business_name')
                                }
                                maxLength={240}
                              />
                            </Form.Item>
                          </div>
                          <div className="item" id="accounting_number">
                            <Form.Item
                              className="input-wrapper"
                              name="accounting_number"
                              rules={yupSync(
                                'accounting_number',
                                validationSchema,
                                true
                              )}
                            >
                              <CustomInput
                                name="Accounting Number"
                                placeholder="Enter Accounting Number"
                                onBlur={() =>
                                  validateFormOnBlur('accounting_number')
                                }
                                maxLength={17}
                                validation="onlyNumbers"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="partner-info__row">
                          <div className="item" id="account_holder_name">
                            <Form.Item
                              className="input-wrapper"
                              name="account_holder_name"
                            >
                              <CustomInput
                                name="Account Holder’s Name"
                                placeholder="Enter Account Holder’s Name"
                                onBlur={() =>
                                  validateFormOnBlur('account_holder_name')
                                }
                                maxLength={240}
                              />
                            </Form.Item>
                          </div>
                          <div className="item" id="routing_number">
                            <Form.Item
                              className="input-wrapper"
                              name="routing_number"
                              rules={yupSync(
                                'routing_number',
                                validationSchema,
                                true
                              )}
                            >
                              <CustomInput
                                name="Routing Number"
                                placeholder="Enter Routing Number"
                                onBlur={() =>
                                  validateFormOnBlur('routing_number')
                                }
                                maxLength={9}
                                validation="onlyNumbers"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="main__btns">
                  <Button
                    className="gray-btn"
                    style={{ margin: '0 16px 0 0' }}
                    onClick={() => {
                      setClickOnSave(true);
                      if (location.pathname.includes('edit')) {
                        if (isDirty) {
                          setConfirmationModal(true);
                        } else {
                          isProfile
                            ? navigate('/dashboard')
                            : navigate('/main_accounts');
                        }
                      } else {
                        if (form.isFieldsTouched()) {
                          setConfirmationModal(true);
                        } else {
                          isProfile
                            ? navigate('/dashboard')
                            : navigate('/main_accounts');
                        }
                      }
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="blue-btn"
                    htmlType="submit"
                    onClick={() => setClickOnSave(true)}
                  >
                    {location.pathname.includes('edit')
                      ? 'Save Changes'
                      : 'Create'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
      {confirmationModal && (
        <ConfirmationModal
          openModal={confirmationModal}
          setOpenModal={setConfirmationModal}
          type="warning"
          title={
            location.pathname.includes('edit') && !isProfile
              ? 'Are you sure you want to discard the Main Account Editing?'
              : location.pathname.includes('edit') && isProfile
              ? 'Are you sure you want to discard the profile editing?'
              : !location.pathname.includes('edit') && !isProfile
              ? 'Are you sure you want to discard the Main Account Creation?'
              : ''
          }
          cancelText="No"
          confirmText="Yes"
          cancel={() => {
            setClickOnSave(false);
            setConfirmationModal(false);
          }}
          confirm={() => {
            if (isClickOnHome) {
              navigate('/dashboard');
            } else {
              isProfile ? navigate('/dashboard') : navigate('/main_accounts');
            }
          }}
          closeIcon={true}
        />
      )}
      {successPopup && data?.data && (
        <ConfirmationModal
          openModal={successPopup}
          setOpenModal={setSuccessPopup}
          type="success"
          title="Main Account has been successfully created"
          subtitle={`Main Account ID: ${data?.data?.item_id}`}
          cancelText=""
          confirmText="Okay"
          confirm={() => {
            navigate('/main_accounts');
          }}
          closeIcon={false}
          maskClosable={false}
        />
      )}
      {showPewview && (
        <ShowPreviewModal
          openModal={showPewview}
          setOpenModal={setShowPreview}
          closeIcon={false}
          image={appLogoUrl}
        />
      )}
      {addBankModal && (
        <AddBankModal
          banks={dataBanks?.data || []}
          openModal={addBankModal}
          setOpenModal={setAddBankModal}
          setBanks={setTableBanks}
          tableBanks={tableBanks}
          editBank={editBank}
          setEditBank={setEditBank}
        />
      )}
    </>
  );
};
