import './index.scss'
import {Button, message} from "antd";
import pen from '../../../assets/edit-2.svg'
import user from '../../../assets/user-icon-40.svg'
import {useCallback, useEffect, useState} from "react";
import {useLazyGetProfileInfoQuery} from "../../../api/profile";
import {MyProfileEditModal} from "../../../components/Modals/MyProfileEditModal/MyProfileEditModal";
import {successMessage} from "../../../components/Messages/messages";
import {Employee} from "../../../api/types/common";

export const MyProfilePage = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [getProfile ,{data: profileData}] = useLazyGetProfileInfoQuery()
    const [openEdit, setOpenEdit] = useState(false)
    const [editEmployee, setEditEmployee] = useState<Employee | null>(null)


    const createSuccess = useCallback(() => {
        successMessage(messageApi, `Profile has been successfully saved`)
    }, [])

    useEffect(() => {
        getProfile()
    }, [])

    useEffect(() => {
        profileData && setEditEmployee(profileData.data.resource)
    }, [profileData])

    return (
        <div className="my-profile__container">
            {contextHolder}
            <div className="d-flex">
                <img className="mr-6" src={user} alt="user"/>
                <div>
                    <div className="my-profile__name">
                        {`${profileData?.data?.resource?.first_name} ${profileData?.data?.resource?.last_name}`}</div>
                    <div className="my-profile__id">
                        # {profileData?.data?.resource?.item_id ??
                        profileData?.data?.id}
                    </div>
                </div>
            </div>
            <div className="my-profile__body">
                <div className="my-profile__header">
                    <div className="my-profile__header-title">Personal information</div>
                    <Button
                        className="gray-btn-lg h-40 d-flex align-center justify-center w-auto"
                        onClick={() => {setOpenEdit(true)}}
                    >
                        <span>
                            <img
                                src={pen}
                                alt="edit"
                                className="mr-4"
                            />
                        </span> Edit info</Button>
                </div>
                <div className="my-profile__body-info" >
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Contact number
                        </div>
                        <div className="my-profile__body-info__text">
                            {profileData?.data?.resource?.contact_number}
                        </div>
                    </div>
                    <div className="my-profile__body-info__block">
                        <div className="my-profile__body-info__title">
                            Contact email
                        </div>
                        <div className="my-profile__body-info__text">
                            {profileData?.data?.resource?.contact_email}
                        </div>
                    </div>
                </div>
            </div>
            <MyProfileEditModal openModal={openEdit} setOpenModal={setOpenEdit} editEmployee={editEmployee} createSuccess={createSuccess}/>
        </div>
    )
}