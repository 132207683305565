import {Button, message} from 'antd';
import doneicon from '../../assets/doneicon.svg';
import copyLinkIcon from '../../assets/ci_copy.svg';
import { useState } from 'react';
import {successMessage} from "../Messages/messages";

interface CopyLinkPropI {
  title: string;
  link: string;
}

export const CopyLink = ({ title, link }: CopyLinkPropI) => {
    const [messageApi, contextHolder] = message.useMessage();
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
          successMessage(messageApi,'Link copied!')
      })
      .catch(() => {
        console.error('Failed to copy text');
      });
  };

  return (
    <div className="block__row">
        {contextHolder}
      <div className="block__item" style={{ width: '66%' }}>
        <div className="block__item-title">{title}</div>
        <div className="block__item-value block__application-link">{link}</div>
      </div>
      <Button className="blue-btn block__btn" onClick={handleCopyLink}>
          <img src={copyLinkIcon} className="block__btn-image" alt="copy" />
        Copy link
      </Button>
    </div>
  );
};
