import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, message } from 'antd';
import './index.scss';
import { useLazyGetMeQuery } from '../../api/common';
import { Header } from '../Header/Header';
import { Navigation } from '../Navigation/Navigation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setUser } from '../../reducers/profile/profileSlice';
import { setMessage } from '../../reducers/common/commonSlice';
import Favicon from 'react-favicon';
import faviconWL from '../../assets/WLFavicon_192x192.svg';
import faviconNoLable from '../../assets/Favicon192x192.svg';
import { subdomain } from '../../helpers/common';
import { Spinner } from '../Spinner/Spinner';
import { beforeInit } from '../../api/axiosCrud';

interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = () => {
  const [getMe, { data }] = useLazyGetMeQuery();

  const [initData, setInitData] = useState<any>();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const messageText = useAppSelector((state) => state.common.isSuccessMessage);
  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (!subdomain) return;
    beforeInit({ subdomain: subdomain }).then((response: any) => {
      setInitData(response?.data);
    });
  }, [subdomain]);

  useEffect(() => {
    if (initData?.company_name) {
      if (subdomain === 'magwitch') {
        document.title = 'Magwitch';
      } else {
        document.title = initData?.company_name;
      }
    }
  }, [initData, subdomain]);

  useEffect(() => {
    if (messageText) {
      success(messageText);
      dispatch(setMessage(''));
    }
  }, [messageText]);

  useEffect(() => {
    if (data) {
      dispatch(setUser(data.data));
    }
  }, [data]);

  useEffect(() => {
    getMe();
  }, []);

  // if (isInitLoading) {
  //   // return <Spinner size="large"/>
  // }

  return (
    <Layout>
      <Favicon
        url={initData?.wl ? faviconWL : faviconNoLable}
        sizes="192x192"
      />
      {contextHolder}
      <Navigation accountLogo={initData?.logo} />

      <div style={{ width: '85%' }}>
        <Header />
        <Outlet />
      </div>
    </Layout>
  );
};
