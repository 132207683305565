import { Skeleton } from 'antd';
import './index.scss';

const stylesSmallLongInput = {
  width: 150,
  height: 14,
  margin: '10px',
  borderRadius: 40,
};
export const NavigationSkeleton = () => {
  return (
    <div className="navigation-skeleton">
      <Skeleton.Image
        active
        style={{ height: '50px', margin: '-30px 0 40px 10px' }}
      />
      <Skeleton.Button size="small" style={stylesSmallLongInput} active />{' '}
      <Skeleton.Button size="small" style={stylesSmallLongInput} active />{' '}
      <Skeleton.Button size="small" style={stylesSmallLongInput} active />{' '}
      <Skeleton.Button size="small" style={stylesSmallLongInput} active />{' '}
      <Skeleton.Button size="small" style={stylesSmallLongInput} active />{' '}
      <Skeleton.Button size="small" style={stylesSmallLongInput} active />{' '}
    </div>
  );
};
