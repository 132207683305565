import { Pagination } from '../Pagination';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useAppSelector } from '../../../hooks';
import moment from 'moment';
import {
  ADMIN,
  PARTNER,
  SUBMITTED,
  SUPER_ADMIN,
} from '../../../helpers/variables';
import letter from '../../../assets/letter_48px.svg';
import { appTooltip, trimmer } from '../../../helpers/common';
import { Tooltip } from 'antd';
import arrow from '../../../assets/arrow-down.svg';
import { TableParamsApp } from '../../../api/types/applicants';

interface PartnersTableProps {
  data: any;
  setTableParams: React.Dispatch<React.SetStateAction<any>>;
  tableParams: any;
  metaData: any;
}

export const ApplicationsTable = ({
  data,
  setTableParams,
  tableParams,
  metaData,
}: PartnersTableProps) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.profile);
  const sorting = (name: string) => {
    setTableParams((prev: TableParamsApp) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? tableParams.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  return (
    <>
      <div>
        <div className="ticket-table">
          <div className="ticket-table__header">
            <div className="ticket-table__header__item short">
              ID
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'public_id' &&
                  tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('public_id')}
              />
            </div>
            <div className="ticket-table__header__item">
              Applicant Name
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'name' && tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('name')}
              />
            </div>
            {user.role.key !== PARTNER && (
              <div className="ticket-table__header__item">
                Partner
                <img
                  src={arrow}
                  alt="arrow"
                  className={`header-image ${
                    tableParams.order_by === 'partner' &&
                    tableParams.order === 'asc'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => sorting('partner')}
                />
              </div>
            )}
            <div className="ticket-table__header__item middle">
              Product
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'product' &&
                  tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('product')}
              />
            </div>
            <div className="ticket-table__header__item middle">
              Loan Amount
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'loan_amount' &&
                  tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('loan_amount')}
              />
            </div>
            <div className="ticket-table__header__item">
              Application Status
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'application_status' &&
                  tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('application_status')}
              />
            </div>
            <div className="ticket-table__header__item">
              Transaction Status
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'transaction_status' &&
                  tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('transaction_status')}
              />
            </div>
            <div className="ticket-table__header__item">
              Date
              <img
                src={arrow}
                alt="arrow"
                className={`header-image ${
                  tableParams.order_by === 'date' && tableParams.order === 'asc'
                    ? 'active'
                    : ''
                }`}
                onClick={() => sorting('date')}
              />
            </div>
          </div>
          {data && data?.length === 0 && (
            <>
              {!tableParams.status_key &&
                !tableParams.search &&
                !tableParams.loan_product_id &&
                !tableParams.application_status &&
                !tableParams.application_date_from &&
                !tableParams.application_date_to &&
                !tableParams.transaction_status &&
                data?.length === 0 && (
                  <div className="ticket-table__empty-continer">
                    <div className="employees__empty">
                      <img src={letter} alt="team member" />
                      <div className="employees__empty__title">
                        No Applications
                      </div>
                      <div className="employees__empty__text">
                        All your Applications will be shown here
                      </div>
                    </div>
                  </div>
                )}
              {(tableParams.status_key ||
                tableParams.search ||
                tableParams.loan_product_id ||
                tableParams.application_status ||
                (tableParams.application_date_from &&
                  tableParams.application_date_to) ||
                tableParams.transaction_status) &&
                data?.length === 0 && (
                  <div className="ticket-table__empty-continer">
                    <div className="employees__empty">
                      <img src={letter} alt="team member" />
                      <div className="employees__empty__title">
                        No results found
                      </div>
                      <div className="employees__empty__text">
                        Try adjusting your search to find what you are looking
                        for
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}

          {data?.map((item: any) => {
            return (
              <div
                className="ticket-table__row"
                onClick={() => {
                  navigate(`/applications/edit/${item.id}`);
                }}
              >
                <div className="ticket-table__row__item blue short">
                  {item.public_id}
                </div>
                <div className="ticket-table__row__item">
                  {trimmer(item.first_name + ' ' + item.last_name, 20)}
                </div>
                {user.role.key !== PARTNER && (
                  <div className="ticket-table__row__item">
                    {trimmer(item.partner.company_name, 20)}
                  </div>
                )}
                <div className="ticket-table__row__item middle">
                  {item.financing_type?.name ? item.financing_type?.name : '-'}
                </div>
                <div className="ticket-table__row__item middle amount">
                  {item.loan_amount
                    ? `$${item.loan_amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : '-'}
                </div>
                <div className="ticket-table__row__item">
                  <div className="d-flex justify-center">
                    <Tooltip title={appTooltip(item)}>
                      {(item.application_status === 'Offer Made' ||
                        item.application_status === 'Pending') && (
                        <div className="ticket-table__status offerMade">
                          {item.application_status}
                        </div>
                      )}
                      {(item.application_status === 'No Offer' ||
                        item.application_status === 'Declined') && (
                        <div className="ticket-table__status noOffer">
                          {item.application_status}
                        </div>
                      )}
                      {item.application_status === 'Awaiting Signature' &&
                        item.additional_application_status !==
                          'Awaiting Down Payment' && (
                          <div className="ticket-table__status Awaiting">
                            {item.additional_application_status
                              ? item.additional_application_status
                              : item.application_status}
                          </div>
                        )}

                      {item.additional_application_status ===
                        'Awaiting Down Payment' && (
                        <div className="ticket-table__status Awaiting_payment">
                          Await. Down Payment
                        </div>
                      )}
                      {(item.application_status === 'Approved' ||
                        item.application_status === 'Refunded') && (
                        <div className="ticket-table__status Approved">
                          {item.application_status}
                        </div>
                      )}
                      {item.application_status === 'Draft' && (
                        <div className="ticket-table__status Draft">
                          {item.application_status}
                        </div>
                      )}
                    </Tooltip>
                  </div>
                </div>
                <div className="ticket-table__row__item">
                  <div className="d-flex justify-center">
                    <Tooltip title={appTooltip(item)}>
                      {item.transaction_status === 'In Progress' && (
                        <div className="ticket-table__status offerMade">
                          {item.transaction_status}
                        </div>
                      )}
                      {item.transaction_status === 'Timed Out' && (
                        <div className="ticket-table__status noOffer">
                          {item.transaction_status}
                        </div>
                      )}
                      {item.transaction_status === 'Failed' && (
                        <div className="ticket-table__status noOffer">
                          {item.transaction_status}
                        </div>
                      )}
                      {item.transaction_status === 'Approved' && (
                        <div className="ticket-table__status Approved">
                          {item.transaction_status}
                        </div>
                      )}
                      {!item.transaction_status && '-'}
                    </Tooltip>
                  </div>
                </div>
                <div className="ticket-table__row__item">
                  {moment(item.created_at).format('MM/DD/YYYY h:mma')}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
