import { iconI } from './types';

export const PartnersIcon = ({ fill, style }: iconI) => {
  return (
  <svg width="24"
       height="24"
       viewBox="0 0 24 24" fill="none"
       style={style}
       xmlns="http://www.w3.org/2000/svg">
    <path d="M6 17.3333C7.55719 15.6817 9.67134 14.6667 12 14.6667C14.3287 14.6667 16.4428 15.6817 18 17.3333M15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9Z"
          stroke={fill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  );
};
