import { Skeleton } from 'antd';
import './index.scss';

const stylesSmallInput = {
  width: 250,
  height: 14,
  borderRadius: 40,
  margin: '0 0 0 15px',
};

const stylesSmallLongInput = {
  width: 450,
  height: 14,
  borderRadius: 40,
  margin: '0 0 0 15px',
};

const stylesExtraSmallInput = {
  width: 20,
  height: 14,
  borderRadius: 40,
  margin: '0 0 0 15px',
};
const stylesExtraInput = {
  width: 40,
  height: 18,
  borderRadius: 40,
  margin: '10px 0 15px 15px',
};

export const NotificationSkeleton = () => {
  return (
    <div>
      <div className="notif-item">
        <Skeleton.Button size="small" style={stylesExtraInput} active />
        <Skeleton.Button size="small" style={stylesSmallLongInput} active />
        <Skeleton.Button size="small" style={stylesSmallInput} active />
        <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
      </div>
      <div className="notif-item">
        <Skeleton.Button size="small" style={stylesExtraInput} active />
        <Skeleton.Button size="small" style={stylesSmallLongInput} active />
        <Skeleton.Button size="small" style={stylesSmallInput} active />
        <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
      </div>
      <div className="notif-item">
        <Skeleton.Button size="small" style={stylesExtraInput} active />
        <Skeleton.Button size="small" style={stylesSmallLongInput} active />
        <Skeleton.Button size="small" style={stylesSmallInput} active />
        <Skeleton.Button size="small" style={stylesExtraSmallInput} active />
      </div>
    </div>
  );
};
