import wl_small from '../../../assets/wl-small.svg';
import noData from '../../../assets/noData.svg';
import { Pagination } from '../Pagination';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useAppSelector } from '../../../hooks';
import { trimmer } from '../../../helpers/common';
import { message, Tooltip } from 'antd';
import arrow from '../../../assets/arrow-down.svg';
import { AdminItem, TableParamsAdmin } from '../../../api/types/admins';
import send from '../../../assets/send-new.svg';
import pause from '../../../assets/pause-new.svg';
import activate from '../../../assets/activate-new.svg';
import { useResendInvitationMutation } from '../../../api/mainAccounts';
import { useEffect } from 'react';
import { successMessage } from '../../Messages/messages';
import { useChangeStatusMutation } from '../../../api/mainAccounts';
import { MainAccTableItem } from '../../../api/types/mainAcc';

interface PartnersTableProps {
  data: MainAccTableItem[] | null;
  setTableParams: React.Dispatch<React.SetStateAction<any>>;
  tableParams: any;
  metaData: any;
}

export const MainAccountsTable = ({
  data,
  setTableParams,
  tableParams,
  metaData,
}: PartnersTableProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const [resendLinkRequest, { isSuccess: resendLinkSuccess }] =
    useResendInvitationMutation();
  const [changeStatusRequest] = useChangeStatusMutation();

  const resendLink = (id: string) => {
    resendLinkRequest(id);
  };
  const changeStatus = (user: any) => {
    if (user.status === 'Active') {
      changeStatusRequest({ id: user.id, key: { status_key: 1 } });
    }
    if (user.status === 'Paused') {
      changeStatusRequest({ id: user.id, key: { status_key: 2 } });
    }
  };

  useEffect(() => {
    resendLinkSuccess &&
      successMessage(messageApi, 'Invitation link has been successfully sent!');
  }, [resendLinkSuccess]);

  return (
    <>
      {contextHolder}
      <div>
        <div className="ticket-table">
          <div className="ticket-table__header">
            <div className="ticket-table__header__item short">ID</div>
            <div className="ticket-table__header__item long">Company Name</div>
            <div className="ticket-table__header__item account_type">
              Account Type
            </div>
            <div className="ticket-table__header__item">Status</div>
          </div>
          {data && data?.length === 0 && (
            <>
              {' '}
              {!tableParams.status_key &&
                !tableParams.search &&
                data?.length === 0 && (
                  <div className="ticket-table__empty-continer">
                    <div className="employees__empty">
                      <img src={noData} alt="team member" />
                      <div className="employees__empty__title">
                        No users yet
                      </div>
                      <div className="employees__empty__text">
                        Add new Main Account to start work with them
                      </div>
                    </div>
                  </div>
                )}
              {(tableParams.status_key || tableParams.search) &&
                data?.length === 0 && (
                  <div className="ticket-table__empty-continer">
                    <div className="employees__empty">
                      <img src={noData} alt="team member" />
                      <div className="employees__empty__title">
                        No results found
                      </div>
                      <div className="employees__empty__text">
                        Try adjusting your search to find what you are looking
                        for
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}

          {data?.map((item: any) => {
            return (
              <div
                className="ticket-table__row"
                onClick={() => {
                  navigate(`/main_accounts/${item.id}`);
                }}
              >
                <div className="ticket-table__row__item blue short">
                  {item.item_id}
                </div>
                <div className="ticket-table__row__item long d-flex justify-between">
                  {trimmer(item.company_name, 30)}
                  {!!item.white_labeled_account && (
                    <img src={wl_small} alt="white labled" />
                  )}
                </div>
                <div className="ticket-table__row__item account_type">
                  <Tooltip
                    title={item.account_types
                      ?.map((elem: any) => elem.name)
                      .join(', ')}
                  >
                    {item.account_types?.length
                      ? item.account_types
                          ?.map((elem: any) => elem.name)
                          .join(', ')
                      : '-'}
                  </Tooltip>
                </div>
                <div className="ticket-table__row__item d-flex justify-between">
                  <div
                    className={`team-table__status 
                                    ${item.status === 'Active' && 'active'}
                                    ${item.status === 'Paused' && 'paused'}
                                    ${item.status === 'Pending' && 'pending'}
                                    `}
                  >
                    <div
                      className={`team-table__dot 
                                            ${
                                              item.status === 'Active' &&
                                              'active'
                                            }
                                            ${
                                              item.status === 'Paused' &&
                                              'paused'
                                            }
                                            ${
                                              item.status === 'Pending' &&
                                              'pending'
                                            }
                                            `}
                    >
                      &#8226;
                    </div>
                    {item.status}
                  </div>
                  {item.status === 'Pending' && (
                    <Tooltip title="Resend invitation link">
                      <img
                        className="cursor-pointer ml-3"
                        src={send}
                        alt="send"
                        onClick={(e) => {
                          e.stopPropagation();
                          resendLink(item.id.toString());
                        }}
                      />
                    </Tooltip>
                  )}
                  {item.status === 'Active' && (
                    <Tooltip title="Pause">
                      <img
                        className="cursor-pointer ml-3"
                        src={pause}
                        alt="send"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeStatus(item);
                        }}
                      />
                    </Tooltip>
                  )}
                  {item.status === 'Paused' && (
                    <Tooltip title="Reactivate">
                      <img
                        className="cursor-pointer ml-3"
                        src={activate}
                        alt="send"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeStatus(item);
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {data && data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
