import { Button, FormInstance, Input, Modal, message } from 'antd';
import { FC, useState, useEffect, useRef } from 'react';
import { useVerifyCodeMutation } from '../../../api/applicants';
import { FormData } from './CreateApplications';
import './codeModal.scss';
import { isApiError } from '../../../utils/general';

interface ModalI {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<FormData>;
  type: string;
  setIsVerifiedPhone: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVerifiedEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IError {
  value: boolean;
  message: string;
}
export const CodeModal: FC<ModalI> = ({
  openModal,
  setOpenModal,
  form,
  type,
  setIsVerifiedPhone,
  setIsVerifiedEmail,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [errorPhone, setErrorPhone] = useState<IError>({
    value: false,
    message: '',
  });
  const [errorEmail, setErrorEmail] = useState<IError>({
    value: false,
    message: '',
  });
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '']);
  const inputRefs = useRef<any[]>([]);
  const [click, setClick] = useState(false);

  const [
    verifyCodePhone,
    {
      isSuccess: phoneCodeVerified,
      isError: errorCodeVarificationPhone,
      isLoading: isLoadingPhone,
    },
  ] = useVerifyCodeMutation();
  const [
    verifyCodeEmail,
    {
      isSuccess: emailCodeVerified,
      isError: errorCodeVarificationEmail,
      isLoading: isLoadingEmail,
    },
  ] = useVerifyCodeMutation();

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    setClick(false);
  }, [
    phoneCodeVerified,
    errorCodeVarificationPhone,
    emailCodeVerified,
    errorCodeVarificationEmail,
  ]);

  useEffect(() => {
    if (emailCodeVerified) {
      setOpenModal(false);
      setIsVerifiedEmail(true);
    }
  }, [emailCodeVerified]);

  useEffect(() => {
    if (phoneCodeVerified) {
      setOpenModal(false);
      setIsVerifiedPhone(true);
    }
  }, [phoneCodeVerified]);

  useEffect(() => {
    if (errorCodeVarificationPhone) {
      setErrorPhone({
        value: true,
        message:
          'The code is invalid. Please double-check it for any syntax errors, missing components, or typos',
      });
    }
  }, [errorCodeVarificationPhone]);

  useEffect(() => {
    if (errorCodeVarificationEmail) {
      setErrorEmail({
        value: true,
        message:
          'The code is invalid. Please double-check it for any syntax errors, missing components, or typos',
      });
    }
  }, [errorCodeVarificationEmail]);

  const handleSubmitFunc = async () => {
    setClick(true);
    const newOtp = [...otp];
    const isValid = newOtp.every(
      (digit) => digit !== '' && !isNaN(Number(digit))
    );

    if (type === 'phone') {
      if (newOtp.length - 1 && isValid) {
        const enteredOtp = otp.join('');
        setErrorPhone({ value: false, message: '' });
        const values = form.getFieldsValue();

        const data = {
          phone: values.phone,
          type: type,
          code: enteredOtp,
        };
        try {
          verifyCodePhone(data);
        } catch (error) {
          const message = isApiError(error) && (error.data.message as string);
          errorMessage(message || 'Verification phone code failed');
          return;
        }
      } else {
        setErrorPhone({ value: true, message: 'This field is required' });
      }
    }
    if (type === 'email') {
      if (newOtp.length - 1 && isValid) {
        const enteredOtp = otp.join('');
        setErrorEmail({ value: false, message: '' });
        const values = form.getFieldsValue();

        const data = {
          email: values.email,
          type: type,
          code: enteredOtp,
        };
        try {
          verifyCodeEmail(data);
        } catch (error) {
          const message = isApiError(error) && (error.data.message as string);
          errorMessage(message || 'Verification email code failed');
          return;
        }
      } else {
        setErrorEmail({ value: true, message: 'This field is required' });
      }
    }
  };

  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    const newOtp = [...otp];

    if (value.match(/[0-9]/gi)) {
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        const nextInput = e.currentTarget
          .nextElementSibling as HTMLInputElement | null;
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (
      e.nativeEvent instanceof InputEvent &&
      e.nativeEvent.inputType === 'deleteContentBackward'
    ) {
      newOtp[index] = '';
      setOtp(newOtp);
    }
  };

  const handleOnPasteOtp = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const data = e.clipboardData.getData('text');
    const value = data.split('');
    const isValidValue = value.every((item) => item.match(/[0-9]/gi));

    if (value.length === otp.length && isValidValue) {
      setOtp(value);

      setErrorPhone({ value: false, message: '' });
      setErrorEmail({ value: false, message: '' });
    } else {
      if (type === 'phone') {
        setErrorPhone({ value: true, message: 'Please, enter a valid code' });
      }
      if (type === 'email') {
        setErrorEmail({ value: true, message: 'Please, enter a valid code' });
      }
    }
  };

  const handleInputFocus = () => {
    const isSomethingEntered = otp.some((digit) => digit !== '');
    const isAllFilled = otp.every((digit) => digit !== '');

    if (!isAllFilled && isSomethingEntered) {
      if (type === 'phone') {
        setErrorPhone({ value: true, message: 'Please, enter a valid code' });
      }
      if (type === 'email') {
        setErrorEmail({ value: true, message: 'Please, enter a valid code' });
      }
    } else if (!isAllFilled && !isSomethingEntered) {
      if (type === 'phone') {
        setErrorPhone({ value: true, message: 'This field is required' });
      }
      if (type === 'email') {
        setErrorEmail({ value: true, message: 'This field is required' });
      }
    } else {
      setErrorPhone({ value: false, message: '' });
      setErrorEmail({ value: false, message: '' });
    }
  };

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.currentTarget.value;
    const input = e.currentTarget;

    if (e.nativeEvent.key === 'Backspace' && value === '') {
      const previousSibling =
        input.previousElementSibling as HTMLInputElement | null;

      if (previousSibling) {
        const newOtp = [...otp];
        newOtp[index - 1] = '';
        setOtp(newOtp);

        previousSibling.focus();
      }
    }
  };

  return (
    <Modal
      destroyOnClose
      className={`codeModal`}
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={<></>}
      closeIcon={<></>}
      width={344}
      maskClosable={true}
    >
      <div className="codeModal__container">
        <div className="codeModal__title">
          {type === 'phone' ? 'SMS' : 'Email'} Verification
        </div>
        <div className="codeModal__subtext">
          Please enter the 5-Digit Code you received on{' '}
          {form.getFieldValue(type)}
        </div>
        <div className="DigitCodePage">
          <div className="DigitCodePage__otp-field">
            {otp.map((digit, index) => (
              <Input
                key={index}
                ref={(input) => (inputRefs.current[index] = input)}
                className={`DigitCodePage__otp-input-input ${
                  type === 'phone'
                    ? errorPhone.value && 'DigitCodePage__otp-error-input'
                    : type === 'email'
                    ? errorEmail.value && 'DigitCodePage__otp-error-input'
                    : ''
                }`}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                onPaste={handleOnPasteOtp}
                onKeyDown={(e) => handleKeyUp(e, index)}
                onBlur={handleInputFocus}
                // disabled={isLoading}
              />
            ))}
          </div>

          {type === 'phone' && errorPhone.message && (
            <div className="error-text" style={{ margin: '10px 0 0 10px ' }}>
              {errorPhone.message}
            </div>
          )}
          {type === 'email' && errorEmail.message && (
            <div className="error-text" style={{ margin: '10px 0 0 10px ' }}>
              {errorEmail.message}
            </div>
          )}
        </div>

        <Button
          className={`blue-btn ${click ? 'disable' : ''}`}
          style={{ width: '100%', margin: '44px 0 16px 0', height: '59px' }}
          onClick={() => {
            if (isLoadingPhone || isLoadingEmail) {
              return;
            } else {
              handleSubmitFunc();
            }
          }}
        >
          Verify
        </Button>
        <Button
          className="gray-btn"
          style={{ width: '100%', height: '59px' }}
          onClick={() => setOpenModal(false)}
        >
          <span className="DigitCodePage__button-text">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};
