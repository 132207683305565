import { Table, Tooltip } from 'antd';
import { useState, useEffect, useRef } from 'react';
import arrow from '../../assets/arrow-down.svg';
import noData from '../../assets/noData.svg';
import NoUser from '../../assets/NoUser.svg';
import type { ColumnsType } from 'antd/es/table';
import { Pagination } from './Pagination';
import './index.scss';

import { useAppSelector } from '../../hooks';
import {
  TableParamsReports,
  TableItemReportAccount,
  Totals,
} from '../../api/types/reports';
import { MAIN_ACCOUNT, PARTNER } from '../../helpers/variables';

interface PartnersTableProps {
  data: TableItemReportAccount[];
  setTableParams: React.Dispatch<React.SetStateAction<TableParamsReports>>;
  metaData: any;
  tableParams: TableParamsReports;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  dataTotals?: Totals;
}

export const ReportsAccountTable = ({
  data,
  setTableParams,
  metaData,
  tableParams,
  dataTotals,
}: PartnersTableProps) => {
  const ref = useRef(null);
  const user = useAppSelector((state) => state.profile);
  const [tableData, setTableData] = useState<TableItemReportAccount[]>([]);
  useEffect(() => {
    if (!data) return;
    const newTable: any = [...data];
    !!data.length && newTable.push(dataTotals);
    setTableData(newTable);
  }, [data, dataTotals]);
  const sorting = (name: string) => {
    setTableParams((prev: TableParamsReports) => ({
      ...prev,
      order_by: name,
      order:
        prev.order_by === name
          ? prev.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));
  };

  const columns: ColumnsType<TableItemReportAccount> = [
    {
      title: (
        <div className="header-item" onClick={() => sorting('partner')}>
          Partner Account
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'partner' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      dataIndex: 'partner',
      key: 'partner',
      render: (text, record, index) => (
        <>
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('main_account')}>
          Main Account
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'main_account' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      dataIndex: 'main_account',
      key: 'main_account',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <Tooltip title={text}>
                <div className="table-text">{text ? text : '-'}</div>
              </Tooltip>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('applications')}>
          Applications
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'applications' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 95,
      dataIndex: 'applications',
      key: 'applications',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('no_offers')}>
          No Offer
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'no_offers' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 80,
      dataIndex: 'no_offers',
      key: 'no_offers',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('offers')}>
          Offer Made
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'offers' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 90,
      dataIndex: 'offers',
      key: 'offers',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Approved{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'approved' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 80,
      dataIndex: 'approved',
      key: 'approved',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('a_r')}>
          A/R{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'a_r' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 75,
      dataIndex: 'a_r',
      key: 'a_r',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                {' '}
                {text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('ala')}>
          ALA{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'ala' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 75,
      dataIndex: 'ala',
      key: 'ala',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                $
                {Math.round(text)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },

    {
      title: (
        <div className="header-item" onClick={() => sorting('loan_amount')}>
          Funding
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'loan_amount' &&
              tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 85,
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                $
                {Math.round(text)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },

    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Bank Fee
        </div>
      ),
      width: 100,
      dataIndex: 'bank_fee_amount',
      key: 'bank_fee_amount',
      render: (text, record, index) => (
        <div
          className={
            tableData.length - 1 === index ? 'table-item-footer' : 'table-item'
          }
        >
          <div className="table-text">
            ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Magwitch Fee
        </div>
      ),
      width: 100,
      dataIndex: 'processing_fee_amount',
      key: 'processing_fee_amount',
      render: (text, record, index) => (
        <div
          className={
            tableData.length - 1 === index ? 'table-item-footer' : 'table-item'
          }
        >
          <div className="table-text">
            ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Discount Fee
        </div>
      ),
      width: 100,
      dataIndex: 'discount_fee_amount',
      key: 'discount_fee_amount',
      render: (text, record, index) => (
        <div
          className={
            tableData.length - 1 === index ? 'table-item-footer' : 'table-item'
          }
        >
          <div className="table-text">
            ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Sent to Partner{' '}
        </div>
      ),
      width: 120,
      dataIndex: 'sent_to_partner',
      key: 'sent_to_partner',
      render: (text, record, index) => (
        <div
          className={
            tableData.length - 1 === index ? 'table-item-footer' : 'table-item'
          }
        >
          <div className="table-text">
            ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('approved')}>
          Part. Bonus
        </div>
      ),
      width: 120,
      dataIndex: 'participation_bonus_amount',
      key: 'participation_bonus_amount',
      render: (text, record, index) => (
        <div
          className={
            tableData.length - 1 === index ? 'table-item-footer' : 'table-item'
          }
        >
          <div className="table-text">
            ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="header-item" onClick={() => sorting('revenue')}>
          Revenue{' '}
          <img
            src={arrow}
            className={`header-image ${
              tableParams.order_by === 'revenue' && tableParams.order === 'asc'
                ? 'active'
                : ''
            }`}
            alt="arrow"
          />
        </div>
      ),
      width: 100,
      dataIndex: 'revenue',
      key: 'revenue',
      render: (text, record, index) => (
        <>
          {' '}
          {tableData.length - 1 === index ? (
            <div className="table-item-footer">
              <div className="table-text">
                ${text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          ) : (
            <div className="table-item">
              <div className="table-text">
                $
                {Math.round(text)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          )}
        </>
      ),
    },
  ];
  if (user.role.key === PARTNER || user.role.key === MAIN_ACCOUNT) {
    columns.splice(-2);
  }

  if (user.role.key === 'main_account') {
    const roleColumnIndex1 = columns.findIndex(
      (column) => column.key === 'revenue'
    );
    if (roleColumnIndex1 !== -1) {
      columns.splice(roleColumnIndex1, 1);
    }
    const roleColumnIndex2 = columns.findIndex(
      (column) => column.key === 'main_account'
    );
    if (roleColumnIndex2 !== -1) {
      columns.splice(roleColumnIndex2, 1);
    }
    const roleColumnIndex3 = columns.findIndex(
      (column) => column.key === 'bank_fee_amount'
    );
    if (roleColumnIndex3 !== -1) {
      columns.splice(roleColumnIndex3, 1);
    }
    const roleColumnIndex4 = columns.findIndex(
      (column) => column.key === 'processing_fee_amount'
    );
    if (roleColumnIndex4 !== -1) {
      columns.splice(roleColumnIndex4, 1);
    }
  }

  const customNoDataText = {
    emptyText: (
      <>
        {!tableParams.date_from && !tableParams.search && (
          <div className="no-data-found">
            <img src={NoUser} alt="no-user" />
            <div className="no-data-found__text">No reports yet</div>
          </div>
        )}
        {(tableParams.date_from || tableParams.search) && (
          <div className="no-data-found">
            <img src={noData} alt="no-data" />
            <div className="no-data-found__text">No results found</div>
            <div className="no-data-found__subtext">
              Try adjusting your search to find what you are looking for
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <>
      <div>
        <Table
          ref={ref}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          locale={customNoDataText}
          // footer={footerContent}
        />
      </div>

      {data?.length > 0 && (
        <Pagination
          setTableParams={setTableParams}
          metaData={metaData}
          tableParams={tableParams}
        />
      )}
    </>
  );
};
