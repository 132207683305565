import { useNavigate } from 'react-router-dom';
import { SetStateAction, useEffect, useState } from 'react';
import edit from '../../../assets/edit.svg';
import { useGetAccountTypesQuery } from '../../../api/common';
import { Ficotable } from '../EditLenders/Ficotable';
import { LoanProps } from '../EditLenders/LoanTypeTab';
import './index.scss';
import { DiscountFeeTable } from '../../../components/Tables/DiscountFeeTable';

interface PropsI {
  data: any;
  tab: string;
}

export const LoanTypeView = ({ data, tab }: PropsI) => {
  const navigate = useNavigate();
  const { data: accountTypesData } = useGetAccountTypesQuery();
  const [element, setElement] = useState<LoanProps | null>(null);

  useEffect(() => {
    if (!data.data) return;
    const element = data.data.loan_types.find(
      (elem: LoanProps) => elem.name === tab
    );
    if (element) {
      setElement(element);
    }
  }, [tab, data.data]);

  return (
    <div className="new-member">
      <div className="view-member">
        <div className="view-member__block block">
          <div className="block__row">
            <div className="block__item small" style={{ margin: '0' }}>
              <div className="block__item-title">Personal/Commercial</div>
              <div className="block__item-value">
                {element?.personal && element?.commercial ? 'Both' : ''}
                {element?.personal && !element?.commercial ? 'Personal' : ''}
                {element?.commercial && !element?.personal ? 'Commercial' : ''}
                {!element?.commercial && !element?.personal ? 'Both' : ''}
              </div>
            </div>
            <div className="block__item small" style={{ margin: '0' }}>
              <div className="block__item-title">Secured/Unsecured</div>
              <div className="block__item-value">
                {element?.secured ? 'Secured' : 'Unsecured'}
              </div>
            </div>
          </div>
          <div className="block__row">
            <div className="block__item small" style={{ margin: '10px 0' }}>
              <div className="block__item-title">Bank Fee</div>
              <div className="block__item-value">
                {element?.bank_fee
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                %
              </div>
            </div>
          </div>
          <div style={{ margin: '15px 0 0 0' }}>
            <DiscountFeeTable
              fees={element?.processing_fees ?? []}
              accountTypesData={accountTypesData?.data}
              error={false}
            />
          </div>
        </div>
        <div className="view-member__block block">
          <div className="block__row">
            <div className="block__item small" style={{ margin: '0' }}>
              <div className="block__item-title">Coverage Amount</div>
              <div className="block__item-value">
                {element?.coverage_amount_min
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                - $
                {element?.coverage_amount_max
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </div>
          </div>
          <div className="block__top">
            <div className="block__edit-wrapper">
              <img
                src={edit}
                className="block__edit"
                onClick={() => navigate(`/lenders/edit/${data?.data?.id}`)}
              />
            </div>
            {element?.fico_ranges.length ? (
              <div className="block__item" style={{ margin: '15px 0 0 0' }}>
                <div className="custom-field__title">
                  FICO Buckets (reference)
                </div>
                <Ficotable
                  ficoData={element?.fico_ranges ?? []} //@ts-ignore
                  id={tab === 'Lease-to-own' ? 3 : 1}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
