import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip, AreaChart, Area,
} from 'recharts';
import dayjs from 'dayjs';
import {
  formatNumberToCurrency,
  formatNumberToCurrencyWithoutDollar,
} from '../../pages/Dashboard/features';
import moment from 'moment';

interface dataI {
  data: { name: string; uv: number; pv: number }[];
  values: string[];
  breakdown: string;
  noCompApplied: boolean;
  type: string;
}

export const YourChartComponent: React.FC<dataI> = ({
  data,
  values,
  breakdown,
  noCompApplied,
  type,
}) => {
  const Week = (date: any) => {
    const [year, week] = date.split(' ');

    let first = moment()
      .isoWeekYear(Number(year))
      .isoWeek(Number(week))
      .startOf('isoWeek')
      .format('MM/DD/YYYY');
    let last = moment()
      .isoWeekYear(Number(year))
      .isoWeek(Number(week))
      .endOf('isoWeek')
      .format('MM/DD/YYYY');

    return `${first} - ${last}`;
  };

  const CustomActiveDot = (props:any) => {
    const { cx, cy, fill } = props;

    return (
        <g>
          <circle cx={cx} cy={cy} r={7} fill="url(#radialGradient)" />
          {/* Define radial gradient */}
          <defs>
            <radialGradient id="radialGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
              <stop offset="30%" stopColor={fill} />
              <stop offset="50%" stopColor={'#E3ECFD'}  />
            </radialGradient>
          </defs>
        </g>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={
            breakdown === 'Weekly' || breakdown === 'Hourly'
              ? { width: '280px' }
              : {}
          }
        >
          <div className="custom-tooltip__title">{type}</div>

          <div className="custom-tooltip__row mb-4">
            <div className="custom-tooltip__graytext">
              <svg
                strokeWidth="2"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
              >
                <circle cx="4" cy="4.5" r="4" fill="#1F44DD" />
              </svg>{' '}
              {breakdown === 'Hourly' &&
                dayjs(payload[0]?.payload?.CurDate)
                  .locale('en')
                  .format('MM DD YYYY hh:mm A')}
              {breakdown === 'Daily' &&
                dayjs(payload[0]?.payload?.CurDate)
                  .locale('en')
                  .format('DD MMMM YYYY')}
              {breakdown === 'Weekly' && Week(payload[0]?.payload?.CurDate)}
              {breakdown === 'Monthly' &&
                dayjs(payload[0]?.payload?.CurDate)
                  .locale('en')
                  .format('MMMM YYYY')}
            </div>
            <div className="custom-tooltip__blacktext">
              {formatNumberToCurrency(
                payload[0]?.payload.CurValue ? payload[0]?.payload.CurValue : 0
              )}
            </div>
          </div>

          {!noCompApplied && (
            <div className="custom-tooltip__row">
              <div className="custom-tooltip__graytext">
                <svg
                  strokeWidth="2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="9"
                  viewBox="0 0 8 9"
                  fill="none"
                >
                  <circle cx="4" cy="4.5" r="4" fill="#0B4A6F" />
                </svg>{' '}
                {breakdown === 'Hourly' &&
                  dayjs(payload[1]?.payload?.ComDate)
                    .locale('en')
                    .format('DD MMMM YYYY hh:mm A')}
                {breakdown === 'Daily' &&
                  dayjs(payload[1]?.payload?.ComDate)
                    .locale('en')
                    .format('DD MMMM YYYY')}
                {breakdown === 'Weekly' && Week(payload[0]?.payload?.ComDate)}
                {breakdown === 'Monthly' &&
                  dayjs(payload[1]?.payload?.ComDate)
                    .locale('en')
                    .format('MMMM YYYY')}
              </div>
              <div className="custom-tooltip__blacktext">
                {formatNumberToCurrency(
                  payload[0]?.payload.ComValue
                    ? payload[0]?.payload.ComValue
                    : 0
                )}
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: 0,
            bottom: 50,
          }}
          //@ts-ignore
          // xAxis={null}
        >
          <defs>
            <linearGradient id="colorComValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0B4A6F" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#0B4A6F" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorCurValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1F44DD" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#1F44DD" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#DBEAFE" horizontal={true} vertical={false} />

          <XAxis dataKey="CurData" />
          <YAxis
            tickCount={3}
            tick={(props: any) => (
              <text x={props.x} y={props.y} textAnchor="end" fill="#666666" fontSize={12} >
                {formatNumberToCurrencyWithoutDollar(props.payload.value)}
              </text>
            )}
          />
          <Tooltip
              content={<CustomTooltip />}
              //@ts-ignore
              cursor={{ stroke: false }}
          />
          <Legend />
          <Area
              type="monotone"
            dataKey="ComValue"
            stroke="#0B4A6F"
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorComValue)"
            activeDot={{ r: 0}}
          />
          <Area
              type="monotone"
              dataKey="CurValue"
              stroke="#1F44DD"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorCurValue)"
              activeDot={<CustomActiveDot />}
          />
        </AreaChart>
      </ResponsiveContainer>
      <div className="values">
        <div>{values[0]}</div>
        <div>{values[1]}</div>
      </div>
    </>
  );
};
