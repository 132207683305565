import moment from 'moment';
import { Spinner } from '../../components/Spinner/Spinner';
import useCustomerRecordController from './customer-record';
import { ApplicationsTable } from '../../components/Tables/ApplicationsTable';
import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { SubsequentPurchaseModal } from '../../components/Modals/SubsequentPurchaseModal/SubsequentPurchaseModal';
import { useAppSelector } from '../../hooks';
import { UserI } from '../../reducers/profile/profileSlice';
import { PARTNER } from '../../helpers/variables';
import { ProfileSkeleton } from '../../components/Skeletons/ProfileSkeleton';
import './index.scss';

export const CustomerRecord = () => {
  const {
    navigate,
    customerInfo,
    isCustomerInfoLoading,
    options,
    tableData,
    setTableParams,
    tableParams,
    setTrigger,
    handleGetConcoraBalance,
    concoraBalance,
    isLoadingBalance,
    refetchCustomer,
    refetchApplicationHistoryList,
  } = useCustomerRecordController();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [messageApi, contextHolder] = message.useMessage();
  const [openModal, setOpenModal] = useState(false);
  const [successPurchase, setSuccessPurchase] = useState<boolean | string>(
    false
  );

  const successMessage = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  useEffect(() => {
    if (successPurchase) {
      successMessage('Subsequent purchase request has been successfully sent');
      setSuccessPurchase(false);
      refetchCustomer();
      refetchApplicationHistoryList();
    }
  }, [successPurchase]);

  const handleBalanceClick = () => {
    if (concoraBalance) {
      setOpenModal(true);
    } else {
      handleGetConcoraBalance();
    }
  };

  return (
    <div className="container">
      {contextHolder}
      {isCustomerInfoLoading ? (
        <ProfileSkeleton />
      ) : (
        <div className="new-member">
          <div className="view-member">
            <div className="CustomerRecord">
              <div className="CustomerRecord__content">
                <div className="CustomerRecord__sub-block CustomerRecord__left-side">
                  <div className="CustomerRecord__user-info-container">
                    <div className="CustomerRecord__user-info">
                      <p className="CustomerRecord__user-name">
                        {customerInfo?.data?.first_name}{' '}
                        {customerInfo?.data?.last_name}
                      </p>
                      <p className="CustomerRecord__user-id-container">
                        <p className="CustomerRecord__user-id-label">ID#</p>
                        <p className="CustomerRecord__user-id">
                          {customerInfo?.data.public_id}
                        </p>
                      </p>
                    </div>
                    <div className="CustomerRecord__contact-info">
                      <p className="CustomerRecord__header-label">
                        Contact info
                      </p>
                      <div className="CustomerRecord__info-item">
                        <div className="CustomerRecord__user-info-item-wrapper">
                          <p className="CustomerRecord__label">
                            Contact Number
                          </p>
                          <p className="CustomerRecord__additional-info">
                            {customerInfo?.data.contact_number}
                          </p>
                        </div>
                        <div className="CustomerRecord__user-city">
                          <p className="CustomerRecord__label">Contact Email</p>
                          <p className="CustomerRecord__additional-info">
                            {customerInfo?.data.contact_email}
                          </p>
                        </div>
                      </div>
                      {user.role.key !== PARTNER && (
                        <div className="CustomerRecord__info-item">
                          <div className="CustomerRecord__user-info-item-wrapper">
                            <p className="CustomerRecord__label">Partner</p>
                            <p className="CustomerRecord__additional-info">
                              {customerInfo?.data.partner?.company_name}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="CustomerRecord__sub-block CustomerRecord__right-side">
                  <div className="CustomerRecord__date-block">
                    <p className="CustomerRecord__date-label">Date Created</p>
                    <p className="CustomerRecord__date">
                      {moment(customerInfo?.data?.created_at).format('LL')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user.role.key === PARTNER &&
            customerInfo?.data?.additional_data?.genesis && (
              <p className="CustomerRecord__bank-label">
                <img
                  src={
                    customerInfo?.data?.additional_data?.genesis.bank_logo ?? ''
                  }
                  className="CustomerRecord__bank-logo"
                  alt="bank logo"
                />
                {customerInfo?.data?.additional_data?.genesis.bank_name}
              </p>
            )}
          {user.role.key === PARTNER &&
            customerInfo?.data?.additional_data?.genesis && (
              <div className="view-member">
                <div className="CustomerRecord">
                  <p className="CustomerRecord__bank-text">
                    This customer has previously been approved for a{' '}
                    {customerInfo?.data?.additional_data?.genesis.bank_name}!
                  </p>
                  <div className="d-flex align-center">
                    <div>
                      <p className="OfferItem__name mb-3">Available Credit</p>
                      <p className="OfferItem__value bold">
                        $
                        {concoraBalance?.data.availableCredit ??
                          customerInfo?.data?.additional_data?.genesis
                            ?.availableCredit}
                      </p>
                    </div>
                    <Button
                      className="violet-btn CustomerRecord__refresh-btn"
                      onClick={handleBalanceClick}
                      disabled={isLoadingBalance}
                    >
                      {!concoraBalance ? (
                        <div className="d-flex align-center">
                          Refresh Balance{' '}
                          {isLoadingBalance && <Spinner size="small" />}
                        </div>
                      ) : (
                        'Make Subsequent Purchase'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}

          <div className="CustomerRecord__table">
            <p className="CustomerRecord__table-label">Application history</p>
            <div className="CustomerRecord__list">
              <ApplicationsTable
                data={tableData}
                setTableParams={setTableParams}
                tableParams={tableParams}
                metaData={options.list}
                setTrigger={setTrigger}
                isCard
              />
            </div>
          </div>
        </div>
      )}
      <SubsequentPurchaseModal
        openModal={openModal}
        setSuccessPurchase={setSuccessPurchase}
        setOpenModal={setOpenModal}
        phone={customerInfo?.data.contact_number}
        email={customerInfo?.data.contact_email}
        availableAmount={concoraBalance?.data.availableCredit}
      />
    </div>
  );
};
