import { Spinner } from '../../../components/Spinner/Spinner';
import plus from '../../../assets/plus.svg';
import team_member from '../../../assets/team-member-new.svg';
import './index.scss';
import { Button, message } from 'antd';
import { EmployeesTable } from '../../../components/Tables/EmploeesTable';
import { useCallback, useEffect, useState } from 'react';
import { Employee } from '../../../api/types/common';
import { AddEmployeeModal } from '../../../components/Modals/AddEmployeeModal';
import { useAppSelector } from '../../../hooks';
import { UserI } from '../../../reducers/profile/profileSlice';
import { successMessage } from '../../../components/Messages/messages';
import { ADMIN, SUPER_ADMIN } from '../../../helpers/variables';
import { useLazyGetAdminsListQuery } from '../../../api/admins';
import { AdminsManagementTable } from '../../../components/Tables/AdminsManagementTable';
import { AdminItem } from '../../../api/types/admins';
import { AddAdminModal } from '../../../components/Modals/AddAdminModal';
import { TableSkeleton } from '../../../components/Skeletons/TableSkeleton';
import { AnotheTableSkeleton } from '../../../components/Skeletons/AnotheTableSkeleton';

export const AdminsManagment = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAppSelector((state: { profile: UserI }) => state.profile);
  const [getUserList, { data: users, isLoading }] = useLazyGetAdminsListQuery();
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [editEmployee, setEditEmployee] = useState<AdminItem | null>(null);
  const [order, setOrder] = useState<string>('desc');
  const setFilters = useCallback(() => {
    order === 'desc' ? setOrder('asc') : setOrder('desc');
  }, [order]);

  useEffect(() => {
    getUserList({
      per_page: 100,
      order_by: '',
      order: order,
      search: '',
      status_key: '',
      page: 1,
    });
  }, [order]);

  const createSuccess = useCallback((edit: boolean) => {
    successMessage(
      messageApi,
      `User has been successfully ${edit ? 'saved' : 'added'}!`
    );
  }, []);

  return (
    <>
      {contextHolder}
      {isLoading ? (
        <AnotheTableSkeleton />
      ) : (
        <div className="employees__table">
          <div className="employees__main-user">
            <div>
              <img
                className="employees__main-user__logo"
                src={user.logo}
                alt="logo"
              />
            </div>
            <div>
              <div className="employees__name">
                {user?.model.first_name} {user?.model.last_name} (Account
                holder)
              </div>
              <div className="employees__id">
                #{user?.model.item_id ?? user?.model.id}
              </div>
            </div>
          </div>
          <div className="employees__title_row">
            <div>
              <span className="employees__title">Admins</span>
              {!!users?.data.length && (
                <span className="employees__bage">
                  {users?.data.length} users
                </span>
              )}
            </div>

            {user.role.key === SUPER_ADMIN && (
              <Button
                className="blue-btn h-40"
                onClick={() => setAddEmployeeModal(true)}
              >
                <img src={plus} alt="plus" className="mr-4" />
                Add User
              </Button>
            )}
          </div>
          {!!users?.data.length && (
            <AdminsManagementTable
              employees={users?.data || []}
              view={user.role.key === ADMIN}
              setAddEmployeeModal={setAddEmployeeModal}
              setEditEmployee={setEditEmployee}
              order={order}
              setFilters={setFilters}
            />
          )}
          {users?.data.length === 0 && (
            <div className="employees__empty">
              <img src={team_member} alt="team member" />
              <div className="employees__empty__title">No admins yet</div>
              <div className="employees__empty__text">
                Add new admin to start collaboration.
              </div>
            </div>
          )}
        </div>
      )}
      <AddAdminModal
        openModal={addEmployeeModal}
        setOpenModal={setAddEmployeeModal}
        editEmployee={editEmployee}
        setEditEmployee={setEditEmployee}
        createSuccess={createSuccess}
      />
    </>
  );
};
