import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, message } from 'antd';
import { isApiError } from '../../utils/general';
import { useLogoutMutation } from '../../api/common';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { useAppSelector } from '../../hooks';
import { SettingsIcon } from '../icons/SettingsIcon';
import { Notifications } from '../Notifications/Notifications';
import { NotifIcon } from '../icons/NotifIcon';
import close from '../../assets/x-close.svg';
import { TicketingMenu } from '../LayoutBlocks/TicketingMenu';
import { SubmitTicketModal } from '../Modals/SubmitTicketModal/SubmitTicketModal';
import { InfoBlock } from '../InfoBlock/InfoBlock';
import { RocketIcon } from '../icons/RocketIcon';
import plus from '../../assets/plus-white-small.svg';
import chevron from '../../assets/chevron-right-small.svg';
import './index.scss';

import { useDispatch } from 'react-redux';
import {
  setOpenCreatePartner as setOpenPartnerModal,
  setOpenSubmitTicket as setOpenModal,
} from '../../reducers/tabs/tabsSlice';
import { AnyAction } from '@reduxjs/toolkit';
import { HomeIcon } from '../icons/HomeIcon';
import { ArrowIcon } from '../icons/ArrowIcon';
import {
  ADMIN,
  MAIN_ACCOUNT,
  PARTNER,
  SUPER_ADMIN,
} from '../../helpers/variables';
import { SupportIcon } from '../icons/SupportIcon';

export const Header = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setOpenSubmitTicket = (payload: boolean): AnyAction =>
    dispatch(setOpenModal(payload));
  const setOpenCreatePartner = (payload: boolean): AnyAction =>
    dispatch(setOpenPartnerModal(payload));

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openNotif, setOpenNotif] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [open2Fmodal, setOpen2Fmodal] = useState(false);
  const [openTickets, setOpenTickets] = useState<boolean>(false);
  const [hoverSupport, setHoverSupport] = useState<boolean>(false);
  const [hoverRocket, setHoverRocket] = useState<boolean>(false);
  const [hoverNotif, setHoverNotif] = useState<boolean>(false);
  const [hoverSettings, setHoverSettings] = useState<boolean>(false);

  const [verifySuccess, setVerifySuccess] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [logout] = useLogoutMutation();
  const navigation = useNavigate();
  const ref = useRef(null);
  const refInfo = useRef(null);
  const refNotif = useRef(null);
  const refTickets = useRef(null);
  useClickOutside(ref, () => setOpenMenu(false));
  useClickOutside(refNotif, () => setOpenNotif(false));
  useClickOutside(refTickets, () => setOpenTickets(false));
  useClickOutside(refInfo, () => setOpenInfo(false));
  const location = useLocation();
  const user = useAppSelector((state) => state.profile);
  const isAuth = localStorage.getItem('token');
  const [openBillInfo, setOpenBillInfo] = useState(false);
  const isSettings = location.pathname.includes('settings');
  const noTitle = location.pathname === '/';
  const isTicketList = location.pathname === '/ticket-list';
  const isTicket = location.pathname === `/ticket/${id}`;
  const isDashboard = location.pathname === '/dashboard';
  const isMainAccounts = location.pathname === '/main_accounts';
  const isMainAccount = location.pathname === `/main_accounts/${id}`;
  const isCreateMainAccount =
    location.pathname === '/new-main-account' ||
    location.pathname.includes('main_accounts/edit/');
  const isPartners = location.pathname === '/partners';
  const isPartner = location.pathname === `/partners/${id}`;
  const isPartnerEdit =
    location.pathname === `/partners/edit/partner/${id}` ||
    location.pathname === `/new-partner/related_partner` ||
    location.pathname === `/new-partner/partner` ||
    location.pathname === `/new-partner/external_partner`;
  const isReports = location.pathname === '/reports';
  const isApplications = location.pathname === '/applications';
  const isApplicationEdit =
    location.pathname === `/applications/edit/${id}` ||
    location.pathname === `/new-application`;
  const isCustomers = location.pathname === '/customers';
  const isCustomer = location.pathname === `/customers/${id}`;
  const isLenders = location.pathname === '/lenders';
  const isLender = location.pathname === `/lenders/${id}`;
  const isLenderEdit = location.pathname === `/lenders/edit/${id}`;

  useEffect(() => {
    setOpenBillInfo(user.need_bill_2fa);
  }, [user.need_bill_2fa]);

  const successMessage = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text,
      style: {
        marginTop: '90vh',
      },
    });
  };

  const ticketCreateSuccess = () => {
    successMessage('Your ticket has been successfully submitted!');
  };

  useEffect(() => {
    if (verifySuccess) {
      successMessage(
        '2FA for Bill.com has been successfully passed. All transactions are resumed.'
      );
      setVerifySuccess(false);
    }
  }, [verifySuccess]);

  const errorMessage = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text,
    });
  };

  useEffect(() => {
    if (!isAuth) {
      navigation('/login');
    }
  }, [isAuth]);

  return (
    <>
      {contextHolder}
      <div className="header">
        {noTitle && <div>&nbsp;</div>}
        {isTicketList && (
          <div className="header__title-container">
            <div className="header__title">Tickets</div>
            {(user.role.key === PARTNER || user.role.key === MAIN_ACCOUNT) && (
              <Button
                className="blue-btn"
                onClick={() => setOpenSubmitTicket(true)}
              >
                <img src={plus} alt="plus" className="mr-1" />
                New Ticket
              </Button>
            )}
          </div>
        )}
        {isTicket && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/ticket-list')}
              >
                Tickets
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                Ticket #{id}
              </div>
            </div>
          </div>
        )}
        {isDashboard && (
          <div className="header__title-container">
            <div className="header__title">Dashboard</div>
          </div>
        )}
        {isSettings && (
          <div className="header__title-container">
            <div className="header__title">Settings</div>
          </div>
        )}
        {isPartners && (
          <div className="header__title-container">
            <div className="header__title">Partners</div>
            {(user.role.key === SUPER_ADMIN || user.role.key === ADMIN) && (
              <Button
                className="blue-btn"
                onClick={() => setOpenCreatePartner(true)}
              >
                <img src={plus} alt="plus" className="mr-1" />
                Add new
              </Button>
            )}
          </div>
        )}
        {isPartner && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/partners')}
              >
                Partners
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                Partner Profile
              </div>
            </div>
          </div>
        )}
        {isPartnerEdit && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/partners')}
              >
                Partners
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                {location.pathname.includes('edit') ? 'Edit' : 'Create'} Partner
              </div>
            </div>
          </div>
        )}
        {isReports && (
          <div className="header__title-container">
            <div className="header__title">Reports</div>
          </div>
        )}
        {isLenders && (
          <div className="header__title-container">
            <div className="header__title">Lenders</div>
          </div>
        )}
        {isLender && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/lenders')}
              >
                Lenders
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                Lender Profile
              </div>
            </div>
          </div>
        )}
        {isLenderEdit && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <ArrowIcon fill="" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/lenders')}
              >
                Lenders
              </div>
              <div className="breadscrumbs__separatop">
                <ArrowIcon fill="#4D4D56" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                {location.pathname?.includes('edit') ? 'Edit' : 'Create'}{' '}
                Lenders
              </div>
            </div>
          </div>
        )}
        {isCustomers && (
          <div className="header__title-container">
            <div className="header__title">Customers</div>
          </div>
        )}
        {isCustomer && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  navigate('/dashboard');
                }}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  navigate('/customers');
                }}
              >
                Customers
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                Customer Profile
              </div>
            </div>
          </div>
        )}
        {isApplications && (
          <div className="header__title-container">
            <div className="header__title">Applications</div>
            {/* app flow */}
            {/* {user.role.key === PARTNER && (
              <Button
                className="blue-btn"
                onClick={() => navigate('/new-application')}
              >
                <img src={plus} alt="plus" className="mr-1" />
                Add new
              </Button>
            )} */}
          </div>
        )}
        {isApplicationEdit && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  navigate('/dashboard');
                }}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => {
                  navigate('/applications');
                }}
              >
                Applications
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                View Application
              </div>
            </div>
          </div>
        )}
        {isMainAccounts && (
          <div className="header__title-container">
            <div className="header__title">Main Accounts</div>
            <Button
              className="blue-btn"
              onClick={() => navigate('/new-main-account')}
            >
              <img src={plus} alt="plus" className="mr-1" />
              Add new
            </Button>
          </div>
        )}
        {isCreateMainAccount && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/main_accounts')}
              >
                Main Accounts
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                {location.pathname.includes('edit') ? 'Edit' : 'Create'} Main
                Account
              </div>
            </div>
          </div>
        )}
        {isMainAccount && (
          <div className="header__title-container">
            <div className="new-member__breadscrumbs breadscrumbs">
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/dashboard')}
              >
                <HomeIcon fill="" />
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div
                className="breadscrumbs__title"
                onClick={() => navigate('/main_accounts')}
              >
                Main Accounts
              </div>
              <div className="breadscrumbs__separatop">
                <img src={chevron} alt="chevron right" />
              </div>
              <div className="breadscrumbs__title breadscrumbs__title-active">
                Main account profile
              </div>
            </div>
          </div>
        )}
        <div className="header__row">
          <div className="header__settings">
            {user &&
              user?.role?.key === 'partner' &&
              !(
                user?.model?.loan_products?.length === 1 &&
                user?.model?.loan_products[0].id === 26
              ) && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenInfo((prevState) => !prevState);
                    setOpenTickets(false);
                    setOpenNotif(false);
                  }}
                  onMouseEnter={() => {
                    setHoverRocket(true);
                  }}
                  onMouseLeave={() => {
                    setHoverRocket(false);
                  }}
                  className="header__image"
                >
                  <RocketIcon
                    fill={
                      hoverRocket && !openInfo
                        ? '#475467'
                        : openInfo
                        ? '#1F44DD'
                        : '#808086'
                    }
                  />
                </div>
              )}
            <div className="header__wrapper-notif">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenNotif((prevState) => !prevState);
                  setOpenTickets(false);
                  setOpenInfo(false);
                }}
                onMouseEnter={() => {
                  setHoverNotif(true);
                }}
                onMouseLeave={() => {
                  setHoverNotif(false);
                }}
                className="header__image"
              >
                <NotifIcon
                  fill={
                    hoverNotif && !openNotif
                      ? '#475467'
                      : openNotif
                      ? '#1F44DD'
                      : '#808086'
                  }
                />
              </div>
              {user.notifications ? (
                <div className="header__notif-count"></div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="header__image"
              onClick={(e) => {
                e.stopPropagation();
                setOpenTickets((prevState) => !prevState);
                setOpenNotif(false);
                setOpenInfo(false);
              }}
              onMouseEnter={() => {
                setHoverSupport(true);
              }}
              onMouseLeave={() => {
                setHoverSupport(false);
              }}
            >
              <SupportIcon
                fill={
                  location.pathname === '/ticket-list' || openTickets
                    ? '#1F44DD'
                    : hoverSupport && !openTickets
                    ? '#475467'
                    : '#667085'
                }
              />
            </div>
            {openTickets && (
              <TicketingMenu
                refTickets={refTickets}
                setOpenTickets={setOpenTickets}
              />
            )}
            <div
              className="header__image"
              onClick={(e) => {
                navigation('/settings');
              }}
              onMouseEnter={() => {
                setHoverSettings(true);
              }}
              onMouseLeave={() => {
                setHoverSettings(false);
              }}
            >
              <SettingsIcon
                fill={
                  isSettings || openTickets
                    ? '#1F44DD'
                    : hoverSettings && !openTickets
                    ? '#475467'
                    : '#667085'
                }
              />
            </div>
          </div>
          {user && user?.role?.key === 'partner' && (
            <InfoBlock
              refInfo={refInfo}
              open={openInfo}
              errorMessage={errorMessage}
            />
          )}

          <Notifications
            refNotif={refNotif}
            openNotif={openNotif}
            setOpenNotif={setOpenNotif}
            openModal={open2Fmodal}
            setOpenModal={setOpen2Fmodal}
            setVerifySuccess={setVerifySuccess}
          />
          {!openNotif && openBillInfo && user.need_bill_2fa && (
            <div className={`notif__container ${openBillInfo ? 'open' : ''}`}>
              <div
                className="notif__main"
                style={{ padding: '2px 25px 2px 0' }}
              >
                <div className="notif__bill">
                  <div className="notif__bill-text">
                    Your transactions have been suspended. 2FA for Bill.com is
                    required.
                  </div>
                  <div
                    className="notif__bill-btn violet-btn"
                    onClick={() => {
                      setOpen2Fmodal(true);
                    }}
                  >
                    Pass 2FA
                  </div>
                  <div>
                    <img
                      src={close}
                      onClick={() => setOpenBillInfo(false)}
                      className="notif__close-btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*{user.role.key === SUPER_ADMIN && <div ref={ref}>*/}
          {/*  <div className="header__user-info">*/}
          {/*    <div*/}
          {/*      className={`header__profile ${openMenu ? 'open' : ''}`}*/}
          {/*      onClick={() => setOpenMenu((prev: boolean) => !prev)}*/}
          {/*    >*/}
          {/*      <div className="header__name">{truncatedUserName}</div>*/}
          {/*      <img*/}
          {/*        className={`header__arrow ${openMenu ? 'open' : ''}`}*/}
          {/*        src={arrow}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className={`header__menu */}
          {/*  ${openMenu ? '' : 'close'}`}>*/}
          {/*    {user.role.key === SUPER_ADMIN && (*/}
          {/*      <div*/}
          {/*        className={`header__my-profile ${*/}
          {/*          location.pathname.includes('admins') ? 'active' : ''*/}
          {/*        }`}*/}
          {/*        onClick={() => {*/}
          {/*          navigation('/admins');*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <SettingsIcon*/}
          {/*          fill={*/}
          {/*            location.pathname.includes('admins') ? 'white' : `#1A1A26`*/}
          {/*          }*/}
          {/*        />*/}
          {/*        <div*/}
          {/*          className="header__item-title"*/}
          {/*          style={{ margin: '0 0 0 8px' }}*/}
          {/*        >*/}
          {/*          Manage Admins*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*    /!*{(user.role.key == ADMIN) && (*!/*/}
          {/*    /!*  <div className="header__my-profile" onClick={viewProfile}>*!/*/}
          {/*    /!*    <PartnersIcon fill="#1A1A26" />*!/*/}
          {/*    /!*    <div className="header__item-title">My Profile</div>*!/*/}
          {/*    /!*  </div>*!/*/}
          {/*    /!*)}*!/*/}
          {/*    <div className="header__logout" onClick={logoutFunc}>*/}
          {/*      <div>Log Out</div>*/}
          {/*      <img src={logoutImage} />*/}
          {/*    </div>*/}
          {/*    <div></div>*/}
          {/*  </div>*/}
          {/*</div>}*/}
        </div>
        <SubmitTicketModal ticketCreateSuccess={ticketCreateSuccess} />
      </div>
    </>
  );
};
