import {calcPercentWithChart, formatNumberToCurrency} from "../features";
import {useAppSelector} from "../../../hooks";

interface CardSmallI {
    title: string
    dataCurrentTotals: any
    dataComparedTotals: any
    noCompApplied: boolean
    filters: any
    prevComp: string
    formatted?: boolean

}

export const CardSmall = ({
                              title,
                              dataComparedTotals,
                              dataCurrentTotals,
                              noCompApplied,
                              filters,
                              prevComp,
                              formatted
                          }: CardSmallI) => {
    const user = useAppSelector((state) => state.profile);

    return (
        <div
            className={`dashboard__card card ${
                user?.role?.key === 'partner' ||
                user?.role?.key === 'main_account'
                    ? 'partner'
                    : ''
            }`}
        >
            <div className="card__column first">
                <div className="card__title">{title}</div>
                <div className="card__number">
                    {!formatted ?
                        dataCurrentTotals :
                        formatNumberToCurrency(
                        dataCurrentTotals
                            ? dataCurrentTotals
                            : 0
                    )}
                </div>
                <div className="card__period">
                    {!formatted ?
                        dataComparedTotals
                        : formatNumberToCurrency(
                            dataComparedTotals
                                ? dataComparedTotals
                                : 0
                        )}
                    <br/>
                    {!noCompApplied &&
                    filters.comparedPeriod !== 'No comparison'
                        ? filters.comparedPeriod === 'Previous period'
                            ? 'Prev. Period'
                            : filters.comparedPeriod === 'Previous year'
                                ? 'Prev. Year'
                                : ''
                        : ''}
                    {!noCompApplied &&
                    filters.comparedPeriod === 'No comparison'
                        ? prevComp === 'Previous period'
                            ? 'Prev. Period'
                            : prevComp === 'Previous year'
                                ? 'Prev. Year'
                                : ''
                        : ''}
                    {noCompApplied
                        ? prevComp === 'Previous period'
                            ? 'Prev. Period'
                            : prevComp === 'Previous year'
                                ? 'Prev. Year'
                                : ''
                        : ''}
                </div>
            </div>
            {!noCompApplied && (
                <div className="card__column">
                    <div className="card__value">
                        {calcPercentWithChart(
                            dataCurrentTotals ?? 0,
                            dataComparedTotals ?? 0
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}