import { iconI } from "./types";

export const LendersIcon = ({ fill, style }: iconI) => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7345 9.38759H5.26553C5.19749 9.38759 5.17513 9.29632 5.23547 9.26487L11.4699 6.01567C11.4888 6.00585 11.5112 6.00585 11.5301 6.01567L17.7645 9.26487C17.8249 9.29632 17.8025 9.38759 17.7345 9.38759Z" stroke={fill} stroke-width="1.2"/>
    <path d="M5 18.0625L11.5 18.0625L18 18.0625" stroke={fill} strokeWidth="1.2" strokeLinecap="round"/>
    <path d="M15.2129 11.7266L15.2129 15.155" stroke={fill} strokeWidth="1.2" strokeLinecap="round"/>
    <path d="M8 11.7266L8 15.155" stroke={fill} strokeWidth="1.2" strokeLinecap="round"/>
    <path d="M11.6582 11.7266L11.6582 15.155" stroke={fill} strokeWidth="1.2" strokeLinecap="round"/>
  </svg>
  );
};
